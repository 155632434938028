.gnav-by-collection-item {
  display: table;
  text-decoration: none;
  color: $color-black;
  padding: 0 0 5px 40px;
  width: 100%;
  vertical-align: middle;
  @include breakpoint($landscape-up) {
    padding: 0;
  }
  &:hover {
    text-decoration: none;
  }
  &__img {
    display: table-cell;
    vertical-align: middle;
    width: 36px;
    height: auto;
    max-width: none;
    max-height: none;
    margin: 0;
    @include breakpoint($medium-up) {
      width: auto;
    }
  }
  &__copy {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    width: 100%;
  }
  &__desc {
    @include font--heading;
    font-size: 12px;
    color: $color-green;
    margin: 0;
    padding: 0 0 0 16px;
    @include breakpoint($medium-up) {
      padding: 0;
      font-size: 11px;
    }
    @include breakpoint($largest-up) {
      padding: 0 0 0 23px;
      font-size: 12px;
    }
  }
}
