.gnav-find-store-block {
  @include pie-clearfix;
  &--auth {
    display: none;
  }
  &__image {
    display: none;
    float: left;
    border: solid 1px $color-white;
    height: 100%;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  &__content {
    margin: 0 0 0 22px;
    @include breakpoint($medium-up) {
      margin: 0 0 0 315px;
    }
  }
  &__title {
    @include font--heading;
    text-align: center;
    font-size: 25px;
    margin: 0;
    margin-bottom: 5px;
    padding: 25px 0 0;
  }
  &__subtitle {
    @include font--text-alt;
    font-size: 18px;
    color: $color-dark-gray;
    text-align: center;
    margin: 0 0 21px;
  }
  &__fields {
    margin: 0 auto;
    position: relative;
  }
  &__field-wrapper {
    padding: 0 193px 0 0;
  }
  &__field {
    display: block;
    margin-right: 8px;
    width: 100%;
  }
  &__submit {
    display: block;
    position: absolute;
    right: 22px;
    top: 1px;
    min-width: 162px;
  }
  &__use-location {
    @include icon('location');
    display: block;
    clear: right;
    color: $color-black;
    text-align: center;
    margin: 20px 0 10px 0;
    &:before {
      font-size: 18px;
      margin: -1px 11px 0 0;
    }
    &:hover:before {
      text-decoration: none;
    }
    &--active {
      @include icon('location--filled');
    }
    &--loading {
      // @TODO: spin icon?
    }
  }
}
