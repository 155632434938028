.discover {
  @include clearfix;
  max-width: $max-width-large;
  margin: 0 auto;
  text-align: center;
  position: relative;
  padding: 20px 0;
  @include breakpoint($landscape-up) {
    padding: 50px 0;
  }
  &__close {
    // This icons only show on ingredient gallery quick view
    display: none;
    & .icon {
      position: absolute;
      z-index: z('discover', 'close-icon');
      cursor: pointer;
      top: 0;
      right: 0;
      padding: 2px 5px;
      font-size: 15px;
      @include breakpoint($medium-up) {
        padding: 15px 20px;
        font-size: 20px;
      }
    }
  }
  &__title {
    @include h2;
    font-family: $font--heading;
    font-size: 25px;
    color: $color-black;
    text-align: center;
    padding: 0;
    margin: 0;
    position: relative;
    z-index: z('discover', 'title');
    @include breakpoint(480px) {
      font-size: 36px;
    }
    @include breakpoint($medium-up) {
      font-size: 50px;
    }
    @include breakpoint($landscape-up) {
      font-size: 76px;
    }
  }
  &__top {
    position: relative;
  }
  &__image-top {
    position: relative;
    z-index: z('discover', 'bg-img-top');
    margin-top: -30px;
    overflow: hidden;
    @include breakpoint(480px) {
      margin-top: -40px;
    }
    @include breakpoint($medium-up) {
      margin-top: -50px;
    }
    @include breakpoint($landscape-up) {
      margin-top: -65px;
    }
    @include breakpoint($large-up) {
      margin-top: -85px;
    }
    & .fluid-image {
      width: 150%;
      max-width: 150%;
      position: relative;
      left: -15%;
      @include breakpoint($medium-up) {
        max-width: 135%;
        width: 135%;
        left: -8%;
      }
      @include breakpoint($landscape-up) {
        max-width: 100%;
        width: 100%;
        left: -6%;
      }
      @include breakpoint($large-up) {
        left: 0;
      }
    }
  }
  &__image-left {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
      position: relative;
      z-index: z('discover', 'bg-img-left');
      max-width: 660px;
      max-width: 51.76471%;
      float: left;
    }
  }
  &__label {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: left;
      z-index: z('discover', 'label');
    }
    img {
      max-width: 80%;
      max-height: 80%;
      @include breakpoint($large-up) {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  &__text {
    padding: 20px;
    max-width: 450px;
    margin: 0 auto;
    @include breakpoint($landscape-up) {
      padding: 20px 0;
      position: absolute;
      z-index: z('discover', 'text');
      top: 40%;
      right: 10px;
    }
  }
  &__stamp {
    margin: 0 auto;
    text-align: center;
  }
  &__ingredient {
    @include h3;
    font-family: $font--heading;
    padding: 0;
    margin: 5px 0 10px 0;
    font-size: 20px;
    @include breakpoint($landscape-up) {
      font-size: 36px;
    }
  }
  &__description {
    font-family: $font--text;
    font-size: 18px;
    line-height: 22px;
    color: $color-black;
    margin-bottom: 10px;
    @include breakpoint($landscape-up) {
      padding: 0 20px;
    }
  }
  &__link {
    @include link;
    font-family: $font--cta;
    text-transform: none;
    text-decoration: none;
    color: $color-black;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    display: block;
    padding: 10px 0;
    margin: 0 auto;
    max-width: 250px;
    @include breakpoint($medium-up) {
      max-width: 100%;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__video {
    display: block;
    position: relative;
    margin: 0 5px 0 10px;
    @include breakpoint($landscape-up) {
      margin: 0;
      float: right;
      // max-width: 615px;
      width: 48.04688%;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
/// Discover Additional Ingredients
/// Used in collapsible_block_discover
////////////////////////////////////////////////////////////////////////////////
.discover__additional {
  @include clearfix;
  max-width: $max-width-large;
  margin: 0 auto;
  text-align: center;
  position: relative;
  clear: both;
  display: block;
  padding: 20px 0;
  @include breakpoint($landscape-up) {
    padding: 50px 0;
  }
  &-item {
    max-width: 280px;
    margin: 0 auto 5px auto;
    @include breakpoint($medium-up) {
      max-width: 320px;
    }
    @include breakpoint($landscape-up) {
      width: 33.3333%;
      display: inline-block;
      margin: 0 0 0 -4px;
      max-width: 420px;
      padding: 0 20px;
    }
  }
}
