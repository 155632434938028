$tab-bg-color: $color-dark-gray !default;
$tab-active-bg-color: $color-white !default;
$tab-font-color: $color-white !default;
$tab-active-font-color: #595959 !default;

.responsive-tabs {
  text-align: center;
  .resp-tabs-list {
    display: none;
  }
  .resp-tab-item {
    @include font--subheading;
    font-size: 20px;
    background: $tab-bg-color;
    margin-bottom: 40px;
    padding: 8px 56px;
    border: 1px solid $color-black;
    border-width: 1px 1px 0 1px;
    color: $tab-font-color;
    line-height: normal;
    cursor: pointer;
    &:first-child {
      border-width: 1px 1px 0 1px;
    }
    &.resp-tab-active {
      color: $tab-active-font-color;
      border-bottom: none;
    }
  }
  .resp-accordion {
    @include font--subheading;
    border-bottom: 1px solid $tab-bg-color;
    margin: 0px;
    padding: 10px 15px;
    text-align: center;
    cursor: pointer;
  }
  .resp-tab-content {
    display: none;
    padding: 15px;
  }
  .resp-tab-content-active {
    display: block;
  }
  .resp-tab-active {
    border-bottom: none;
    background-color: $tab-active-bg-color;
  }
  .resp-tabs-container {
    border-top: 1px solid $tab-bg-color;
  }
}

.resp-vtabs {
  .resp-tab-content {
    border: 1px solid $color-silver-gray;
  }
  .resp-tabs-container {
    border: none;
    float: none;
    width: 100%;
    min-height: initial;
    clear: none;
  }
}

.resp-accordion-closed {
  display: none !important;
}
/* -----------Accordion styles----------- */

.resp-easy-accordion {
  h2.resp-accordion {
    display: block;
  }
  .resp-tab-content {
    border: 1px solid $color-silver-gray;
  }
}

.resp-jfit {
  width: 100%;
  margin: 0px;
}

.resp-arrow {
  width: 0;
  height: 0;
  float: right;
  margin-top: 3px;
}

.resp-tab-active span.resp-arrow {
  border: none;
}
/* Here your can change the breakpoint to set the accordion, when screen resolution changed */
@include breakpoint($medium-up) {
  .responsive-tabs {
    .responsive-tabs__list-wrapper {
      border-bottom: 1px solid $tab-bg-color;
      line-height: 0;
    }
    .resp-tabs-list {
      display: block;
      display: inline-block;
      position: relative;
      li {
        display: inline-block;
        position: relative;
        bottom: -1px;
        padding: 12px 15px;
        margin: 0;
        list-style: none;
        float: left;
      }
    }
    .resp-tabs-container {
      padding: 0px;
      clear: left;
    }
    .resp-accordion {
      cursor: pointer;
      padding: 5px;
      display: none;
    }
    .resp-content-active,
    .resp-accordion-active {
      display: block;
    }
  }
}
