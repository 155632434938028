// Part of the styling was initially done under _gnav_email_signup.scss.
// Reference that file as well for any design changes.
.welcome15-overlay {
  #cboxClose {
    color: $color-black;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    background-image: none;
    text-indent: 0;
    right: 4%;
  }
  .icon--close {
    position: relative;
    width: 16px;
    height: 16px;
    color: $color-black;
    font-weight: 900;
  }
}

.welcome-15 {
  width: 100%;
  padding: 40px 20px 10px;
  @include breakpoint($medium-up) {
    width: auto;
    padding: 50px 0 30px;
    display: flex;
    justify-content: center;
  }
  .site-email-signup__success-bg--1 {
    top: 50%;
    @include transform(translateY(-50%));
  }
  .site-email-signup__success-bg--2 {
    bottom: auto;
    top: 50%;
    @include transform(translateY(-50%));
  }
  .site-email-signup__message--error {
    margin-top: 11px;
  }
  .site-email-signup__success-header {
    margin-bottom: 5px;
  }
  .site-email-signup__success--welcome-15 {
    padding: 0 10px;
    @include breakpoint($medium-up) {
      padding: 0 30px;
    }
    .site-email-signup__success-offer {
      width: 320px;
      margin: auto;
      &.site-email-signup__success-offer--line1 {
        max-width: 100%;
      }
    }
  }
  .site-email-signup {
    &__success-header {
      font-size: 70px;
      line-height: 1;
    }
    &__sms-terms {
      max-width: 81%;
      display: none;
    }
    &__terms,
    &__sms-terms {
      font-size: 12px;
      color: $color-cr18-black;
      margin-left: 6%;
      text-align: left;
      line-height: 1.4;
      font-family: $font--futura-medium;
      p > a {
        text-decoration: underline;
        text-transform: none;
      }
    }
  }
  .email_input {
    .form-text,
    .form-submit {
      display: block;
      margin: auto;
    }
    .form-text {
      width: 100%;
      max-width: 280px;
      margin-top: 16px;
      margin-bottom: 15px;
    }
    .form-submit {
      margin-bottom: 15px;
    }
    &-checkbox {
      font-size: 14px;
      margin-bottom: 15px;
      display: block;
    }
  }
}

.email-signup-modified-layout {
  .site-email-signup {
    &__success-header {
      font-family: $font--futura-demi;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 1.4;
    }
    &__success-offer {
      font-size: 30px;
      line-height: 1.2;
      letter-spacing: 0;
      font-style: normal;
      margin: 0 0 0 6%;
    }
    &__success-header,
    &__success-offer {
      text-align: left;
      margin: 0 0 2% 6%;
      text-transform: uppercase;
    }
  }
  .email_input {
    .form-submit {
      margin-left: 6%;
      min-width: 139px;
      height: 41px;
      line-height: 2;
      font-size: 15px;
      letter-spacing: 1px;
      &:hover {
        background-color: $color-green;
      }
    }
    .form-text {
      margin: 16px auto 15px 24px;
      width: 255px;
      height: 30px;
      font-size: 15px;
      letter-spacing: 1px;
      line-height: 1.4;
      font-size: 15px;
      margin-top: 14px;
    }
  }
}

@if $loyalty-redesign == true {
  .welcome-15 {
    padding: 0;
    height: 100%;
    @include breakpoint($medium-up) {
      height: 480px;
    }
    &__banner {
      @include breakpoint($medium-up) {
        width: 50%;
      }
    }
    &__messages__container {
      div {
        margin: 10px auto;
        padding: 10px;
        letter-spacing: 0.5px;
        border-radius: 4px;
        border: 2px solid $color-red;
        color: $color-red;
        background-color: $color-light-red;
        font-size: 16px;
        font-family: $font--futura-medium;
      }
    }
    .site-email-signup__success--welcome-15 {
      padding: 40px;
      @include breakpoint($medium-up) {
        padding: 50px 30px 70px;
        width: 50%;
      }
    }
    .site-email-signup {
      &__success {
        background: $color-overlay-gray;
        .email_input {
          .form-text {
            font-family: $font--futura-medium;
            font-size: 14px;
            margin: 20px 0;
            @include breakpoint($medium-up) {
              margin: 50px auto 30px;
              max-width: 100%;
            }
          }
          .form-submit {
            font-size: 15px;
            font-family: $font--futura-book;
            line-height: 1;
            height: auto;
            padding: 10px 40px;
            text-transform: none;
            border: none;
          }
        }
      }
      &__success-header {
        font-size: 30px;
        @include breakpoint($medium-up) {
          font-size: 35px;
        }
        font-family: $font--futura-book;
      }
    }
  }
  .welcome15-overlay {
    #cboxClose {
      top: 15px;
      right: 15px;
      .icon--close {
        font-size: 20px;
      }
    }
  }
}
