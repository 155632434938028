.gnav-util--search .gnav-util__content {
  width: 100%;
  &__inner {
    border-bottom: solid 2px $color-black;
  }
}

.gnav-search {
  text-align: left;
  padding: 13px 12px 30px;
  min-height: 450px;
  @include breakpoint($medium-up) {
    min-height: 0;
    padding: 20px 22px 40px;
  }
  &--suggestions,
  &--no-suggestions {
    .gnav-search__content {
      display: none;
    }
  }
  &--suggestions {
    .gnav-search__suggestions-wrapper {
      display: block;
      clear: both;
    }
  }
  &--no-suggestions {
    .gnav-search__no-suggestions-message {
      display: block;
    }
  }
  input[type='search'] {
    @include font--text;
    padding: 0;
    font-size: 25px;
    width: 90%;
    height: auto;
    display: block;
    border: none;
    border-bottom: solid 1px $color-black;
    float: left;
    @include input-placeholder {
      font-size: 23px;
      vertical-align: baseline;
    }
    &:focus {
      outline: 0;
    }
    @include breakpoint($medium-up) {
      margin: 20px 0 0;
      @if $cr18 == true {
        font-size: 40px;
      }
      @if $cr18 == false {
        font-size: 40px;
      }
      @include input-placeholder {
        @if $cr18 == true {
          font-size: 40px;
        }
        @if $cr18 == false {
          font-size: 40px;
        }
      }
    }
  }
  &__close {
    float: right;
    padding: 0.25em;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  &__content {
    @include pie-clearfix;
    padding: 40px 0 0;
    display: none;
    clear: both;
    @include breakpoint($medium-up) {
      display: block;
    }
    &__block,
    .menuref {
      float: left;
      width: 33%;
      padding: 0 20px 0 0;
    }
    &__title,
    .menuref__title {
      @include font--subheading;
      font-size: 24px;
      margin: 0;
      &:after {
        content: '';
        display: block;
        width: 35px;
        height: 1px;
        background-color: $color-black;
        margin: 18px 0 14px;
      }
    }
    &__subtitle {
      @include font--text-alt;
      font-size: 18px;
      color: $color-dark-gray;
      margin: 0;
      &:after {
        content: '';
        display: block;
        width: 35px;
        height: 1px;
        background-color: $color-light-gray;
        margin: 10px auto 12px;
      }
    }
    .menu {
      background-color: transparent;
      position: static;
      width: auto;
      padding: 0;
      margin: 0;
      &__item {
        margin: 0;
        padding: 0;
        border: none;
        float: none;
        display: block;
        text-align: left;
      }
      &__link {
        color: #565656;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.01em;
        text-decoration: none;
        font-style: normal;
        display: block;
      }
    }
    &__cta {
      @include icon('arrow--right', after);
      font-size: 13px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      line-height: 1em;
      color: $color-black;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
      &:after {
        font-size: 8px;
        margin: -2px 0 0 8px;
      }
    }
    &__block--find-store {
      text-align: center;
      background-color: #f8f8f8;
      border: solid 1px #d9d9d9;
      padding: 38px 35px 44px;
      .gnav-search__content {
        &__title {
          margin: 0 0 3px;
          &:after {
            content: normal;
          }
        }
      }
    }
  }
  &__suggestions-wrapper,
  &__no-suggestions-message {
    display: none;
  }
  &__suggestions {
    margin: 14px 0 0;
    /*
    &:after {
      content: "";
      display: block;
      width: 35px;
      height: 1px;
      background-color: #d6d6d6;
      margin: 7px 0 12px;
    }
    */
  }
  .typeahead-search__suggestion {
    // @include font--text;
    color: $color-black;
    text-decoration: none;
    display: block;
    text-align: right;
    width: 90%;
    padding-top: 0.25em;
    &:hover {
      text-decoration: none;
    }
  }
  &__see-all-results {
    font-size: 13px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    line-height: 1em;
    color: $color-black;
    text-decoration: none;
  }
  &__no-suggestions-message {
    padding: 100px 22px 0;
    text-align: center;
  }
}
