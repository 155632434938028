.gift-samples {
  position: relative;
  &__btn {
    display: block;
    width: 100%;
    margin: 1em auto;
    &:hover {
    }
    //   background-color: $color-medium-gray;
    // -webkit-box-shadow: 0 0 0 1px $color-medium-gray;
    //   -moz-box-shadow: 0 0 0 1px $color-medium-gray;
    //       box-shadow: 0 0 0 1px $color-medium-gray;

    @include breakpoint($xsmall-up) {
      max-width: 250px;
    }
  }
  &__text {
    @include t6;
    display: block;
    width: 100%;
    margin-bottom: 1.5em;
    color: $color-medium-gray;
    text-align: center;
  }
  &__list {
    @include clearfix;
    width: 100%;
    margin-bottom: 3em;
    border-left: 1px solid $color-black;
    border-bottom: 1px solid $color-black;
    .product-grid {
      &__item {
        .product-brief {
          min-height: 15em;
        }
        .selected {
          background-color: $color-light-gray;
        }
        &:hover {
          cursor: pointer;
          background-color: $color-light-gray;
        }
      }
    }
  } // &__list
} // .gift-samples
