.gift-options {
  position: relative;
  &__gift-ribbon {
    .gift-ribbon {
      &__hdr {
        @include h11;
        margin: 0 0 1em 0;
        text-align: center;
      }
      &__options {
        text-align: center;
        .gift-ribbons-list {
          text-align: center;
          &__item {
            display: block;
            margin-bottom: 2em;
            margin: 0 20px 2em 20px;
            text-align: center;
            &:hover,
            &.selected {
              cursor: pointer;
              .gift-ribbons-list__img {
                border: 1px solid $color-green;
              }
              .gift-ribbons-list__title {
                color: $color-green;
                text-decoration: underline;
              }
            }
            @include breakpoint($xsmall-up) {
              display: inline-block;
            }
          } // &__item

          &__img {
            display: block;
            margin: 0 auto 0.5em auto;
            border: 1px solid $color-white;
          }
          &__title {
            @include t10;
          }
        } // .gift-ribbons-list
      } // &__options
    } // .gift-ribbon
  } // &__gift-ribbon

  &__gift-image {
    clear: both;
    width: 100%;
    padding: 1em 0;
    border-bottom: 1px solid $color-light-gray;
    img {
      margin: 0 auto;
      text-align: center;
      display: block;
    }
  } // &__gift-image
} // .gift-options
