.gnav-mini-facials-block {
  &__image {
    display: none;
    float: left;
    border: solid 1px $color-white;
    height: 255px;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  &__content {
    @include pie-clearfix;
    margin: 0 25px;
    @include breakpoint($medium-up) {
      margin: 0 36px 0 314px;
    }
  }
  &__title {
    @include font--heading;
    text-align: center;
    font-size: 25px;
    margin: 0;
    margin-bottom: 10px;
    padding-top: 25px;
  }
  &__subtitle {
    @include font--text;
    font-size: 18px;
    color: $color-dark-gray;
    font-style: italic;
    text-align: center;
    margin: -5px 0 29px;
    font-weight: normal;
  }
  &__copy {
    @include font--text;
    font-size: 16px;
    color: #565656;
    margin: 0 205px 0 0;
    padding: 0;
  }
  &__learn-more {
    color: $color-black;
  }
  &__cta {
    float: right;
    width: 160px;
  }
}
