// Utility Nav Mobile is the Gnav secondary
.utility-nav-mob {
  @if is_2020_nav_redesign() {
    display: flex;
    flex: 1;
    width: 86%;
    padding: 13px 0;
    justify-content: space-around;
    @include breakpoint($landscape-up) {
      width: auto;
      justify-content: space-evenly;
    }
  } @else {
    padding-top: 13px;
    display: inline-block;
    width: 83%;
  }
  @include breakpoint($landscape-up) {
    display: none;
  }
  &__item {
    display: inline-block;
    font-family: $font--futura-book;
    text-align: center;
    width: 23%;
    @if is_2020_nav_redesign() {
      @include breakpoint($ipad-below-landscape) {
        width: auto;
      }
    }
    a {
      font-size: 14px;
      @include breakpoint($xsmall-up) {
        font-size: 15px;
      }
      text-decoration: none;
    }
  }
}
