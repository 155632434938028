// By default LBO styles are not included for all locales
$enable_lbo: false !default;
$color-lighter-green-lbo: #f0f8f2;

.lbo__container--ref {
  display: none;
}

// If LBO is enabled then apply the corresponding styles
@if $enable_lbo == true {
  .lbo {
    color: $color-black;
    &__container--message,
    &__container {
      background-color: $color-lighter-green-lbo;
      padding-bottom: 16px;
      display: block;
      position: relative;
      border-bottom: solid 1px $color-light-gray;
    }
    &__container--message {
      margin-bottom: 1em;
      border-top: solid 1px $color-light-gray;
      color: $color-black;
      text-align: center;
      @include breakpoint($largest-up) {
        margin: 1em;
        border: solid 1px $color-light-gray;
      }
    }
    &__container--header-marker,
    &__header--marker {
      font-weight: normal;
      font-size: $base-font-size;
      line-height: 15px;
      letter-spacing: 0.2em;
      text-align: center;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      margin: 20px auto 10px;
      padding: 0 33px;
      width: 100%;
      &:before {
        content: '';
        background-color: $color-black;
        width: 100%;
        height: 1px;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
      }
      @include breakpoint($largest-up) {
        margin-top: 20px;
        margin-bottom: 18px;
      }
    }
    &__header {
      display: block;
    }
    &__container--header-marker .heading,
    &__header--text {
      background-color: $color-lighter-green-lbo;
      display: inline-block;
      position: relative;
      padding: 0 10px 0 12px;
      font-family: $font--heading--secondary;
      font-size: $base-font-size;
      text-transform: uppercase;
    }
    &__message,
    &__container--message .lbo-msg {
      display: block;
      margin: 0 10px;
      text-align: center;
      h1 {
        font-size: 24px;
        font-family: $font--text;
        margin: 0 0 14px;
        font-weight: normal;
        padding: 0;
      }
      h2 {
        font-size: $base-font-size;
        font-family: $font--text;
        font-weight: normal;
        margin: 0;
        padding: 0;
      }
    }
    &__message--cta {
      text-align: center;
      font-size: 24px;
      margin-top: 10px;
      @include breakpoint($largest-up) {
        display: inline;
      }
      a {
        text-decoration: none;
        border-bottom: solid 1px $color-black;
        cursor: pointer;
        display: inline-block;
      }
    }
    &__banner--close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 14px;
      cursor: pointer;
      @include breakpoint($largest-up) {
        top: 22px;
        right: 20px;
      }
    }
    &__banner--icon-close {
      font-weight: bold;
      vertical-align: top;
    }
    @include breakpoint($largest-up) {
      &__container {
        border-bottom: 0;
      }
      &__banner {
        position: relative;
        max-width: $max-width-large;
        padding: 0 20px;
        margin: auto;
      }
      &__content--message {
        margin-right: 24px;
        .lbo {
          &__message--content {
            display: inline;
          }
          &-msg {
            display: inline-block;
            h1 {
              display: inline-block;
              margin-bottom: 0;
            }
            h2 {
              display: inline;
            }
          }
        }
      }
    }
  }
  .single-message {
    .lbo-msg {
      display: none;
    }
    h1,
    h2 {
      font-size: inherit;
      font-weight: inherit;
      margin: 0;
    }
  }
}
