.egiftWrapper {
  padding: 0;
  @include breakpoint($medium-up) {
    padding: 0 20px;
  }
}

.giftcards-nav {
  &__header {
    line-height: 1;
    font-size: 50px;
    margin: 0.3em 0;
    @include breakpoint($medium-up) {
      line-height: 1.143;
      font-size: 63px;
    }
  }
  &__tabs {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    @include breakpoint($medium-up) {
      left: 1em;
      right: 1em;
    }
  }
}

.giftcards-nav__link {
  font-size: 12px;
  @include breakpoint($medium-up) {
    font-size: 18px;
  }
}

.egiftcard-retrieve {
  width: 100%;
  &__panel {
    padding: 68px 20px;
    @include breakpoint($medium-up) {
      padding: 68px 20px;
      padding-left: 200px;
    }
  }
  &__panel--form {
    float: left;
    padding-right: 21px;
    margin-bottom: 60px;
    @include breakpoint($medium-up) {
      width: 60%;
    }
  }
  &__panel--form .buttonMargin {
    margin-bottom: 18px;
    padding: 0px 59px;
    @include breakpoint($medium-up) {
      padding: 0px 58px;
    }
  }
  &__panel--form .retrieve-form--title {
    text-transform: uppercase;
    font-family: $font--subheading;
    font-weight: normal;
    font-size: 32px;
  }
}

.giftcard-retrieve {
  &__messageSection {
    margin-top: 15px;
    font-size: 20px;
    @include breakpoint($medium-up) {
      width: 50%;
      float: left;
    }
  }
  &__messageOne {
    font-family: $font--subheading--secondary;
  }
  &__messageTwo {
    background-color: #f5f5f3;
    padding: 20px;
    margin-bottom: 20px;
    div span:nth-child(1) {
      font-family: $font--subheading--secondary;
      font-size: 18px;
    }
  }
  &__messageTwo div:nth-child(1) {
    padding-bottom: 10px;
  }
  &__messageTwo div span:nth-child(2) {
    word-wrap: break-word;
    font-weight: normal;
    font-family: $font--subheading;
    font-size: 24px;
    text-transform: uppercase;
  }
  &__topsection {
    margin-top: 60px;
    margin-left: 0px;
    padding: 0 20px;
    @include breakpoint($medium-up) {
      margin-left: 200px;
    }
  }
  &__content {
    .info_container {
      padding: 20px;
      background: url('/media/images/giftcards/bg_splash_b.jpg') right no-repeat;
      border-top: 1px solid $color-light-gray;
      box-shadow: 0 -6px 4px -5px $color-light-gray;
      h1 {
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        font-size: 20px;
        font-family: $font--heading;
        @include breakpoint($medium-up) {
          font-size: 36px;
        }
      }
    }
    .egift_data_wrapper {
      margin: 0 auto;
      @include breakpoint($medium-up) {
        width: 400px;
      }
      .store-card__barcode {
        .redeemBarcode {
          width: 160px;
          height: 104px;
          margin: 0 auto;
        }
      }
    }
    .shopping_btn_container {
      margin: 0 auto;
      @include breakpoint($medium-up) {
        width: 604px;
      }
    }
    .gifcard-retreive_egiftNumber,
    .gifcard-retreive_egiftPin,
    .store-card__barcode barcode {
      padding-bottom: 20px;
    }
    .gifcard-retreive_egiftNumber span:nth-child(2),
    .gifcard-retreive_egiftPin span:nth-child(2) {
      font-family: $font--futura-medium;
    }
    .gifcard-retreive_egiftNumber,
    .gifcard-retreive_egiftPin {
      margin-left: 0px;
      @include breakpoint($medium-up) {
        margin-left: 50px;
        text-align: left;
      }
    }
    .info_container p {
      font-weight: normal;
      text-align: center;
      font-size: 18px;
      margin-left: 33px;
    }
    .egift_buttons_info {
      margin-left: 38px;
      @include breakpoint($medium-up) {
        margin-left: 0px;
        margin-right: 27px;
      }
      h3 {
        font-size: 16px;
        font-family: $font--subheading--secondary;
        text-align: left;
        margin-left: 5px;
      }
      ol li,
      p {
        text-align: left;
        font-size: 16px;
        font-family: $font--subheading--secondary;
        list-style: decimal;
        padding: 2px;
        margin: 2px;
      }
      ol {
        margin-left: 20px;
      }
      .egift_ContentOne {
        float: none;
        @include breakpoint($medium-up) {
          float: left;
        }
      }
      .egift_ContentTwo {
        float: none;
        @include breakpoint($medium-up) {
          float: right;
        }
        p a {
          margin: 0;
          padding: 0;
          display: inline;
        }
      }
    }
    .giftcardDetails {
      margin: 0 auto;
      text-align: center;
      @include breakpoint($medium-up) {
        width: 800px;
      }
    }
    .shopping_btn_container a {
      line-height: 35px;
      margin: 0 28px 15px;
      display: block;
      @include breakpoint($medium-up) {
        margin: 0 15px 10px 0;
        padding: 0 90px;
        display: inline-block;
      }
    }
    .shopping_btn_container .buttonOnline {
      margin-right: 15px;
    }
    .egiftcard_aboutInfo {
      text-align: center;
      @include breakpoint($medium-up) {
        width: 800px;
        margin: 0 auto;
      }
    }
    .giftcardHeader {
      text-align: center;
    }
    #information_center {
      background: url('/media/images/giftcards/bg_splash_g.jpg') left no-repeat;
      border-top: 1px solid $color-light-gray;
      box-shadow: 0 -6px 4px -5px $color-light-gray;
      padding: 25px;
      .mainAboutInfo h2 {
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        font-size: 20px;
        font-family: $font--heading;
        @include breakpoint($medium-up) {
          font-size: 36px;
        }
      }
      .giftcard_info {
        text-align: left;
        padding-bottom: 10px;
        @include breakpoint($medium-up) {
          margin-left: 80px;
        }
      }
      .giftcardBalance {
        color: $color-mid-gray;
        text-transform: uppercase;
        text-decoration: none;
        font-family: $font--futura-book;
      }
      .giftcardBalance:hover {
        color: $color-mid-gray;
        text-decoration: none;
      }
    }
  }
}

.egiftcard-print {
  text-align: center;
  @include breakpoint($medium-up) {
    margin: 0 auto;
    width: 700px;
  }
  .print__logo {
    color: $color-black;
    text-decoration: none;
    font-size: 46.09px;
    margin-top: 5px;
  }
  &__value {
    font-weight: normal;
    font-family: $font--futura-book;
    color: $color-black;
    font-size: 18px;
  }
  .print_icon_logo,
  .egiftcard_values {
    margin-bottom: 20px;
  }
  .egiftcard__number,
  .egiftcard_pin {
    p {
      margin: 0;
      padding: 0;
      &:nth-child(1) {
        font-family: $font--subheading;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: normal;
      }
    }
  }
  .resetClass {
    margin: 0px;
    padding: 5px 0 0 0;
    font-family: $font--subheading--secondary;
  }
  .store-card__barcode {
    padding: 20px;
  }
  .barcodeValue {
    overflow: hidden !important;
    margin: 0 auto;
  }
  &__amountInfo {
    background-color: $color-light-gray;
    -webkit-print-color-adjust: exact;
    padding: 25px;
    text-align: left;
    margin: 0 20px 0 20px;
    @include breakpoint($medium-up) {
      width: 400px;
      margin: 0 auto;
    }
    div {
      &:nth-child(1) {
        padding-bottom: 0px;
      }
      span {
        &:nth-child(1) {
          font-family: $font--subheading--secondary;
          font-size: 18px;
        }
      }
      span {
        &:nth-child(2) {
          word-wrap: break-word;
          font-weight: normal;
          text-transform: uppercase;
          font-family: $font--subheading;
          font-size: 24px;
        }
      }
    }
  }
  @media print {
    &__amountInfo {
      width: 300px;
      margin: 0 auto;
    }
  }
  &__messageInformation {
    padding: 25px;
    border-top: 2px solid $color-light-gray;
    font-size: 18px;
    font-family: $font--subheading--secondary;
    font-weight: normal;
  }
  &__instructions {
    text-align: left;
    margin-top: 25px;
    border-top: 2px solid $color-light-gray;
    padding: 20px 30px;
    font-family: $font--subheading--secondary;
  }
  @media print {
    &__instructions {
      font-size: 16px;
    }
  }
}

#form--giftcard_retrieve--field--EMAIL_ADDRESS {
  width: 302px;
  height: 43px;
  margin-bottom: 17px;
}

.giftcards-content {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: $giftcards-margin 1em;
  @include breakpoint($small-up) {
    margin: 5em auto;
  }
  &__pgiftcard-image,
  &__egiftcard-image {
    width: 100%;
    max-width: 478px;
    margin: 0 auto $giftcards-margin auto;
    @include breakpoint($medium-up) {
      float: left;
      width: 30%;
      padding: 20px;
      margin-right: 48px;
    }
  }
  &__inner-wrap {
    @include breakpoint($medium-up) {
      float: right;
      width: 46.875%;
    }
  }
  &__description {
    &-header {
      @include h11;
      margin-bottom: 1em;
    }
    &-text {
      color: $color-mid-gray;
      margin-bottom: $giftcards-margin;
    }
  } // &__description

  &__errors {
    margin-bottom: $giftcards-margin;
    color: $color-green;
  }
  &__question {
    @include h12;
    position: relative;
    padding: 1.1428571429em 0; // value * 21px = 24px
    border-bottom: 1px solid $color-light-gray;
    @include breakpoint($small-up) {
      padding: 1.1428571429em;
    }
    &--first {
      border-top: 1px solid $color-light-gray;
    }
    &:hover {
      cursor: pointer;
    }
    span.icon {
      position: absolute;
      right: 0;
      margin-top: 0.1666666667em;
      font-size: 0.6666666667em;
      @include breakpoint($small-up) {
        right: 1.7142857143em; // value * 14px = 24px
      }
    }
  } // &__question

  &__answer {
    display: none;
    padding: 1.3333333333em 0;
    color: $color-mid-gray;
    border-bottom: 1px solid $color-light-gray;
    @include breakpoint($small-up) {
      padding: 1.3333333333em;
    }
    &.active {
      display: block;
    }
  }
} // .giftcards-content
