///
/// Careers service internal page
///

.careers-grid {
  margin: -1em;
}

.careers-grid__item {
  padding: 1em;
  position: relative;
  @include breakpoint($medium-up) {
    border: solid 1px $color-dark-gray;
    border-left: none;
    border-top: none;
    float: left;
    width: 50%;
  }
}

.careers-grid__item-content {
  margin-bottom: 60px;
  h4 {
    @include h10;
    margin-top: 0;
  }
}

.careers-grid__item-cta {
  position: absolute;
  bottom: 1em;
  left: 1em;
}

///
/// Careers menu
///
.careers-page__navigation {
}
