.sticky-footer-mobile {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $color-cr18-black;
  padding: 13px 0;
  z-index: 20;
  color: $color-white;
  height: 40px;
  a {
    font-size: 14px;
    text-transform: uppercase;
  }
  &__wrapper {
    .sticky-footer-mobile__item {
      text-align: center;
      display: inline-block;
      float: left;
      font-size: 14px;
      height: 16px;
      line-height: 16px;
    }
    .sticky-footer-mobile__item--1 {
      width: 100%;
      float: left;
    }
    &--two-items {
      .sticky-footer-mobile__item {
        width: 50%;
      }
      .sticky-footer-mobile__item--2 {
        border-left: solid 1px $color-white;
      }
    }
    &--three-items {
      .sticky-footer-mobile__item {
        width: 33.33%;
      }
      .sticky-footer-mobile__item--3 {
        border-left: solid 1px $color-white;
      }
      .sticky-footer-mobile__item--1,
      .sticky-footer-mobile__item--2,
      .sticky-footer-mobile__item--3 {
        font-size: 14px;
        a {
          font-size: 14px;
        }
      }
    }
    #footer_sticky_chat {
      margin-top: -1px;
    }
  }
  .confirm &,
  .samples &,
  .wp_samples &,
  .index & {
    display: none;
  }
}
