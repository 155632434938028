/// INCLUDES styles for the following:
/// HERO LARGE
/// HERO IMAGE LARGE
/// hero-carousel-formatter
/// HERO IMAGE SMALL

///////////////////////////////
/// HERO LARGE
/// bg image dictates container height
/// used primarily on Special Offers
///////////////////////////////
.hero-large {
  height: auto;
  position: relative;
  width: 100%;
  &.clickable-processed {
    cursor: pointer;
  }
  .hero-large--color-light {
    background-color: $color-black;
  }
  @for $i from 1 through 20 {
    &--padding-#{$i} {
      padding: 0px + $i;
    }
  }
  &__inner {
    position: relative;
    width: 100%;
    @include breakpoint($landscape-up) {
      position: absolute;
      z-index: 3;
      top: 20px;
      bottom: auto;
      &.hero-large--cta-only {
        width: 100%;
      }
      // content widths
      &.hero-large__inner-width--20 {
        width: 20%;
      }
      &.hero-large__inner-width--30 {
        width: 30%;
      }
      &.hero-large__inner-width--40 {
        width: 40%;
      }
      &.hero-large__inner-width--50 {
        width: 50%;
      }
      &.hero-large__inner-width--60 {
        width: 60%;
      }
      &.hero-large__inner-width--70 {
        width: 70%;
      }
      &.hero-large__inner-width--80 {
        width: 80%;
      }
      &.hero-large__inner-width--90 {
        width: 90%;
      }

      // horizontal position
      &-hpos--center {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
      &-hpos--left {
        left: 0;
        // margin details
        &.hero-large__inner-margin--50 {
          left: 50%;
        }
        &.hero-large__inner-margin--40 {
          left: 40%;
        }
        &.hero-large__inner-margin--30 {
          left: 30%;
        }
        &.hero-large__inner-margin--25 {
          left: 25%;
        }
        &.hero-large__inner-margin--20 {
          left: 20%;
        }
        &.hero-large__inner-margin--10 {
          left: 10%;
        }
      }
      &-hpos--right {
        right: 0;
        // margin details
        &.hero-large__inner-margin--50 {
          right: 50%;
        }
        &.hero-large__inner-margin--40 {
          right: 40%;
        }
        &.hero-large__inner-margin--30 {
          right: 30%;
        }
        &.hero-large__inner-margin--20 {
          right: 20%;
        }
        &.hero-large__inner-margin--10 {
          right: 10%;
        }
      }
      // vertical position
      &-vpos--middle {
        height: 100%;
        top: 0;
        .hero-large__content {
          @include vertical-horizontal-center;
        }
      }
      &-vpos--top {
        top: 20px;
      }
      &-vpos--top-80 {
        top: 80px;
      }
      &-vpos--bottom {
        position: absolute;
        bottom: 20px;
      }
      &-banner-vpos--10 {
        top: 10%;
      }
      &-banner-vpos--20 {
        top: 20%;
      }
      &-banner-vpos--30 {
        top: 30%;
      }
      &-banner-vpos--40 {
        top: 40%;
      }
      &-banner-vpos--50 {
        top: 50%;
      }
      &-banner-vpos--60 {
        top: 60%;
      }
      &-banner-vpos--70 {
        top: 70%;
      }
      &-banner-vpos--80 {
        top: 80%;
      }
      &-banner-vpos--90 {
        top: 90%;
      }
      &-banner-vpos--100 {
        top: 100%;
      }
    }
  }
  &__content {
    padding: 10px;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
  }
  &__text {
    margin-bottom: 10px;
    .hero-large--color-light & {
      color: $color-white;
    }
    p {
      margin: 0;
    }
  }
  &__separator:after {
    content: '';
    display: block;
    width: 35px;
    height: 1px;
    background-color: $color-black;
    margin: 12px 0 12px;
    .align-center & {
      margin: 12px auto 12px;
    }
    @include breakpoint($landscape-up) {
      margin: 12px 0 22px;
      .align-center & {
        margin: 12px auto 22px;
      }
    }
    .hero-large--color-light & {
      background-color: $color-white;
    }
  }
  &__separator_1 {
    @for $i from 1 through 20 {
      &__thickness_color--green-#{$i} {
        border-bottom: (#{$i}px) solid $color-green;
      }
    }
    @for $j from 1 through 20 {
      &__margin_bottom--#{$j} {
        margin-bottom: (#{$j}px);
      }
    }
    &__length--10 {
      width: 10%;
    }
    &__length--11 {
      width: 11%;
    }
    &__length--12 {
      width: 12%;
    }
    &__length--13 {
      width: 13%;
    }
    &__length--14 {
      width: 14%;
    }
    &__length--15 {
      width: 15%;
    }
    &__length--16 {
      width: 16%;
    }
    &__length--17 {
      width: 17%;
    }
    &__length--18 {
      width: 18%;
    }
    &__length--19 {
      width: 19%;
    }
    &__length--20 {
      width: 20%;
    }
    &__margin_auto {
      margin: auto;
    }
  }
  &__cta-button--second {
    margin-left: 10px;
  }
  &__image,
  &__image img {
    width: 100%;
  }
  &__image img {
    float: left;
  }
}
// special alignments for cta only
.hero-large__cta-wrapper {
  position: absolute;
  width: 100%;
  height: 42px;
  .hero-large__inner-vpos--bottom & {
    bottom: 20px;
  }
}

///////////////////////////////
/// HERO IMAGE LARGE
/// bg image sets container height
/// used primarily on homepage
///////////////////////////////

// header 26
// sub 22px
// extra line 18
// hero-image-large--color-dark (dark overlay light text)
// hero-image-large__content--has-extra

.hero-image-large {
  cursor: pointer;
  background-color: $color-black;
  color: $color-black;
  position: relative;
  overflow: hidden;
  &.hero-image-large--color-dark {
    background-color: $color-white;
    color: $color-white;
  }
  &__link {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  &__bg-img {
    @include transition(opacity, 200ms);
    width: 100%;
    height: auto;
    opacity: 1;
    float: left;
    img {
      width: 100%;
      float: left;
    }
  }
  &:hover {
    .hero-image-large__bg-img {
      opacity: 0.7;
    }
  }
  &.hero-image-large--has-extra:hover {
    .hero-image-large__content {
      bottom: 0;
    }
  }
  &__content {
    background-color: $color-white;
    width: 100%;
    height: auto;
    padding: 10px;
    clear: both;
    position: relative;
    .hero-image-large--color-dark & {
      background-color: $color-black;
      color: $color-white;
    }
    .hero-image-large--color-dark.hero-image-small--overlay &,
    .hero-image-large--color-light.hero-image-small--overlay & {
      @include transition(position, 200ms);
      background-color: $transparent--black;
      position: absolute;
      z-index: 2;
    }
    .hero-image-large--color-light.hero-image-small--overlay & {
      background-color: $transparent--white;
    }
    @include breakpoint($landscape-up) {
      @include transition(position, 200ms);
      bottom: 0;
      clear: none;
      position: absolute;
      background-color: $transparent--white;
      z-index: 2;
      .hero-image-large--color-dark & {
        background-color: $transparent--black;
      }
      .hero-image-large--has-extra & {
        bottom: -40px;
      }
    }
  }
  &__content-inner {
    opacity: 1;
  }
  // header text
  &__header {
    font-family: $font--heading;
    margin: 0;
    font-size: 26px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.1;
    @include breakpoint($large-up) {
      font-size: 32px;
    }
    &.font--adobe-caslon {
      font-family: $font--text;
    }
    &.font--franklin {
      @include font--subheading;
      // font-family: $font--subheading;
    }
  }
  // sub header text
  &__subheader {
    font-family: $font--text;
    margin: 0;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 1.2;
    @include breakpoint($largest-up) {
      font-size: 22px;
    }
    &.font--copperplate {
      font-family: $font--heading;
    }
    &.font--franklin {
      @include font--subheading;
      // font-family: $font--subheading;
    }
  }
  // extra text
  &__content-extra {
    @include font--subheading;
    // font-family: $font--subheading;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: normal;
    @include breakpoint($xsmall-up) {
      font-size: 22px;
    }
    @include breakpoint($largest-up) {
      height: 40px;
      line-height: 40px;
      font-size: 26px;
    }
    &.font--copperplate {
      font-family: $font--heading;
    }
    &.font--adobe-caslon {
      font-family: $font--text;
    }
  }
  &__counter {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 20px;
    color: $color-black;
    &.hero-image-large__counter--color-light {
      color: $color-white;
    }
  }
}

// specific positioning for youch screens
.touchevents .hero-image-large--has-extra .hero-image-large__content {
  bottom: 0;
  .hero-image-large__content-extra {
    height: 22px;
    line-height: 22px;
    font-size: 22px;
  }
}

///////////////////////////////
// hero-carousel-formatter
///////////////////////////////
.hero-carousel-formatter {
  position: relative;
  .carousel-dots {
    position: absolute;
    bottom: 5px;
  }
  @include breakpoint($landscape-up) {
    .slick-dots {
      margin-top: -40px;
      padding-bottom: 18px;
      position: absolute;
    }
  }
  .carousel-controls .slick-arrow {
    background-color: transparent;
    background-image: url('/media/export/cms/global/carousel.arrows.png');
    background-repeat: no-repeat;
    height: 55px;
    margin: -27px 0 0 0;
    position: absolute;
    text-indent: -9999px;
    top: 50%;
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
    width: 27px;
    &.slick-prev {
      left: 20px;
    }
    &.slick-next {
      background-position: right 10px;
      right: 20px;
    }
  }
}
.hero-carousel {
  .hero-carousel__slide:nth-child(n+2) {
    display: none;
  }
  &.slick-slider .hero-carousel__slide:nth-child(n+2) {
    display: initial;
  }
}

///////////////////////////////
/// HERO IMAGE SMALL
/// bg image sets container height
/// may be deprecated for responsive large version
///////////////////////////////

.hero-image-small {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &.hero-image-small--color-dark {
    color: $color-white;
  }
  &__link {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  &__bg-img {
    width: 100%;
    height: auto;
  }
  &__image {
    width: 100%;
    float: left;
  }
  &__content {
    background-color: $color-white;
    color: $color-black;
    width: 100%;
    padding: 10px;
    float: left;
    .hero-image-small--overlay & {
      position: absolute;
      bottom: 0;
      height: auto;
      z-index: 2;
    }
    .hero-image-small--color-dark & {
      background-color: $color-black;
      color: $color-white;
    }
    .hero-image-small--color-dark.hero-image-small--overlay & {
      background-color: $transparent--black;
    }
    .hero-image-small--color-light.hero-image-small--overlay & {
      background-color: $transparent--white;
    }
  }
  &__content-inner {
    opacity: 1;
  }
  // header text
  &__header {
    font-family: $font--heading;
    margin: 0;
    font-size: 32px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1;
  }
  // sub header text
  &__subheader {
    font-family: $font--text;
    margin: 0;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 1;
  }
  // cta
  &__cta {
    @include font--subheading;
    // font-family: $font--subheading;
    display: inline-block;
    font-size: 26px;
    font-weight: normal;
    text-decoration: underline;
  }
}

///////////////////////////////
// hero-carousel-with-text-formatter
///////////////////////////////

.hero-carousel-text-formatter {
  position: relative;
  .hero-carousel {
    .basic-tout__inner--white {
      @include breakpoint($medium-up) {
        min-height: 286px;
      }
    }
  }
  .slick-dots {
    max-width: $max-width-large;
    margin: 0 auto;
    border-bottom: 5px solid $color-green;
    padding: 2px 0;
    margin-bottom: 2px;
    li {
      width: auto;
      height: auto;
      margin: 0 30px;
      font-size: 28px;
      color: $color-light-gray;
      @include font--subheading;
      letter-spacing: 0.03em;
      &.slick-active {
        color: $color-drak-black;
      }
      @include breakpoint($xsmall-down) {
        margin: 0 10px;
      }
    }
  }
  .carousel-controls .slick-arrow {
    background-color: transparent;
    background-image: url('/media/export/cms/global/carousel.arrows.png');
    background-repeat: no-repeat;
    height: 55px;
    margin: -27px 0 0 0;
    position: absolute;
    text-indent: -9999px;
    top: 50%;
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
    width: 27px;
    &.slick-prev {
      left: 20px;
    }
    &.slick-next {
      background-position: right 10px;
      right: 20px;
    }
  }
}

.section-our-values {
  &-our-formulas {
    .bold-text {
      font-family: $font--futura-medium;
      @include breakpoint($small-down) {
        color: $color-drak-black;
      }
    }
    .careers-page__content-item {
      @include breakpoint($medium-up) {
        padding-right: 10px;
      }
      @include breakpoint($large-up) {
        padding-right: 0;
      }
    }
    .hero-large__inner-width--60 {
      width: 64%;
      line-height: 3em;
      @include breakpoint($medium-up) {
        width: 75%;
      }
      @include breakpoint($landscape-up) {
        width: 64%;
      }
    }
    .hero-large__image {
      padding: 0;
    }
    .careers-page__content-item:nth-child(3) {
      .hero-large__image {
        img {
          padding: 0 20px;
          @include breakpoint($medium-up) {
            padding: 5px;
          }
        }
      }
      .hero-large__inner-width--60 {
        @include breakpoint($medium-up) {
          width: 100%;
        }
      }
    }
    .our-formulas {
      &__heading {
        font-size: 21px;
        position: absolute;
        letter-spacing: 6px;
        margin: 13% 0 0 14%;
        text-transform: uppercase;
        color: $color-light-white;
        @include breakpoint($medium-up) {
          line-height: 32px;
          margin-left: 20%;
          font-size: 22px;
        }
        @include breakpoint($landscape-up) {
          line-height: 40px;
          margin-left: 1%;
          letter-spacing: 7px;
          font-size: 27px;
        }
        @include breakpoint($large-up) {
          margin: 14% 0 0;
          font-size: 36px;
          letter-spacing: 10px;
          line-height: 55px;
        }
      }
      &__first-header {
        .header-content {
          font-size: 16px;
          margin: 2em 2.3em;
          line-height: 22px;
          font-weight: bold;
          text-align: center;
          font-family: $font--text;
          @include breakpoint($medium-up) {
            font-size: 14px;
            line-height: 20px;
          }
          @include breakpoint($landscape-up) {
            font-size: 18px;
            margin: 2em 2em 0.5em 2em;
            line-height: 25px;
            letter-spacing: 0.5px;
          }
        }
        .left-content {
          width: 100%;
          margin: 0;
          font-size: 13px;
          padding: 0 20px;
          float: left;
          line-height: 22px;
          font-family: $font--text;
          letter-spacing: 0.5px;
          @include breakpoint($medium-up) {
            font-size: 12px;
            width: 47%;
            line-height: 15px;
            padding-right: 5px;
          }
          @include breakpoint($landscape-up) {
            width: 42%;
            margin: 35px 0 0 23px;
            padding: 0;
            line-height: 18px;
            font-size: 14px;
          }
          @include breakpoint($large-up) {
            width: 43%;
            font-size: 16px;
            line-height: 22px;
          }
          p {
            text-indent: 20px;
            margin-bottom: 1em;
          }
          .highlight-content {
            color: $color-dark-green;
            font-family: $font--futura-medium;
          }
          .first-content {
            margin-bottom: 1em;
            margin-top: 0;
          }
          .middle-content {
            margin-bottom: 2.5em;
            display: inline-block;
          }
        }
        .our-formulas__image {
          width: 100%;
          margin: 1em 0 2em 0;
          float: right;
          @include breakpoint($medium-up) {
            width: 44%;
            margin: 5px 20px 0 0;
          }
          @include breakpoint($landscape-up) {
            margin: 35px 15px 0 45px;
          }
          @include breakpoint($large-up) {
            margin: 2.5em 1.5em 0 1.2em;
          }
        }
      }
      &__second-header {
        font-size: 18px;
        padding: 0;
        line-height: 25px;
        display: inline-block;
        font-weight: bold;
        font-family: $font--text;
        letter-spacing: normal;
        @include breakpoint($landscape-up) {
          margin: 1.5em 0 2em 5em;
        }
        @include breakpoint($large-up) {
          margin: 1.5em 0 2em 10em;
          letter-spacing: 1px;
        }
        @include breakpoint($portrait-down) {
          margin-left: 19%;
          font-size: 12px;
        }
        @include breakpoint($small-down) {
          padding: 0 20px;
          font-size: 15px;
          line-height: 22px;
          margin: 2em 0 1.5em 0;
        }
      }
      &__third-header {
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        margin-top: 1.5em;
        letter-spacing: 1px;
        font-family: $font--text;
        color: $color-light-white;
        @include breakpoint($portrait-down) {
          position: absolute;
          top: 40px;
          left: 26%;
          font-size: 10px;
          line-height: 16px;
          margin-top: 7%;
          letter-spacing: 0.5px;
        }
        @include breakpoint($small-down) {
          padding: 0 30px;
          margin-top: 2em;
          color: $color-black;
          font-size: 15px;
          line-height: 22px;
          font-weight: bold;
          letter-spacing: normal;
          position: static;
        }
      }
      &__fourth-header {
        padding: 0 20px;
        @include breakpoint($landscape-up) {
          padding: 0;
        }
        .main-header {
          font-size: 25px;
          margin: 1.5em 0;
          letter-spacing: normal;
          font-weight: bold;
          text-align: center;
          color: $color-dark-green;
          word-spacing: 1px;
          font-family: $font--text;
          @include breakpoint($medium-up) {
            font-size: 22px;
          }
          @include breakpoint($landscape-up) {
            margin: 1.2em 0 1em 0;
            font-size: 34px;
            letter-spacing: 2px;
          }
        }
        .sub-header {
          margin: 0;
          padding: 0 30px;
          line-height: 22px;
          font-size: 15px;
          text-align: center;
          font-weight: bold;
          font-family: $font--text;
          letter-spacing: normal;
          @include breakpoint($medium-up) {
            font-size: 12px;
            line-height: 18px;
            padding: 0 20px;
          }
          @include breakpoint($landscape-up) {
            font-size: 14px;
            margin: 0 10%;
            padding: 0;
            line-height: 25px;
          }
          @include breakpoint($large-up) {
            font-size: 17px;
            letter-spacing: 1px;
          }
        }
        .sub-header-text {
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          font-family: $font--text;
          @include breakpoint($medium-up) {
            font-size: 12px;
          }
          @include breakpoint($landscape-up) {
            margin-top: 0;
            font-size: 16px;
            line-height: 25px;
          }
        }
        .our-formulas__image {
          width: 100%;
          margin: 1.5em 0 0;
          padding: 0;
          @include breakpoint($medium-up) {
            width: 45%;
            float: left;
            margin: 1.3em 2em 0 0;
          }
          @include breakpoint($landscape-up) {
            float: left;
            width: 48%;
            margin: 2em 2em 0 1.2em;
            padding-bottom: 40px;
          }
        }
        .right-content {
          width: 100%;
          font-size: 13px;
          margin-top: 2em;
          text-indent: 20px;
          line-height: 22px;
          font-family: $font--text;
          letter-spacing: 0.5px;
          @include breakpoint($medium-up) {
            font-size: 12px;
            line-height: 15px;
            float: right;
            width: 47%;
          }
          @include breakpoint($landscape-up) {
            width: 43%;
            font-size: 14px;
            line-height: 18px;
          }
          @include breakpoint($large-up) {
            font-size: 16px;
            line-height: 22px;
          }
          p {
            margin-right: 0;
            text-align: left;
            &.first-content {
              margin-top: 0;
            }
            @include breakpoint($landscape-up) {
              margin: 0.5em 2em 2.5em 0;
              &.first-content {
                margin-bottom: 3em;
              }
            }
          }
        }
      }
    }
    .header-content-over-image {
      text-align: center;
      letter-spacing: 1px;
      font-size: 16px;
      line-height: 15px;
      font-family: $font--text;
      color: $color-light-white;
      position: absolute;
      top: 70px;
      left: 113px;
      @include breakpoint($medium-up) {
        top: 40px;
        left: 37%;
        font-size: 12px;
      }
      @include breakpoint($landscape-up) {
        position: static;
        font-size: 18px;
      }
      @include breakpoint($xxsmall-down) {
        top: 0;
        left: 0;
        margin-top: 18%;
        margin-left: 30%;
      }
    }
    .hero-large {
      &__separator_1__length--20 {
        width: 20%;
        @include breakpoint($medium-up) {
          position: absolute;
          top: 60px;
          left: 37%;
          width: 24%;
        }
        @include breakpoint($landscape-up) {
          top: 23px;
          left: 38%;
        }
        @include breakpoint($large-up) {
          width: 20%;
          left: 40%;
        }
      }
    }
  }
  &-packaging {
    .bold-text {
      font-weight: bold;
      @include breakpoint($small-down) {
        color: $color-drak-black;
      }
    }
    .careers-page__content-item {
      @include breakpoint($medium-up) {
        padding-right: 15px;
      }
      @include breakpoint($large-up) {
        padding-right: 0;
      }
    }
    .packaging {
      &__heading {
        letter-spacing: 10px;
        font-size: 36px;
        margin: 0 0 0 29%;
        text-transform: uppercase;
        font-family: $font--banner;
        color: $color-light-white;
        @include breakpoint($portrait-down) {
          margin: 10% 0 0 26%;
          position: absolute;
          font-size: 22px;
          letter-spacing: 7px;
        }
        @include breakpoint($small-down) {
          font-size: 21px;
          letter-spacing: 7px;
          margin: 20% 0 0 20%;
        }
      }
    }
    .hero-large__image {
      img {
        padding: 0;
        @include breakpoint($landscape-up) {
          padding: 23px;
        }
      }
    }
    .our-values__packaging {
      .header-content {
        margin: 2.5em 3.5em;
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
        text-align: center;
        word-spacing: 1px;
        font-family: $font--text;
        @include breakpoint($medium-up) {
          font-size: 14px;
        }
        @include breakpoint($landscape-up) {
          font-size: 18px;
          margin: 2em 0 3em 0;
          line-height: 25px;
          letter-spacing: 0.5px;
        }
      }
      .left-content {
        width: 100%;
        padding: 0 20px;
        margin: 0;
        font-size: 13px;
        line-height: 22px;
        font-family: $font--text;
        letter-spacing: 0.5px;
        @include breakpoint($medium-up) {
          font-size: 12px;
          width: 50%;
          line-height: 15px;
          float: left;
          padding: 0 20px 0 0;
        }
        @include breakpoint($landscape-up) {
          width: 45%;
          margin-left: 23px;
          padding: 0;
          font-size: 14px;
          line-height: 22px;
        }
        @include breakpoint($large-up) {
          font-size: 16px;
        }
        p {
          text-indent: 20px;
          margin-bottom: 2.5em;
        }
        .first-content {
          margin-top: 0;
          @include breakpoint($medium-up) {
            margin-bottom: 1em;
          }
          @include breakpoint($landscape-up) {
            margin-bottom: 2.5em;
          }
        }
        .middle-content {
          display: inline-block;
          margin-top: 2em;
          @include breakpoint($landscape-up) {
            margin-top: 0.4em;
          }
        }
        .highlight-content {
          color: $color-dark-green;
          font-weight: bold;
        }
      }
      .packaging__image {
        width: 47%;
        margin-left: 1em;
        float: right;
        padding-right: 23px;
        @include breakpoint($portrait-down) {
          width: 44%;
          margin-left: 25px;
          padding: 0;
          float: right;
        }
        @include breakpoint($small-down) {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
  &-manufacturing {
    .bold-text {
      font-weight: bold;
      @include breakpoint($small-down) {
        color: $color-drak-black;
      }
    }
    .careers-page__content-item {
      @include breakpoint($medium-up) {
        padding-right: 20px;
      }
      @include breakpoint($large-up) {
        padding-right: 0;
      }
    }
    .manufacturing {
      &__heading {
        font-size: 21px;
        position: absolute;
        letter-spacing: 6px;
        margin: 20% 0 0 6%;
        text-transform: uppercase;
        color: $color-light-white;
        @include breakpoint($medium-up) {
          margin: 13% 0 0 15%;
          font-size: 22px;
        }
        @include breakpoint($landscape-up) {
          font-size: 27px;
          letter-spacing: 7px;
          margin: 0 0 0 19%;
          position: static;
          bottom: 0px;
        }
        @include breakpoint($large-up) {
          font-size: 36px;
          letter-spacing: 9px;
          margin: 0 0 0 18%;
        }
      }
      &__header-content {
        font-family: $font--text;
        text-align: center;
        line-height: 22px;
        font-weight: bold;
        font-size: 16px;
        margin: 2em;
        @include breakpoint($medium-up) {
          font-size: 14px;
          line-height: 20px;
          margin: 2em 9em;
        }
        @include breakpoint($landscape-up) {
          margin: 3% 23%;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.5px;
        }
        @include breakpoint($large-up) {
          margin: 3% 25%;
        }
      }
      &__banner-content-first,
      &__banner-content-last {
        width: 100%;
        text-align: left;
        text-indent: 20px;
        font-size: 13px;
        letter-spacing: 0.5px;
        margin: 0 auto;
        font-family: $font--text;
        line-height: 22px;
        @include breakpoint($medium-up) {
          position: absolute;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
        }
        @include breakpoint($landscape-up) {
          letter-spacing: 1px;
          font-size: 16px;
          line-height: 25px;
          position: static;
        }
      }
      &__banner-content-first {
        padding: 0 20px;
        @include breakpoint($medium-up) {
          top: 48px;
          left: 120px;
          color: $color-dark-green;
          font-weight: bold;
          padding: 0 0 5px 0;
          width: 55%;
        }
        @include breakpoint($landscape-up) {
          padding: 0;
          width: 58%;
        }
        @include breakpoint($large-up) {
          padding: 0 0 20px 0;
          width: 45%;
        }
        .highlight-content {
          color: $color-dark-green;
          font-weight: bold;
        }
      }
      &__banner-content-last {
        margin: 2.5em 0 2em 0;
        padding: 0 20px;
        color: $color-black;
        @include breakpoint($medium-up) {
          padding: 15px 0 0 0;
          margin: 0 auto;
          color: $color-light-white;
          top: 80px;
          left: 130px;
          width: 55%;
        }
        @include breakpoint($landscape-up) {
          padding: 20px 0 0;
          width: 60%;
        }
        @include breakpoint($large-up) {
          padding: 0 0 15px 0;
          width: 45%;
        }
      }
    }
    .hero-large__image {
      img {
        padding: 0;
        @include breakpoint($landscape-up) {
          padding: 23px;
        }
      }
    }
    .manufacturing__image {
      padding: 0 20px 20px 20px;
      @include breakpoint($landscape-up) {
        padding: 0;
      }
    }
  }
  &-sourcing {
    .bold-text {
      font-weight: bold;
      @include breakpoint($small-down) {
        color: $color-drak-black;
      }
    }
    .careers-page__content-item {
      @include breakpoint($medium-up) {
        padding-right: 20px;
      }
      @include breakpoint($large-up) {
        padding-right: 0;
      }
    }
    .sourcing {
      &__heading {
        text-transform: uppercase;
        font-size: 21px;
        position: absolute;
        letter-spacing: 7px;
        margin: 20% 0 0 23%;
        color: $color-light-white;
        @include breakpoint($medium-up) {
          font-size: 22px;
          margin: 13% 0 0 15%;
        }
        @include breakpoint($landscape-up) {
          font-size: 27px;
          margin: 0 0 0 6%;
          position: static;
          letter-spacing: 7px;
        }
        @include breakpoint($large-up) {
          font-size: 36px;
          margin: 0 0 0 5%;
          letter-spacing: 10px;
        }
      }
    }
    .hero-large__image {
      img {
        padding: 0;
        @include breakpoint($landscape-up) {
          padding: 23px;
        }
      }
    }
    .our-values__sourcing {
      .header-content {
        font-family: $font--text;
        text-align: center;
        margin: 2em 2em 2.5em;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        @include breakpoint($medium-up) {
          font-size: 14px;
        }
        @include breakpoint($landscape-up) {
          margin: 1.7em 0 3.5em 0;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.5px;
        }
      }
      .sourcing__image {
        width: 100%;
        padding: 0;
        margin-bottom: 2em;
        @include breakpoint($medium-up) {
          width: 48%;
          float: left;
        }
        @include breakpoint($landscape-up) {
          padding-left: 23px;
        }
      }
      .right-content {
        width: 100%;
        font-size: 13px;
        margin: 0;
        padding: 0 20px;
        line-height: 22px;
        word-spacing: normal;
        text-indent: 20px;
        font-family: $font--text;
        display: inline-block;
        letter-spacing: 0.5px;
        @include breakpoint($medium-up) {
          width: 50%;
          word-spacing: normal;
          padding-left: 20px;
          margin-left: 2%;
          font-size: 12px;
          line-height: 15px;
        }
        @include breakpoint($landscape-up) {
          width: 49%;
          word-spacing: 1px;
          padding: 0 15px;
          margin-left: 3%;
          font-size: 14px;
          line-height: 22px;
        }
        @include breakpoint($large-up) {
          padding-right: 23px;
          font-size: 16px;
        }
        p {
          text-indent: 20px;
          margin-bottom: 3em;
        }
        .first-content {
          margin-top: 0;
        }
      }
    }
    .careers-page__content-item:nth-child(3) {
      .hero-large__image {
        img {
          padding: 0 20px;
          @include breakpoint($medium-up) {
            padding: 0;
          }
          @include breakpoint($landscape-up) {
            padding: 23px;
          }
        }
      }
    }
    .sourcing-header-content {
      width: 55%;
      position: absolute;
      left: 23%;
      line-height: 25px;
      text-align: center;
      font-size: 16px;
      margin-top: 10%;
      font-weight: bold;
      font-family: $font--text;
      letter-spacing: 0.5px;
      color: $color-light-white;
      span {
        border-bottom: 2px solid $color-light-white;
        display: inline-block;
      }
      @include breakpoint($medium-up) {
        border-bottom: 4px solid $color-light-white;
        font-size: 11px;
        width: 45%;
        margin: 0 0 0 8%;
        line-height: 18px;
        letter-spacing: normal;
      }
      @include breakpoint($landscape-up) {
        font-size: 13px;
        width: 38%;
        margin: 0 auto;
        line-height: 30px;
        padding-top: 15px;
        position: static;
      }
      @include breakpoint($large-up) {
        font-size: 17px;
        line-height: 35px;
        padding-top: 25px;
        letter-spacing: 1px;
        width: 37%;
      }
    }
    .content-over-image {
      padding: 0 20px;
      position: relative;
      @include breakpoint($landscape-up) {
        padding: 0;
      }
      .first-content {
        margin-top: 1em;
        @include breakpoint($landscape-up) {
          margin-top: 0;
        }
      }
      div {
        padding: 20px;
        text-align: center;
        font-family: $font--text;
        @include breakpoint($medium-up) {
          padding: 0;
        }
        img {
          width: 20%;
        }
        .header-content {
          font-size: 22px;
          letter-spacing: 1px;
        }
        .sub-content {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.5px;
        }
      }
    }
    .top-content,
    .bottom-content {
      @include breakpoint($medium-up) {
        margin-top: 3em;
        padding: 0;
        position: absolute;
        left: 0;
      }
      @include breakpoint($landscape-up) {
        position: static;
      }
      .left-content,
      .right-content {
        width: 35%;
        font-family: $font--text;
        letter-spacing: 1px;
        line-height: 22px;
        font-size: 15px;
        @include breakpoint($medium-up) {
          width: 50%;
          padding: 0;
        }
        @include breakpoint($landscape-up) {
          width: 35%;
        }
        .header-content {
          text-align: center;
          font-size: 17px;
          color: $color-light-white;
          @include breakpoint($medium-up) {
            font-size: 13px;
            line-height: 15px;
          }
          @include breakpoint($landscape-up) {
            font-size: 15px;
            line-height: 22px;
          }
          @include breakpoint($large-up) {
            font-size: 17px;
          }
        }
        .sub-content {
          text-align: center;
          font-size: 13px;
          line-height: 22px;
          color: $color-light-white;
          @include breakpoint($medium-up) {
            font-size: 11px;
            line-height: 15px;
            margin-left: 6%;
          }
          @include breakpoint($landscape-up) {
            font-size: 11px;
            line-height: 18px;
          }
          @include breakpoint($large-up) {
            font-size: 13px;
            line-height: 22px;
          }
        }
      }
      .left-content {
        float: left;
        margin-top: 2%;
        @include breakpoint($medium-up) {
          margin-top: 0;
        }
        @include breakpoint($landscape-up) {
          margin-top: 2%;
          margin-left: 8%;
        }
        @include breakpoint($large-up) {
          margin-left: 5%;
        }
      }
      .right-content {
        display: inline-block;
        @include breakpoint($medium-up) {
          margin-left: 0;
        }
        @include breakpoint($landscape-up) {
          margin-left: 5%;
        }
        @include breakpoint($large-up) {
          margin-left: 14%;
        }
      }
    }
    .top-content {
      margin-top: 4em;
      @include breakpoint($medium-up) {
        margin-top: 4.5em;
      }
      @include breakpoint($landscape-up) {
        margin-top: 3em;
      }
      @include breakpoint($large-up) {
        margin-top: 4em;
      }
      .left-content {
        margin-left: 5%;
        @include breakpoint($medium-up) {
          margin-left: 0;
        }
        @include breakpoint($landscape-up) {
          margin-left: 5%;
        }
        .header-content {
          @include breakpoint($medium-up) {
            font-size: 14px;
          }
          @include breakpoint($landscape-up) {
            font-size: 15px;
          }
          @include breakpoint($large-up) {
            font-size: 17px;
          }
        }
        .sub-content {
          margin-left: 13%;
          @include breakpoint($landscape-up) {
            margin-left: 21%;
          }
          @include breakpoint($large-up) {
            margin-left: 13%;
          }
        }
      }
      .right-content {
        margin: 2% 0 0 14%;
        @include breakpoint($medium-up) {
          margin: 0;
        }
        @include breakpoint($landscape-up) {
          margin: 2% 0 0 14%;
        }
        .sub-content {
          padding: 0 10px;
        }
      }
    }
    .bottom-content {
      margin-top: 5em;
      @include breakpoint($medium-up) {
        margin-top: 11em;
      }
      @include breakpoint($landscape-up) {
        margin-top: 4em;
      }
      @include breakpoint($large-up) {
        margin-top: 5em;
      }
      .left-content {
        margin-left: 5%;
        @include breakpoint($medium-up) {
          margin-left: 0;
        }
        @include breakpoint($landscape-up) {
          margin-left: 5%;
        }
        .sub-content {
          margin-left: 10%;
        }
      }
      .right-content {
        margin: 1% 0 0 12%;
        @include breakpoint($medium-up) {
          margin: 0;
        }
        @include breakpoint($landscape-up) {
          margin: 1% 0 0 12%;
        }
        .header-content {
          padding: 0 10%;
        }
        .sub-content {
          padding: 0 5px;
        }
      }
    }
  }
  &-tree-planting {
    .bold-text {
      font-weight: bold;
      @include breakpoint($small-down) {
        color: $color-drak-black;
      }
    }
    .careers-page__content-item {
      @include breakpoint($medium-up) {
        padding-right: 20px;
        .hero-large__inner-width--50 {
          width: 100%;
        }
      }
      @include breakpoint($landscape-up) {
        .hero-large__inner-width--50 {
          width: 65%;
        }
      }
      @include breakpoint($large-up) {
        padding-right: 0;
        .hero-large__inner-width--50 {
          width: 50%;
        }
      }
    }
    .tree-planting {
      &__heading {
        font-size: 21px;
        position: absolute;
        letter-spacing: 7px;
        margin: 20% 0 0 9%;
        text-transform: uppercase;
        color: $color-light-white;
        @include breakpoint($medium-up) {
          margin: 15% 0 0 26%;
          font-size: 22px;
        }
        @include breakpoint($landscape-up) {
          font-size: 27px;
          margin: 0 0 0 21%;
          position: static;
          letter-spacing: 7px;
        }
        @include breakpoint($large-up) {
          font-size: 36px;
          margin: 0 0 0 20%;
          letter-spacing: 10px;
        }
      }
      &__banner-content-first,
      &__banner-content-last {
        text-align: center;
        font-family: $font--text;
        line-height: 25px;
        letter-spacing: 1px;
        font-size: 16px;
        color: $color-light-white;
        @include breakpoint($medium-up) {
          position: absolute;
          top: 75px;
          font-size: 12px;
          left: 18%;
          line-height: 18px;
        }
        @include breakpoint($landscape-up) {
          position: static;
          line-height: 25px;
          font-size: 16px;
        }
      }
      &__banner-content-first {
        margin-bottom: 2em;
        @include breakpoint($medium-up) {
          margin-top: 0;
          width: 65%;
        }
        @include breakpoint($landscape-up) {
          width: 100%;
          margin-top: 1em;
        }
        .highlight-content {
          color: $color-dark-green;
          font-weight: bold;
        }
      }
      &__banner-content-last {
        @include breakpoint($medium-up) {
          margin-top: 14%;
          width: 67%;
        }
        @include breakpoint($landscape-up) {
          width: 100%;
          margin-top: 0.4em;
        }
      }
    }
    .hero-large__image {
      img {
        padding: 0;
        @include breakpoint($landscape-up) {
          padding: 23px;
        }
      }
    }
    .careers-page__content-item:nth-child(3) {
      .hero-large {
        display: inline-block;
      }
      .hero-large__image {
        img {
          @include breakpoint($medium-up) {
            margin-top: 2em;
          }
        }
      }
    }
    .our-values__tree-planting {
      margin-bottom: 4%;
      .header-content {
        text-align: center;
        font-family: $font--text;
        padding: 30px 40px 20px;
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
        @include breakpoint($medium-up) {
          font-size: 14px;
          margin: 2em 0 3em 0;
          padding: 0;
          line-height: 25px;
        }
        @include breakpoint($landscape-up) {
          font-size: 18px;
          letter-spacing: 0.5px;
        }
      }
      .left-content {
        width: 100%;
        padding: 0 20px;
        font-size: 13px;
        line-height: 22px;
        text-indent: 20px;
        font-family: $font--text;
        letter-spacing: 0.5px;
        img {
          width: 100%;
          padding: 0;
        }
        @include breakpoint($medium-up) {
          font-size: 12px;
          line-height: 15px;
          float: left;
          width: 47%;
          padding: 0;
        }
        @include breakpoint($landscape-up) {
          width: 49%;
          padding: 0 20px 0 23px;
          font-size: 14px;
          line-height: 22px;
        }
        @include breakpoint($large-up) {
          font-size: 16px;
        }
        .highlight-content {
          color: $color-dark-green;
          font-weight: bold;
        }
        p {
          margin-top: 0;
          margin-bottom: 2em;
          @include breakpoint($medium-up) {
            margin-bottom: 0;
          }
        }
        .middle-content {
          margin-top: 2em;
          @include breakpoint($medium-up) {
            margin-top: 0;
          }
        }
      }
      .tree-planting {
        &__image {
          width: 50%;
          padding: 0 23px 0 40px;
          @include breakpoint($medium-up) {
            padding: 0 0 0 20px;
            float: right;
          }
          @include breakpoint($landscape-up) {
            padding: 0 23px;
            width: 51%;
          }
        }
      }
    }
  }
  &-animal-testing {
    .bold-text {
      font-weight: bold;
      @include breakpoint($small-down) {
        color: $color-drak-black;
      }
    }
    .careers-page__content-item {
      @include breakpoint($medium-up) {
        padding-right: 20px;
      }
      @include breakpoint($large-up) {
        padding-right: 0;
      }
    }
    .animal-testing {
      &__heading {
        text-transform: uppercase;
        font-size: 21px;
        position: absolute;
        letter-spacing: 7px;
        margin: 20% 0 0 7%;
        color: $color-light-white;
        @include breakpoint($medium-up) {
          margin: 12% 0 0 15%;
          font-size: 22px;
          letter-spacing: 5px;
        }
        @include breakpoint($landscape-up) {
          font-size: 27px;
          letter-spacing: 7px;
          margin: 0 0 0 6%;
          position: static;
        }
        @include breakpoint($large-up) {
          margin: 0 0 0 5%;
          font-size: 36px;
          letter-spacing: 9px;
        }
      }
    }
    .hero-large__image {
      img {
        padding: 0;
        @include breakpoint($landscape-up) {
          padding: 23px;
        }
      }
    }
    .our-values__animal-testing {
      padding: 0 20px;
      @include breakpoint($medium-up) {
        padding: 0 10px;
      }
      @include breakpoint($landscape-up) {
        padding: 0;
      }
      .header-content {
        font-family: $font--text;
        text-align: center;
        font-weight: bold;
        margin: 2em 1.5em;
        font-size: 16px;
        line-height: 22px;
        @include breakpoint($medium-up) {
          font-size: 14px;
          margin: 2em 0 3em 0;
          line-height: 25px;
        }
        @include breakpoint($landscape-up) {
          font-size: 18px;
          margin: 2em 0 4em 0;
          letter-spacing: 0.5px;
        }
      }
      .animal-testing__image {
        width: 100%;
        padding: 0;
        @include breakpoint($medium-up) {
          width: 50%;
          padding-left: 0;
        }
        @include breakpoint($landscape-up) {
          float: left;
          width: 47%;
          padding-left: 23px;
        }
      }
      .right-content {
        width: 100%;
        padding: 0;
        line-height: 22px;
        font-size: 13px;
        text-indent: 20px;
        font-family: $font--text;
        letter-spacing: 0.5px;
        @include breakpoint($medium-up) {
          font-size: 12px;
          line-height: 15px;
          padding: 0 0 0 25px;
          width: 50%;
          float: right;
        }
        @include breakpoint($landscape-up) {
          font-size: 14px;
          line-height: 18px;
          width: 47%;
          padding: 0 0 0 23px;
        }
        @include breakpoint($large-up) {
          font-size: 16px;
          line-height: 22px;
          padding: 0;
        }
        .highlight-content {
          color: $color-dark-green;
          font-weight: bold;
        }
        p {
          margin-bottom: 3em;
          @include breakpoint($medium-up) {
            margin-bottom: 2.5em;
          }
        }
        .first-content {
          margin-top: 0;
        }
        .last-content {
          margin-top: 3em;
          @include breakpoint($medium-up) {
            margin-top: 0;
          }
        }
      }
    }
  }
}
