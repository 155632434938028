.gnav-util {
  &__avatar {
    display: none;
    width: 31px;
    height: 31px;
    position: relative;
    top: 4px;
    border: solid 1px $color-light-gray;
    @include breakpoint($landscape-up) {
      width: 43px;
      height: 43px;
      top: 12px;
      right: 15px;
    }
  }
  &--account {
    // Trigger:
    .gnav-util {
      &__icon {
        display: block;
        color: $color-black;
        text-decoration: none;
        position: relative;
        .icon--my-origins {
          font-size: 50px;
          position: relative;
          top: 12px;
          display: none;
          z-index: 5;
          @include breakpoint($landscape-up) {
            display: inline;
          }
        }
        .gnav_lyl {
          display: block;
          float: right;
          &_pts {
            display: none;
            @include breakpoint($landscape-up) {
              clear: both;
              display: block;
              line-height: 1em;
              margin-top: -0.6em;
            }
          }
        }
        &:hover {
          text-decoration: none;
        }
        @include breakpoint($landscape-up) {
          height: 67px;
          line-height: 67px;
        }
      }
    }
    .icon--account {
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
    .gnav-util__icon__arrow {
      @include breakpoint($landscape-up) {
        @include bordered-triangle(18px, $color-white, up, $color-black);
        position: absolute;
        left: 50%;
        margin-left: -5px;
        top: 50px;
        z-index: 4;
      }
    }
    &--has-img {
      .gnav-util__icon {
        line-height: 27px;
        .icon--my-origins {
          top: 10px;
        }
      }
      .gnav-util__avatar {
        display: inline-block;
      }
      .gnav-util__icon__arrow {
        @include breakpoint($landscape-up) {
          left: 3px;
        }
      }
      .icon--account {
        display: none;
      }
    }

    // Content:
    .gnav-util__content {
      @include breakpoint($landscape-up) {
        border-top: solid 1px $color-black;
        &__inner {
          border: solid 1px $color-black;
          border-top: none;
          border-bottom-width: 3px;
        }
      }
    }
  }
}

// version 2
.gnav-util--account.gnav-util--v2 {
  // logged in
  .elc-user-state-logged-in & {
    .gnav_lyl_signin {
      display: none;
    }
  }
  // logged out
  .elc-user-state-anonymous & {
    .gnav-util__avatar,
    .gnav_lyl_pts {
      display: none;
    }
  }
  // avatar - user photo
  &.gnav-util--account--has-img {
    .gnav-util__avatar {
      @include breakpoint($landscape-up) {
        display: inline;
        right: auto;
        margin: 0 10px 0 0;
      }
    }
    .gnav-util__avatar--mobile {
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
  }
  .gnav-util__avatar--mobile {
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  // desktop
  .gnav_lyl {
    display: block;
    float: right;
  }
  .gnav_lyl_signin {
    position: relative;
    top: 1px;
    font-size: 14px;
    margin-right: 10px;
  }
  .gnav_lyl_rewards {
    // specific gnav breakpoint
    @include breakpoint(max-width 1060px) {
      display: none;
    }
  }
  .gnav_lyl_pts {
    display: none;
    @include breakpoint($landscape-up) {
      position: relative;
      top: 1px;
      font-size: 14px;
      //overrides
      clear: none;
      display: inline;
      float: none;
      line-height: normal;
      margin: 0 10px 0 0;
    }
    // specific gnav breakpoint
    @include breakpoint(max-width 1090px) {
      display: none;
    }
  }
  .icon {
    // display: block;
    // color: $color-black;
    // text-decoration: none;
    // position: relative;
  }
  .icon--my-origins {
    font-size: 58px;
    position: relative;
    top: 14px;
    display: none;
    z-index: 5;
    @include breakpoint($landscape-up) {
      display: inline;
    }
  }
  // utility for points, triggers tooltip
  .gnav-util__icon__arrow {
  }
}
