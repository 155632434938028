@mixin appt_booking {
  $ab-font-family: $font--futura-book;
  $ab-font-family-heading: $font--futura-demi;
  $ab-font-family-subheading: $font--futura-book;
  $ab-font-family-select: $font--futura-demi;
  $ab-font-family-button: $font--futura-demi;
  $ab-font-family-text: $font--futura-book;
  $ab-font-family-text-medium: $font--futura-medium;
  $ab-font-family-service-heading: $font--futura-book;
  $ab-font-family-virtual-review-location: $font--futura-book;

  // width sign posts
  $width-xsmall: 401px !default;
  $width-large: 769px !default;
  $width-large_: 768px !default;
  $width-largest: 967px !default;
  $width-xlarge: 1023px !default;
  $largest-up: 1281px;
  $xxsmall-down: max-width 480px;
  $max-large-up: max-width 1023px;
  $min-large-up: min-width 1024px;

  $ab-mobile-size-1: (width 320px 479px);
  $ab-mobile-size-2: (width 480px 639px);
  $ab-mobile-size-3: (width 640px 767px);

  $ab--large-down: max-width $width-xlarge - 1;

  //breakpoint
  $ab-small-down: $small-down;
  $ab-large-up: $medium-up;
  $ab-landscape-up: $large-up;
  $ab-landscape-down: $medium-down;
  $ab-ipad-portrait: (orientation portrait) $medium-only;
  $ab-ipad-landscape: (orientation landscape) $medium-only;

  //OR AB colors
  $ab--color--pantone-orange-21c: #ff5000;
  $ab--color--orange: #fe782f;
  $ab--color--gray: #e6e6e6;
  $ab--color--gray--white: #f0f0f0;
  $ab--color--gray--lightest: #dbdbdb;
  $ab--color--gray--lighter: #c7c7c7;
  $ab--color--gray-light: $color-mid-gray;
  $ab--color--gray-light_1: #afafaf;
  $ab--color--gray-1: #bfbfbf;
  $ab--color--gray-2: #6a6969;
  $ab--color--gray-3: #f5f5f5;
  $ab--color--gray-4: #9c9c9c;
  $ab--color--gray--dark: $color-dark-gray;
  $ab--color--gray--darker: $color-darker-gray;
  $ab--color--gray--darkest: #292929;
  $ab--color--gray--black: #1a1a1a;
  $ab--color--gray--dropdown: $color-light-gray;
  $ab--color--gray-dot-look: $color-mid-gray;
  $ab--color--white: $color-white;
  $ab--color--white-transparent: rgba(255, 255, 255, 0.75);
  $ab--color--brown: #392720;
  $ab--color--cream: #fbf8f6;
  $ab--color--red: $color-red;
  $ab--color--green: #72c6a7;
  $ab--color--black: $color-darker-gray;

  $ab--color--btn-black: $color-black;
  $ab_color--btn-gray-disabled: $ab--color--gray-light_1;
  $ab_color--btn-gray-disabled-alt: $ab--color--gray--lightest;

  .selected-totals {
    @include menu_item;
  }
  .ab-button {
    @include ab-button;
  }

  // Classes outside of .appt-book wrapper class
  .section-book-appointment {
    .selectBox-dropdown-menu {
      z-index: 5;
    }
  }

  //Slider AB
  .hideAccordionContent {
    display: none;
  }
  .showAccordionContent {
    display: block;
  }
  .iconCaretUp {
    height: 54px;
    line-height: 54px;
    margin: 0;
    cursor: pointer;
    &:before {
      background: transparent
        url(/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_up.jpg)
        no-repeat;
      content: '';
      width: 30px;
      height: 54px;
      z-index: 99999;
      position: absolute;
      right: 0;
      background-position: 50% 50%;
      @include breakpoint($ab-small-down) {
        z-index: 0;
      }
    }
  }
  .iconCaretDown {
    height: 54px;
    line-height: 54px;
    margin: 0;
    cursor: pointer;
    &:after {
      background: transparent
        url(/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_down-1.jpg)
        no-repeat;
      content: '';
      width: 30px;
      height: 54px;
      z-index: 99999;
      position: absolute;
      background-position: 50% 50%;
      right: 0;
      @include breakpoint($ab-small-down) {
        z-index: 0;
      }
    }
  }

  //Slider My Appointment
  .iconCaretUpMA {
    height: 40px;
    border-bottom: 0;
    &:before {
      background: transparent
        url(/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_up.jpg)
        no-repeat;
      content: '';
      width: 30px;
      height: 40px;
      z-index: 99999;
      position: absolute;
      right: 0;
      background-position: 50% 25%;
      margin-right: 30px;
    }
  }
  .iconCaretDownMA {
    height: 40px;
    border-bottom: 1px solid $ab--color--gray-light_1;
    &:after {
      background: transparent
        url(/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_down-1.jpg)
        no-repeat;
      content: '';
      width: 30px;
      height: 40px;
      z-index: 99999;
      position: absolute;
      right: 0;
      background-position: 50% 25%;
      margin-right: 30px;
    }
  }
  .appt-book-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $ab--color--white-transparent;
    z-index: 100;
    &.appt-book-services-overlay,
    &.appt-book-services-unavailabe-overlay,
    &.appt-book-redeemable-services-overlay,
    &.appt-book-location-conflict-overlay,
    &.appt-book-book-conflict-overlay {
      z-index: 9995;
      @include breakpoint($ab-small-down) {
        z-index: 9999;
      }
      .overlay-content-container {
        width: 553px;
        min-height: 250px;
        top: 50%;
        left: 50%;
        margin-top: -95px;
        margin-left: -300px;
        background-color: $ab--color--white;
        @include breakpoint($ab-small-down) {
          width: 300px;
          min-height: 232px;
          margin-left: -150px;
          margin-top: -50%;
          padding: 16px 30px;
        }
      }
    }
    &#appt-book-my-appointments-overlay {
      .overlay-content-container {
        min-height: 165px;
        .ab-button {
          @include ab-button;
        }
        #cboxClose {
          &:hover {
            background-position: -25px -25px;
          }
        }
        .canceled {
          span {
            display: block;
            line-height: 24px;
          }
        }
      }
    }
    .overlay-content-container {
      position: absolute;
      display: block;
      margin-top: 100px;
      padding: 16px 25px;
      text-align: center;
      background-color: $color-white;
      border: 1px solid $color-black;
      z-index: 1;
      .overlay-content {
        display: block;
        position: relative;
        text-align: center;
        font-size: 16px;
        font-family: $ab-font-family-text;
        padding: 0;
        line-height: 32px;
        margin: 0 0 1em;
        @include breakpoint($ab-small-down) {
          margin: 1em 0;
          font-size: 16px;
        }
        h4,
        .at-h4-appt-book {
          text-transform: uppercase;
          font-family: $ab-font-family-heading;
          font-size: 32px;
          margin: 1em 0;
        }
        p {
          line-height: 32px;
          margin: 2em 0;
        }
        .button {
          cursor: pointer;
        }
      }
    }
    &.appt-book-datepicker-overlay {
      @include breakpoint($ab-small-down) {
        z-index: 10000;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
      }
      .date-picker-container {
        @include breakpoint($ab-small-down) {
          overflow: auto;
          position: relative;
          width: 100%;
          border: 1px solid $ab--color--gray--dark;
          background-color: $color-white;
          padding: 49px 1% 25px;
          opacity: 0.98;
          button {
            min-width: auto;
          }
          .is-disabled .pika-button {
            opacity: 1;
            background-color: $color-light-gray;
          }
          p {
            font-size: 0.9em;
            text-align: center;
          }
        }
        .date-picker-close {
          @include breakpoint($ab-small-down) {
            font-family: $ab-font-family-heading;
            position: absolute;
            display: block;
            right: 15px;
            top: 15px;
            cursor: pointer;
            z-index: 3;
            overflow: hidden;
            text-indent: 100%;
            white-space: nowrap;
          }
        }
        .pika-title {
          @include breakpoint($ab-small-down) {
            .pika-label {
              background: none;
              font-family: $ab-font-family-subheading;
              font-size: 1em;
            }
          }
        }
        .pika-table {
          background-color: $ab--color--gray-3;
          th {
            @include breakpoint($ab-small-down) {
              background-color: $ab--color--green;
              color: $ab--color--gray--dark;
              text-transform: uppercase;
              border: 1px solid $ab--color--green;
            }
          }
          @include breakpoint($ab-small-down) {
            td {
              border: 1px solid $ab--color--gray--dark;
              button {
                background-color: $ab--color--white;
                position: initial;
              }
              &.is-disabled {
                color: $ab--color--white;
                background-color: $ab--color--gray--dark;
              }
            }
          }
        }
        .pika-button {
          @include breakpoint($ab-small-down) {
            text-align: center;
            font-family: $ab-font-family-text;
            font-size: 16px;
          }
        }
        .pika-single {
          @include breakpoint($ab-small-down) {
            padding: 0px 8px;
            border: none;
            background: none;
          }
          .pika-lendar {
            @include breakpoint($ab-small-down) {
              width: 100%;
              margin: 8px auto;
            }
            .is-today {
              .pika-button {
                @include breakpoint($ab-small-down) {
                  color: $ab--color--gray--darkest;
                  background: $ab--color--white;
                  border-radius: 0;
                }
              }
            }
            .is-selected {
              .pika-button {
                @include breakpoint($ab-small-down) {
                  background: $ab--color--green;
                  color: $ab--color--white;
                  border-radius: 0;
                }
              }
            }
          }
        }
        table.day {
          @include breakpoint($ab-small-down) {
            text-align: center;
            margin: 0 auto;
            width: 100%;
            font-family: $ab-font-family-text;
            font-size: 16px;
            max-width: 854px;
            border: none;
          }
          td {
            @include breakpoint($ab-small-down) {
              border: none;
              text-align: center;
              cursor: default;
              letter-spacing: 0;
              padding: 0;
              height: 60px;
              line-height: 60px;
              background-color: $color-white;
              color: $ab--color--gray;
              border-bottom: 1px solid $ab--color--gray--lighter;
            }
            &.store_open.enabled {
              @include breakpoint($ab-small-down) {
                color: $color-black;
              }
            }
          }
        }
      }
    }
    .step-header {
      text-align: center;
      font-size: 2.5em;
      font-family: $ab-font-family-subheading;
      margin: 0 0 0.4em;
      @include breakpoint($ab-small-down) {
        font-size: 1em;
        font-weight: bold;
        .step-header__inner {
          font-weight: lighter;
        }
      }
    }
  }

  // Book Now Bar
  .book-now-bar {
    &.top {
      display: none;
      .book-now-bar-inner {
        position: fixed;
        right: 1px;
        top: 9.75em;
        height: 36px;
        line-height: 36px;
        width: 250px;
        padding: 0;
        text-align: left;
        padding: 0 1em 0;
        cursor: pointer;
        font-family: $ab-font-family-heading;
        font-size: 18px;
        z-index: 15;
        border: none;
        color: $ab--color--white;
        @include breakpoint($ab-ipad-portrait) {
          //top: 150px;
        }
        @include breakpoint($ab-ipad-landscape) {
          //top: 105px;
        }
        @include breakpoint($ab-small-down) {
          width: 100%;
        }
        .section-content_left {
          float: left;
        }
        .section-content_right {
          float: right;
          @include icon('carat--r', after);
          padding-right: 0.5em;
          &:after {
            text-decoration: none;
            font-size: 18px;
            vertical-align: text-top;
            margin-left: 0.25em;
          }
        }
        &.button {
          @include breakpoint($ab-small-down) {
            margin: 0;
            padding: 0 15%;
            height: 42px;
            line-height: 47px;
            font-size: 18px;
            left: 0;
          }
        }
        &.disabled {
          cursor: default;
          border: none;
          color: $ab--color--gray--lightest;
          background-color: $ab--color--gray-light_1;
          .section-content_right {
            &:after {
              display: none;
            }
          }
          &:hover .section-content_right {
          }
          &:hover .section-content_left {
          }
        }
      }
    }
  }
  .appt-book {
    .selectBox-label {
      display: inline;
    }
    letter-spacing: 0;
    margin: 0 auto 16px;
    padding: 0px;
    position: relative;
    text-align: left;
    color: $ab--color--black;
    font-family: $ab-font-family-text;
    .appt-basic-responsive {
      position: relative;
      display: block;
      font-size: 17px;
      letter-spacing: 0;
      /* font-family:$ab-font-family; */
      @include breakpoint($ab-small-down) {
        font-size: 16x;
      }
      a {
        margin-top: 2px;
        @include breakpoint($ab-small-down) {
          margin-top: 0;
        }
      }
    }
    p {
      margin: 18px auto 11px;
      letter-spacing: 0;
      @include breakpoint($ab-small-down) {
        margin-top: 11px;
        letter-spacing: 0;
      }
    }
    .appt-book-link {
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 0;
      font-family: $ab-font-family-button;
      font-weight: bold;
      font-size: 18px;
      @include breakpoint($ab-small-down) {
        padding-bottom: 0;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .button {
      padding: 10px 16px;
    }
    .button.disabled {
      background-color: $ab_color--btn-gray-disabled;
      cursor: default;
    }

    // small sign-in tout on landing
    &__tout {
      &--sign-in {
        @include breakpoint($ab-small-down) {
          width: 100%;
          padding: 0 2em;
        }
      }
    }

    // Appointment Booking page header
    .appt-book-page-header {
      color: $color-black;
      display: block;
      margin: 0 auto;
      position: relative;
      text-decoration: none;
      .appt-book-page-header-bg {
        display: block;
        width: 100%;
        height: auto;
      }
      .appt-book-page-header-bg.bg-img-mobile {
        display: none;
      }
      // Don't show the pc background image on mobile
      @include breakpoint($ab-small-down) {
        .appt-book-page-header-bg.bg-img-pc {
          display: none;
        }
        .appt-book-page-header-bg.bg-img-mobile {
          display: block;
        }
      }
      .appt-book-page-header-menu {
        .submenu--sticky {
          .submenu {
            &__header {
              .appointment-nav {
                &__link {
                  letter-spacing: 0.05em;
                }
              }
            }
          }
        }
      }
      .appt-book-page-header-content {
        margin: 0;
        @include breakpoint($ab-small-down) {
          padding: 0;
          margin: 0;
        }
        &__head {
          text-transform: uppercase;
          font-family: $ab-font-family-heading;
          font-size: 2em;
          font-weight: normal;
          line-height: 20px;
          @include breakpoint($ab-small-down) {
            font-size: 1em;
            padding: 0;
            line-height: 25px;
          }
          @include breakpoint($ab-ipad-portrait) {
            font-size: 1.5em;
          }
        }
        &__title {
          margin-bottom: 0.25em;
          @include breakpoint($ab-small-down) {
            margin: 0.25em 0;
            line-height: 32px;
          }
        }
        &__subhead {
          font-family: $font--futura-medium;
          font-size: 2em;
          @include breakpoint($ab-small-down) {
            font-size: 1.75em;
            line-height: 32px;
          }
        }
        &__desc {
          font-size: 1.25em;
          margin: 0.75em 0 0;
          font-family: $ab-font-family-subheading;
          @include breakpoint($ab-small-down) {
            font-size: 1em;
          }
        }
        .section-cancel-appointment &,
        .appointments-page & {
          top: 20%;
        }
        .inner {
          text-align: center;
          position: relative;
          margin: 0 auto;
          padding: 8px 5% 0;
          @include breakpoint($ab-small-down) {
            padding-top: 12px;
          }
        }
        h2,
        h3,
        h4 {
          font-family: $ab-font-family;
          line-height: 1.2;
          margin-bottom: 0;
          text-align: center;
          b,
          strong {
            font-family: $ab-font-family;
          }
        }
        h2 {
          font-size: 48px;
          letter-spacing: -0.02em;
          color: $color-black;
          font-family: $ab-font-family;
          margin-top: 1px;
          padding-bottom: 32px;
          @include breakpoint($ab-small-down) {
            font-size: 30px;
            line-height: 36px;
            font-family: $ab-font-family;
            margin-top: 3px;
          }
        }
        h3 {
          font-size: 27px;
          @include breakpoint($ab-small-down) {
            font-size: 25px;
            line-height: 24px;
            color: $color-black;
            letter-spacing: 3px;
            margin: 2px 0 10px;
            font-weight: 100;
            padding: 0 15px 20px;
          }
          .confirmation & {
            @include breakpoint($ab-small-down) {
              margin-bottom: -1px;
            }
          }
        }
        h4 {
          font-size: 19px;
          line-height: 1.3;
          color: $color-black;
          margin-left: 4px;
          padding-top: 10px;
          @include breakpoint($ab-small-down) {
            line-height: 18px;
            text-align: left;
            padding-top: 12px;
          }
        }
        .multi {
          white-space: nowrap;
        }
        .multi--mobile-inline {
          display: inline-block;
          @include breakpoint($ab-small-down) {
            display: block;
          }
        }
        &__subhead {
          margin: 0 auto;
          @include breakpoint($ab-small-down) {
            padding: 0 15px;
          }
          p {
            margin: 18px auto 11px;
            font-size: 12px;
            line-height: 18px;
            &:first-child {
              margin-top: -7px;
            }
          }
          @include breakpoint($ab-small-down) {
            p {
              margin-top: 0;
            }
          }
        }
        &__dots {
          @include menu_h;
          display: none;
          li {
            @include breakpoint($ab-small-down) {
              padding: 0.75em 0.5em 0.5em;
              font-size: 14px;
              width: 25%;
              vertical-align: middle;
            }
            &:last-child {
              border-right: 1px solid $ab--color--gray--dark;
            }
          }
        }
      }
    }

    // Appointment Booking Landing Page
    .appt-book-sections-container {
      position: relative;
      margin: 0 auto;
      //padding: 0 33px 65px 40px;
      .appt-book-section {
        // Landing section hero tout
        .location-hero {
          margin-bottom: 1em;
          @include breakpoint($ab-small-down) {
            margin-bottom: 2em;
          }
          &__hero {
            text-align: center;
            width: 100%;
            color: $color-white;
            padding: 9em 0 8em;
            text-transform: uppercase;
            position: relative;
            margin: 0;
            min-height: 25em;
            display: flex;
            justify-content: center;
            flex-direction: column;
            @include breakpoint($ab-small-down) {
              padding: 1em 1.5em;
              min-height: 16em;
            }
            &--bkground {
              position: absolute;
              left: 0;
              top: 0;
              min-height: 100%;
              min-width: 100%;
              background-color: $ab--color--gray;
              background-size: cover;
              background-repeat: no-repeat;
              z-index: -1;
              @include breakpoint($ab-small-down) {
                min-height: 210px;
                margin-bottom: 1.5em;
              }
            }
            .hero-hdr {
              font-family: $ab-font-family-heading;
              font-size: 11rem;
              line-height: 9rem;
              margin: 0;
              @include breakpoint($ab-small-down) {
                font-size: 7em;
                line-height: 4.25rem;
              }
              &__subhdr {
                display: block;
                font-family: $ab-font-family;
                font-size: 4.25rem;
                line-height: 1em;
                margin-top: 0.25em;
                @include breakpoint($ab-small-down) {
                  font-size: 1.5rem;
                }
              }
            }
          }
        }

        // Location section location tout
        .location-selector {
          max-width: 100%;
          padding: 0 0 2em;
          margin: 0 5em 1em;
          text-align: center;
          color: $color-black;
          background-size: cover;
          background-repeat: no-repeat;
          border-bottom: 1px solid $ab--color--brown;
          @include breakpoint($ab-ipad-portrait) {
            margin: 0 3% 1em;
          }
          @include breakpoint($ab-small-down) {
            margin: 0 0 1em;
            padding: 0 1em 1em;
            max-width: 100%;
          }
          &__hdr {
            font-family: $ab-font-family;
            font-size: 3.5rem;
            line-height: 46px;
            margin: 0 0 17px;
            @include breakpoint($ab-small-down) {
              font-size: 2rem;
              line-height: 27px;
              margin: 0 0 12px;
            }
          }
          &__pre-content {
            line-height: 19px;
            margin: 0 auto;
            width: 70%;
            font-family: $ab-font-family-text;
            font-size: 16px;
            @include breakpoint($ab-small-down) {
              line-height: inherit;
              margin: 1em auto 0;
              width: 90%;
            }
          }
          &__form {
            margin-top: 0.5em;
            color: $color-black;
            .ui-select {
              display: inline-block;
              width: 40%;
              @include breakpoint($ab-small-down) {
                margin: 0 0 0.5em;
                display: block;
                width: 100%;
              }
              .location-select,
              .state-select {
                vertical-align: middle;
                vertical-align: left;
                text-align: left;
                text-decoration: none;
                font-family: $ab-font-family-select;
                @include breakpoint($ab-small-down) {
                  width: 100%;
                  background-color: $color-white;
                  border-radius: 0;
                  line-height: inherit;
                  padding-top: 0.7em;
                }
                .select-box {
                  vertical-align: top;
                  &__label {
                    background-color: $color-white;
                    width: 100%;
                    height: 35px;
                    text-align: left;
                    &:after {
                      padding: 0.25em 0 0;
                      text-align: center;
                      background-color: $ab--color--cream;
                      width: 2em;
                      top: 0;
                      right: 0;
                    }
                  }
                  &__options {
                    top: 34px;
                    overflow: hidden;
                    text-align: left;
                  }
                }
                &.select-box--disabled {
                  .select-box {
                    &__label {
                      color: $ab--color--gray--white;
                      background-color: $ab_color--btn-gray-disabled;
                    }
                  }
                }
                &.disabled {
                  background-color: $ab_color--btn-gray-disabled;
                }
              }
              .state-select {
                margin-right: 0.5em;
              }
              .location-submit {
                float: right;
                margin-top: 0.5em;
                &:last-child {
                  border: 1px solid $ab--color--gray-light;
                }
                @include breakpoint($ab-small-down) {
                  width: 100%;
                  margin-top: 1em;
                  float: none;
                }
              }
            }
          }
          .virtual__appointment--btn {
            color: $ab--color--white;
            font-size: 15px;
            text-align: center;
            padding: 0 20px;
            height: 60px;
            line-height: 2;
            border: 1px solid $ab--color--white;
            display: inline-block;
            background-color: $ab--color--gray--dark;
            text-transform: uppercase;
            margin-top: 20px;
            font-family: $font--futura-medium;
          }
        }
      }
      .counter-section {
        text-align: center;
        margin-bottom: 3em;
        .appointment-booking-counter {
          min-width: 21em;
          display: inline-block;
          font-size: 16px;
          @include breakpoint($ab-small-down) {
            min-width: 90%;
            margin-bottom: 1.5em;
          }
          &__hdr {
            font-size: 24px;
            margin: 0 0 0.5em;
          }
          &__info {
            line-height: 20px;
            p {
              margin: 0;
              line-height: 20px;
            }
          }
        }
      }
    }

    // Appointment Booking Counters
    &.counters {
      text-align: center;
      a {
        text-decoration: underline;
      }
    }

    // Appointment Booking Choose your service
    &.services {
      #appointment-book-sections {
        margin: 0 auto;
        @include breakpoint($ab-small-down) {
          padding: 0;
        }
      }
      .appt-book-page-header-content {
        .inner {
          padding-bottom: 8px;
        }
      }
      .book-now-bar {
        &.top {
          display: block;
        }
      }
      .appt-book-page-header {
        @include breakpoint($ab-small-down) {
          padding-top: 43px;
          margin-bottom: -27px;
        }
      }
      .services-submission {
        text-align: center;
        padding: 1em 0 0;
        @include breakpoint($ab-small-down) {
          padding: 1em 5%;
        }
        .selection-data {
          display: inline-block;
          margin: 0;
          font-family: $ab-font-family-button;
          border-top: 1px solid $ab--color--gray-light;
          border-bottom: 1px solid $ab--color--gray-light;
          border-left: 1px solid $ab--color--gray-light;
          padding: 0.55em 2.25em 0.37em;
          border-right: none;
          text-transform: uppercase;
          vertical-align: top;
          line-height: 0.97em;
          @include breakpoint($ab-small-down) {
            width: 35%;
            line-height: inherit;
            padding: 0.55em 1.25em 0.37em;
          }
        }
      }
      .selection-data {
        font-family: $ab-font-family-text;
        font-weight: bold;
        font-size: 18px;
        @include breakpoint($ab-small-down) {
          margin: 0 0 1em;
        }
      }
      .submit-services {
        @include breakpoint($ab-small-down) {
          width: 65%;
          line-height: 0.95em;
        }
        &.button {
          padding: 0.1em 1em;
        }
      }
      .appt-book-section {
        position: relative;
        width: 100%;
        h2.service-type {
          margin-top: 0px;
          padding: 7px 0;
          cursor: pointer;
        }
        h3.service-content__details--type {
          display: none;
        }
        .appt-book-section-content {
          position: relative;
          width: 100%;
          margin-bottom: 95px;
          @include breakpoint($ab-small-down) {
            margin: 0 0 20px;
          }
          .appt-book-content-header {
            margin: 0;
            padding: 0;
            width: 100%;
            background-color: $color-white;
            z-index: 1;
            @include breakpoint($ab-small-down) {
              position: relative;
              @include breakpoint($ab-small-down) {
                padding: 0;
              }
            }
            .step-breadcrumb {
              text-transform: uppercase;
              .mobile-only {
                @include breakpoint($ab-ipad-portrait) {
                  display: inline !important;
                }
                @include breakpoint($ab-small-down) {
                  display: inline !important;
                }
              }
            }
            .content-header-wrapper {
              max-width: 1200px;
              margin: 0 auto;
              text-align: center;
              @include breakpoint($ab-small-down) {
                margin: 1.5em auto 0;
              }
              .section-subheader {
                @include breakpoint($ab-small-down) {
                  text-align: center;
                  display: block;
                }
              }
              .section-header {
                text-align: center;
                font-family: $ab-font-family-subheading;
                font-size: 4em;
                line-height: 1;
                margin: 0;
                @include breakpoint($ab-small-down) {
                  font-size: 6em;
                  line-height: 70px;
                  margin: 0.25em 0;
                  display: none;
                }
                &__pre-content {
                  font-family: $ab-font-family-text;
                  text-align: center;
                  width: 100%;
                  padding: 0.5em 0 0;
                  text-transform: uppercase;
                }
              }
            }
          }
          .service_type_header {
            clear: both;
          }
          .service {
            width: 500px;
            height: 230px;
            text-align: center;
            position: relative;
            margin: 0;
            padding: 30px 30px 50px 0;
            border-color: $color-light-gray;
            border-style: solid;
            border-width: 0;
            &:nth-child(2) {
              @include breakpoint($ab-large-up) {
                border-width: 0;
              }
            }
            @include breakpoint($ab-ipad-portrait) {
              width: 350px;
              height: 185px;
              padding: 30px 10px 50px 0;
            }
            @include breakpoint($ab-ipad-landscape) {
              width: 475px;
            }
            @include breakpoint($ab-small-down) {
              width: 100%;
              height: auto;
              padding: 0.7em 5% 1em;
              border-bottom: 1px solid $color-light-gray;
              margin: 0;
              &:first-child {
                border-top: 1px solid $color-light-gray;
              }
            }
            .expando-block {
              &__title {
                @include breakpoint($ab-small-down) {
                  padding: 0 5%;
                }
              }
              &__header {
                display: none;
                @include breakpoint($ab-small-down) {
                  display: block;
                  text-transform: uppercase;
                  font-size: 1em;
                  font-family: $ab-font-family-service-heading;
                  font-weight: 100;
                  i {
                    font-size: 0.5em;
                    top: calc(50% - 5px);
                    right: 0.5em;
                  }
                }
              }
              &__icon {
                top: -0.25em;
              }
            }
            &-content {
              font-size: 16px;
              position: relative;
              text-align: left;
              @include clearfix;
              @include breakpoint($ab-large-up) {
                height: 100%;
                overflow: visible;
              }
              @include breakpoint($ab-small-down) {
                font-size: inherit;
              }
              &__image {
                height: 200px;
                width: 200px;
                @include breakpoint($ab-ipad-portrait) {
                  width: 150px;
                  height: 150px;
                }
                @include breakpoint($ab-small-down) {
                  margin: 1em auto;
                  display: block;
                }
                &-placeholder {
                  display: block;
                  height: 250px;
                  width: 184px;
                  margin-right: 30px;
                  float: left;
                  background-color: $ab--color--gray--dark;
                  @include breakpoint($ab-small-down) {
                    height: 150px;
                    width: 38%;
                    margin-right: 4%;
                  }
                }
              }
              &__details {
                position: relative;
                float: right;
                width: 250px;
                min-height: 200px;
                margin: 0 0 0 15px;
                padding: 0 0 2.5em;
                @include breakpoint($ab-ipad-portrait) {
                  width: 175px;
                  min-height: 150px;
                }
                @include breakpoint($ab-ipad-landscape) {
                  width: 225px;
                }
                @include breakpoint($ab-small-down) {
                  margin: 1em auto 0;
                  width: 100%;
                  min-height: 125px;
                  float: none;
                  text-align: center;
                }
                &--title {
                  font-size: 22px;
                  line-height: 1.05;
                  margin: 0 0 0.45em;
                  text-align: left;
                  text-transform: uppercase;
                  font-family: $ab-font-family-service-heading;
                  font-weight: 100;
                  @include breakpoint($ab-ipad-portrait) {
                    font-size: 18px;
                  }
                  @include breakpoint($ab-small-down) {
                    font-size: inherit;
                  }
                }
                &--type {
                  font-family: $ab-font-family-text;
                  font-size: 16px;
                }
                &--description {
                  margin: 0 0 0.5em;
                  display: none;
                }
                .selection-bar {
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  font-family: $ab-font-family-button;
                  @include breakpoint($ab-small-down) {
                    bottom: 2px;
                  }
                  .selection-time,
                  .selection-price {
                    line-height: 3px;
                  }
                  .selection-data {
                    width: 49%;
                    @include breakpoint($ab-small-down) {
                      padding: 0.65em 0 0.75em;
                    }
                    .selection-time {
                      text-transform: uppercase;
                    }
                    .selection-price {
                      padding-left: 5px;
                      @include breakpoint($ab-small-down) {
                        display: none;
                      }
                    }
                    .selection-separator {
                      @include breakpoint($ab-small-down) {
                        display: none;
                      }
                    }
                  }
                  .selection-selector {
                    cursor: pointer;
                    padding: 0.6em 0 0.4em;
                    width: 45%;
                    text-align: center;
                    border: 1px solid $ab--color--gray-light;
                    @include breakpoint($ab-small-down) {
                      padding: 0.65em 0 0.55em;
                      width: 100%;
                    }
                    &.selected {
                      background-color: $ab--color--gray--dark;
                      color: $color-white;
                    }
                    &.disabled {
                      color: $ab--color--brown;
                      background-color: inherit;
                    }
                  }
                  .service-select {
                    cursor: pointer;
                    width: 100%;
                    border-radius: 0;
                    @include breakpoint($ab-small-down) {
                      font-size: 12px;
                      padding: 7px;
                      height: 32px;
                      line-height: 1;
                    }
                    .select-box {
                      &__label {
                        text-align: center;
                        width: 100%;
                        padding: 0 4em;
                      }
                      &__option {
                        text-align: center;
                      }
                    }
                  }
                  &__remove {
                    visibility: hidden;
                    margin-top: 0.75em;
                    text-decoration: underline;
                  }
                  .ui-select {
                    float: left;
                    width: 49%;
                  }
                }
              }
              .service-head {
                .service-title {
                  @include breakpoint($ab-small-down) {
                    margin-bottom: 0px;
                  }
                }
              }
              .service-details {
                .service-message {
                  li {
                    list-style-type: disc;
                    list-style-position: inside;
                    margin: 0 0 1em;
                  }
                }
              }
              .service-content-container {
                .service-details {
                  .lesson_description {
                    p {
                      @include breakpoint($ab-small-down) {
                        margin-top: 0px;
                      }
                    }
                  }
                }
              }
            }
            &.selected {
              .service-select {
                @include breakpoint($ab-small-down) {
                  background-color: $color-black;
                  background-image: none;
                  color: $color-white;
                }
              }
              .select-box {
                &__label {
                  background-color: $color-black;
                  color: $color-white;
                }
              }
              .selection-bar {
                &__remove {
                  visibility: visible;
                }
              }
            }
          }
          .services-applications {
            padding: 0 0 25px;
            border-style: solid;
            border-width: 1px 0;
            border-color: $ab--color--gray--dark;
            &:last-child {
              border: none;
            }
            @include breakpoint($ab-small-down) {
              padding: 0;
              border-width: 0;
            }
            h2 {
              text-align: center;
              text-transform: uppercase;
              font-size: 48px;
              margin: 0;
              @include breakpoint($ab-small-down) {
                padding: 0 15px;
              }
              &.service_type_header.active {
                clear: both;
                padding: 20px 0 10px;
                @include breakpoint($ab-small-down) {
                  display: none;
                }
                .service_type_header_sub {
                  display: inline-block;
                  font-size: 30px;
                  line-height: 1em;
                  font-weight: normal;
                  @include breakpoint($ab-small-down) {
                    text-align: left;
                  }
                }
              }
            }
            .services-container {
              margin: 0 auto;
              padding: 0;
              max-width: 1000px;
              @include breakpoint($ab-ipad-portrait) {
                max-width: 100%;
              }
              @include breakpoint($ab-small-down) {
                padding: 0;
              }
              &-templates {
                margin: 0;
                display: block;
                &.enable-centering {
                  text-align: center;
                }
              }
              &__sub-head {
                text-align: center;
                text-transform: uppercase;
                margin: 0;
                font-size: 22px;
                line-height: 1.05;
                @include breakpoint($ab-small-down) {
                  margin: 0;
                  padding: 0 3em 2em;
                }
              }
            }
          }
          .services-lesson {
            margin-top: 30px;
            border-bottom: 1px solid $color-black;
            h2 {
              text-align: center;
              text-transform: uppercase;
              font-size: 48px;
              margin: 0;
              @include breakpoint($ab-small-down) {
                padding: 0 15px;
              }
            }
            .services-lesson-content {
              overflow: hidden;
              margin: 0 auto;
              padding: 0 0 41px 0;
              max-width: 951px;
              @include breakpoint($ab-small-down) {
                padding: 0 15px 41px;
              }
              &-templates {
                margin-top: 30px;
                border-top: 1px solid $color-light-gray;
                @include breakpoint($ab-small-down) {
                  padding-top: 0;
                }
              }
              &__sub-head {
                text-align: center;
                text-transform: uppercase;
                margin: 0;
                font-size: 22px;
                line-height: 1.05;
              }
            }
          }
          .book-now-bar {
            .minutes {
            }
            .book-now-bar-down {
              @include breakpoint($ab-small-down) {
                margin: 25px 15px 30px;
              }
            }
          }
          .book-now-bar.bottom {
            overflow: hidden;
            margin: 10px 0 0;
            text-align: center;
            display: block;
            clear: both;
            span.button {
              cursor: pointer;
              float: none;
              display: block;
              width: 164px;
              margin: 0 auto 1em;
              @include breakpoint($ab-small-down) {
                width: 90%;
              }
            }
            span.button.disabled {
              cursor: default;
            }
          }
        }
      }
    }

    // Appointment Booking Schedule your service
    &.appointments {
      .appt-book-page-header-content {
        .inner {
          //padding-bottom: 15px;
          //border-bottom: 1px solid $ab--color--gray-light_1;
        }
      }
      .appt-book-sections-container {
        margin-top: 0px;
        .appt-book-section {
          ::before {
            display: none;
          }
        }
      }
      #booking-step1 {
        max-width: 1200px;
        margin: 0 auto 50px;
        @include breakpoint($ab-landscape-down) {
          margin: 0 3% 50px;
        }
        @include breakpoint($ab-small-down) {
          margin: 0 5%;
        }
        .appt-book-content-header {
          margin: 0 auto;
          border-bottom: 1px solid $ab--color--brown;
          padding-bottom: 1em;
          @include breakpoint($ab-small-down) {
            padding-bottom: 0;
          }
          .section-header {
            text-transform: uppercase;
            font-family: $ab-font-family-service-heading;
            font-weight: lighter;
            font-size: 1.5em;
            line-height: 20px;
            margin: 2em 0 0;
            text-align: center;
            .section-header__inner {
            }
            @include breakpoint($ab-small-down) {
              font-size: 24px;
              text-align: center;
              margin: 2em 0 0;
              &:first-child {
                margin: 1em 0 0;
              }
            }
          }
        }
        .step-breadcrumb {
          text-transform: uppercase;
          .mobile-only {
            @include breakpoint($ab-ipad-portrait) {
              display: inline !important;
            }
            @include breakpoint($ab-small-down) {
              display: inline !important;
            }
          }
        }
        .services {
          width: 50%;
          float: left;
          @include breakpoint($ab-small-down) {
            width: 100%;
            clear: both;
          }
          .service {
            width: 100%;
            float: left;
            color: $ab--color--brown;
            border-right: 1px solid $ab--color--gray--lighter;
            border-top: none;
            position: relative;
            @include breakpoint($ab-small-down) {
              width: 90%;
              margin: 0 auto;
              padding: 0;
              border: none;
              float: none;
              text-align: center;
            }
            &:first-child {
              @include breakpoint($ab-small-down) {
                .selection-service .service-head {
                  margin: 0;
                }
              }
            }
            &-title {
              margin: 30px 0 0.55em;
              text-transform: uppercase;
              font-family: $ab-font-family-heading;
              font-size: 1.5em;
              @include breakpoint($ab-small-down) {
                font-size: 1.25em;
                margin: 0 0 0.55em;
              }
            }
            .selection-service {
              min-height: 65px;
              padding-right: 2em;
              @include breakpoint($ab-small-down) {
                padding: 0;
                min-height: 2.5em;
              }
              .service-type {
                padding: 0;
                font-size: 16px;
                font-family: $ab-font-family-text;
                margin: 0.5em 0 0;
                padding-right: 2em;
                @include breakpoint($ab-small-down) {
                  padding: 0 0 1.2em;
                }
              }
              .service-head {
                margin: 0;
                font-size: 19px;
                line-height: 10px;
                letter-spacing: 2px;
                text-transform: uppercase;
                font-family: $ab-font-family-service-heading;
                font-weight: lighter;
                @include breakpoint($ab-small-down) {
                  font-size: 0.83em;
                  line-height: inherit;
                }
              }
            }
            .selection-data {
              font-size: 14px;
              margin: 5px 0;
              text-transform: uppercase;
              @include breakpoint($ab-small-down) {
                font-size: 1em;
                height: 2.5em;
                margin: 0;
              }
              .selection-time {
                display: inline-block;
                width: 25%;
                font-weight: bold;
                font-family: $ab-font-family-text-medium;
                font-size: 16px;
                @include breakpoint($ab-small-down) {
                  float: none;
                  width: auto;
                }
              }
              .selection-price {
                display: none;
              }
              .selection-selected {
                @include ab-button;
                display: inline-block;
                width: 25%;
                text-align: center;
                border: 1px solid $ab--color--gray--dark;
                color: $ab--color--white;
                background-color: $ab--color--gray--dark;
                @include breakpoint($ab-small-down) {
                  width: auto;
                }
              }
            }
            .change-services {
              text-transform: uppercase;
              display: inline-block;
              vertical-align: bottom;
              font-weight: bold;
              font-family: $ab-font-family-button;
              font-size: 18px;
              @include breakpoint($ab-small-down) {
                padding-left: 0;
              }
            }
          }
        }
        @include breakpoint($ab-small-down) {
          hr {
            border-color: $ab--color--brown;
            width: 50%;
            margin: 3em 25% 0;
            display: inline-block;
            @include breakpoint($ab-small-down) {
              margin: 1em 25% 0;
              border: none; // Remove all cascading borders
              border-top: 1px solid $ab--color--gray--lighter;
            }
          }
          .selects-container {
            clear: both;
            width: 100%;
            display: inline-block;
            margin: 2em auto;
            text-align: center;
            @include breakpoint($ab-small-down) {
              margin: 1.5em auto 0.5em;
            }
            h4 {
              font-family: $ab-font-family-heading;
              font-size: 1.5em;
              margin: 0 auto 0.5em;
              @include breakpoint($ab-small-down) {
                font-size: 1.25em;
              }
            }
            .ui-select {
              padding: 0 1em;
              @include breakpoint($ab-small-down) {
                padding: 0;
              }
              .select-location-arrow {
                right: 3em;
              }
              .restart {
                text-align: right;
                width: 100%;
                display: inline-block;
                padding: 5px 10% 0 0;
                text-transform: uppercase;
                text-decoration: underline;
                font-weight: bold;
                font-family: $ab-font-family-button;
                font-size: 18px;
                @include breakpoint($ab-small-down) {
                  padding: 1em 0;
                  text-align: center;
                  text-decoration: none;
                }
              }
            }
            .virtual__appt__content {
              color: $color-black;
              font-size: 17px;
              font-family: $ab-font-family-virtual-review-location, sans-serif;
            }
          }
        }
        .services-location {
          width: 50%;
          float: left;
          padding: 0 2em;
          @include breakpoint($ab-small-down) {
            width: 100%;
            text-align: center;
          }
          .services-location-head {
            margin: 30px 0 0.55em;
            text-transform: uppercase;
            font-family: $ab-font-family-heading;
            font-size: 1.5em;
          }
          .select-box {
            width: 80%;
            margin: 0 30px;
          }
          .restart {
            display: inline-block;
            float: right;
            margin-top: 1em;
            font-family: $ab-font-family-button;
            font-size: 18px;
            @include breakpoint($ab-small-down) {
              margin-right: 10%;
            }
          }
          .virtual__appt__content {
            color: $color-black;
            font-size: 17px;
            font-family: $ab-font-family-virtual-review-location, sans-serif;
          }
        }
        .book-now-services-header {
          display: none;
        }
        .step-breadcrumb {
          text-align: center;
          margin: 2em auto 0;
          font-size: 1em;
          text-transform: uppercase;
          @include breakpoint($ab-small-down) {
            text-align: center;
          }
        }
        .step-header {
          text-align: center;
          font-family: $ab-font-family-heading;
          font-size: 3em;
          @include breakpoint($ab-small-down) {
            font-size: 1em;
            padding: 0.5em 0;
            margin: 0 0 0.75em;
            font-family: $ab-font-family-service-heading;
            font-weight: lighter;
            border-bottom: 1px solid $ab--color--brown;
          }
          .step-header__inner {
            font-weight: lighter;
          }
        }
        .back-to {
          display: none;
        }
      }
      #booking-step2 {
        max-width: 1200px;
        margin: 0 auto;
        text-align: center;
        clear: both;
        @include breakpoint($ab-landscape-down) {
          margin: 0 3% 50px;
        }
        @include breakpoint($ab-small-down) {
          margin: 0 5% 2em;
        }
        .artist-select-container {
          .ui-select {
            width: 40%;
            margin: 0 auto;
            @include breakpoint($ab-small-down) {
              width: 100%;
            }
            .artist-select {
              width: 100%;
              margin-bottom: 10px;
              .select-box__label {
                width: 50%;
              }
            }
          }
          .artist-info {
            text-decoration: underline;
            cursor: pointer;
            text-transform: uppercase;
            font-family: $ab-font-family-service-heading;
          }
        }
        .selects-container {
          .counter-select-container {
            .location-select {
              width: 80%;
              @include breakpoint($ab-small-down) {
                width: 100%;
              }
              .select-box__label {
                width: 50%;
                border-color: $ab--color--gray--dropdown;
                &:after {
                  border-color: $ab--color--gray--dropdown;
                }
              }
            }
          }
        }
        .step-header {
          text-align: left;
          text-transform: uppercase;
          font-family: $ab-font-family-service-heading;
          font-weight: lighter;
          font-size: 1.5em;
          line-height: 20px;
          margin: 30px 0 20px;
          padding: 0.75em;
          border-bottom: 1px solid $ab--color--brown;
          text-align: center;
          .section-header__inner {
          }
          @include breakpoint($ab-small-down) {
            font-size: 1.3em;
            text-align: center;
            margin: 0 0 1.5em;
            .step-header__inner {
              font-weight: lighter;
            }
          }
        }
        .step-desc {
          display: none; // margin: 0 0 30px; when booking for 2 available
        }
        .appointment-select {
          margin-bottom: 30px;
          padding: 0 5%;
          @include breakpoint($ab-small-down) {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
            padding: 0;
          }
          @include breakpoint($ab-ipad-portrait) {
            padding: 0;
          }
          h4 {
            font-family: $ab-font-family-subheading;
            display: none;
          }
          .calendar-head {
            position: relative;
            text-align: center;
            margin: 0 auto;
            .selects-container {
              margin-top: 1.5em;
            }
            .select-box__options {
              width: 50%;
              left: 25%;
              border-color: $ab--color--gray-light;
              .select-box__option {
                border-color: $ab--color--gray-light;
              }
            }
            .select-box__label:after {
              border-color: $ab--color--gray-light;
            }
            @include breakpoint($ab-small-down) {
              text-align: left;
              height: 3.5em;
              margin: 0 0 4px 0;
              font-size: 17px;
            }
            .appt-book-datepicker-overlay {
            }
            .cal-head-controls {
              position: relative;
              display: inline-block;
              overflow: hidden;
              margin: 0 auto;
              width: 100%;
              height: 50px;
              line-height: 40px;
              .cal-controls {
                margin-top: 5px;
                text-transform: uppercase;
                font-weight: bold;
                font-family: $ab-font-family-heading;
              }
              .cal-controls.previous {
                width: 20%;
                height: auto;
                float: left;
                text-align: left;
              }
              .cal-controls.day-time {
                margin: 0px auto;
                float: left;
                text-align: center;
                width: 60%;
                text-transform: uppercase;
                .morning,
                .afternoon,
                .evening {
                  padding: 0;
                  margin: 5px 19px 0;
                  display: inline-block;
                  font-family: $font--futura-medium;
                }
                .morning.selected,
                .afternoon.selected,
                .evening.selected {
                  /* border-bottom: 1px solid black; */
                  line-height: 12px;
                }
              }
              .cal-controls.next {
                width: 20%;
                height: auto;
                text-indent: -26px;
                float: right;
                text-align: right;
              }
            }
            .cal-head-content {
              position: relative;
              margin: 0 auto;
              width: 100%;
              .date-picker {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 40px;
                cursor: pointer;
                overflow: hidden;
                text-indent: 100%;
                white-space: nowrap;
                z-index: 200;
                @include breakpoint($ab-small-down) {
                  background: inherit;
                  position: relative;
                  height: auto;
                  width: 100%;
                  text-indent: inherit;
                  white-space: inherit;
                  margin-bottom: 13px;
                  z-index: auto;
                }
                .default-cal-copy {
                  @include breakpoint($ab-small-down) {
                    position: relative;
                    font-family: $ab-font-family-heading;
                    font-size: 1.05em;
                    line-height: 45px;
                    text-transform: uppercase;
                    text-align: center;
                    border: 1px solid $ab--color--gray-4;
                    letter-spacing: 0;
                    margin: 0 auto;
                    .select-calendar-arrow {
                      //@include icon("caret--down", after);
                      position: absolute;
                      right: 0;
                      top: 0;
                      width: 26px;
                    }
                  }
                  &:after {
                    @include breakpoint($ab-small-down) {
                      content: '';
                      position: absolute;
                      right: 18px;
                      border: 4px solid transparent;
                      border-top-color: $color-black;
                      top: 44%;
                    }
                  }
                }
              }
              .cal-copy {
                position: relative;
                font-family: $ab-font-family-heading;
                font-size: 1.5em;
                line-height: 40px;
                height: 40px;
                width: 100%;
                margin-left: 0px;
                text-transform: uppercase;
                text-align: center;
                letter-spacing: 0;
                &:after {
                  content: ' >';
                }
                @include breakpoint($ab-small-down) {
                  font-size: 1.1em;
                  border: 1px solid $ab--color--gray--dark;
                  &:after {
                    content: '';
                  }
                }
              }
            }
            .cal_book_now_thru {
              margin-top: -1.5em;
              float: right;
              text-transform: uppercase;
              font-family: $ab-font-family-heading;
              @include icon('calendar', before);
              &:before {
                margin-top: -3px;
                margin-right: 5px;
                font-size: 1.8em;
              }
            }
            .appt-book-datepicker-overlay {
              z-index: 1000;
              position: absolute;
              top: 39px;
              .overlay-content-container {
                top: 0;
                position: relative;
                margin-left: auto;
                margin-right: auto;
                margin-top: 0;
                background-color: $color-white;
                font-family: $ab-font-family-text;
                font-size: 16px;
                border-top: none;
                border: 1px solid $ab--color--gray--dark;
                padding: 0;
                #appt-book-datepicker-calendar-container {
                  border-top: none;
                  border-left: none;
                  border-right: none;
                }
                .calendar_wrapper {
                  .pika-lendar {
                    margin: 0;
                    width: 100%;
                  }
                  .pika-title {
                    margin-top: 0.25em;
                  }
                  .pika-single {
                    color: $color-black;
                    border: none;
                  }
                  .pika-label {
                    font-family: $ab-font-family-heading;
                    font-size: 24px;
                    vertical-align: text-top;
                  }
                  .pika-table {
                    th {
                      color: $ab--color--gray--darker;
                    }
                    abbr[title],
                    abbr {
                      border: none;
                      text-decoration: none;
                      cursor: default;
                    }
                    tbody {
                      height: auto;
                      margin: 0 0 2em;
                      td {
                        font-family: $ab-font-family-text;
                        border: 1px solid $ab--color--gray--dark;
                        width: 14.24%;
                      }
                      tr {
                        td.is-today.is-selected .pika-button {
                          box-shadow: none;
                          background-color: $color-black;
                          color: $color-white;
                        }
                        td.is-disabled .pika-button {
                          color: $ab--color--gray--darker;
                          background-color: $ab--color--gray;
                        }
                        &:hover {
                          background-color: $ab--color--gray-1;
                          td {
                            &.is-today .pika-button {
                            }
                            &.is-selected .pika-button,
                            &.is-today.is-selected .pika-button,
                            &:hover .pika-button {
                              box-shadow: none;
                              background-color: $ab--color--green;
                              color: $color-white;
                            }
                            &.is-disabled:hover .pika-button,
                            .pika-button {
                              background-color: $ab--color--gray-1;
                              color: $ab--color--gray--darker;
                            }
                          }
                        }
                      }
                    }
                  }
                  .pika-button {
                    background: transparent;
                    color: $color-black;
                    border-radius: 0;
                    font-size: 18px;
                    padding: 20px;
                    text-align: center;
                    opacity: 1;
                    @include breakpoint($ab-small-down) {
                      font-size: 24px;
                      padding: 25px 20px;
                      line-height: 5px;
                    }
                    &:hover {
                      background-color: $color-black;
                      color: $color-white;
                      border-radius: 0;
                    }
                  }
                  .is-selected {
                    .pika-button {
                      box-shadow: none;
                      background-color: $color-black;
                      color: $color-white;
                    }
                  }
                  .is-today .pika-button {
                    // background: $ab--color--gray_td_bg_pika_default_week;
                  }
                  .pika-prev,
                  .is-rtl .pika-next,
                  .pika-next,
                  .is-rtl .pika-prev {
                    width: 53px;
                    margin: 1.25em 0 0.25em;
                  }
                  .pika-prev,
                  .is-rtl .pika-next {
                    @include icon('carat--l', before);
                    color: $ab--color--brown;
                    background-image: none;
                    content: '';
                    height: 1.25em;
                    &:before {
                      float: left;
                      text-indent: 14px;
                    }
                    &:hover {
                      background: none;
                    }
                  }
                  .pika-next,
                  .is-rtl .pika-prev {
                    @include icon('carat--r', before);
                    color: $ab--color--brown;
                    background-image: none;
                    content: '';
                    height: 1.25em;
                    &:before {
                      float: left;
                    }
                    &:hover {
                      background: none;
                    }
                  }
                  .pika-prev.is-disabled,
                  .pika-next.is-disabled {
                    visibility: hidden;
                  }
                }
              }
              .overlay-close {
                font-family: $ab-font-family-heading;
                z-index: 10000;
                right: 12px;
                top: 5px;
                position: absolute;
                cursor: pointer;
                overflow: hidden;
                white-space: nowrap;
                text-align: right;
                font-weight: bold;
              }
            }
          }
          .calendar_wrapper {
            background: $ab--color--brown;
            border: 1px solid $ab--color--green;
            border-right: 1px solid $ab--color--green;
            border-bottom: 1px solid $ab--color--gray--dark;
            border-top: 1px solid $ab--color--green;
            margin: 0 auto;
            table {
              width: 100%;
              text-align: center;
              border: none;
              margin: 0 auto;
              font-family: $ab-font-family-text;
              font-size: 16px;
              tbody {
                display: block;
                overflow: auto;
                height: 20em;
              }
              tr {
                display: table;
                width: 100%;
              }
              th {
                font-family: $ab-font-family-text-medium;
                background-color: $ab--color--green;
                border: 1px solid $ab--color--green;
                text-align: center;
                font-size: 16px;
                padding: 15px 0;
                letter-spacing: 0;
                text-transform: uppercase;
                width: 14.24%;
              }
              td {
                text-align: center;
                color: $ab--color--gray--darker;
                cursor: default;
                font-size: 16px;
                letter-spacing: 0;
                background-color: $color-white;
                width: 14.49%;
                height: 55px;
              }
            }
            table.weekdays {
              border-collapse: separate;
              thead {
                tr {
                  //padding-right: 1.5%;
                }
              }
              td {
                border: 1px solid $ab--color--gray--dark;
                background-color: $ab--color--gray;
                text-align: center;
                padding: 1em 0 0.75em;
              }
              td.store_open.enabled {
                background-color: $ab--color--white;
              }
              td.store_open.enabled:hover {
                background-color: $ab--color--gray;
              }
              td.store_open.enabled.selected {
                background-color: $ab--color--green;
                color: $color-white;
              }
            }
          }
        }
        .book-now-bar {
          width: 100%;
          @include breakpoint($ab-small-down) {
            padding: 0;
          }
          #appt-book-book-submit {
            margin: 1em auto 0.5em;
            float: none;
            @include breakpoint($ab-small-down) {
              width: 100%;
              border: none;
            }
          }
          &_txt {
            font-size: 18px;
          }
          .selection-data {
            display: inline-block;
            margin: 0;
            line-height: 0.89em;
            text-transform: uppercase;
            vertical-align: baseline;
          }
          .start-over {
            display: block;
            margin: 0 auto 3em;
            font-family: $ab-font-family-button;
            font-weight: bold;
            font-size: 18px;
          }
        }
        .add-service {
          margin: 1.2em 0 1em;
        }
      }
    }
    // Appointment Booking Confirm your service
    &.confirm {
      .appt-book-page-header {
        .appt-book-page-header-content {
          &__dots {
            margin-bottom: 0px;
          }
        }
      }
      .appt-book-content-header {
        .step-breadcrumb {
          text-align: center;
          text-transform: uppercase;
          @include breakpoint($ab-small-down) {
            margin: 0 0 1em;
          }
          .mobile-only {
            @include breakpoint($ab-ipad-portrait) {
              display: inline !important;
            }
            @include breakpoint($ab-small-down) {
              display: inline !important;
            }
          }
        }
        .section-header {
          text-align: center;
          font-family: $ab-font-family-heading;
          font-size: 3em;
          line-height: 32px;
          margin: 0;
        }
      }
      .appt-book-sections-container {
        margin: 0px auto 0;
      }
      .confirm-container {
        @include breakpoint($ab-small-down) {
          padding: 0 5%;
        }
        .error-messages {
          display: none;
          .error-message {
            display: none;
            color: $ab--color--red;
            margin-bottom: 5px;
          }
        }
        .picker-handle.error {
          border-color: $ab--color--red;
        }
        .booking-step {
          margin-bottom: 22px;
          text-align: left;
          &.step3 {
            padding: 0;
            max-width: 1200px;
            margin: 0 auto;
            min-height: 25em;
            @include breakpoint($ab-landscape-down) {
              padding: 0 15px;
            }
            @include breakpoint($ab-small-down) {
              padding: 0;
            }
          }
          @include breakpoint($ab-small-down) {
            .step3 {
              .step-header {
                margin-left: 20px;
                margin-right: 20px;
              }
              .error-messages,
              .sign-in-container,
              #book-appt-container {
                padding: 0 20px;
              }
            }
          }
          .signin-block {
            &__field-wrapper {
              width: 49.3%;
              height: 35px;
              margin: 10px 0;
              @include breakpoint($ab-landscape-down) {
                margin: 10px 0 0.5em;
              }
              @include breakpoint($ab-small-down) {
                width: 100%;
              }
              &.login-password.guest {
                width: 100%;
              }
              &.registration-first-name,
              &.registration-email-address,
              &.login-email {
                float: left;
                input {
                  width: 100%;
                }
              }
              &.registration-last-name,
              &.registration-mobile-phone,
              &.login-password {
                float: right;
                input {
                  width: 100%;
                }
              }
              &.login-submit {
                float: right;
                width: auto;
                @include breakpoint($ab-small-down) {
                  float: none;
                  width: 100%;
                  height: auto;
                  clear: both;
                }
              }
            }
          }
        }
        .sign-in-container {
          display: block;
          overflow: hidden;
          width: 100%;
          height: auto;
          letter-spacing: 0.03em;
          @include breakpoint($ab-small-down) {
            letter-spacing: 0;
            margin-top: 10px;
          }
          h4,
          .at-h4-appt-book-signin {
            margin-bottom: 0;
            font-size: 16px;
            color: $color-black;
            font-size: 12px;
            font-weight: 100;
            text-transform: none;
            line-height: normal;
            @include breakpoint($ab-small-down) {
              margin-bottom: 3px;
              font-size: 15px;
            }
            &.sign-in-head,
            &.guest-head {
              font-size: 16px;
              font-weight: 100;
              text-transform: underline;
              vertical-align: middle;
              display: inline-block;
            }
          }
          &__sub-text {
            margin-top: 1em;
          }
          .sign-in-link-container {
            @include clearfix;
            margin: 0 0 0.75em;
            @include breakpoint($ab-small-down) {
              margin: 0;
            }
            a {
              font-size: 16px;
              color: $color-black;
              border-color: $color-black;
              display: inline-block;
              margin-right: 7px;
              text-decoration: underline;
            }
            .sign-in-head {
              margin-top: 0;
              margin: 0 5px 0 0;
            }
          }
          .guest-link-container {
            display: none;
            margin: 0 0 0.75em;
            @include breakpoint($ab-small-down) {
              margin: 0;
            }
            h4 {
              padding-right: 5px;
            }
            a {
              font-size: 16px;
              color: $color-black;
              border-color: $color-black;
              text-decoration: underline;
            }
            .guest-head {
              display: inline-block;
              margin: 0 5px 0 0;
            }
          }
          .sign-in-form-container {
            display: none;
            position: relative;
            overflow: visible !important;
            #appt-book-login-form {
              input {
                height: 40px;
                padding: 4px 1em;
                @include input-placeholder {
                  font-family: $ab-font-family-text;
                  font-size: 16px;
                  line-height: 28px;
                  color: $ab--color--gray-2;
                  background-color: $ab--color--white;
                }
                @include breakpoint($ab-small-down) {
                  margin-bottom: 0px;
                  width: 100%;
                }
              }
              .login__submit {
                text-transform: uppercase;
                cursor: pointer;
                letter-spacing: 0;
                &.login__email {
                  @include breakpoint($ab-small-down) {
                    width: 100%;
                    float: none;
                  }
                }
                &.login__password {
                  margin-bottom: 0;
                  @include breakpoint($ab-small-down) {
                    float: none;
                    margin-bottom: 0px;
                    padding-top: 0.5em;
                    width: 100%;
                  }
                }
              }
              a.login_forgot {
                @include breakpoint($ab-small-down) {
                  margin-bottom: 15px;
                  margin-left: 0;
                  display: block;
                }
              }
            }
          }
        }
        #book-appt-container {
          display: block;
          overflow: hidden;
          width: 100%;
          height: auto;
          margin: 0 0 55px;
          letter-spacing: 0;
          @include breakpoint($ab-small-down) {
            margin-bottom: 0;
          }
          a {
            color: $color-black;
          }
          h4.book-appt-header {
            margin-bottom: 8px;
            font-size: 12px;
            color: $color-black;
            line-height: 18.4px;
            text-transform: none;
            font-family: $ab-font-family;
            @include breakpoint($ab-small-down) {
              margin-bottom: 10px;
            }
          }
          #book-appt-fieldset {
            margin-bottom: 15px;
            .register-password-container {
              label.label {
                display: none;
              }
            }
            &.no-last-name {
              .registration-first-name {
                width: 100%;
              }
              .registration-last-name {
                display: none;
              }
            }
            input {
              font-size: 16px;
              height: 40px;
              text-transform: none;
              padding: 4px 1em;
              @include input-placeholder {
                font-family: $ab-font-family-text;
                line-height: 28px;
                color: $ab--color--gray-2;
                background-color: $ab--color--white;
              }
              &:disabled {
                color: $ab--color--gray--darker;
                background-color: $ab--color--gray;
              }
            }
            span {
              width: 90%;
            }
          }
          .registration {
            &__privacy_policy-container {
            }
          }
          input,
          fieldset {
            font-size: 12px;
            letter-spacing: 0.02em;
            @include breakpoint($ab-small-down) {
              width: 100%;
              height: 40px;
              padding: 0 12px;
              margin-bottom: 0px;
            }
            &.appt-book-first-name,
            &.appt-book-email {
              @include breakpoint($ab-small-down) {
                width: 100%;
              }
            }
            &.appt-book-last-name,
            &.mobile-phone-fieldset,
            &.appt-book-mobile {
              @include breakpoint($ab-small-down) {
                width: 100%;
              }
            }
            &.appt-book-mobile {
              box-shadow: none;
              -webkit-appearance: none;
            }
            &.mobile-phone-fieldset {
              border: none;
              padding: 0;
              margin: 0;
              overflow: hidden;
            }
            &.register__password {
              width: 100%;
            }
            &.placeholder {
              color: $ab--color--gray-2;
            }
          }
          fieldset {
            @include breakpoint($ab-small-down) {
              margin: 0 0 15px;
              padding: 0;
            }
          }
          .picker {
            &-checkbox {
              margin-left: 90px;
              @include breakpoint($ab-small-down) {
                margin-left: 0;
                margin-right: 0;
              }
            }
            &-handle {
              border-color: $color-black;
            }
            &-flag {
              &:before {
                color: $color-black !important;
              }
            }
          }
          p.appt-book-register-password-text {
            margin: 10px auto 49px;
            @include breakpoint($ab-small-down) {
              margin-bottom: 24px;
            }
          }
          .appt-book-note {
            width: 100%;
            display: block;
            height: 149px;
            margin-bottom: 16px;
            font-family: $ab-font-family;
            font-size: 16px;
            letter-spacing: 0.03em;
            @include breakpoint($ab-small-down) {
              letter-spacing: 0.03em;
              height: 90px;
              margin-bottom: 12px;
              font-size: 12px;
            }
          }
          .registration__privacy-list,
          .registration__email-list,
          .registration__sms-list {
            @include clearfix;
            display: block;
            font-size: 16px;
            line-height: 24px;
            width: 100%;
            margin: 0;
            letter-spacing: 0;
            padding: 2px 2px 2px 0;
            input {
              width: 15px;
              height: 15px;
              float: left;
              margin: 2px 5px 5px 0;
              @include breakpoint($ab-small-down) {
                margin: 4px 0 0;
                padding: 0;
              }
              &.error + span,
              &.error + span a {
                color: $ab--color--red;
              }
            }
            &-text {
              width: 90%;
              display: inline;
              text-transform: none;
              color: $color-black;
              margin-top: 0;
              vertical-align: top;
              &:before {
                margin-top: 3px;
              }
            }
            @include breakpoint($ab-small-down) {
              margin-left: 0;
              margin-bottom: 10px;
              width: 100%;
            }
          }
          /* .registration__sms-list {
            @include breakpoint($ab-small-down) {
              width: auto;
              float: none;
            }
          } */
        }
        .booking-container {
          .appt-book-book-submit {
            float: right;
            text-transform: uppercase;
            cursor: pointer;
            letter-spacing: 0;
            margin: 1em auto 0.5em;
            &.disabled {
              background-color: $ab_color--btn-gray-disabled;
              color: $color-white;
              cursor: default;
            }
            @include breakpoint($ab-small-down) {
              margin-top: 5px;
              width: 100%;
              float: left;
              margin-bottom: 30px;
            }
          }
          .start-over {
            display: block;
            margin-top: 24px;
            text-transform: uppercase;
            letter-spacing: 0;
          }
        }
        input[type='password']::-ms-reveal,
        input[type='password']::-ms-clear {
          display: none;
        }
      }
    }

    // Appointment Booking Thank you page
    &.confirmation {
      position: relative;
      border-top: 1px solid $ab--color--gray--lightest;
      margin: 0 auto;
      .appt-book-page-header-menu {
        .submenu {
          &--sticky {
            &.appointment-nav {
              @include breakpoint($ab-small-down) {
                margin: -27px 0 0 0;
              }
            }
          }
        }
      }
      .appt-book-page-header-content {
        &__title {
        }
        &__dots {
          display: none !important;
        }
        &__subhead {
          hr {
            border-top: 1px solid $ab--color--gray-light_1;
            margin-top: 18px;
          }
          h4 {
            font-size: 25px;
            margin-top: 15px;
            @include breakpoint($ab-small-down) {
              margin: 0;
            }
          }
        }
      }
      .appt-book-section-content {
        width: 90%;
        margin: 0 auto;
        @include breakpoint($ab-small-down) {
          width: 100%;
          padding: 0 1em;
        }
        .appt-book-content-header {
          margin: 0;
          text-align: center;
          font-size: 16px;
          @include breakpoint($ab-small-down) {
            font-size: 14px;
          }
          .section-header {
            font-size: 1.5em;
            margin: 0.25em 0 0;
            padding: 0 0 0.25em;
            text-transform: uppercase;
            font-family: $ab-font-family-subheading;
            border-bottom: 1px solid $ab--color--gray--dark;
            font-weight: lighter;
          }
          p {
            width: 56%;
            margin: 18px auto;
          }
        }
      }
      #appointment-book-sections {
        margin: 25px auto;
      }
      .confirmation-container {
        position: relative;
        width: 100%;
        margin: 0 auto 1.25em;
        &__item {
          text-align: center;
          font-size: 14px;
          line-height: 18.4px;
          padding: 2em 0 0;
          @include breakpoint($ab-small-down) {
            float: none;
            width: 100%;
            min-height: auto;
            text-align: center;
          }
          h4 {
            font-family: $ab-font-family-heading;
            letter-spacing: 0;
            text-transform: uppercase;
            line-height: 18px;
            color: $color-black;
            margin: 0;
            margin-bottom: 5px;
            font-size: 18px;
            font-weight: bold;
            @include breakpoint($ab--large-down) {
              width: 100%;
            }
            @include breakpoint($ab-small-down) {
              text-align: center;
              font-size: 18px;
            }
          }
          .appt-date {
            color: $color-black;
            @include breakpoint($ab-small-down) {
              width: 100%;
            }
            span {
              &.appt-date-day,
              &.appt-date-hours {
                text-transform: none;
              }
            }
          }
          .appt-date-day {
            @include breakpoint($ab-small-down) {
              color: $color-black !important;
            }
          }
          .appt-date-hours {
            @include breakpoint($ab-small-down) {
              color: $color-black !important;
            }
          }
          a {
            display: block;
            text-align: left;
            margin: 0;
            color: $color-black;
            letter-spacing: 0;
            line-height: 18px;
            font-weight: normal;
          }
          &.confirmation-services {
            .content {
              &__item {
                display: block;
                vertical-align: top;
                @include breakpoint($ab-small-down) {
                  width: 100%;
                  padding: 0;
                }
                &--img {
                  width: 13em;
                  float: left;
                  background-color: $color-black;
                  height: 15em;
                  margin: 0 1.5em 0 0;
                }
                &--name {
                  font-size: 16px;
                  line-height: 30px;
                  margin: 0.25em 0 0;
                }
                &--desc {
                  text-align: left;
                  font-size: 16px;
                  line-height: 24px;
                }
              }
            }
          }
          &.confirmation-date {
            padding: 0.75em 0 0;
            .content {
              width: 100%;
              .appt-date {
                &__hdr {
                  font-family: $font--futura-medium;
                }
                &__content {
                  &--item {
                    list-style-type: none;
                    padding: 0.5em 0;
                    font-size: 16px;
                    line-height: 30px;
                    vertical-align: top;
                    text-align: center;
                  }
                }
              }
            }
          }
          &.confirmation-location {
            padding: 0.5em 0 0;
            font-weight: 100;
            .location-hdr {
              margin: 0 0 0.5em;
            }
            .content {
              font-size: 16px;
              line-height: 24px;
            }
            .location-actions {
              margin-top: 1em;
              .location-direction-links {
                font-size: 18px;
                display: inline-block;
                margin: 0 1em 0 0;
              }
            }
          }
          &.confirmation-instructions {
            font-size: 16px;
            font-weight: 100;
            padding: 1em 4em 0;
            margin: 1em 0 0;
            line-height: 25px;
            border-top: 1px solid $ab--color--gray--lighter;
            @include breakpoint($ab-small-down) {
              padding: 1em 0.5em 0;
            }
          }
        }
        @include breakpoint($ab-small-down) {
          border-bottom: 0px;
          padding-bottom: 0px;
        }
        .add-cal-link {
          border-bottom: 0;
          display: inline-block;
          padding-bottom: 0px;
          margin-bottom: 0px;
          cursor: pointer;
          line-height: 18px;
          text-transform: uppercase;
          height: 24px;
          font-size: 18px;
          font-family: $ab-font-family-button;
          &:hover {
            text-decoration: underline;
          }
          @include breakpoint($ab-small-down) {
            padding-bottom: 0;
          }
        }
        .cals-container {
          width: 150px;
          display: none;
          margin-top: 5px;
          position: absolute;
          top: 1.5em;
          left: -1.75em;
          padding: 0.5em 0 0.75em;
          border: 1px solid $color-black;
          background-color: $color-white;
          .appt-book-link {
            border: none;
            display: block;
            font-size: 16px;
            text-align: center;
            &:first-child {
              padding-top: 5px;
            }
            &:last-child {
              padding-bottom: 5px;
            }
          }
          &.expanded {
            display: block;
          }
        }
        .appt-book-link {
          line-height: 18.4px;
          font-family: $ab-font-family-button;
          font-size: 16px;
          letter-spacing: 0;
          margin: 0.5em 0;
          border-color: 1px solid $color-black;
        }
        .artist {
          width: 100%;
          h4 {
            border: none;
            padding-bottom: 0;
          }
          span {
            color: $ab--color--gray--dark;
            text-transform: capitalize;
          }
          @include breakpoint($ab-small-down) {
            display: none;
            margin-top: 0;
            border: none;
            h4 {
              border-bottom: 1px solid $ab--color--gray--lightest;
              padding-bottom: 5px;
            }
          }
        }
        .location {
          a {
            border-bottom: 0;
            letter-spacing: 0;
            line-height: 18px;
            display: inline-block;
            height: 24px;
            @include breakpoint($ab-small-down) {
              font-size: 12px;
              padding-bottom: 0;
            }
            &.location-phone {
              color: $ab--color--gray-light_1;
              border-bottom: 1px solid $ab--color--gray-light_1;
              font-size: 24px;
              @include breakpoint($ab-small-down) {
                // font-size: 21px;
                font-size: 12px;
                border-bottom: 1px solid $color-black;
                color: $color-black;
              }
            }
          }
          .location-info {
            margin-right: 10px;
            @include breakpoint($ab-ipad-landscape) {
              width: 100%;
              float: none;
            }
            @include breakpoint($ab-ipad-portrait) {
              width: 100%;
              float: none;
            }
            @include breakpoint($ab-small-down) {
              margin: 0;
              width: 100%;
              text-align: center;
            }
            span {
              @include breakpoint($ab-small-down) {
                color: $color-black;
              }
            }
          }
          .location-name,
          .location-address,
          .location-address2,
          .location-town-postcode {
            @include breakpoint($ab-small-down) {
              margin: 0;
            }
          }
          .appt-date-cal {
            margin: 0 auto;
            position: relative;
            display: inline-block;
          }
          .virtual-confirm-content {
            font-size: 16px;
          }
          .virtual-zoom-link a {
            font-family: $ab-font-family-text;
            font-size: 16px;
            text-transform: none;
            text-decoration: underline;
          }
        }
      }
      .appts-actions-container {
        text-align: center;
        border-top: 1px solid $ab--color--gray--lighter;
        padding: 1em 0 0;
        a.appts-button {
          @include ab-button;
          @include breakpoint($ab-small-down) {
            width: 100%;
          }
        }
        @include breakpoint($ab-small-down) {
          margin-top: 0;
        }
        .action-items {
          &__item {
            display: block;
            font-size: 16px;
            vertical-align: top;
            text-align: center;
            font-weight: 100;
            &--hdr {
              margin: 0 0 0.5em;
              font-weight: 100;
            }
            @include breakpoint($ab-small-down) {
              margin: 0 0 1em;
            }
          }
        }
      }
    }

    // My Appointments
    .my-appointments {
      max-width: 1200px;
      margin: 0 auto;
      @include breakpoint($ab-ipad-landscape) {
        margin: 40px auto;
        padding: 0 20px 0;
      }
      @include breakpoint($ab-ipad-portrait) {
        margin: 40px auto;
        padding: 0 25px 0;
      }
      @include breakpoint($ab-small-down) {
        max-width: 100%;
        text-align: center;
      }
      #appt-book-my-appointments-no-appts {
        margin: 0 0 1.5em;
      }
      #appt-book-my-appointments-no-signin {
        width: 100%;
        overflow: hidden;
        margin-bottom: 75px;
        .section-header {
          font-family: $ab-font-family-subheading;
          font-size: 1.5em;
          font-weight: lighter;
          margin: 0.25em 0 0;
          padding: 0 0 0.25em;
          font-weight: 100;
          text-transform: uppercase;
          border-bottom: 1px solid $ab--color--gray--dark;
          @include breakpoint($ab-small-down) {
            font-size: 1em;
            padding: 0 0 0.5em;
          }
        }
        .sign-in-container {
          .sign-in-form-container {
            margin: 1em 0 0;
            @include breakpoint($ab-small-down) {
              padding: 0 1em;
            }
            .signin-block {
              &__field-wrapper {
                width: 49.3%;
                height: 40px;
                @include breakpoint($ab-small-down) {
                  width: 100%;
                  margin-bottom: 1em;
                }
                input[type='text'],
                input[type='email'],
                input[type='tel'],
                input[type='password'] {
                  width: 100%;
                  padding: 8px 1em;
                  @include breakpoint($ab-small-down) {
                    padding: 0 1em;
                  }
                  @include input-placeholder {
                    padding: 8px;
                  }
                }
                &.login-email {
                  float: left;
                }
                &.login-password {
                  float: right;
                }
                .login__email {
                  width: 100%;
                }
                .login__password {
                  width: 100%;
                }
              }
            }
            .login_forgot {
              display: block;
              margin-left: 75%;
              position: relative;
              text-decoration: none;
              top: 45px;
              @include breakpoint($ab-small-down) {
                margin-#{$ldirection}: 0;
                top: 0;
              }
            }
            .login__submit {
              @include ab-button;
              margin-top: 20px;
              float: right;
              @include breakpoint($ab-small-down) {
                width: 100%;
                float: auto;
              }
            }
          }
        }
        .registration-container {
          .signin-block {
            &__field-wrapper {
              width: 49.3%;
              height: 40px;
              margin: 10px 0;
              @include breakpoint($ab-small-down) {
                width: 100%;
              }
              input[type='text'],
              input[type='email'],
              input[type='tel'],
              input[type='password'] {
                width: 100%;
                padding: 8px 1em;
              }
              &.registration-first-name,
              &.registration-email-address {
                float: left;
              }
              &.registration-last-name,
              &.registration-mobile-phone {
                float: right;
              }
            }
          }
          .registration {
            &__email-list {
              @include breakpoint($ab-small-down) {
                display: block;
              }
            }
            &__submit {
              @include ab-button;
              float: right;
              @include breakpoint($ab-small-down) {
                margin-top: 25px;
              }
            }
          }
          input[type='password'] {
            width: 100%;
            padding: 8px 1em;
          }
          .registration__privacy_policy-container,
          .registration__sms-list {
            display: none;
          }
          .registration-password-container {
            padding-top: 0.5em;
            clear: both;
          }
        }
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        input {
          @include input-placeholder {
            font-family: $ab-font-family-text;
            font-size: 16px;
            line-height: 28px;
            color: $ab--color--gray-2;
            background-color: $ab--color--white;
          }
        }
      }
      .button-container {
        overflow: hidden;
        text-align: center;
        margin: 2em auto 4em;
        @include breakpoint($ab-small-down) {
          margin: 0 auto 4em;
        }
        .ab-button {
          @include ab-button;
          float: none;
          @include breakpoint($ab-small-down) {
            width: 90%;
          }
        }
      }
      .current {
        text-align: center;
        margin-bottom: 1.5em;
        @include breakpoint($ab-small-down) {
          margin-bottom: 0;
        }
        .appointment-container {
          border: none;
          .appointment-details {
            .date-time {
              margin: 1em 0 0;
              padding: 0;
              &__content {
                &--item {
                  list-style-type: none;
                  margin-top: 1em;
                }
              }
            }
            .location {
              margin: 1em 0 0;
              padding: 0;
            }
            .artist {
              h4 {
                @media (min-width: 1025px) {
                  width: 7%;
                }
              }
            }
          }
        }
      }
      .past {
        margin: 2.5em 0 0;
        text-align: center;
        @include breakpoint($ab-small-down) {
          margin: 0 0 2em;
        }
        .appointment-container {
          border-bottom: 1px solid $ab--color--gray--lighter;
          overflow: hidden;
          padding: 0;
          @include breakpoint($ab-small-down) {
            padding: 0;
            margin-bottom: 0;
          }
          h4 {
            color: $color-black;
          }
          .appointment-details {
            margin: 0 0 1em;
            @include breakpoint($ab-small-down) {
              margin: 0;
              padding-bottom: 1em;
            }
            .your-services {
              h4 {
                @media (min-width: 1025px) {
                  width: 7%;
                }
              }
            }
            &__summary {
              display: block;
              font-size: 12px;
              @include breakpoint($ab-small-down) {
                display: none;
              }
            }
            &__content {
              text-align: center;
              &--item {
                margin: 0 0 1em;
                &:last-child {
                  margin: 0;
                }
                &.date-time {
                  margin: 0 0 1em;
                }
              }
              &--btn {
                position: absolute;
                top: 0;
                right: 0;
                text-decoration: underline;
                font-family: $font--futura-medium;
              }
            }
          }
        }
      }
      .past-appt-head {
        .section-header {
          &__hdr {
            cursor: pointer;
            position: relative;
          }
          &__sub {
            display: block;
            font-family: $ab-font-family-text;
            font-size: 16px;
            text-transform: capitalize;
            margin: 0.5em 0 1em;
          }
        }
        .appointment-header {
          border-bottom: 1px solid $color-black;
          padding-left: 2em;
          @include breakpoint($ab-small-down) {
            display: none;
          }
          &__item {
            float: left;
            width: 30%;
            h4 {
              color: $color-black;
            }
          }
        }
      }
      .section-header {
        font-family: $ab-font-family-subheading;
        border-bottom: 1px solid $ab--color--gray--lighter;
        margin: 0 0 1em;
        text-align: center;
        text-transform: uppercase;
        @include breakpoint($ab-small-down) {
          padding: 0 0 0.5em;
          margin: 1em 0 0.5em;
        }
        &:after {
          @include breakpoint($ab-small-down) {
            font-size: 12px;
            margin-bottom: 0;
            position: absolute;
            right: 1em;
            top: 1.75em;
          }
        }
        &__hdr {
          font-size: 1.5em;
          font-weight: lighter;
          margin: 0 0 0.5em;
          @include breakpoint($ab-small-down) {
            font-size: 1em;
            margin-bottom: 0;
          }
        }
      }
      .expando-block {
        @include breakpoint($ab-large-up) {
          border: none;
        }
      }
      .expando-block--alt {
        .section-header {
          @include breakpoint($ab-small-down) {
            //@include icon('caret--right', after);
          }
        }
      }
      .appointment-container {
        width: 50%;
        display: inline-block;
        margin: 0;
        border-right: 1px solid $ab--color--gray--lighter;
        border-bottom: 1px solid $ab--color--gray--lighter;
        vertical-align: top;
        &:nth-child(even),
        &:last-child {
          border-right: none;
        }
        @include breakpoint($ab-small-down) {
          width: 100%;
          border: none;
          padding-bottom: 0px;
          padding-left: 1em;
          padding-right: 1em;
          margin-bottom: 1em;
        }
        &:last-child {
          @include breakpoint($ab-small-down) {
            margin-bottom: 0;
          }
        }
      }
      .appointment-details {
        position: relative;
        width: 100%;
        margin: 0px auto;
        &__item {
          font-size: 16px;
          line-height: 24.4px;
          text-align: center;
          padding: 0.5em 0 0;
          @include breakpoint($ab-small-down) {
            min-height: auto;
            clear: both;
            font-size: 16px;
          }
          h4,
          .at-h4-appt-book-service {
            font-family: $ab-font-family-heading;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0;
            text-transform: uppercase;
            text-align: center;
            line-height: 18px;
            color: $ab--color--brown;
            margin: 0;
            width: 100%;
            @include breakpoint($ab-small-down) {
              font-size: 16px;
              margin-bottom: 5px;
            }
          }
          .appt-date {
            @include breakpoint($ab--large-down) {
              margin-left: 0px;
            }
            span {
              float: left;
              &.appt-date-day,
              &.appt-date-hours {
                text-transform: none;
                margin-right: 1em;
              }
            }
            .appt-date-hours {
            }
          }
          span,
          a {
            display: block;
            margin: 0;
            color: $color-black;
            letter-spacing: 0;
            line-height: 18px;
            font-family: $ab-font-family;
            font-weight: 100;
          }
          .services-name {
            margin: 0.25em 0;
            font-size: 16px;
            line-height: 32px;
            @include breakpoint($ab-small-down) {
              line-height: auto;
            }
          }
        }
        @include breakpoint($ab-small-down) {
          border-bottom: 0px;
          padding-bottom: 0px;
          margin: 0 auto;
        }
        .location {
          a {
            border-bottom: 0;
            padding-bottom: 1px;
            font-size: 18px;
            font-family: $ab-font-family-button;
            margin: 1em 0 0;
            letter-spacing: 0;
            color: $color-black;
            line-height: 18px;
            display: inline-block;
            &.location-phone {
              color: $ab--color--gray-light_1;
              border-bottom: 1px solid $ab--color--gray-light_1;
              font-size: 24px;
              @include breakpoint($ab-small-down) {
                font-size: 12px;
                border-bottom: 1px solid $color-black;
                color: $color-black;
              }
            }
          }
          .virtual-zoom-link {
            a {
              font-family: $ab-font-family-text;
              text-transform: none;
              text-decoration: underline;
            }
          }
          .location-info {
            @include breakpoint($ab-landscape-down) {
              margin-left: 0;
            }
            span {
              &.location-town-postcode,
              &.location-name {
                margin: 0 8px 0 0;
                @include breakpoint($ab-small-down) {
                  margin: 0;
                }
              }
            }
          }
        }
        .location-actions {
          margin: 1em 0 0;
          .location-direction-links {
            float: none;
            display: inline-block;
            margin: 0 1em 0 0;
          }
          .appt-date-cal {
            position: relative;
            margin: 0 auto;
            display: inline-block;
            .add-cal-link {
              border-bottom: 0;
              display: inline-block;
              padding-bottom: 0px;
              margin-bottom: 0px;
              cursor: pointer;
              line-height: 18px;
              text-transform: uppercase;
              height: 24px;
              font-size: 18px;
              font-family: $ab-font-family-button;
              &:hover {
                text-decoration: underline;
              }
            }
            .cals-container {
              width: 160px;
              display: none;
              position: absolute;
              border: 1px solid $color-black;
              background-color: $color-white;
              z-index: 1;
              margin-left: -2em;
              .appt-book-link {
                border: none;
                display: block;
                padding: 0 15px;
              }
              &.expanded {
                display: block;
              }
              .appt-book-link {
                font-size: 16px;
                line-height: 18.4px;
                font-family: $ab-font-family-button;
                letter-spacing: 0;
                margin: 0.5em 0;
                border-color: 1px solid $color-black;
              }
            }
          }
        }
      }
      .appointments-buttons {
        padding: 12px 0;
        float: left;
        width: 100%;
        text-align: center;
        font-size: 12px;
        line-height: 18.4px;
        @include breakpoint($ab-small-down) {
          text-align: center;
        }
        &__list {
          &--item {
            margin: 0;
            display: inline-block;
            @include breakpoint($ab-small-down) {
              margin: 0 0 1em;
              width: 100%;
            }
            &:last-child {
              margin: 0 0 1em 0;
            }
          }
        }
        a {
          @include ab-button;
          margin: 0 0.75em 0 0;
          @include breakpoint($ab-small-down) {
            text-align: center;
            margin: 0;
            width: 100%;
          }
        }
      }
    }
    .appointment-book-sections {
      margin: 0 auto;
      max-width: 1280px;
    }
    .appt-book-content-header {
      margin: 20px auto;
    }
    .no-appts-content {
      text-align: center;
      @include breakpoint($ab-small-down) {
        padding: 0 1em;
      }
    }
    .ui-select {
      @include breakpoint($ab-small-down) {
        position: relative;
      }
      .select-location-arrow {
        @include breakpoint($ab-small-down) {
          position: absolute;
          right: 0.5em;
          top: 0.75em;
          width: 28px;
          height: 35px;
          background-position: 50% 50%;
        }
      }
      select.selectBox {
        font-family: $ab-font-family-heading;
        font-size: 0.95em;
        @include breakpoint($ab-small-down) {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: transparent
            url(/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_down.jpg)
            no-repeat;
          background-position: calc(100% - 5px) 50%;
          padding-right: 30px;
        }
      }
    }
  }

  // GNAV General
  .appt-book-page-header-menu {
    width: 100%;
    /*
    @include breakpoint($ab-ipad-portrait) {
      height: 75px !important;
    }
    @include breakpoint($ab-ipad-landscape) {
      height: 75px !important;
    }
    */
    .submenu--sticky {
      font-family: $ab-font-family;
      margin: 0 auto;
      max-width: 1280px;
      .submenu__content {
        float: left;
        height: 99px;
        padding: 24px 0 0 33px;
        @include breakpoint($ab-ipad-portrait) {
          padding: 0 0 0 10px;
        }
        @include breakpoint($ab-ipad-landscape) {
          padding: 0 0 0 10px;
        }
        @include breakpoint($ab-small-down) {
          padding: 0px;
          border-bottom: 1px solid black;
          width: 100%;
          height: 125px;
        }
        @include breakpoint($xxsmall-down) {
          height: 126px;
        }
        .submenu__header {
          border-right: 1px solid $color-black;
          display: inline-block;
          font-size: 22px;
          padding: 0 60px 1px 8px;
          height: 75px;
          @include breakpoint($ab-small-down) {
            padding: 0px;
            border: 0;
            height: auto;
          }
          h3 {
            line-height: 80px;
            margin: 0px;
            @include breakpoint($ab-ipad-portrait) {
              margin: 0 0 0 23px;
            }
            @include breakpoint($ab-ipad-landscape) {
              margin: 0 0 0 23px;
            }
            a {
              font-size: 20px;
              @include breakpoint($ab-small-down) {
                font-size: 36px;
                letter-spacing: 5px;
              }
              @include breakpoint($xxsmall-down) {
                letter-spacing: 3px;
              }
            }
            .appointment-header-nav__link-arrow {
              display: none;
            }
          }
        }
        .submenu__nav {
          display: inline-block;
          @include breakpoint($ab-ipad-portrait) {
            margin-left: -18px;
          }
          @include breakpoint($ab-ipad-landscape) {
            margin-left: -18px;
          }
          @include breakpoint($ab-small-down) {
            padding: 0 10px;
            border-bottom: 0;
          }
          @include breakpoint($xxsmall-down) {
            padding: 0 3px;
          }
          li {
            display: inline-block;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 80px;
            padding: 0 0 0 70px;
            @include breakpoint($ab-ipad-portrait) {
              padding: 0 0 0 40px;
            }
            @include breakpoint($ab-small-down) {
              padding: 0px;
            }
            .link {
              &.active {
                text-decoration: underline;
                @include breakpoint($ab-small-down) {
                  text-decoration: none;
                  background: $color-white;
                  color: $color-black;
                  border-bottom: 1px solid $color-white;
                }
                @include breakpoint($xxsmall-down) {
                  border-bottom: 2px solid $color-white;
                }
              }
              @include breakpoint($ab-small-down) {
                text-decoration: none;
                background: $color-black;
                color: $color-white;
                border: 1px solid $color-black;
              }
              @include breakpoint($xxsmall-down) {
                font-size: 12px;
                padding: 0px 2px;
              }
            }
          }
        }
        a {
          text-decoration: none;
        }
      }
    }
  }

  // Look Book AB
  .section-lookbook {
    .site-footer {
      @include breakpoint($width-large_) {
        margin: -20px 0 0 0;
      }
      @include breakpoint($ab-small-down) {
        margin: -30px 0 0 0;
      }
    }
  }
  .look-book {
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    &__slide {
      color: $color-black;
      display: none;
      background-color: $color-white;
      &:first-child {
        display: block;
      }
      .slick-initialized & {
        display: block;
      }
      &__inner {
        margin: 0 auto;
        height: auto;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 0;
        &_img {
          @include breakpoint($ab-large-up) {
            &--center {
              width: auto;
            }
            &--right {
              float: right;
              width: 70%;
            }
            &--left {
              float: left;
              width: 70%;
            }
          }
        }
      }
      &__link {
        display: block;
        width: 100%;
        height: 100%;
        color: $ab--color--gray--black;
        text-decoration: none;
      }
      &__wrap {
        height: auto;
        padding: 0;
      }
      &__abstract {
        position: absolute;
        top: 0;
        text-align: center;
        padding: 0 15px;
        max-width: 1024px;
        &__inner {
          margin-top: 0;
          margin-left: 60px;
          @media (min-width: 1025px) and (max-width: 1230px) {
            margin-left: 26px;
          }
          @include breakpoint($ab-ipad-portrait) {
            margin-left: 0;
          }
          @include breakpoint($ab-ipad-landscape) {
            margin-left: 25px;
          }
          @include breakpoint($ab-small-down) {
            margin: 40px 5px 60px 5px;
          }
        }
        &--default-y-pos {
          top: 34px;
          @include breakpoint($ab-small-down) {
            top: auto;
          }
        }
        &--center {
          width: 100%;
          .look-book__abstract__inner {
            margin-left: auto;
            margin-right: auto;
          }
        }
        &--right {
          right: 0;
        }
        &--align-left {
          text-align: left;
        }
        &--align-right {
          text-align: right;
        }
        @include breakpoint($ab-small-down) {
          position: relative;
          color: $color-black;
          .look-book__link {
            font-size: 12px;
            letter-spacing: 0.24em;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom-width: 3px;
            padding: 0 0 6px;
            &:hover {
              border-color: $color-white;
            }
          }
        }
      }
      &__title {
        border-bottom: 1px solid $ab--color--gray--dark;
        width: 265px;
        @include breakpoint($ab-small-down) {
          width: 100%;
        }
        @media (min-width: 1025px) and (max-width: 1105px) {
          width: 230px;
        }
        &__header {
          font-size: 36px;
        }
        @include breakpoint($ab-ipad-portrait) {
          width: 190px;
        }
        @include breakpoint($ab-ipad-landscape) {
          width: 220px;
        }
      }
      &__headline {
        width: 340px;
        text-transform: uppercase;
        margin-top: 15px;
        &__header {
          font-size: 25px;
          margin: 22px 0 0 0;
          /* font-family: $ab-font-family-gothic; */
          @include breakpoint($ab-small-down) {
            font-size: 24px;
            letter-spacing: -1px;
          }
        }
        &__subheader {
          font-size: 24px;
          text-transform: uppercase;
          @include breakpoint($ab-small-down) {
            font-size: 18px;
          }
        }
      }
      &__description {
        font-size: 12px;
        margin: 0;
        padding: 0;
        width: 70%;
        font-family: $ab-font-family;
        line-height: 18.4px;
        letter-spacing: 0;
        @include breakpoint($ab-ipad-portrait) {
          width: 200px;
        }
        @include breakpoint($ab-ipad-landscape) {
          width: 220px;
        }
        @media (min-width: 561px) and (max-width: 768px) {
          width: 50%;
        }
        @media (min-width: 371px) and (max-width: 560px) {
          width: 80%;
        }
        @media (min-width: 320px) and (max-width: 370px) {
          width: 100%;
        }
        &__subheader {
          font-size: 16px;
          line-height: normal;
          margin-top: 5px;
          text-transform: none;
          letter-spacing: 0;
          font-family: $ab-font-family-text;
          font-weight: normal;
          text-transform: uppercase;
        }
        p {
          font-size: 12px;
          line-height: 18.4px;
          margin: 0;
          padding-top: 70px;
          display: none;
          @include breakpoint($ab-small-down) {
            display: none;
          }
        }
        a {
          display: none;
        }
        &__link {
          background: $color-black;
          color: $color-white;
          font-size: 16px;
          /* font-family: $ab-font-family-gothic; */
          letter-spacing: 0.02em;
          line-height: 60px;
          margin: 31px 0 0 0;
          text-decoration: none;
          text-transform: uppercase;
          text-align: center;
          display: inline-block;
          width: 240px;
          border: none;
          &:hover,
          &:active,
          &:visited {
            background: $color-black;
            color: $color-white;
          }
          @include breakpoint($ab-small-down) {
            display: none;
          }
        }
      }
      &__image {
        width: 100%;
      }
    } // slide

    &__action_buttons {
      left: 3.45%;
      position: absolute;
      top: 0;
      z-index: 1;
      display: none;
      @include breakpoint($ab-small-down) {
        left: 0;
        width: 100%;
        &_wrapper {
          max-width: 768px;
          margin: 0px auto;
          padding: 0 14px;
        }
      }
      &_slick-action {
        &.pause,
        &.play {
          top: 0;
          cursor: pointer;
          position: relative;
          left: 13px;
          display: none;
          &:before {
            content: '';
          }
        }
      } // slick action
      &_slick-dots {
        text-align: center;
        margin-top: 36px;
        button::before {
          content: '';
        }
        button {
          width: 11px;
          height: 11px;
          border-radius: 20px;
          background: $ab--color--gray-dot-look;
          border: 1px solid $ab--color--gray-dot-look;
        }
        li {
          margin: 0 8px;
          width: 28px;
          height: 11px;
          vertical-align: middle;
          display: inline;
          button {
            width: 11px;
            height: 11px;
            text-indent: -9999px;
            &:hover,
            &:focus {
              width: 11px;
              height: 11px;
              background: $color-black;
              &:before {
                content: '';
              }
            }
          }
          &.slick-active button {
            width: 11px;
            height: 11px;
            background: $color-black;
            &:before {
              color: $color-black;
              opacity: 0.6;
            }
          }
        }
      }
    } //__action-buttons

    &__debug-layer {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      opacity: 0.5;
    }
    &__panel {
      background-color: $ab--color--gray--white;
      width: 100%;
      position: absolute;
      height: 427px;
      @include breakpoint($ab-small-down) {
        height: auto;
      }
      .desc {
        font-size: 25px;
        font-family: $font--futura-medium;
        margin: 54px 0 0 47px;
      }
      &__nav {
        z-index: 100;
        position: relative;
        top: 235px;
        margin: 0;
        width: 300px;
        padding: 0 15px;
        color: $color-black;
        margin-left: 60px;
        @include breakpoint($ab-small-down) {
          padding: 0;
          width: 95%;
          margin: -37px 0 0 10px;
        }
        @media (min-width: 1025px) and (max-width: 1230px) {
          margin-left: 30px;
        }
        @include breakpoint($ab-ipad-portrait) {
          margin-left: 0;
        }
        @include breakpoint($ab-ipad-landscape) {
          margin-left: 30px;
        }
        > span {
          float: left;
        }

        // Slick navigation
        .slick-prev,
        .slick-next {
          color: $color-black;
          height: auto;
          font-size: 12px;
          margin-top: 0;
          //top: 100px;
          position: relative;
          float: left;
          width: auto;
          line-height: 0;
          cursor: pointer;
          background: transparent;
          top: 50%;
          border: 1px solid $ab--color--white;
          outline: none;
          z-index: 999;
          &:before {
            font-size: 12px;
            color: $color-black;
          }
          span {
            border-bottom: 1px solid $color-black;
            text-transform: uppercase;
          }
        }
        .slick-prev {
          left: auto;
          margin-right: 5px;
          &:before {
            content: '<';
            margin-right: 5px;
            font-size: 16px;
            font-family: $ab-font-family-text;
            font-weight: normal;
            @include breakpoint($ab-small-down) {
              font-size: 30px;
            }
          }
        }
        .slick-next {
          right: auto;
          margin-right: 0;
          float: right;
          @media (min-width: 1025px) and (max-width: 1105px) {
            margin-right: 32px;
          }
          &:after {
            content: '>';
            font-size: 16px;
            color: $color-black;
            font-family: $ab-font-family-text;
            font-weight: normal;
            @include breakpoint($ab-small-down) {
              font-size: 30px;
            }
          }
          &:before {
            position: static;
            content: '';
          }
          @include breakpoint($ab-ipad-portrait) {
            margin: 0 90px 0 0;
          }
          @include breakpoint($ab-ipad-landscape) {
            margin: 0 70px 0 0;
          }
        }
      }
    } // look-book__panel
    &__inner {
      &.slick-slider {
        margin-bottom: 0;
      }
    }
  } //end look-book

  // Appointment booking Cancelation confirmation from email
  .appointment-cancellation {
    width: 100%;
    max-width: 1200px;
    margin: 40px auto 0;
    padding: 0 33px 0 40px;
    @include breakpoint($largest-up) {
      padding: 0;
    }
    @include breakpoint($ab-ipad-landscape) {
      padding: 0 40px;
    }
    @include breakpoint($ab-ipad-portrait) {
      padding: 0 17px;
    }
    @include breakpoint($ab-small-down) {
      padding: 0;
      margin: 20px auto 0;
    }
    p {
      margin-bottom: 26px;
    }
    hr {
      border-top: 1px solid $ab--color--gray-light_1;
    }
    .appt-book-content-header {
      @include breakpoint($ab-small-down) {
        padding: 0 15px;
      }
      h1 {
        @include breakpoint($ab-small-down) {
          font-size: 25px;
          line-height: 24px;
        }
      }
    }
    .cancel-content,
    .error-content {
      @include breakpoint($ab-small-down) {
        padding: 0 15px;
      }
    }
    .appt-book-link {
      width: 125px;
      text-align: center;
      float: right;
      height: 40px;
      line-height: 36px;
      border: 2px solid $color-black;
      background-color: $color-black;
      color: $ab--color--white;
      font-size: 18px;
      margin: 10px 0 9em;
      font-family: $ab-font-family;
      text-decoration: none;
      @include breakpoint($ab-small-down) {
        float: left;
      }
    }
  }

  // Appointment booking Menu
  .submenu {
    &__header {
      .appointment-nav__link {
        border-bottom: 1px solid transparent;
        @include breakpoint(max-width $width-largest) {
          &.appointment-header-nav__link {
            font-size: 22px;
          }
        }
      }
    }
  }

  // Appointment booking - Artist
  .artists-title {
    margin: 35px 0 0 76px;
    @include breakpoint($ab-small-down) {
      margin: 0 0 0 5%;
    }
    h2 {
      @include breakpoint($width-large) {
        font-size: 35px;
      }
      @include breakpoint($ab-small-down) {
        letter-spacing: 3px;
      }
    }
  }
  .appt-artist-page-content {
    @include breakpoint($ab-ipad-landscape) {
      padding: 0 10px 30px 30px;
    }
    @include breakpoint($ab-ipad-portrait) {
      padding: 0 10px 30px 30px;
    }
    @include breakpoint($width-large) {
      column-count: 2;
      -webkit-column-count: 2;
      -moz-column-count: 2;
      -o-column-count: 2;
      column-gap: 0em;
      -webkit-column-gap: 0em;
      -moz-column-gap: 0em;
      -o-column-gap: 0em;
      padding: 5px 45px 0 77px;
    }
    @include breakpoint($ab-small-down) {
      padding: 5px 0 0 5%;
    }
    .artists-content {
      width: 94%;
      background-color: rgb(248, 248, 248);
      padding: 20px 20px 20px 20px;
      margin-bottom: 35px;
      overflow: hidden;
      @media (min-width: 1025px) and (max-width: 1310px) {
        height: 215px;
      }
      @include breakpoint($ab-ipad-landscape) {
        height: 215px;
      }
      @include breakpoint($ab-ipad-portrait) {
        height: 250px;
      }
    }
    .artists-content__title {
      display: flex;
      .artists-content__image {
        width: 70px;
        border-radius: 80px;
      }
      .artists-content__header {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        .artists-content__headline {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-family: $ab-font-family;
          text-transform: uppercase;
          font-weight: 100;
          font-size: 18px;
          line-height: 25px;
          margin-left: 15px;
        }
      }
    }
    .artists-content__description {
      float: left;
      padding: 10px 0 0 0;
    }
    .artists-content__link {
      float: right;
    }
  }

  // Appointment booking - Makeup Services
  .appointment-use-tout {
    position: relative;
    margin-bottom: 50px;
    @include breakpoint($ab-small-down) {
      margin-bottom: 25px;
    }
    &__image {
      width: 100%;
      max-width: 100%;
      height: auto;
      margin-bottom: 0px;
    }
    &__caption {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
    }
    &__caption-inner {
      position: relative;
      color: $color-white;
      h2.hero-text--xl {
        font-family: $ab-font-family-heading;
        font-weight: normal;
        font-style: normal;
        text-transform: uppercase;
        margin-bottom: 0;
        @include breakpoint($xxsmall-down) {
          font-size: 45px;
          margin-top: 25px;
        }
      }
    }
    &__body {
      margin-top: 13px;
      margin-bottom: 24px;
      @include breakpoint($xxsmall-down) {
        font-size: 18px;
        line-height: 16px;
      }
    }
    &__cta {
      background: $color-white;
      padding: 7px 16px;
      text-decoration: none;
      font-family: $ab-font-family;
      font-size: 18px;
      border: 2px solid $color-white;
    }
    &__cta:hover {
      background: $color-black;
      color: $color-white;
      text-decoration: none;
    }
    .site-content,
    .responsive-container,
    .site-footer--bottom .node-menu,
    .customer-service__navigation .basic-responsive-v1,
    .mac-faq,
    .header-tout__content,
    .artistry-videos__filter-container,
    .giftcard-container {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 33px 0 40px;
      @include breakpoint($largest-up) {
        padding: 0;
      }
      @include breakpoint($ab-ipad-landscape) {
        padding: 0 40px;
      }
      @include breakpoint($ab-ipad-portrait) {
        padding: 0 17px;
      }
      @include breakpoint($ab-small-down) {
        padding: 0 15px;
      }
    }
  }
  // Appointment Booking Tout
  .appts-booking-tout {
    max-width: 1040px;
    margin: 0 auto 5em;
    width: 100%;
    display: table;
    height: 100%;
    text-align: center;
    color: $color-white;
    a {
      font-size: 15px;
      color: $color-white;
    }
    &.dark-theme {
      color: $color-black;
      a {
        color: $color-black;
      }
    }
    @include breakpoint($ab-small-down) {
      display: none;
    }
    .tout-container {
      height: 19em;
      min-height: 15em;
      display: table-cell;
      vertical-align: middle;
      &__hdr {
        margin: 0 0 0.75em;
        text-transform: uppercase;
        .icon {
          font-size: 12em;
          margin: 0;
          line-height: 36px;
        }
      }
      &__subhdr {
        font-family: $ab-font-family;
        font-size: 2.5em;
        margin-bottom: 1em;
        text-transform: uppercase;
      }
    }
  }

  // Store Locator
  .store-locator {
    &__hero {
      padding-top: 5px !important;
      .store-locator {
        &__title {
          margin: 35px 0 35px 0;
        }
      }
    }
    &__map-canvas-container {
      .store-locator {
        &__map-canvas {
          border-bottom: 1px solid $color-black;
        }
      }
      .store-locator {
        &__touts--results {
          @include breakpoint($min-large-up) {
            display: block !important;
          }
          @include breakpoint($max-large-up) {
            display: none !important;
          }
        }
        .hero-large {
          &__image {
            img {
              border: 1px solid $color-black;
            }
          }
        }
      }
      .store-locator__map-recentering-search-prompt-button {
        display: none;
      }
    }
    &__results-container {
      @include breakpoint($ab--large-down) {
        border-bottom: 1px solid $color-black;
      }
      .store-locator {
        &__controls {
          .search-submit {
            letter-spacing: 0.08em;
          }
        }
      }
    }
    &__touts--landing {
      @include breakpoint($max-large-up) {
        padding: 31px 0 0 0 !important;
      }
      .hero-large--max-width {
        max-width: none;
      }
      .hero-large {
        &__image {
          img {
            border-top: 1px solid $color-black;
            border-bottom: 1px solid $color-black;
          }
        }
        &__content {
          margin-left: 62px;
          @media (min-width: 1025px) and (max-width: 1190px) {
            margin-left: 20px;
          }
        }
        &__text--1 {
          font-size: 88px;
          margin-top: -3px;
          @include breakpoint($ab-ipad-landscape) {
            margin: -5px 0 0 -69px;
          }
          span {
            &.one {
              margin-left: -3px;
            }
            &.two {
              font-family: $ab-font-family;
              position: absolute;
              margin-top: -17px;
              height: 55px;
            }
            color: $color-black;
          }
        }
        &__text--3 {
          @include breakpoint($ab-ipad-portrait) {
            margin: -188px 0 0 -37px;
          }
          @include breakpoint($ab-mobile-size-1) {
            margin: -95px 0 0 -60px;
          }
          @include breakpoint($ab-mobile-size-2) {
            margin: -122px 0 0 -160px;
          }
          @include breakpoint($ab-mobile-size-3) {
            margin: -159px 0 0 -210px;
          }
          span {
            color: $color-black;
            &.first {
              @include breakpoint($ab-ipad-portrait) {
                margin-left: -553px !important;
              }
              @include breakpoint($ab-mobile-size-1) {
                margin: 0 0 0 -223px !important;
              }
              @include breakpoint($ab-mobile-size-2) {
                margin: 0 0 0 -230px !important;
              }
              @include breakpoint($ab-mobile-size-3) {
                margin: 0 0 0 -240px !important;
              }
            }
            &.one {
              font-family: $ab-font-family-heading;
              font-weight: normal;
              font-style: normal;
              text-transform: uppercase;
              @include breakpoint($ab-ipad-portrait) {
                margin-left: -526px;
                font-size: 50px;
                line-height: 40px;
              }
              @include breakpoint($ab-mobile-size-1) {
                font-size: 19px;
                margin: 0 0 0 -213px;
                line-height: 8px;
              }
              @include breakpoint($ab-mobile-size-2) {
                font-size: 31px;
                margin: 0 0 0 -213px;
                line-height: 17px;
              }
              @include breakpoint($ab-mobile-size-3) {
                font-size: 49px;
                margin: 0 0 0 -213px;
                line-height: 36px;
              }
            }
            &.two {
              font-family: $ab-font-family;
              @include breakpoint($ab-ipad-portrait) {
                position: absolute;
                height: 55px;
                margin: -17px 0 0 -367px;
              }
              h2 {
                @include breakpoint($ab-ipad-portrait) {
                  font-size: 20px;
                }
                @include breakpoint($ab-mobile-size-1) {
                  margin: -6px 0 0 -153px;
                  font-size: 6.5px;
                }
                @include breakpoint($ab-mobile-size-2) {
                  margin: -6px 0 0 -166px;
                  font-size: 8.5px;
                }
                @include breakpoint($ab-mobile-size-3) {
                  margin: -6px 0 0 -172px;
                  font-size: 12.5px;
                }
              }
            }
          }
        }
        &__cta-wrapper {
          .hero-large {
            &__cta {
              display: block;
              margin: -34px 0.5em 0 0;
              @media (min-width: 320px) and (max-width: 767px) {
                a {
                  font-size: 9px;
                  padding: 0px 9px 0px 10px;
                }
              }
              @include breakpoint($ab-ipad-landscape) {
                margin: 85px 0.5em 0 -45px;
              }
              @include breakpoint($ab-ipad-portrait) {
                margin: -111px 0.5em 0 -33px;
              }
              @media (min-width: 360px) and (max-width: 360px) {
                margin: -46px 0 0 -261px !important;
              }
              @media (min-width: 375px) and (max-width: 375px) {
                margin: -47px 0 0 -275px !important;
              }
              @media (min-width: 414px) and (max-width: 414px) {
                margin: -48px 0 0 -305px !important;
              }
              @media (min-width: 568px) and (max-width: 568px) {
                margin: -55px 0 0 -427px !important;
              }
              @media (min-width: 667px) and (max-width: 667px) {
                margin: -60px 0 0 -508px !important;
              }
              @media (min-width: 736px) and (max-width: 736px) {
                margin: -61px 0 0 -565px !important;
              }
              @include breakpoint($ab-mobile-size-1) {
                margin: -45px 0 0 -229px;
                a {
                  height: 18px;
                  padding: 0 5px 0 6px;
                  line-height: 14px;
                }
              }
              @include breakpoint($ab-mobile-size-2) {
                margin: -52px 0 0 -354px;
              }
              @include breakpoint($ab-mobile-size-3) {
                margin: -55px 0 0 -486px;
              }
              .hero-large {
                &__cta-button {
                  letter-spacing: 0.07em;
                  @include breakpoint($ab-ipad-landscape) {
                    margin: -19px 469px 0 0;
                  }
                  @include breakpoint($ab-ipad-portrait) {
                    margin: 45px 469px 0 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    &__touts--results {
      @include breakpoint($min-large-up) {
        display: none !important;
      }
      @include breakpoint($max-large-up) {
        display: block !important;
        padding: 31px 0 0 0 !important;
      }
      .hero-large {
        &__image {
          img {
            border: 1px solid $color-black;
            @include breakpoint($ab-ipad-landscape) {
              border: 0;
            }
            @include breakpoint($ab-ipad-portrait) {
              border: 0;
            }
            @include breakpoint($ab-small-down) {
              border: 0;
            }
          }
        }
      }
      .hero-large {
        &__content {
          .hero-large {
            &__text--1 {
              font-size: 83px;
              margin: -4px 0 0 -25px;
              span {
                &.one {
                  font-size: 65px;
                  line-height: 40px;
                }
                &.two {
                  font-size: 16px;
                  font-family: $ab-font-family;
                  position: absolute;
                  margin: -11px 0 0 2px;
                  height: 31px;
                }
                color: $color-black;
              }
            }
          }
          .hero-large {
            &__text--3 {
              @include breakpoint($ab-ipad-portrait) {
                margin: -245px 0 0 0;
              }
              @include breakpoint($ab-mobile-size-1) {
                margin: -115px 0 0 3px;
              }
              @include breakpoint($ab-mobile-size-2) {
                margin: -161px 0 0 -67px;
              }
              @include breakpoint($ab-mobile-size-3) {
                margin: -159px 0 0 -210px;
              }
              span {
                &.first {
                  @include breakpoint($ab-ipad-portrait) {
                    margin: 0 0 0 -504px !important;
                  }
                  @include breakpoint($ab-mobile-size-1) {
                    margin: 0 0 0 -223px !important;
                  }
                  @include breakpoint($ab-mobile-size-2) {
                    margin: 0 0 0 -235px !important;
                  }
                  @include breakpoint($ab-mobile-size-3) {
                    margin: 0 0 0 -240px !important;
                  }
                }
                &.one {
                  font-family: $ab-font-family-heading;
                  font-weight: normal;
                  font-style: normal;
                  text-transform: uppercase;
                  @include breakpoint($ab-ipad-portrait) {
                    font-size: 65px;
                    margin: 0 0 0 -470px;
                    line-height: 40px;
                  }
                  @include breakpoint($ab-mobile-size-1) {
                    font-size: 19px;
                    margin: 0 0 0 -213px;
                    line-height: 8px;
                  }
                  @include breakpoint($ab-mobile-size-2) {
                    font-size: 40px;
                    margin: 0 0 0 -213px;
                    line-height: 25px;
                  }
                  @include breakpoint($ab-mobile-size-3) {
                    font-size: 49px;
                    margin: 0 0 0 -213px;
                    line-height: 36px;
                  }
                }
                &.two {
                  font-family: $ab-font-family;
                  @include breakpoint($ab-ipad-portrait) {
                    position: absolute;
                    height: 55px;
                    margin: -17px 0 0 -367px;
                  }
                  h2 {
                    @include breakpoint($ab-ipad-portrait) {
                      mamargin: 18px 0 0 0px;
                      font-size: 20px;
                    }
                    @include breakpoint($ab-mobile-size-1) {
                      margin: -6px 0 0 -153px;
                      font-size: 6.5px;
                    }
                    @include breakpoint($ab-mobile-size-2) {
                      margin: -6px 0 0 -169px;
                      font-size: 10.5px;
                    }
                    @include breakpoint($ab-mobile-size-3) {
                      margin: -6px 0 0 -172px;
                      font-size: 12.5px;
                    }
                  }
                }
              }
            }
          }
          .hero-large {
            &__cta-wrapper {
              .hero-large {
                &__cta {
                  display: block;
                  margin: -25px 0.5em 0 -22px;
                  @include breakpoint($ab-ipad-portrait) {
                    margin: 129px 0 0 -75px;
                    a {
                      font-size: 18px;
                    }
                  }
                  @include breakpoint($ab-ipad-landscape) {
                    margin: 76px 0.5em 0 -26px;
                  }
                  @media (min-width: 360px) and (max-width: 360px) {
                    margin: 72px 0 0 -240px !important;
                  }
                  @media (min-width: 375px) and (max-width: 375px) {
                    margin: 71px 0 0 -254px !important;
                  }
                  @media (min-width: 414px) and (max-width: 414px) {
                    margin: 68px 0 0 -291px !important;
                  }
                  @media (min-width: 568px) and (max-width: 568px) {
                    margin: 101px 0 0 -401px !important;
                  }
                  @media (min-width: 667px) and (max-width: 667px) {
                    margin: 94px 0 0 -449px !important;
                  }
                  @media (min-width: 736px) and (max-width: 736px) {
                    margin: 91px 0 0 -516px !important;
                  }
                  @include breakpoint($ab-mobile-size-1) {
                    margin: 74px 0 0 -201px;
                    a {
                      font-size: 9px;
                      padding: 0px 9px 0px 10px;
                    }
                  }
                  @include breakpoint($ab-mobile-size-2) {
                    margin: 107px 0 0 -317px;
                  }
                  @include breakpoint($ab-mobile-size-3) {
                    margin: 96px 0 0 -423px;
                  }
                  .hero-large {
                    &__cta-button {
                      letter-spacing: 0.07em;
                      @include breakpoint($ab-ipad-landscape) {
                        margin: -19px 469px 0 0;
                        font-size: 15px;
                      }
                      @include breakpoint($ab-ipad-portrait) {
                        margin: 45px 469px 0 0;
                      }
                      @include breakpoint($ab-mobile-size-2) {
                        font-size: 13px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // Appt Menu formatter
  @include breakpoint($ab-small-down) {
    .submenu {
      &--sticky {
        &.appointment-nav {
          width: 100%;
          text-align: center;
          z-index: 9995;
          background: $color-white;
          display: block !important;
          .submenu__header h3 {
            margin: 0;
          }
          .submenu__nav,
          .submenu__header {
            padding: 0;
            border-bottom: inherit;
            .appointment-nav__link {
              padding: 0 15px;
              display: block;
              background-color: $ab--color--cream;
              border-bottom: 1px solid $ab--color--gray--white;
              line-height: 33px;
              &.appointment-header-nav__link {
                background: transparent;
                height: 60px;
                margin-top: 30px;
                border: 0;
              }
            }
            .appointment-header-nav__link-arrow {
              content: '';
              background: $ab--color--cream url('/media/export/cms/makeup_services/arrow_top.png')
                no-repeat center center;
              border-bottom: none;
              height: 35px;
              width: 35px;
              display: inline-block;
              top: 11px;
              position: absolute;
              right: 8%;
              text-indent: -9999px;
              cursor: pointer;
            }
          }
          &.collapse {
            .submenu__header {
              .appointment-header-nav__link-arrow {
                display: none;
                content: '';
                background: $ab--color--cream
                  url('/media/export/cms/makeup_services/arrow_bottom.png') no-repeat center center;
              }
            }
          }
        }
      }
    }
  }

  // Collection formatter - main container
  @include breakpoint($ab-small-down) {
    abbr,
    abbr[title] {
      border: none;
      cursor: default;
    }
    .section-cancel-appointment .appt-book-page-header {
      display: none;
    }
  }
  .at-h1-appt-book-header {
    font-size: 2em;
    margin: 0.67em 0;
  }

  // Password strengthening tooltip
  #booking-step3.change-password {
    .password-field {
      position: relative;
      height: auto;
      &__info {
        @include breakpoint($medium-up) {
          width: 160px;
          border: 1px solid $color-gray;
        }
        background-color: $color-white;
        z-index: 99;
        margin-top: 15px;
        &:before {
          @include breakpoint($medium-up) {
            content: '';
            position: absolute;
            top: 45px;
            left: 75px;
            border: 5px solid transparent;
            border-bottom-color: $color-gray;
          }
        }
      }
      &__rules {
        @include breakpoint($medium-up) {
          column-count: 1;
        }
        column-count: 2;
        padding: 10px;
        font-size: 12px;
        list-style: none;
        li {
          display: flex;
          align-items: center;
          padding: 3px 0;
          color: $color-gray;
          -webkit-transition: 0.2s;
          -ms-transition: 0.2s;
          -o-transition: 0.2s;
          transition: 0.2s;
          white-space: nowrap;
          &:before {
            content: $cross;
            display: inline-block;
            color: $color-red;
            font-size: 16px;
            line-height: 0;
            margin: 0 6px 0 0;
            -webkit-transition: 0.2s;
            -ms-transition: 0.2s;
            -o-transition: 0.2s;
            transition: 0.2s;
          }
          &.pass {
            color: $color-success;
            &:before {
              color: $color-success;
              content: $tick;
              text-shadow: 0 0 8px ($color-black, 0.5);
            }
          }
        }
      }
    }
  }
  #cboxOverlay {
    background-color: $color-black;
  }
  .appt-artist-overlay {
    #cboxContent {
      padding: 0;
      #cboxClose {
        .icon--close {
          font-weight: 900;
        }
        right: 25.3px;
        top: 10px;
        width: 12.4px;
        height: 16.4px;
        background: none;
        text-indent: unset;
      }
    }
  }
  .app-artist-info {
    top: 10px;
    .slick-slide {
      width: 295px;
    }
    .slick-list {
      margin: 25px 36px 0 35px;
      @include breakpoint($ab-small-down) {
        margin: 10px 36px 0 -10px;
      }
    }
    .slick-track {
      @include breakpoint($ab-small-down) {
        #{$ldirection}: 7px;
        margin-top: 15px;
      }
    }
    .slick-prev {
      &:before {
        content: '\2039';
        color: $ab--color--gray--black;
        font-size: 50px;
      }
    }
    .slick-next {
      &:before {
        content: '\203A';
        color: $ab--color--gray--black;
        font-size: 50px;
      }
    }
    .slick-dots {
      @include breakpoint($ab-small-down) {
        bottom: -35px;
      }
      li {
        @include breakpoint($ab-small-down) {
          display: inline-block;
        }
        button {
          &:before {
            @include breakpoint($ab-small-down) {
              font-size: 30px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .artist-bio {
    margin-#{$rdirection}: 5px;
    margin-#{$ldirection}: 15px;
    @include breakpoint($ab-small-down) {
      margin: 0;
      margin-#{$rdirection}: 20px;
    }
    border-radius: 4px;
    border: 1px solid $ab--color--gray--lighter;
    overflow: hidden;
    .artist-image {
      padding: 0 0 1rem;
      img {
        width: 100%;
        height: auto;
      }
    }
    .artist-details {
      padding: 0px 0px 10px 10px;
      text-align: left;
      background-color: $ab--color--white;
      .artist_pre_header {
        text-transform: uppercase;
        font-size: 10px;
      }
      .artist-title {
        word-wrap: break-word;
        .artist-header {
          font-size: 12px;
          font-family: $font--futura-medium;
          text-transform: capitalize;
        }
      }
      .artist-description {
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 16px;
      }
    }
  }
} //end appt_booking mixin

$ab-small-down: $small-down;
$ab--color--gray--dark: $color-dark-gray;
$ab-font-family-heading: 'BabasNeue-Bold';
$ab-font-family-button: 'BabasNeue-Bold';
$ab--color--gray-light: $color-mid-gray;
$ab--color--gray--dark: $color-dark-gray;
$ab--color--gray--lightest: #dbdbdb;
$ab_color--btn-gray-disabled: #afafaf;
$ab--color--white: $color-white;

@mixin menu_item {
  display: inline-block;
  margin: 0;
  padding: 0.65em 1.5em 0.5em;
  border-top: 1px solid $ab--color--gray--dark;
  border-bottom: 1px solid $ab--color--gray--dark;
  border-#{$ldirection}: 1px solid $ab--color--gray--dark;
  line-height: 0.75em;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  &.active {
    color: $color-white;
    background-color: $ab--color--gray--dark;
  }
}

@mixin menu_h {
  font-size: 18px;
  list-style-type: none;
  font-family: $ab-font-family-heading;
  margin: 1.5em 0 1.35em;
  @include breakpoint($ab-small-down) {
    font-weight: 100;
    margin-#{$ldirection}: 0;
    font-size: 12px;
    line-height: 16px;
    padding: 0 15px;
    letter-spacing: 2px;
    max-width: 100%;
    text-align: center;
  }
  li {
    @include menu_item;
  }
}

@mixin ab-button {
  @include menu_item;
  font-family: $ab-font-family-button;
  border: 1px solid $ab--color--gray-light;
  color: $ab--color--white;
  background-color: $ab--color--gray--dark;
  text-decoration: none;
  border-radius: 0;
  cursor: pointer;
  &.disabled {
    color: $ab--color--gray--lightest;
    background-color: $ab_color--btn-gray-disabled;
    cursor: default;
  }
  &.disabled:hover {
  }
}

#my-appts-sign-in-form-container {
  .social-login {
    .tooltip {
      margin: 5px 25px 3px;
    }
  }
}
