.custom-gift-edit {
  width: 100%;
  margin-bottom: 3em;
  &__title {
    @include h10;
    margin-bottom: 0.5em;
  }
  &__error {
    color: $color-red;
    li {
      margin-bottom: 0.5em;
    }
  }
  &__info {
    margin-bottom: 3em;
    padding: 0 0 0.5em 0;
    border-bottom: 2px solid $color-light-gray;
    .gift-info {
      &__name {
        @include h12;
        width: 100%;
        line-height: 40px;
        @include breakpoint($small-up) {
          float: left;
          width: 50%;
          text-align: left;
        }
      }
      &__price {
        width: 100%;
        @include breakpoint($small-up) {
          float: right;
          width: 50%;
          text-align: right;
        }
        &--price {
          @include t9;
          line-height: 40px;
        }
        &--points {
          @include t10;
          margin-left: 0.5em;
          color: $color-gray;
          line-height: 40px;
          vertical-align: 30%;
        }
      } // &__price
    } // .gift-info
  } // &__info

  &__gift-box {
  } // &__gift-box

  &__items {
    .gift-items {
      &__item {
        width: 100%;
        padding: 1.5em 0;
        text-align: center;
        border-bottom: 1px solid $color-light-gray;
        position: relative;
        &--img {
          float: left;
          height: 100%;
          background: $color-light-gray;
        }
        &--info {
          float: left;
          width: 75%;
          padding: 0.5em;
          text-align: left;
          @include breakpoint($small-up) {
            width: 42%;
          }
          .gift-item-info {
            &__title {
              @include h7;
            }
            &__subtext {
              @include h13;
              margin-bottom: 0.5em;
            }
            &__price {
              @include t6;
            }
          }
        }
        &--qty-wrapper {
          float: left;
          clear: left;
          width: 25%;
          padding: 0.5em 0;
          @include breakpoint($small-up) {
            clear: none;
            width: 14%;
            margin-top: 1.5em;
          }
        }
        &--qty {
        }
        &--remove {
          @include t10;
          float: right;
          width: 35%;
          margin-top: 0.5em;
          padding: 0.5em 0;
          color: $color-link;
          text-decoration: underline;
          text-transform: uppercase;
          text-align: right;
          &:hover {
            color: $color-link-hover;
            cursor: pointer;
          }
          @include breakpoint($small-up) {
            float: left;
            width: 18%;
            margin-top: 0;
            text-align: center;
            line-height: 85px;
          }
        }
        &--total-price {
          @include t6;
          float: left;
          width: 40%;
          padding: 0.5em;
          text-align: left;
          line-height: 1.5em;
          @include breakpoint($small-up) {
            width: 14%;
            text-align: center;
            line-height: 85px;
          }
        }
      } // &__item
    } // .gift-items
  } // &__items

  &__samples {
  } // &__samples

  &__totals {
    border-bottom: 1px solid $color-light-gray;
    .totals-row {
      padding: 1em 0;
      &:first-child {
        border-bottom: 1px solid $color-light-gray;
      }
      @include breakpoint($small-up) {
        padding: 1em;
      }
      &__header {
        @include h10;
        width: 100%;
        margin-bottom: 0.5em;
        @include breakpoint($xsmall-up) {
          float: left;
          width: 45%;
          margin-bottom: 0;
          margin-right: 5%;
          line-height: 40px;
        }
      }
      &__price {
        width: 100%;
        @include breakpoint($xsmall-up) {
          float: right;
          width: 45%;
          margin-left: 5%;
          text-align: right;
          line-height: 40px;
        }
        &--price {
          @include t9;
        }
        &--points {
          @include t10;
          vertical-align: 75%;
        }
      }
      &__items {
        @include h11;
        float: left;
        width: 100%;
        margin-bottom: 0.5em;
        @include breakpoint($xsmall-up) {
          width: 50%;
          margin-top: 3px;
          margin-bottom: 0;
          line-height: 42px;
        }
      }
      &__btn {
        float: right;
        width: 100%;
        text-align: center;
        @include breakpoint($xsmall-up) {
          width: 50%;
          max-width: 250px;
          text-align: right;
        }
        a {
          width: 100%;
        }
      }
    } // .total-row
  } // &__totals

  &__link--remove {
    @include t10;
    display: block;
    margin: 1em auto;
    color: $color-link;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
    &:hover {
      color: $color-link-hover;
      cursor: pointer;
    }
  } // &__link--remove

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: $color-white url(/images/global/ajax-loading.gif) no-repeat center center;
    text-align: center;
    opacity: 0;
    filter: alpha(opacity=00);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=00)';
    -webkit-transition: opacity 3s ease-in-out;
    -moz-transition: opacity 3s ease-in-out;
    -ms-transition: opacity 3s ease-in-out;
    -o-transition: opacity 3s ease-in-out;
    transition: opacity 3s ease-in-out;
    &.fade-in {
      opacity: 0.8;
      filter: alpha(opacity=80);
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
    }
    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -0.25em;
    }
    .text {
      display: inline-block;
      vertical-align: middle;
      width: 100px;
      padding-top: 50px;
    }
  } // .loading
} // .custom-gift-edit
