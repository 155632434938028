.footer-formatter-primary {
  background: $color-cr18-black;
  color: $color-white;
  font-family: $font--futura-book;
  font-weight: normal;
  width: 100%;
  &__content {
    display: table;
    margin: 0 auto;
    max-width: $max-width-large + 40;
    width: 100%;
    @include breakpoint($landscape-up) {
      padding: 3em 20px 2em;
    }
  }
  &__section {
    display: table-row;
    @include breakpoint($landscape-up) {
      display: table-cell;
      padding-right: 2em;
      &:last-child {
        padding-right: 0;
      }
    }
  }
}
