.page-origins {
  &__header {
    padding-bottom: 55px;
    border-bottom: 1px solid $color-black;
    margin-bottom: 135px;
    margin-top: 23px;
    @include breakpoint($medium-up) {
      margin-top: 0;
    }
    &-image {
      display: block;
      margin: auto;
      width: 100%;
    }
    &-copy {
      padding-top: 5px;
      font-size: 20px;
      text-align: center;
      @include font--text;
      font-style: normal;
      em {
        @include font--text--italic;
      }
      @include breakpoint($medium-up) {
        padding-top: 0;
        width: 68%;
        margin: auto;
        font-size: 24px;
        @include font--heading--secondary;
        margin-top: -100px; // Remove this if the design changes in the future. This allows us to place the text above the image
        em {
          @include font--heading--secondary--italic;
        }
      }
    }
    &-information {
      @include font--cta;
      text-align: center;
    }
  }
}
