.gnav-dropdown {
  @include breakpoint($landscape-up) {
    background: $color-white;
    position: relative;
    border-top: solid 1px $color-black;
    border-bottom: solid 2px $color-black;
  }
  &__content {
    @include breakpoint($landscape-up) {
      @include pie-clearfix;
      display: table;
      width: 100%;
      padding: 25px 13px;
      max-width: $max-width-large;
      margin: 0 auto;
      > div,
      > aside {
        display: table-cell;
        vertical-align: top;
        padding-right: 25px;
        padding-left: 25px;
        border-right: solid 1px $color-light-gray;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
          border-right: none;
        }
      }
      > aside {
        vertical-align: middle;
      }
    }
    &--no-table {
      display: block;
    }
    > .block:first-child,
    > .menuref:first-child,
    > .node-elc-nodeblock:first-child {
      .menuref__title,
      .gnav-by-collection__title {
        border-top-color: $color-black;
        @include breakpoint($landscape-up) {
          border-top: none;
        }
      }
    }
  }
  &__footer {
    @include a1;
    background-color: #f8f8f8;
    padding-top: 16px;
    padding-bottom: 16px;
    display: none;
    @include breakpoint($landscape-up) {
      display: table;
      width: 100%;
    }
    &__inner {
      max-width: $max-width-large;
      margin: 0 auto;
    }
    &__copy {
      display: table-cell;
      line-height: 1.1;
      text-transform: none;
      padding-left: 25px;
      font-size: 13px;
      text-align: center;
      text-transform: none;
      color: $color-darker-gray;
      a {
        text-transform: uppercase;
      }
    }
    &__items {
      display: table-cell;
      min-width: 350px;
      text-align: right;
    }
    &__item {
      display: inline-block;
      font-size: 13px;
      letter-spacing: 0.02em;
      color: $color-darker-gray;
      margin-right: 25px;
      &__link {
        text-transform: uppercase;
        color: $color-darker-gray;
        text-decoration: none;
      }
    }
  }

  // Headers:
  .menuref,
  .gnav-by-collection {
    &--selected {
      .menuref__title,
      .gnav-by-collection__title {
        @include icon(minus, after) {
          font-size: 10px;
          position: relative;
          top: 16px;
        }
        @include breakpoint($landscape-up) {
          &:after {
            content: '';
            top: 0;
          }
        }
      }
    }
    &__title {
      @include h11;
      @include font-padding(subheading, 24px, 55px);
      color: $color-black;
      padding-right: 20px;
      padding-left: 40px;
      margin: 0;
      color: $color-mid-gray;
      border-top: solid 1px $color-light-gray;
      cursor: pointer;
      @include icon(plus, after) {
        font-size: 10px;
        position: relative;
        top: 16px;
        float: right;
      }
      @include breakpoint($landscape-up) {
        &:after {
          content: '';
          float: none;
          top: 0;
        }
      }
      @include breakpoint($landscape-up) {
        line-height: 1;
        padding: 0;
        margin: 0 0 13px;
        color: $color-black;
        border-top: none;
        cursor: default;
        &:after {
          content: '';
          width: 35px;
          height: 1px;
          background-color: $color-black;
          display: block;
          margin: 18px 0 0;
        }
      }
    }
  }

  // Menuref columns ("By Category")
  .menuref {
    @include breakpoint($landscape-up) {
      display: table-cell;
      vertical-align: top;
      width: 16.6666%;
      border-right: solid 1px $color-light-gray;
      padding: 0 0 0 25px;
      min-height: 400px;
    }
    &--selected {
      .menuref__content {
        display: block;
      }
    }
    &__content {
      border-top: solid 1px $color-light-gray;
      padding: 12px 0;
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
        border-top: none;
        padding: 0;
      }
    }
    .menu {
      &--lvl-1 {
        position: static;
        top: auto;
        width: auto;
        background-color: transparent;
        border-bottom: none;
        margin: 0;
      }
      &__list {
        border-bottom: none;
        @include breakpoint($landscape-up) {
          float: left;
          width: 100%;
          padding-right: 25px;
          text-transform: none;
          &.list-split--2 {
            width: 50%;
          }
          &.list-split--3 {
            width: 33.333%;
          }
        }
      }
      &__item {
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.01em;
        float: none;
        &--hr {
          display: none;
          @include breakpoint($landscape-up) {
            display: block;
          }
        }
        &__hr {
          width: 35px;
          height: 1px;
          background-color: #d6d6d6;
          border: none;
          text-align: left;
          margin-left: 0;
        }
      }
      &__link {
        @include t2;
        @include icon('arrow--right', after);
        line-height: 35px;
        color: $color-black;
        text-decoration: none;
        text-transform: none;
        padding: 0 20px 0 40px;
        margin: 0;
        border: none;
        &:after {
          font-size: 8px;
          line-height: 30px;
        }
        @include breakpoint($landscape-up) {
          padding: 0;
          line-height: 30px;
          &:after {
            content: normal;
          }
          &:hover {
            color: $color-green;
          }
        }
      }
    }
  }
  &--menu-cols-2 .menuref {
    @include breakpoint($landscape-up) {
      width: 33.3333%;
    }
  }

  // By Collection/Benefit columns
  .gnav-by-collection {
    @include breakpoint($landscape-up) {
      width: 100%;
    }
  }

  // Product grids:
  .product-grid-wrapper {
    border: none;
  }
  .product-brief {
    padding-top: 20px;
    padding-bottom: 0;
    &__title {
      font-size: 16px;
    }
    &__subtitle {
      font-size: 26px;
      margin: 0 0 8px;
    }
  }

  // Touts:
  @include breakpoint($landscape-up) {
    .gnav-tout {
      display: table-cell;
      vertical-align: middle;
    }
    .basic-grid__item {
      padding-bottom: 0;
    }
    .basic-tout {
      &__img {
        display: block;
      }
      &--text-image {
        .basic-tout__text {
          width: 100%;
          max-width: none;
        }
      }
    }
  }
}
