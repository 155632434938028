$nav-animation-timing: 0.5s;
@if is_2020_nav_redesign() {
  @keyframes navPageSlideStart {
    100% {
      transform: translateX(-110%);
      opacity: 0;
    }
  }
  @keyframes navPageSlideStartReverse {
    100% {
      transform: translateX(110%);
      opacity: 0;
    }
  }
  @keyframes navPageSlideEnd {
    0% {
      opacity: 0;
      transform: translateX(110%);
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes navPageSlideEndReverse {
    0% {
      opacity: 0;
      transform: translateX(-110%);
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes navPageHideNotSelectedDuringSlide {
    0% {
      opacity: 0;
    }
    50%,
    100% {
      opacity: 0;
      visibility: hidden;
      height: 0;
      width: 0;
    }
  }
  @keyframes navPageShowSelectedDuringSlide {
    0%,
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.gnav-formatter {
  display: none;
  @if is_2020_nav_redesign() {
    text-align: center;
  }
  @include breakpoint($landscape-up) {
    display: block;
  }
  .gnav-menu-label,
  .gnav-menu-link__item {
    @if is_2020_nav_redesign() {
      font-size: 14px;
      padding: 10px 0;
      color: $color-black;
      @include breakpoint($ipad-below-landscape) {
        text-transform: uppercase;
      }
    } @else {
      padding: 10px 0 10px 20px;
    }
    display: block;
    @include breakpoint($landscape-up) {
      display: inline;
      @if is_cr20_desktop_nav_refresh() {
        padding: 10px 0;
      }
    }
  }
  .gnav-menu-link__item {
    padding: 13px 0;
    width: 100%;
    display: inline-block;
    @include breakpoint($landscape-up) {
      padding: 5px 0;
      margin: -3px 0;
    }
  }
  @if is_2020_nav_redesign() {
    button {
      -webkit-appearance: none;
      border: none;
      background: transparent;
    }
    .gnav-util__account-bar {
      background: $color-nav-2020-account;
      padding: 12px 15px;
    }
    .gnav-util__account-message {
      font-family: $font--futura-medium;
    }
    .gnav-util__account-icon {
      float: right;
      &.active {
        transform: rotate(180deg);
      }
    }
    .gnav-util__account-menu {
      dt,
      dd {
        margin: 0;
        padding: 10px 15px;
      }
      dt {
        margin-bottom: 15px;
        font-family: $font--futura-medium;
      }
      dd > a {
        display: block;
      }
    }
    .gnav-menu-item.gnav-menu-item--utility {
      border-top: 1px solid $color-black;
      margin-top: 10px;
      img {
        display: inline-block;
        margin: 0 7px -1px;
        margin-#{$ldirection}: 0;
        height: 16px;
      }
      .gnav-menu-item__title {
        margin-top: 10px;
      }
      & + .gnav-menu-item.gnav-menu-item--utility {
        border-top: none;
        margin-top: 0;
        .gnav-menu-item__title {
          margin-top: 0;
        }
      }
    }
  }
  &__list {
    @if is_2020_nav_redesign() {
      &:after {
        content: '';
        width: 100%;
        height: 90px;
        position: fixed;
        left: 0;
        top: 100%;
        box-shadow: 0 0 75px 55px $color-white;
        z-index: z('discover', 'close-icon');
        pointer-events: none;
        @include breakpoint($landscape-up) {
          content: none;
        }
      }
      @include breakpoint($ipad-below-landscape) {
        background-color: $color-nav-2020-background;
        &--closed {
          padding-bottom: 150px;
        }
        &.gnav-formatter__list-animation--start {
          overflow: hidden;
          animation: navPageSlideStart ease-in $nav-animation-timing / 2 forwards;
        }
        &.gnav-formatter__list-animation--start-reverse {
          animation: navPageSlideStartReverse ease-in $nav-animation-timing / 2 forwards;
        }
        &.gnav-formatter__list-animation--end {
          overflow: hidden;
          animation: navPageSlideEnd ease-out $nav-animation-timing / 2 forwards;
        }
        &.gnav-formatter__list-animation--end-reverse {
          animation: navPageSlideEndReverse ease-in $nav-animation-timing / 2 forwards;
        }
        &.gnav-formatter__list--selected {
          > .gnav-formatter__list-inner > .basic-menu-ref:not(.active),
          .gnav-menu-item:not(.menu__item--selected),
          .gnav-formatter__list--lvl-3
            > .gnav-formatter__list-inner
            > .gnav-menu-link.menu__item--selected,
          .gnav-menu-item.menu__item--selected .gnav-menu-item__title {
            animation: navPageHideNotSelectedDuringSlide ease-in-out $nav-animation-timing forwards;
          }
          > .gnav-formatter__list-inner > .gnav-menu-tout-v2 {
            display: none;
          }
        }
      }
    } @else {
      @include breakpoint($ipad-below-landscape) {
        background-color: $color-white;
      }
    }
    &--lvl-1 {
      @if is_2020_nav_redesign() == false {
        border-bottom: solid 1px $color-black;
      }
      @include breakpoint($landscape-up) {
        border-bottom: none;
      }
    }
  }
  &__navbar-wrapper {
    display: none;
    @include breakpoint($landscape-up) {
      position: static;
    }
  }
  &__navbar {
    @if is_2020_nav_redesign() {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 15px;
      margin-bottom: 10px;
      border-bottom: 1px solid $color-mid-gray;
      @include breakpoint($iphone6-up) {
        padding: 20px 7px;
      }
      &-slot {
        flex: 0 0 32px;
      }
      &-title {
        text-transform: uppercase;
        font-size: 16px;
        @include breakpoint($iphone6-up) {
          font-size: 18px;
        }
        .selection:last-child {
          font-family: $font--futura-demi;
        }
      }
      &-flex {
        flex: 1 0 auto;
        text-align: center;
      }
      .divider {
        display: inline-block;
        margin: 0 7px;
      }
      @include breakpoint($landscape-up) {
        display: none;
      }
    } @else {
      display: none;
    }
    .gnav-util__account-links {
      font-size: 16px;
      text-decoration: underline;
      a {
        display: inline-block;
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
  .gnav-menu-item__content {
    @include breakpoint($landscape-up) {
      position: absolute;
      left: 0;
      background: $color-white;
    }
  }
  &--lvl-1 {
    position: relative;
    list-style: none;
    width: 100%;
    z-index: 11;
    -webkit-overflow-scrolling: touch;
    @if is_2020_nav_redesign() {
      max-height: 100%;
    } @else {
      max-height: 82vh;
      overflow-y: auto;
    }
    @include breakpoint($landscape-up) {
      @include font-padding(subheading, 23px, 67px);
      @include link-hover($color-dark-gray, none);
      max-height: 100%;
      position: static;
      padding: 0 10px;
      width: auto;
      overflow: visible;
      -webkit-overflow-scrolling: auto;
      @if is_cr20_desktop_nav_refresh() {
        display: inline-flex;
        line-height: 1;
        margin: 0;
      } @else {
        margin-top: 2px;
      }
    }
    .gnav-menu-item {
      @include breakpoint($landscape-up) {
        padding: 0;
        @if is_cr20_desktop_nav_refresh() {
          height: 45px;
          display: inline-flex;
          .sticky-nav-active & {
            height: 63px;
          }
        } @else {
          @include swap_direction(margin, 0 6px 0 0);
        }
      }
      @include breakpoint($largest-up) {
        @if not(is_cr20_desktop_nav_refresh()) {
          @include swap_direction(margin, 0 12px 0 0);
        }
      }
      &:hover {
        @include breakpoint($landscape-up) {
          text-decoration: none;
        }
      }
      .gnav-menu-item__link:hover {
        text-decoration: none;
      }
    }
    .gnav-menu-item__title-wrap {
      @if is_2020_nav_redesign() {
        color: $color-black;
      }
      @include breakpoint($landscape-up) {
        padding-bottom: 2px;
        border-bottom: $color-white solid 1px;
      }
      &:hover {
        @include breakpoint($landscape-up) {
          border-bottom-color: $color-black;
        }
      }
    }
    .gnav-active & {
      display: block;
    }
  }
  &--lvl-2 {
    font-size: 23px;
    border-top-color: $color-light-gray;
    @include breakpoint($landscape-up) {
      position: absolute;
      width: 100%;
      padding: 20px 0;
      background: $color-white;
      left: 0;
      z-index: 1;
      @if is_cr20_desktop_nav_refresh() {
        margin-top: 12px;
        border-top: $color-light-gray solid 1px;
      } @else {
        margin-top: 20px;
        border-top: $color-light-gray solid 1px;
      }
    }
    .sticky-nav-active & {
      @if is_cr20_desktop_nav_refresh() {
        @include breakpoint($landscape-up) {
          margin-top: 21px;
        }
      }
    }
    .gnav-menu-link {
      font-size: 15px;
      margin: 0;
      @if is_2020_nav_redesign() == false {
        border-top: $color-light-gray 1px solid;
      }
      @include breakpoint($landscape-up) {
        font-size: 23px;
        text-transform: uppercase;
      }
    }
    .gnav-formatter__list--lvl-2 {
      max-width: 1280px;
      margin: 0 auto;
      > .gnav-formatter__close {
        @if is_cr20_desktop_nav_refresh() {
          @include breakpoint($medium-landscape-only) {
            display: block;
          }
        }
        .ada-enabled & {
          @include breakpoint($landscape-up) {
            display: block;
          }
        }
      }
    }
    .gnav-menu-tout {
      @if is_2020_nav_redesign() == false {
        display: none;
      }
      @include breakpoint($landscape-up) {
        display: inline-block;
        float: left;
        font-size: 28px;
        text-align: left;
      }
      &--text-over-image,
      &__content {
        @include breakpoint($landscape-up) {
          left: 10px;
          line-height: 1;
          top: 10px;
          width: 95%;
        }
      }
    }
  }
  &--lvl-3 {
    @include t2;
    text-transform: none;
    color: $color-black;
    line-height: 35px;
    @if is_2020_nav_redesign() {
      border-top: none;
    } @else {
      border-top: solid 1px $color-light-gray;
    }
    &:after {
      font-size: 8px;
      line-height: 30px;
    }
    @include breakpoint($landscape-up) {
      border-top: none;
      display: inline-block;
      float: left;
      font-size: 15px;
      margin-top: -5px;
      padding: 0 0 12px;
      vertical-align: top;
      @if is_cr20_desktop_nav_refresh() {
        min-width: 16%;
      }
    }
    .gnav-menu-link {
      border-top: none;
      @include breakpoint($landscape-up) {
        display: block;
        font-size: 15px;
        line-height: 1;
        padding: 0 0 5px 30px;
        text-align: left;
        text-transform: capitalize;
      }
      &.gnav-menu-link--category {
        display: block;
        @include breakpoint($landscape-up) {
          font-size: 17px;
          padding-bottom: 15px;
          text-transform: uppercase;
          font-family: $font--futura-medium;
          color: $color-cr18-black;
          &:after {
            width: 35px;
            height: 1px;
            background-color: $color-black;
            display: block;
            margin: 18px 0 0;
          }
        }
      }
    }
  }
  &__item {
    &--expanded {
      > .gnav-menu-item__link {
        color: $color-dark-gray;
      }
    }
    &--lvl-1 {
      @include breakpoint($landscape-up) {
        float: left;
      }
      &.holiday_guide_menu {
        a.gnav-menu-item__link {
          color: $color-red;
        }
      }
    }
    &--lvl-2 {
      &:first-child > .menu__lgnav-menu-item__link {
        border-top-color: $color-black;
        @include breakpoint($landscape-up) {
          border-top: none;
        }
      }
    }
  }
  .gnav-menu-item__title-wrap {
    @include font--subheading;
    @include font-padding(subheading, 23px, 45px);
    text-transform: capitalize;
    display: block;
    text-decoration: none;
    cursor: pointer;
    font-size: 15px;
    margin: 0;
    color: $color-black;
    @if is_2020_nav_redesign() {
    } @else {
      padding-left: 20px;
      border-top: solid 1px $color-black;
    }
    @include breakpoint($landscape-up) {
      border-top: none;
      padding: 0;
      line-height: 1;
    }
    @if is_cr20_desktop_nav_refresh() {
      @include breakpoint($ipad-pro-portrait-only) {
        font-size: 14px;
      }
    }
    @include breakpoint($largest-up) {
      font-size: 16px;
    }
    &:after {
      float: right;
    }
  }
}

// Dropdown logic - keeping this separated so it's a little more clear what's
// going on:
.gnav-formatter {
  &.gnav-formatter--lvl-1 {
    display: none;
    .utility-nav-active & {
      display: block;
      @if is_2020_nav_redesign() == false {
        overflow: hidden;
      }
    }
    // TABLET/MOBILE:
    // The menu is an accordion, expanding/collapsing is controlled with js, but
    // after the animation completes everything gets reset so it's class-based.
    .gnav-menu-item--has-children .gnav-menu-item__title,
    .gnav-menu-link--category-expandable {
      @include icon(caret--right, after) {
        transition: transform 200ms ease;
        position: absolute;
        top: 13px;
        @if is_2020_nav_redesign() {
          transition-duration: $nav-animation-timing / 2;
          right: 17px;
          font-size: 16px;
        } @else {
          font-size: 18px;
          right: 10px;
          transform: rotate(90deg);
        }
        @include breakpoint($landscape-up) {
          content: none;
        }
      }
      + .gnav-menu-link-group .gnav-menu-link:last-of-type {
        margin-bottom: 4rem;
        @include breakpoint($landscape-up) {
          margin-bottom: inherit;
        }
      }
    }
    .menu__item--selected > .gnav-menu-item__title:after,
    .gnav-formatter--lvl-3 .menu__item--selected.gnav-menu-link--category-expandable:after {
      top: 17px;
      @if is_2020_nav_redesign() == false {
        transform: rotate(270deg);
      }
    }
    .gnav-formatter--lvl-3 .gnav-menu-link--category-fixed-open {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
      + .gnav-menu-link-group {
        transform: scaleY(1);
        .gnav-menu-link {
          display: block;
        }
      }
    }
    .gnav-formatter--lvl-2 {
      @include transition(transform 200ms ease);
      display: block;
      transform: scaleY(0);
      transform-origin: 100% 0;
      overflow: hidden;
      @if is_2020_nav_redesign() {
        transition-delay: $nav-animation-timing / 2;
        transition-duration: $nav-animation-timing / 2;
      }
    }
    .menu__item--selected {
      .gnav-formatter {
        display: block;
      }
      .gnav-formatter--lvl-2 {
        @include transition(transform 200ms ease);
        @if is_2020_nav_redesign() {
          transition-duration: 0;
          transform: none;
        } @else {
          transform: scaleY(1);
        }
      }
    }
    .gnav-formatter--lvl-3 {
      .gnav-menu-link-group {
        @include transition(transform 200ms ease);
        transform: scaleY(0);
        transform-origin: 100% 0;
        @if is_2020_nav_redesign() {
          transition-duration: 0;
          transform: none;
        }
        .gnav-menu-link {
          display: none;
        }
      }
      .menu__item--selected {
        & ~ .gnav-menu-link-group {
          display: block;
          overflow: scroll;
          transform: scaleY(1);
          @if not is_2020_nav_redesign() {
            max-height: 350px;
          }
          .gnav-menu-link {
            display: block;
          }
        }
      }
    }
    @include breakpoint($landscape-up) {
      &:after {
        content: '';
        top: 0;
      }
    }
    // DESKTOP:
    // The menu fades in on the initial mouseover/out, but doesn't fade when
    // switching between items. This is done by first positioning all dropdowns
    // off screen AND setting their opacity to 0, then when the user triggers a
    // particular dropdown, the body gets a main-nav-active class which causes
    // them all to fade in (off screen) and the active menu item also gets an
    // --expanded class which tells it to move into view.
    @include breakpoint($landscape-up) {
      display: block; // Undo the mobile method of hiding it
    }
    .gnav-formatter--lvl-2 {
      @include breakpoint($landscape-up) {
        display: block;
        transform: scaleY(1);
        left: -5000px;
        @if is_cr20_desktop_nav_refresh() {
          top: 0;
          margin-top: 45px;
          .sticky-nav-active & {
            margin-top: 63px;
          }
        }
      }
    }
    .gnav-menu-item__content {
      @include breakpoint($landscape-up) {
        @include transition(opacity 300ms ease);
        transform: scaleY(1);
        opacity: 0;
        visibility: hidden;
        width: 100%;
      }
    }
    .gnav-menu-item--has-children {
      @include breakpoint($landscape-up) {
        @if is_cr20_desktop_nav_refresh() {
          padding-bottom: 0;
        } @else {
          padding-bottom: 20px;
        }
      }
      @if is_cr20_desktop_nav_refresh() {
        &.expanded {
          @include breakpoint($landscape-up) {
            @include transition(opacity 300ms ease);
          }
          .gnav-menu-item__content {
            opacity: 1;
            visibility: visible;
            z-index: 1;
          }
          .gnav-formatter--lvl-2 {
            left: 0;
          }
        }
      } @else {
        &:hover {
          @include breakpoint($landscape-up) {
            @include transition(opacity 300ms ease);
          }
          .gnav-menu-item__content {
            opacity: 1;
            visibility: visible;
          }
          .gnav-formatter--lvl-2 {
            left: 0;
          }
        }
      }
    }
    .menu__item--expanded {
      .gnav-menu-item__content {
        @include breakpoint($landscape-up) {
          left: 0;
          z-index: 20;
          opacity: 1;
        }
      }
    }
    .gnav-formatter--lvl-3 {
      .gnav-menu-link-group {
        @include breakpoint($landscape-up) {
          display: block;
          transform: scaleY(1);
        }
        .gnav-menu-link {
          @include breakpoint($landscape-up) {
            display: block;
          }
        }
      }
    }
  }
  &__close {
    display: none;
    position: absolute;
    #{$rdirection}: 32px;
    top: 15px;
    width: 25px;
    height: 25px;
    opacity: 0.3;
    &:focus,
    &:focus-visible,
    &:hover {
      opacity: 1;
    }
    &:before,
    &:after {
      position: absolute;
      top: 0;
      #{$ldirection}: 11px;
      content: ' ';
      height: 25px;
      width: 2px;
      background-color: $color-black;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

@if is_2020_nav_redesign() {
  // Old nav with existing touts should not show on redesigned navs
  .gnav-menu-tout-v1.mobile-hidden {
    display: none !important;
    @include breakpoint($landscape-up) {
      display: block !important;
    }
  }
}

.gnav-menu-tout-v2 {
  @include breakpoint($ipad-below-landscape) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    margin: 0;
    background: $color-white;
    padding: 5px;
    border-top: 1px solid $color-light-gray;
    z-index: 9011;
    opacity: 0;
    transition: opacity $nav-animation-timing / 2;
    &.active {
      opacity: 1;
      max-height: 120px;
    }
    .gnav-menu-tout__image-wrapper {
      width: 32%;
      margin-right: 8px;
      .gnav-menu-tout__image-link,
      img {
        display: block;
      }
    }
    .gnav-menu-tout__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-size: 16px;
      a {
        text-decoration: underline;
        font-size: 13px;
      }
      &-body {
        text-align: #{$ldirection};
        p {
          font-size: 15px;
          line-height: 1;
          margin-bottom: 0.4rem;
        }
      }
      &-cta {
        margin-top: 4px;
      }
    }
  }
  &-header {
    font-family: $font--futura-medium;
  }
  @include breakpoint($landscape-up) {
    position: static;
    display: table-cell;
    position: relative;
    margin: 0 25px;
    text-align: center;
    width: 16%;
    .gnav-menu-tout__content {
      line-height: 20px;
      &--over-image {
        top: 10px;
        position: absolute;
        width: 95%;
        #{$ldirection}: 0;
      }
      &--under-image {
        padding-top: 5px;
        .gnav-menu-tout__content-header {
          font-family: $font--futura-demi;
          font-size: 14px;
          .tout-carousel-formatter__slide & {
            text-align: center;
          }
        }
        .gnav-menu-tout__content-body {
          font-family: $font--futura-book;
          line-height: get-line-height(16px, 16px);
          font-size: 16px;
          .tout-carousel-formatter__slide & {
            text-align: center;
          }
        }
        .gnav-menu-tout__content-cta {
          a {
            font-size: 15px;
            font-family: $font--futura-book;
            text-align: center;
            &.cta-tout__button--btn-secondary {
              text-decoration: none;
            }
            &.cta-tout__button--btn-primary {
              margin-top: 4px;
            }
          }
          .tout-carousel-formatter__slide & {
            text-align: center;
          }
        }
      }
    }
  }
}
