$portrait-only: (320px, 480px);
$xxlarge-only: (414px, 736px);
$xlarge-only: (375px, 667px);
$color-mild-gray: #303533;
$color-little-gray: #767676;
/* Mixin function for review star Background image */
@mixin pr-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 17px;
  $pr-width-value: 16px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/power_review/stars/rating-#{$pr-value}_single.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* Mixin function for Large review star Background image */
@mixin pr-background-large-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 17px;
  $pr-width-value: 16px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/power_review/stars/rating-#{$pr-value}-large_single.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* Mixin function for review star Background image */
/* black stars */
@mixin pr-background-image-black {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 17px;
  $pr-width-value: 16px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/power_review/stars_black/rating-#{$pr-value}_single.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* Mixin function for Large review star Background image */
/* black stars */
@mixin pr-background-large-image-black {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 17px;
  $pr-width-value: 16px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/power_review/stars_black/rating-#{$pr-value}-large_single.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* Mixin function for pr label, span display */
@mixin pr-label {
  @include font--subheading;
  font-size: 18px;
  color: $color-drak-black;
}
/* Mixin function for pr select */
@mixin pr-select {
  @include selectBox;
  background-image: url('#{$base-theme-path}svg-icons/src/down-arrow.svg');
  padding-top: 2px !important;
}
/* Mixin function for pr link */
@mixin pr-link {
  @include font--cta;
  text-transform: uppercase;
  color: $color-dark-gray;
  &:hover {
    color: $color-green;
    text-decoration: none;
  }
}
/* Mixin function for pr button */
@mixin pr-button {
  @include button-basic();
}
/* Mixin function for pr header */
@mixin pr-headline {
  color: $color-black;
  text-transform: uppercase;
  margin: 0 0 0 10px;
  font-family: $font--futura-medium;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
}
/* Mixin function for pr description */
@mixin pr-desc {
  padding-right: 20px;
  font-family: $font--subheading--secondary;
  color: $color-black;
  font-size: 16px;
  letter-spacing: 0.025em;
  line-height: 1.5;
  @include breakpoint($large-up) {
    font-size: 18px;
  }
}
/* Common Section render for MPP, SPP, Review, Endeca search and Display Page */
#pr-write,
#pr-reviewdisplay,
#pr-reviewsnippet,
.product-full__info,
.product-grid--quickshop,
#pr-questiondisplay {
  .product-extra-content .p-w-r .pr-snippet .pr-snippet-stars-png,
  .product-extra-content .p-w-r .pr-rd-star-rating .pr-snippet-stars-png {
    @include pr-background-image-black();
  }
  .product-extra-content .p-w-r {
    .pr {
      .thank-you-page {
        @include pr-background-image-black();
      }
    }
  }
  .p-w-r {
    /* To display a Star background Image */
    .pr-snippet,
    .pr-rd-star-rating {
      .pr-snippet-stars-png {
        @if $cr18 == true {
          @include pr-background-image-black();
        }
        @if $cr18 == false {
          @include pr-background-image();
        }
      }
    }
    a {
      @include pr-link();
      color: $color-dark-gray;
      &:hover {
        color: $color-green;
        text-decoration: none;
      }
    }
    label {
      @include pr-label();
    }
    select {
      &.form-control {
        @include pr-select;
      }
    }
    [data-filter-tooltip] {
      &:after,
      &:before {
        display: none;
      }
    }
    .pr {
      &-textinput,
      &-textarea {
        @include input-placeholder {
          font-size: 14px;
        }
      }
      &-alert-container,
      &-header-product-img,
      &-header-required,
      &-logo-container,
      &-header-product-name,
      &-review-snapshot-header-intro,
      &-snippet-rating-decimal {
        display: none;
      }
      &-table-cell {
        padding: 0;
      }
      &-helper-text {
        color: $color-drak-black;
      }
      &-clear-all-radios {
        @include pr-link();
        letter-spacing: 0;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      &-accessible-btn {
        @include pr-button();
        @include breakpoint($medium-down) {
          min-width: 100%;
        }
        border-radius: unset;
      }
      &-header-table {
        .pr-header-title {
          @include font--subheading;
          font-size: 34px;
        }
      }
      &-war,
      &-waqwaa {
        max-width: 100%;
      }
      &-snippet-read-and-write {
        .product-full & {
          position: relative;
          top: -4px;
        }
        &::before {
          font-size: 7px;
          content: '\2022';
          margin: 0 5px;
          .product-full &,
          .spp_customer_reviews &,
          .mpp__content &,
          .product-grid-wrapper & {
            content: '';
          }
        }
        .pr-snippet-write-review-link {
          &::before {
            .product-full & {
              content: '\2022';
              padding-right: 10px;
            }
          }
        }
      }
      &-snippet-review-count {
        font-family: $font--cta;
        font-size: 12px !important;
        .spp_customer_reviews & {
          font-family: $font--subheading;
          font-size: 34px !important;
          color: $color-dark-gray;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          line-height: 36px;
          @include breakpoint($medium-down) {
            line-height: 15px;
            font-size: 23px !important;
          }
        }
      }
      /* To Change Bar Graph */
      &-review-snapshot {
        .pr-ratings-histogram {
          &-barValue {
            background: $color-green;
          }
        }
        &-block {
          border: 1px solid $color-green;
          margin: 10px;
          min-height: auto;
          @include breakpoint($medium-down) {
            border-bottom: 1px dotted $color-light-gray;
            border-width: 0 0 1px;
            margin: 0;
            min-height: 90px;
          }
          dt {
            display: none;
          }
        }
      }
      &-snippet-stars-reco {
        &-inline {
          .pr-snippet-read-and-write {
            .spp__container & {
              display: inline-block;
              a {
                text-transform: uppercase;
                text-decoration: underline;
                color: $color-drak-black;
                font-family: $font--futura-medium;
              }
            }
            .pr-snippet-write-review-link {
              .spp-cross-sells__products &,
              .mpp__content &,
              .product-grid-wrapper & {
                display: none;
              }
            }
            a {
              font-size: 14px !important;
            }
            span.pr-snippet-review-count {
              .product-full & {
                font-size: 14px !important;
                text-transform: uppercase;
              }
            }
          }
          &.pr-snippet-minimal {
            .pr-snippet-stars-container {
              display: inline-block;
            }
            .pr-snippet-read-and-write {
              margin-top: 10px;
              vertical-align: sub;
              @include breakpoint($medium-down) {
                text-align: left;
              }
            }
          }
        }
      }
      &-review-snippet-container,
      &-qa-snippet-container {
        text-align: center;
        float: none;
      }
      /* To display a Review description posted by user */
      &-review-display {
        dt,
        dd,
        dl {
          color: $color-black;
          font-family: $font--subheading--secondary;
          text-transform: capitalize;
          font-size: 15px;
          vertical-align: middle;
          font-weight: normal;
          @include breakpoint($medium-down) {
            min-width: auto;
            padding-bottom: 3px;
          }
        }
        dt {
          text-transform: lowercase;
          font-weight: normal;
          font-size: 16px;
          &::first-letter {
            text-transform: uppercase;
          }
          @include breakpoint($medium-down) {
            text-align: left;
            padding-bottom: 5px;
          }
        }
        dd {
          text-align: left;
        }
        &.pr-rd-display-tablet {
          .pr-review {
            border-bottom: 1px solid $color-light-gray;
          }
          .pr-rd-flag-review-container {
            vertical-align: bottom;
          }
        }
        .pr-rd-review-tag {
          padding: 10px 0;
          dt,
          dd {
            @include breakpoint($medium-down) {
              padding: 5px;
            }
          }
        }
        .pr-rd-description-text {
          @include pr-desc();
          @include breakpoint($medium-down) {
            padding-right: 0;
          }
        }
        .pr-rd-footer {
          font-family: $font--subheading--secondary;
          @include breakpoint($medium-down) {
            height: auto;
          }
          .pr-rd-bottomline {
            background-color: $color-white;
            margin-bottom: 32px;
            padding: 0;
            @include breakpoint($medium-up) {
              margin-bottom: 24px;
            }
            span:first-child {
              display: none;
            }
            span {
              color: $color-black;
              font-size: 16px;
              @include breakpoint($medium-down) {
                color: $color-mild-gray;
                font-size: 14px;
                font-family: $font--futura-medium;
              }
              font-family: inherit;
              font-size: inherit;
              font-weight: normal;
            }
          }
          .pr-rd-helpful-action {
            font-family: $font--futura-medium;
            @include breakpoint($small-down) {
              margin-bottom: 14px;
            }
            @include breakpoint($medium-up) {
              width: 100%;
            }
            &-group {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
            }
            &-legend {
              width: 100%;
              @include breakpoint($medium-up) {
                width: auto;
              }
            }
            &-btn-group {
              margin: 0;
              width: auto;
              @include breakpoint($small-up) {
                width: 70%;
              }
              @include breakpoint($medium-up) {
                width: auto;
              }
            }
          }
          aside {
            &.pr-rd-flag-review-container {
              a {
                @include breakpoint($medium-down) {
                  letter-spacing: 0;
                }
              }
            }
          }
        }
        .pr-rd-pagination {
          border-bottom: none;
          border-top: solid 1px $color-black;
          float: none;
          font-family: 'Futura-Book';
          letter-spacing: 1px;
          line-height: 1;
          margin: 0;
          @include breakpoint($medium-up) {
            border-top: none;
            padding-top: 0;
            padding-bottom: 2px;
          }
          &-btn {
            @include pr-link();
            text-transform: capitalize;
          }
        }
      }
    }
    /* For Review Thank You page */
    .thank-you-page {
      @include pr-background-image();
      h2.headline {
        @include pr-headline();
        margin: 20px 0 0;
      }
      .pr-rating {
        &-number {
          border: 1px solid $color-green;
        }
        &-date {
          border: none;
        }
      }
      .title {
        @include font--subheading;
        color: $color-drak-black;
      }
      .pr-dark {
        @include font--subheading;
        color: $color-drak-black;
        font-size: 20px;
      }
      .pr-text,
      .subtitle {
        @include pr-desc();
        span {
          @include pr-desc();
          font-size: 15px;
        }
      }
      .pr-attribute-header {
        @include breakpoint($medium-down) {
          font-family: $font--subheading;
          font-weight: normal;
          font-size: 24px;
        }
      }
    }
    .pr-snippet-qa {
      &.pr-snippet-compact {
        .pr-snippet-qa-metrics {
          display: inline;
        }
      }
      .pr-snippet-qa-answer-link {
        font-size: 14px;
        &:hover {
          color: $color-green;
        }
      }
    }
    .pr-ask-question-link {
      &::before {
        .product-full & {
          content: '\2022';
          padding-right: 10px;
        }
      }
    }
  }
  .product-info__review-summary {
    .pr-review-snippet-container {
      .pr-snippet-stars-reco-reco {
        .spp-page & {
          display: none;
        }
      }
      .pr-snippet-stars-reco-inline {
        margin: 0 0 10px;
        .pr-snippet-read-and-write {
          a {
            padding: 0 3px;
            @include breakpoint($medium-down) {
              margin: 0;
            }
          }
        }
      }
    }
  }
  /* For Question and answer Section */
  #pr-questionsnippet {
    .pr-qa-snippet-container {
      .pr-ask-question {
        &-cta,
        &-subtext {
          display: none;
        }
        &-link {
          font-size: 14px;
        }
      }
    }
  }
}
/* For PWR Review signin page */
.prflow_show {
  .spp-page & {
    .pr_prod_img {
      float: left;
      width: 49%;
    }
    .email_check,
    .full_sign_in {
      padding-top: 50px;
      width: 49%;
      float: right;
      input {
        width: 250px;
        max-width: 100%;
        display: block;
        margin: 30px 0 20px;
      }
    }
    @if $cr18 == true {
      .email_check {
        h2 {
          font-size: 1.5em;
        }
        h3 {
          font-size: 1.17em;
        }
        .social-login__terms {
          p {
            font-size: 15px;
          }
        }
      }
    }
  }
}

#pr-war-form {
  @include pr-background-large-image();
  .product-grid-extra-content & {
    @include pr-background-large-image-black();
  }
  .pr-rating-stars {
    .pr-star-v4 {
      height: 29px;
      width: 31px;
      box-shadow: none;
      border: 0;
      outline: $color-white;
    }
    label {
      &::before {
        content: none;
      }
    }
  }
  span {
    font-size: 18px;
    font-family: $font--subheading;
  }
  .pr-helper-text {
    font-family: $font--futura-book;
    font-size: 14px;
    color: $color-drak-black;
  }
  input,
  textarea {
    box-shadow: none;
    font-family: $font--subheading--secondary;
    font-size: 14px;
    color: $color-drak-black;
  }
  .pr-required-indicator {
    color: $color-drak-black;
  }
  .pr-cons-form-group,
  .pr-bestuses-form-group,
  #pr-wasthisagift,
  .pr-pros-form-group {
    display: none;
  }
}

.write_a_review__container {
  .pr-subscript {
    font-family: $font--futura-book;
    font-size: 14px;
  }
  .pr-submit {
    .pr-footer {
      padding-top: 0;
      .pr-subscript {
        a {
          font-size: 14px;
        }
      }
    }
  }
}
/* Spp Page Review Container */
.spp__container {
  &.wrapper {
    padding: 0 20px 28px;
  }
  .spp_customer_reviews {
    @include breakpoint($medium-down) {
      border: none;
      padding: 0;
    }
  }
  .spp_reviews {
    padding: 0 0 18px;
    margin: 0 0 10px;
    &_heading {
      float: left;
      h2 {
        text-transform: uppercase;
        font-size: 18px;
        font-family: $font--futura-medium;
      }
    }
    &_button {
      text-align: right;
      input[type='submit'] {
        letter-spacing: 0;
      }
    }
    .pr-snippet-stars {
      .pr-rating-stars {
        .pr-star-v4 {
          height: 22px !important;
          width: 21px !important;
          margin-right: 6px;
          @include breakpoint($medium-up) {
            height: 16px !important;
            width: 17px !important;
          }
        }
      }
    }
    .p-w-r {
      .pr-review-snapshot {
        .pr-snippet {
          .pr-snippet-stars-png {
            background-color: $color-silver;
          }
        }
      }
      #pr-review-snapshot {
        .pr-snippet-review-count {
          display: none;
        }
      }
    }
  }
  #pr-reviewdisplay {
    .p-w-r {
      & :focus {
        outline: none;
        box-shadow: none;
      }
      #pr-image-display {
        display: none;
      }
      .pr-snippet-stars-reco-inline {
        width: auto;
        .pr-snippet-stars-container {
          display: inline-block;
          line-height: 2;
          width: auto;
          @include breakpoint($medium-up) {
            display: block;
            float: none;
            line-height: 1;
            text-align: center;
          }
        }
      }
      .pr-review-snapshot-simple,
      .pr-snippet-standard,
      .pr-snippet-minimal {
        margin: 5px 0;
        .pr-review-snapshot-snippets {
          .pr-snippet-read-and-write a {
            display: none;
          }
        }
        .pr-snippet-reco-to-friend-green {
          background: none;
          & circle {
            display: none;
          }
          .pr-checkbox-icon {
            display: none !important;
          }
          .pr-reco-value {
            font-size: 34px;
            font-family: $font--subheading;
            vertical-align: bottom;
            color: $color-dark-gray;
            line-height: 36px;
            letter-spacing: 0.05em;
            @include breakpoint($medium-down) {
              font-size: 23px;
              line-height: 23px;
            }
          }
          .pr-caret-icon__line {
            stroke: $color-drak-black;
          }
        }
      }
      .pr-accordion-btn {
        letter-spacing: 0;
        font-family: $font--futura-medium;
        span {
          font-family: $font--futura-book;
          letter-spacing: 0.04em;
          font-weight: normal;
          line-height: 1;
          font-size: 14px;
          text-transform: uppercase;
          color: $color-black;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
      .pr-review {
        border-bottom: 1px solid $color-black !important;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        margin: 0;
        padding-top: 164px;
        position: relative;
        @include breakpoint($medium-down) {
          padding-bottom: 0;
        }
        @include breakpoint($medium-up) {
          border-color: $color-medium-gray !important;
          font-size: 18px;
          margin: 0;
          min-height: 248px;
          padding: 56px 0 18px 272px;
        }
        &:last-of-type {
          @include breakpoint($small-down) {
            border: none !important;
          }
        }
      }
      .pr-review-snapshot {
        .pr-review-snapshot-content-block {
          margin: 0;
          padding-bottom: 64px;
          @include breakpoint($large-up) {
            padding-bottom: 50px;
          }
        }
        a.pr-snippet-write-review-link {
          @include pr-button();
          position: absolute;
          top: 82px;
          font-size: 24px !important;
          right: 20px;
          min-width: 250px;
        }
      }
      .pr-snippet-reco-to-friend {
        max-width: 100%;
        .pr-reco-to-friend-message {
          max-width: 80%;
          vertical-align: top;
          font-family: $font--subheading;
          font-size: 34px;
          margin-top: 6px;
          color: $color-dark-gray;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          @include breakpoint($medium-down) {
            font-size: 23px;
          }
        }
        div {
          vertical-align: bottom;
        }
        span {
          display: inline !important;
          line-height: inherit;
          margin: 0;
          vertical-align: unset;
        }
        .pr-snippet-reco-to-friend-green {
          background: none;
          display: none;
          .pr-checkbox-icon {
            display: none !important;
          }
          .pr-reco-value {
            color: $color-black;
            font-size: 16px;
            font-family: $font--futura-medium;
            letter-spacing: 0.05em;
            margin-right: 4px;
            @include breakpoint($large-up) {
              font-size: 18px;
            }
          }
        }
      }
    }
    .pr-review-snapshot {
      &-block-container,
      &-faceoff {
        display: none;
      }
      &-block {
        .pr-review-snapshot-block-headline,
        .pr-snapshot-highlight {
          display: none;
        }
        .pr-snapshot-tag-def {
          text-align: center;
          @include breakpoint($medium-down) {
            text-align: left;
            display: inline-block;
          }
          &:first-of-type {
            padding-top: 0;
          }
        }
      }
      .pr-review-snapshot-snippets-headline {
        color: $color-drak-black;
      }
      .pr-review-snapshot-block {
        &-cons {
          .pr-review-snapshot {
            &-tags {
              display: none;
            }
          }
        }
        &-pros {
          .pr-review-snapshot {
            &-block-headline {
              display: block;
              background-color: $color-white;
              text-align: center;
              font-size: 20px;
              @include breakpoint($medium-down) {
                text-align: left;
              }
            }
          }
          dt {
            display: block;
            background-color: $color-white;
          }
        }
      }
      .pr-msq-list {
        dt {
          display: block;
          margin-bottom: 5px;
          color: $color-black;
          font-weight: normal;
          text-transform: capitalize;
          font-family: $font--futura-book;
          @include breakpoint($medium-up) {
            vertical-align: top;
            display: table-cell;
            width: 150px;
          }
        }
        dd {
          vertical-align: top;
          @include swap_direction(padding, 0 20px 10px 0);
          @include breakpoint($medium-up) {
            @include swap_direction(padding, 0 20px 20px 0);
          }
          .pr-filter-btn {
            background: $color-white;
            border: 1px solid $color-black;
            &--active {
              background: $color-black;
              .pr-filter-btn__child {
                color: $color-white !important;
              }
            }
            .pr-filter-btn__child {
              background: none;
              color: $color-black;
              font-size: 16px;
              font-family: $font--futura-book;
              padding: 5px 10px;
              &:last-child {
                display: none;
              }
            }
          }
        }
      }
      .pr-snapshot-highlight {
        background-color: $color-green;
      }
      .pr-faceoff {
        &-readcomplete {
          @include pr-link();
        }
        &-title {
          text-align: center;
          text-transform: capitalize;
        }
      }
    }
    .pr-review-snapshot-msq-container {
      margin: 40px 0 -15px;
      padding: 40px 0 0;
      border-top: 1px solid $color-black;
      @include breakpoint($medium-up) {
        border-top: 1px solid $color-light-gray;
        margin: 24px 0 -15px;
      }

      // Hide new filters showing in SPP
      .pr-review-snapshot-msq-describeyourself {
        display: none;
      }
      .pr-review-snapshot-msq-bestuses {
        display: none;
      }
    }
    .pr-review-display {
      .pr-rd-no-reviews {
        padding: 20px 10px;
      }
    }
    .pr-review-display {
      .pr-rd-review-headline {
        @include pr-headline();
        margin: 0;
        @include breakpoint($medium-up) {
          font-size: 18px;
        }
      }
      .pr-rd-main-footer {
        border-bottom: none;
        @include breakpoint($medium-up) {
          padding: 15px 0;
        }
        .pr-rd-review-position {
          border: none;
          font-size: 14px;
          padding: 10px 0;
          @include breakpoint($medium-up) {
            padding: 0;
          }
          span {
            font-size: 14px;
          }
          .pr-rd-bold {
            font-family: $font--futura-book;
            font-size: 14px;
            font-weight: normal;
          }
        }
        & > .pr-rd-content-block {
          border-right: 0;
          width: 100%;
          @include breakpoint($medium-up) {
            align-items: center;
            display: flex;
            justify-content: center;
          }
        }
        .pr-rd-to-top {
          display: none;
        }
      }
      .pr-flagging-radio {
        padding-left: 20px;
      }
      &.pr-review-display {
        .pr-rd-sort-group {
          float: none;
        }
        .pr-rd-helpful-text {
          font-weight: normal;
          font-size: inherit;
          line-height: 1.4em;
        }
        .pr-rd-content-block {
          &.pr-rd-header {
            padding: 0;
          }
          &.pr-rd-description {
            margin-bottom: 0;
            position: static;
            order: 3;
            width: 100%;
          }
          &.pr-rd-tags,
          &.pr-rd-subratings {
            border-right: none;
          }
          &.pr-rd-tags {
            display: none;
          }
        }
        .pr-rd-reviewer-type {
          display: none;
        }
        .pr-rd-description {
          .pr-rd-side-content-block {
            left: 0;
            position: absolute;
            right: auto;
            top: 16px;
            width: 100%;
            @include breakpoint($medium-up) {
              padding: 0;
              top: 54px;
              width: 280px;
            }
            .pr-rd-reviewer-details {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              flex-wrap: wrap;
              margin-bottom: 12px;
              > p {
                margin: 0;
                @include breakpoint($medium-up) {
                  margin: 6px 0 0;
                }
                &.pr-rd-author-nickname {
                  color: $color-black;
                  font-family: $font--futura-medium;
                  font-size: 16px;
                  font-weight: 700;
                  margin: 0;
                  order: 1;
                  text-transform: capitalize;
                  @include breakpoint($medium-up) {
                    font-size: 18px;
                  }
                  .pr-rd-bold {
                    display: none;
                  }
                  span {
                    color: $color-black;
                  }
                }
                &.pr-rd-author-location {
                  display: inline-block;
                  order: 2;
                  span,
                  span span {
                    color: $color-black;
                    font-family: $font--futura-medium;
                    font-size: 16px;
                    @include breakpoint($medium-up) {
                      font-size: 18px;
                    }
                  }
                  .pr-rd-bold {
                    font-weight: normal;
                  }
                }
                &.pr-rd-author-submission-date {
                  order: 5;
                  @include breakpoint($small-down) {
                    margin-top: 0;
                  }
                  .pr-rd-bold {
                    display: none;
                  }
                  span,
                  time {
                    font-family: $font--futura-medium;
                    font-size: 16px;
                    @include breakpoint($medium-up) {
                      font-size: 18px;
                    }
                  }
                  time {
                    color: $color-mid-gray;
                    display: inline-block;
                  }
                }
                &.pr-rd-demographic {
                  order: 3;
                  span {
                    font-family: $font--futura-medium;
                    color: $color-black;
                    font-size: 16px;
                    padding: 0;
                    font-weight: 400;
                    @include breakpoint($medium-up) {
                      font-size: 18px;
                    }
                    &.pr-rd-bold {
                      text-transform: lowercase;
                      display: inline-block;
                      &::first-letter {
                        text-transform: uppercase;
                      }
                      &::after {
                        content: ':';
                        margin: 0 4px 0 -4px;
                        display: inline-block;
                      }
                    }
                  }
                  &.incentivized {
                    order: 4;
                    span {
                      color: $color-little-gray;
                      &::after {
                        content: '';
                      }
                    }
                    span:last-child {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
        .pr-rd-images {
          display: flex;
          flex-wrap: wrap;
          margin: 0;
          order: 4;
          padding: 16px 0;
          width: 100%;
          .pr-rd-image-container {
            margin: 0;
            padding: 0 10px 12px 0;
            height: 120px;
            width: 120px;
            flex-shrink: 0;
          }
        }
        .pr-rd-sort {
          font-family: $font--subheading;
          font-size: 15px;
          padding: 0 10px;
          letter-spacing: 0.05em;
          optgroup,
          option {
            font-weight: 200;
          }
        }
      }
      .pr-rd-content-block {
        .pr-rd-tags {
          display: none;
        }
      }
      .pr-modal-content {
        .pr-flag-review-btn {
          @include pr-button();
          color: $color-green;
          border-radius: 0;
        }
        input[type='radio'] {
          position: relative;
          left: 0;
        }
        .pr-flag-review {
          span {
            font-size: 15px;
            font-family: $font--text;
            color: $color-drak-black;
            font-weight: normal;
            text-transform: none;
          }
        }
        .pr-control-label {
          @include pr-label();
        }
      }
      .pr-rd-sort {
        &-group {
          label {
            display: none;
          }
        }
      }
      .pr-rd-helpful-text {
        @include breakpoint($medium-down) {
          margin-right: 0;
        }
      }
    }
    .pr-snippet-stars-reco-inline {
      .pr-snippet-stars-reco-reco {
        float: none;
      }
    }
    .pr-review-snapshot-snippets {
      background-color: $color-silver;
      padding-top: 20px;
      @include breakpoint($medium-down) {
        text-align: center;
        padding: 20px;
        min-height: auto;
      }
      .pr-snippet-stars-reco-stars {
        .pr-snippet {
          &::after {
            content: '\f0c8';
            font-family: FontAwesome;
            margin-right: 7px;
            font-size: 5px;
            color: $color-dark-gray;
            @include breakpoint($medium-down) {
              content: ' ';
            }
          }
        }
      }
    }
    .pr-thumbs-icon {
      svg {
        display: none;
      }
      &-down {
        &::before {
          font-size: 62px;
          position: relative;
          top: 6px;
          @include breakpoint($medium-up) {
            font-size: 24px;
            padding-bottom: 8px;
          }
        }
      }
    }
    .pr-helpful {
      &-count {
        @include font--cta;
        text-transform: uppercase;
        padding-right: 10px;
        color: $color-dark-gray;
        &:hover {
          color: $color-green;
        }
        @include breakpoint($medium-down) {
          letter-spacing: 0;
        }
      }
      &-btn {
        border: 0;
        padding: 0 5px;
        margin: 0 3px;
        background: none;
        text-transform: capitalize;
        border-radius: 0;
        @include breakpoint($medium-down) {
          margin: 15px 0;
          float: left;
          padding: 0;
        }
        span {
          font-size: 15px;
        }
      }
      &-yes {
        background: url('#{$base-theme-path}svg-icons/src/thumbs-up.svg') 0 2px / 32px no-repeat
          transparent !important;
        padding: 16px 0;
        &:hover,
        &.pr-helpful-active {
          background: url('#{$base-theme-path}svg-icons/src/thumbs-up--filled.svg') 0 2px / 32px
            no-repeat transparent !important;
          opacity: 1;
          span {
            color: $color-black;
          }
        }
        @include breakpoint($medium-up) {
          background-position: 0 4px;
          margin-left: 16px;
        }
        .pr-helpful-count {
          &::before {
            content: 'yes ';
            visibility: visible;
          }
        }
      }
      &-no {
        background: url('#{$base-theme-path}svg-icons/src/thumbs-down.svg') 0 10px / 32px no-repeat
          transparent !important;
        padding: 16px 0;
        @include breakpoint($medium-up) {
          background: url('#{$base-theme-path}svg-icons/src/thumbs-down.svg') 12px 10px / 32px
            no-repeat transparent !important;
        }
        &:hover,
        &.pr-helpful-active {
          background: url('#{$base-theme-path}svg-icons/src/thumbs-down--filled.svg') 0 10px / 32px
            no-repeat transparent !important;
          opacity: 1;
          @include breakpoint($medium-up) {
            background: url('#{$base-theme-path}svg-icons/src/thumbs-down--filled.svg') 12px 10px /
              32px no-repeat transparent !important;
          }
          span {
            color: $color-black;
          }
        }
        @include breakpoint($medium-up) {
          background-position: 12px 12px;
          padding: 16px 12px;
        }
        .pr-helpful-count {
          &::before {
            content: 'no ';
            visibility: visible;
          }
        }
      }
    }
  }
  .prfieldwrap {
    font-family: $font--text;
    font-size: 15px;
    line-height: 25px;
  }
  .responsive-tabs {
    @include breakpoint($large-up) {
      text-align: left;
    }
    .resp-tabs-container {
      border: none;
      @include breakpoint($medium-up) {
        border-top: solid 1px $color-black;
      }
      .resp-accordion {
        cursor: default;
        display: none;
        padding: 24px 0;
        pointer-events: none;
        text-transform: capitalize;
        @include breakpoint($medium-up) {
          display: block;
        }
      }
    }
    .resp-tabs-list {
      border-top: solid 1px $color-black;
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
      width: 100%;
      @include breakpoint($small-up) {
        padding: 16px 0;
      }
      @include breakpoint($medium-up) {
        border-top: none;
      }
      @include breakpoint($large-up) {
        padding: 0;
      }
      .resp-tab-item {
        background: transparent;
        border-width: 0 0 1px;
        color: $color-gray;
        display: block;
        font-size: 16px;
        line-height: 1;
        margin: 0;
        padding: 16px 0;
        @include breakpoint($small-up) {
          border: none;
        }
        @include breakpoint($small-up) {
          display: inline-block;
          padding: 0 16px;
        }
        @include breakpoint($large-up) {
          font-size: 18px;
          margin: 12px 0;
        }
        &:first-child {
          border: solid $color-black;
          border-width: 0 0 1px;
          @include breakpoint($small-up) {
            border-width: 0 2px 0 0;
          }
          @include breakpoint($medium-up) {
            padding-left: 0;
          }
        }
        &.resp-tab-active {
          font-weight: 700;
          color: $color-black;
        }
      }
    }
    .resp-tab {
      &-content {
        padding: 0;
      }
      &-container {
        border: 0;
      }
    }
    .responsive-tabs__list-wrapper {
      border: 0;
    }
  }
}

#power_review_container {
  .p-w-r {
    .pr-btn-default,
    .tag-group {
      &.active,
      .pr-label-control.checked {
        background-color: $color-green;
      }
    }
    .btn-group-radio-vertical {
      .pr-btn {
        @include icon('radio');
        &.active,
        &:hover {
          @include icon('radio--checked');
        }
      }
    }
    .form-group {
      &.pr-bottomline-form-group {
        overflow: visible;
      }
    }
  }
  .product-full__image,
  .product-full__details {
    width: 49%;
    @include breakpoint($small-down) {
      width: 100%;
    }
    h1 {
      font-size: 32px;
    }
  }
  .product-full__image {
    float: left;
  }
  .product-full__details {
    float: right;
  }
  .product-full {
    position: inherit;
  }
}
/* Question and Answer */

#pr-questionsnippet {
  .pr-snippet-qa {
    margin-top: -10px;
    @include breakpoint($medium-down) {
      margin-top: -13px;
    }
    .pr-qa-large {
      display: none;
    }
  }
  .pr-ask-question-link {
    display: inline;
  }
  .pr-answered-question-circle {
    @include pr-link();
    background-color: $color-white;
    margin: 0;
    float: none;
    &:hover {
      cursor: default;
      color: $color-dark-gray;
    }
    @include breakpoint($medium-down) {
      letter-spacing: 0.5em;
    }
  }
}

.product-grid-wrapper {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-stars-reco-stars {
          @include breakpoint($medium-down) {
            float: right;
            padding-bottom: 5px;
          }
        }
        .pr-snippet-read-and-write {
          vertical-align: sub;
          a {
            @include breakpoint($medium-down) {
              margin: 0;
            }
          }
        }
      }
      &.pr-snippet-compact {
        .pr-snippet-read-and-write {
          vertical-align: sub;
        }
        .pr-snippet-stars-reco-stars {
          @include breakpoint($medium-down) {
            float: left;
          }
        }
      }
    }
  }
}

.product-grid-wrapper.product-grid-extra-content-wrapper {
  .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-stars-reco-stars {
    @include breakpoint($medium-down) {
      float: none;
    }
  }
  .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-stars-reco-stars,
  .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-stars {
    @include breakpoint($medium-down) {
      float: none;
    }
  }
}

.product-grid__wrapper {
  .p-w-r {
    .pr-snippet-stars-reco-inline.pr-snippet-minimal {
      .pr-snippet-stars-reco-stars {
        width: 100%;
        @include breakpoint($medium-up) {
          width: initial;
        }
      }
    }
  }
}

#pr-questiondisplay {
  .p-w-r {
    .pr-qa-display {
      padding: 0;
      @include breakpoint($large-up) {
        padding-right: 0%;
      }
      .pr-qa-display-headline {
        height: 120px;
        margin: 0;
        border-bottom: solid 1px $color-black;
        @include breakpoint($medium-up) {
          height: 152px;
        }
        @media (min-width: 837px) {
          height: 216px;
        }
        @include breakpoint($large-up) {
          border-color: $color-medium-gray;
          height: auto;
        }
        button {
          @include breakpoint($large-up) {
            float: none;
          }
        }
        h1 {
          display: none;
        }
      }
      .pr-qa-display-ask-question {
        text-align: center;
        width: 100%;
        @media (min-width: 837px) {
          margin-bottom: 36px;
        }
        @include breakpoint($medium-up) {
          padding-left: 0;
          float: none;
        }
        @include breakpoint($large-up) {
          margin-bottom: 72px;
        }
        .pr-control-label {
          span {
            font-size: 18px;
          }
        }
        .pr-required-indicator {
          color: $color-drak-black;
        }
        .control-label,
        .pr-control-label {
          .pr-required-indicator {
            color: $color-drak-black;
          }
        }
        button.pr-btn-ask-question {
          @include pr-button();
          min-width: 250px;
          @include breakpoint($medium-down) {
            min-width: 100%;
            margin-bottom: 25px;
          }
          &:hover {
            @include pr-button();
            min-width: 250px;
          }
          span {
            color: $color-drak-black;
            &.pr-qa-display-text {
              color: $color-white;
              font-size: 24px;
              vertical-align: bottom;
              text-shadow: none;
              &:hover {
                color: $color-white;
                text-shadow: none;
                background-image: none;
              }
            }
          }
        }
        .btn-group {
          font-family: $font--subheading--secondary;
        }
        .pr-waq-legal-text {
          font-family: $font--futura-book;
          font-size: 14px;
        }
        .pr-helper-text {
          font-family: $font--text;
          font-size: 14px;
        }
        .pr-question-form {
          .form-group {
            @include breakpoint($medium-down) {
              margin-bottom: 15px;
            }
          }
        }
      }
      .pr-qa-sort {
        height: 36px;
        padding: 0 10px;
        min-width: 30%;
        display: inline;
        optgroup,
        option {
          font-weight: 200;
        }
        @include breakpoint(($small-down) (orientation landscape)) {
          min-width: 89%;
        }
        @include breakpoint(($portrait-only) (orientation portrait)) {
          min-width: 74%;
        }
        /* Iphone6 Potrait */
        @include breakpoint(($xlarge-only) (orientation portrait)) {
          min-width: 79%;
        }
        /* Iphone6 plus Potrait */
        @include breakpoint(($xxlarge-only) (orientation portrait)) {
          min-width: 82%;
        }
        &-headline {
          float: left;
          font-family: $font--subheading;
          font-size: 34px;
          text-transform: uppercase;
          font-weight: normal;
          letter-spacing: 0.05em;
          line-height: normal;
          color: $color-dark-gray;
          padding-left: 20px;
          margin: 0;
          padding-bottom: 15px;
          width: 100%;
          position: relative;
          right: 100%;
          top: 70px;
          @include breakpoint($medium-down) {
            padding: 10px;
            float: none;
            display: inherit;
            right: 0;
            left: 10px;
            top: 30px;
            font-size: 23px;
          }
        }
        &-group {
          margin: 0 10px;
          font-family: $font--subheading;
          font-size: 15px;
          padding: 0 10px;
          position: relative;
          bottom: 50px;
          width: 96%;
          /* Mobile Landscape */
          @include breakpoint(($small-down) (orientation landscape)) {
            bottom: 58px;
          }
          /* Mobile Potrait */
          @include breakpoint(($portrait-only) (orientation portrait)) {
            bottom: 58px;
          }
          @include breakpoint($large-up) {
            float: left;
          }
          &::after {
            @include breakpoint($large-up) {
              content: ' ';
              display: none;
            }
          }
        }
      }
      .pr-qa-display {
        &-sort {
          background-color: $color-silver;
          border-bottom: 1px solid $color-light-gray;
          width: 50%;
          float: left;
          @include breakpoint($medium-down) {
            width: 100%;
            float: none;
          }
          @include breakpoint($medium-up) {
            padding-top: 18px;
          }
        }
        &-search {
          background-color: $color-silver;
          width: 50%;
          float: left;
          border-bottom: 1px solid $color-light-gray;
          @include breakpoint($medium-down) {
            width: 100%;
            height: auto;
            border-bottom: none;
            margin-top: 62px;
          }
          .pr-qa-display-searchbar {
            input {
              @include border-radius(0);
              border: 1px solid $color-suva-grey;
              font-family: $font--subheading;
              font-size: 16px;
              height: 36px !important;
              @include swap_direction(padding, 8px 0 8px 39px);
              &::placeholder {
                font-size: 16px;
                color: $color-black;
              }
              &:active,
              &:focus {
                border: 1px solid $color-suva-grey;
                outline: unset;
                box-shadow: unset;
              }
            }
            .pr-qa-search-btn {
              background-color: transparent;
              border: none;
              height: 34px;
              left: 0;
              top: 0;
              width: 34px;
              #{$ldirection}: 0;
              #{$rdirection}: auto;
            }
            .pr-search-icon {
              align-items: center;
              display: flex;
              height: 100%;
              justify-content: center;
              width: 100%;
            }
            svg {
              height: 18px;
              width: 18px;
              margin: 0;
              transform: rotate(0deg);
            }
            path {
              fill: $color-black;
            }
          }
        }
        &-questionlist {
          border-bottom: 1px solid $color-black !important;
          border-left: 0;
          display: flex;
          flex-direction: column;
          font-size: 16px;
          margin: 0;
          padding: 124px 0 28px;
          position: relative;
          width: 100%;
          @include breakpoint($medium-up) {
            border-color: $color-medium-gray !important;
            font-size: 18px;
            margin: 0;
            padding: 56px 0 18px 272px;
          }
          &:last-of-type {
            @include breakpoint($small-down) {
              border: none !important;
            }
          }
          .pr-qa-display-item,
          .pr-qa-display-item-details {
            h2 {
              @include pr-headline();
              color: $color-green;
              margin-left: 32px;
            }
            time {
              color: $color-mid-gray;
              display: block;
              margin: 0;
            }
          }
        }
        &-answer-by,
        &-answer-by span,
        &-answer-text {
          @include pr-desc();
          letter-spacing: normal;
          @include breakpoint($medium-up) {
            font-size: 18px;
          }
        }
        &-answer-text {
          margin-bottom: 24px;
        }
        &-item-details,
        &-item-details time,
        &-item-details sub,
        &-search-no-results {
          @include pr-desc();
          letter-spacing: normal;
          @include breakpoint($medium-up) {
            font-size: 18px;
          }
        }
        &-question {
          float: none;
          width: 100%;
          @include breakpoint($medium-down) {
            margin-left: 0;
          }
          .pr-qa-display-add-answer {
            .form-group {
              @include breakpoint($medium-down) {
                margin-bottom: 15px;
              }
            }
          }
        }
        .pr-qa-display-answer {
          margin-bottom: 10px;
        }
      }
      .pr-btn-answer {
        @include pr-link();
        color: $color-dark-gray;
        text-decoration: underline;
        &:hover {
          .pr-qa-display-text {
            @include pr-link();
          }
          .pr-cross-icon {
            background: $color-green;
          }
        }
        span {
          color: $color-black;
        }
      }
      .pr-btn-ask-question {
        margin: 0;
        .pr-cross-icon {
          display: none;
        }
      }
      .pr-accessible-btn {
        top: -4px;
        left: 2px;
        @include breakpoint($small-down) {
          width: 99% !important;
          min-width: 99%;
        }
      }
      .pr-answer-form {
        .pr-control-label {
          span {
            font-size: 16px;
            @include breakpoint($medium-down) {
              font-size: 18px;
            }
          }
        }
      }
      .pr-required-indicator {
        color: $color-drak-black;
      }
      .control-label,
      .pr-control-label {
        .pr-required-indicator {
          color: $color-drak-black;
        }
      }
      .pr-btn.active {
        background-color: $color-green;
      }
      .pr-caret-icon {
        float: right;
        position: relative;
        left: 0;
        &__line {
          stroke: $color-drak-black;
        }
      }
      .pr-btn-show-more {
        .pr-qa-display-text {
          @include pr-link();
        }
      }
      .pr-qa-display-answer {
        @include breakpoint($medium-down) {
          margin-bottom: 0;
        }
      }
      .pr-qa-display-questionlist {
        margin: 0 0 20px;
        padding-left: 0;
        border-left: 0;
        border-bottom: 1px solid $color-light-gray;
      }
      .pr-cross-icon {
        display: none;
      }
      .pr-qa-display-headline {
        margin: 0;
      }
    }
    abbr[title] {
      cursor: auto;
      @include font--text;
      border-bottom: none;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: bold;
      font-family: $font--text;
      margin-left: 32px;
    }
    .pr-thumbs-icon {
      svg {
        display: none;
      }
    }
    .pr-helpful {
      &-count {
        @include font--cta;
        text-transform: uppercase;
        color: $color-black;
        padding-left: 0;
        padding-right: 16px;
        &:hover {
          color: $color-green;
        }
        @include breakpoint($medium-down) {
          letter-spacing: 0;
        }
      }
      &-btn {
        border: 0;
        padding: 0 5px;
        margin: 0 3px;
        background: none;
        text-transform: capitalize;
        border-radius: 0;
        @include breakpoint($small-down) {
          margin: 15px 0 0;
          padding: 0;
        }
        &:focus {
          outline: unset;
          box-shadow: unset;
        }
        span {
          font-size: 15px;
        }
      }
      &-yes {
        background: url('#{$base-theme-path}svg-icons/src/thumbs-up.svg') 0 2px / 32px no-repeat
          transparent;
        padding: 16px 10px;
        &:hover,
        &.pr-helpful-active {
          background: url('#{$base-theme-path}svg-icons/src/thumbs-up--filled.svg') 0 2px / 32px
            no-repeat transparent !important;
          opacity: 1;
          span {
            color: $color-black;
          }
        }
        @include breakpoint($medium-up) {
          margin-left: 6px;
        }
        .pr-helpful-count {
          &::before {
            visibility: visible;
          }
        }
      }
      &-no {
        background: url('#{$base-theme-path}svg-icons/src/thumbs-down.svg') 0 10px / 32px no-repeat
          transparent;
        padding: 16px 10px;
        &:hover,
        &.pr-helpful-active {
          background: url('#{$base-theme-path}svg-icons/src/thumbs-down--filled.svg') 0 10px / 32px
            no-repeat transparent !important;
          opacity: 1;
          span {
            color: $color-black;
          }
        }
        @include breakpoint($medium-up) {
          padding: 16px 12px;
        }
        .pr-helpful-count {
          &::before {
            visibility: visible;
          }
        }
      }
      &-voting {
        margin-top: 10px;
        margin-left: 0;
        @include breakpoint($medium-down) {
          margin-top: 0;
        }
      }
      &-active {
        .pr-thumbs-cuff-fill,
        .pr-thumbs-fill {
          fill: $color-green;
        }
      }
      &-active[disabled] {
        .pr-thumbs-cuff-fill,
        .pr-thumbs-fill {
          fill: $color-green;
        }
      }
    }
    .pr-helpful-btn:hover {
      fill: $color-green;
      .pr-thumbs-cuff-fill,
      .pr-thumbs-fill {
        fill: $color-green;
        &:hover {
          fill: $color-green;
        }
      }
    }
    .pr-waq-prequestion-message {
      h3 {
        font-family: $font--subheading;
        font-size: 18px;
        text-transform: uppercase;
      }
      .prfieldwrap {
        font-family: $font--subheading--secondary;
      }
    }
    .pr-cross-icon__line,
    .pr-qa-display-answer-icon {
      display: none;
    }
  }
  &.pr-qa-has-questions {
    .p-w-r .pr-qa-display .pr-qa-display-headline > .pr-h1 {
      display: none;
    }
  }
}

#pr-write {
  .p-w-r {
    .form-group {
      &.pr-media_image-form-group,
      &.pr-media_videourl-form-group {
        label {
          @include pr-button();
          width: 25% !important;
          margin-left: 1px;
          @include breakpoint($medium-down) {
            width: 99% !important;
          }
          span {
            color: $color-white;
            float: none;
            font-size: 24px;
          }
        }
        .pr-icon-delete {
          span {
            float: none;
          }
          fill: $color-white;
        }
        .form-group {
          input {
            display: none;
          }
        }
        .pr-media-preview {
          .pr-loading-indicator {
            margin: 8%;
          }
        }
        .pr-btn-fileinput {
          span::after {
            color: $color-white;
          }
        }
        .pr-btn-danger {
          background-color: $color-dark-gray;
          border: 1px solid $color-drak-black;
          padding: 4px 10px;
          &:hover {
            background-color: $color-dark-gray;
            border: 1px solid $color-drak-black;
          }
        }
      }
    }
  }
}

// Creative refresh 2018
@mixin reviews-stars--cr18 {
  .recommended-product-items,
  .spp-page,
  .product-brief {
    .p-w-r {
      margin-left: 0;
      .pr-snippet {
        &-rating-decimal {
          border: none;
        }
        &-stars-reco-inline .pr-snippet-read-and-write {
          span.pr-snippet-review-count,
          a.pr-snippet-review-count {
            display: inline-flex;
            padding: 0;
            margin-#{$ldirection}: 7px;
            margin-#{$rdirection}: 10px;
            font-family: $font--futura-demi;
            text-decoration: none;
            font-size: 16px;
            line-height: 1.3;
            @include breakpoint($medium-up) {
              display: flex;
              padding-top: 1px;
              font-size: 20px;
              line-height: 1;
            }
          }
          span.pr-snippet-review-count {
            font-family: $font--futura-demi;
          }
          .pr-snippet-review__link {
            font-family: $font--futura-book;
            font-size: 16px !important;
            color: $color-black;
            text-decoration: underline;
            @include breakpoint($medium-up) {
              font-size: 20px !important;
            }
          }
        }
        .pr-snippet-stars-png {
          $pr-count: (0, 25, 50, 75, 100);
          $pr-height-value: 17px;
          $pr-width-value: 16px;
          @each $pr-value in $pr-count {
            .pr-star-v4-#{$pr-value}-filled {
              background-image: url('/media/images/power_review/stars_black/rating-#{$pr-value}_single.png');
              height: $pr-height-value;
              width: $pr-width-value;
            }
          }
        }
      }
      .pr-snippet-minimal,
      .pr-snippet-compact {
        .pr-snippet-stars-reco-reco {
          display: none;
        }
      }
    }
  }
  .spp-page .product-brief {
    .pr-snippet-read-and-write {
      display: none;
    }
  }
  .spp__container {
    .spp_customer_reviews {
      .responsive-tabs {
        .resp-accordion {
          display: none;
          font-size: 24px;
          @include breakpoint($medium-up) {
            font-size: 30px;
          }
          &.resp-tab-active {
            @include breakpoint($medium-up) {
              display: block;
            }
          }
        }
      }
      #pr-reviewdisplay {
        .pr-review-snapshot-snippets {
          background-color: transparent;
          padding: 0;
        }
        .p-w-r {
          .pr-snippet,
          .pr-rd-star-rating {
            .pr-snippet-stars-png {
              @include pr-background-image-black();
              background: transparent;
            }
          }
          .pr-snippet-stars-reco-inline {
            padding: 4px 0;
            @include breakpoint($medium-up) {
              border: solid 1px $color-black;
              margin: 0 auto;
              padding: 10px 0;
              width: 530px;
            }
          }
          .pr-filter-btn--button {
            &:hover {
              background-color: $color-little-gray;
            }
            .pr-filter-btn__buttonText {
              font-size: 16px;
            }
          }
          .pr-review-snapshot {
            padding-bottom: 16px;
            @include breakpoint($large-up) {
              padding: 0 0 32px;
            }
            .pr-snippet-stars-reco-inline {
              .pr-snippet-stars-reco-stars {
                float: none;
                width: 100%;
                margin: 0 auto;
                padding: 0 0 10px;
                .pr-snippet {
                  justify-content: center;
                  &::after {
                    content: none;
                  }
                }
              }
              .pr-snippet-stars-reco-reco {
                position: relative;
              }
              .pr-snippet-read-and-write {
                display: inline-block;
                line-height: 2;
                margin: 0;
                text-align: center;
                @include breakpoint($medium-up) {
                  display: block;
                  line-height: 1.25;
                }
                .pr-snippet-review-count {
                  color: $color-black;
                  font-family: $font--futura-medium;
                  font-size: 16px !important;
                  text-transform: capitalize;
                  @include breakpoint($small-down) {
                    display: inline-block;
                    padding-top: 4px;
                  }
                  @include breakpoint($medium-up) {
                    font-size: 24px !important;
                    line-height: 1.5;
                  }
                }
              }
              .pr-snippet-reco-to-friend {
                text-align: center;
                .pr-reco-to-friend-message {
                  color: $color-black;
                  font-family: $font--futura-medium;
                  font-size: 16px;
                  line-height: inherit;
                  max-width: 100%;
                  text-transform: capitalize;
                  @include breakpoint($large-up) {
                    font-size: 18px;
                  }
                }
              }
            }
            a.pr-snippet-write-review-link {
              position: absolute;
              top: auto;
              bottom: 20px;
              left: calc(50% - 90px);
              background: $color-black;
              border: none;
              font-family: $font--futura-book;
              font-size: 16px !important; //@todo remove !important when legacy code is removed.
              height: 30px;
              line-height: 32px;
              min-width: 1px;
              padding: 0 20px;
              text-transform: capitalize;
              width: 180px;
              @include breakpoint($medium-up) {
                bottom: 0;
              }
            }
          }
          #pr-review-display {
            .pr-rd-main-header {
              background-color: transparent;
              border-bottom: 1px solid $color-black;
              position: relative;
              padding: 0 0 25px;
              @include breakpoint($medium-up) {
                border-bottom: 1px solid $color-light-gray;
                padding-bottom: 0;
              }
              h1,
              .pr-h1 {
                display: none;
              }
              &.pr-rd-main-header-with-filters {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                @include breakpoint($medium-up) {
                  flex-direction: row;
                  justify-content: space-between;
                  margin: 0;
                }
                .pr-rd-review-header-contents {
                  background-color: transparent;
                  order: 2;
                  padding: 0;
                  @include breakpoint($medium-up) {
                    padding: 15px 0 30px;
                    width: 400px;
                  }
                }
                .pr-rd-review-header-sorts {
                  order: 1;
                  @include breakpoint($medium-up) {
                    order: 2;
                    width: 150px;
                    padding: 24px 0 0;
                  }
                }
                .pr-review-filter-info-bar {
                  overflow: hidden;
                  z-index: -1;
                  height: 1px;
                  margin: 0;
                  width: 100%;
                }
                div:last-of-type:not(.pr-rd-review-header-contents):not(.pr-rd-review-header-sorts) {
                  width: 100%;
                }
              }
              .pr-rd-search-container {
                margin: 0 auto;
                @include breakpoint($medium-up) {
                  width: 100%;
                }
                .pr-rd-search-reviews-input {
                  max-width: 340px;
                  margin: 0 auto;
                  @include breakpoint($medium-up) {
                    max-width: auto;
                    margin: 0;
                  }
                  input {
                    font-size: 16px;
                    height: 36px !important;
                    @include swap_direction(padding, 8px 0 8px 39px);
                    &::placeholder {
                      font-size: 16px;
                      color: $color-black;
                    }
                  }
                  .pr-rd-search-reviews-icon-button {
                    background-color: transparent;
                    border: none;
                    height: 34px;
                    width: 34px;
                    #{$ldirection}: 0;
                    #{$rdirection}: auto;
                    .pr-search-icon {
                      align-items: center;
                      display: flex;
                      height: 100%;
                      justify-content: center;
                      width: 100%;
                      svg {
                        height: 18px;
                        width: 18px;
                        margin: 0;
                        transform: rotate(0deg);
                        path {
                          fill: $color-black;
                        }
                      }
                    }
                  }
                }
              }
            }
            .pr-rd-sort-group {
              position: relative;
              top: 0;
              width: 340px;
              margin: 0 auto;
              text-align: center;
              @include breakpoint($medium-up) {
                width: 100%;
              }
              .pr-rd-sort {
                background-image: url('#{$base-theme-path}svg-icons/src/caret--down.svg');
                background-position-x: calc(100% - 14px);
                background-position-y: 14px;
                background-size: 16px 16px;
                font-size: 16px;
                height: auto;
                margin: 0;
                max-width: 340px;
                text-transform: capitalize;
                @include swap_direction(padding, 9px 0 8px 19px);
                @include breakpoint($medium-up) {
                  background-position-y: 4px;
                  border: none;
                  padding-top: 0;
                  padding-bottom: 32px;
                }
              }
            }
            .pr-rd-header {
              display: flex;
              flex-direction: column;
              margin-bottom: 5px;
              order: 2;
              .pr-rd-star-rating {
                order: 3;
              }
              @include breakpoint($medium-up) {
                margin-bottom: 8px;
              }
            }
            .pr-rd-review-headline {
              font-family: $font--futura-book;
              font-size: 15px;
              margin: 0 0 5px;
              color: $color-black !important;
              letter-spacing: 1px;
              order: 1;
            }
            .pr-rd-description {
              &-text {
                font-size: 16px;
                letter-spacing: 0;
                @include breakpoint($medium-up) {
                  font-size: 18px;
                }
              }
            }
            .pr-accordion {
              display: none;
            }
            .pr-accordion-exapanded {
              border-top: 1px solid $color-black;
              border-bottom: 1px solid $color-black;
            }
            .pr-accordion-collapsed {
              border-top: 1px solid $color-black;
              border-bottom: 1px solid $color-black;
            }
            .pr-accordion-content {
              margin: 0;
            }
            .pr-accordion-btn {
              width: 100%;
              padding: 10px 0;
              span {
                font-family: $font--futura-medium;
                font-size: 16px;
                text-decoration: none;
                text-transform: capitalize;
                @include breakpoint($medium-up) {
                  font-size: 18px;
                }
              }
              .pr-caret-icon {
                display: none;
              }
              .pr-rd-author-nickname {
                font-family: $font--futura-book;
                font-size: 18px;
              }
            }
            &.pr-rd-display-desktop .pr-rd-description .pr-rd-author-nickname {
              font-family: $font--futura-medium;
            }
            .pr-rd-footer {
              font-family: $font--futura-medium;
              padding: 0;
              order: 5;
              width: 100%;
              @include breakpoint($medium-up) {
                margin: 0;
                padding: 0;
                order: 3;
              }
              .pr-rd-bottomline {
                span {
                  font-family: $font--futura-medium;
                }
              }
              .pr-helpful-count {
                font-family: $font--futura-medium;
                padding-left: 8px;
                padding-right: 24px;
                text-transform: capitalize;
                @include breakpoint($medium-up) {
                  padding-right: 0;
                }
              }
              .pr-rd-flag-review-container {
                width: auto;
                margin-left: auto;
                @include breakpoint($medium-up) {
                  width: 100%;
                }
                @include breakpoint($large-up) {
                  width: auto;
                  margin-left: auto;
                }
                a {
                  text-transform: capitalize;
                  font-family: $font--futura-medium;
                }
                .pr-rd-flag-review-btn {
                  color: $color-black;
                }
                button {
                  color: $color-white;
                  font-family: $font--futura-medium;
                  text-transform: capitalize;
                }
              }
            }
            .pr-rd-review-position,
            .pr-rd-to-top,
            .pr-rd-pagination-btn {
              font-family: $font--futura-book;
              .pr-rd-bold {
                font-family: $font--futura-book;
              }
            }
          }
        }
      }
      #pr-questiondisplay {
        .pr-qa-display-search,
        .pr-qa-display-sort {
          background: $color-white;
          border: none;
        }
        .p-w-r .pr-qa-display.pr-qa-display-desktop .pr-qa-display-headline {
          @include breakpoint($large-up) {
            margin-bottom: 30px;
            border-bottom: 1px solid $color-black;
          }
          text-align: center;
          &::after {
            display: none;
          }
        }
        .pr-qa-display-search {
          padding: 0;
          float: none;
          @include breakpoint($large-up) {
            margin-bottom: 0;
            float: left;
          }
          form {
            @include breakpoint($small-down) {
              text-align: center;
            }
          }
          label {
            display: none;
          }
        }
        .pr-qa-display-searchbar {
          display: inline-block;
          float: none;
          width: 100%;
          max-width: 340px;
          margin: 0 auto;
          @include breakpoint($medium-up) {
            margin: 0;
          }
        }
        .pr-qa-sort-group {
          width: 100%;
          right: 0;
          position: relative;
          top: -55px;
          display: inline-block;
          margin: 0;
          padding: 0;
          @include breakpoint($medium-up) {
            margin-top: 24px;
          }
          @include breakpoint($large-up) {
            width: auto;
            margin: 0 10px;
            padding: 0 10px;
            float: right;
          }
          &::after {
            display: none;
          }
          label {
            top: 9px;
            position: relative;
            @include breakpoint($large-up) {
              display: inline-block;
            }
          }
          .pr-qa-sort {
            background-image: url('#{$base-theme-path}svg-icons/src/caret--down.svg');
            background-position-x: calc(100% - 14px);
            background-position-y: 14px;
            background-size: 16px 16px;
            font-size: 16px;
            height: auto;
            margin-top: 16px;
            max-width: 340px;
            text-transform: capitalize;
            @include swap_direction(padding, 9px 0 8px 19px);
            min-width: 150px;
            width: 100%;
            @include breakpoint($medium-up) {
              border: none;
              margin: 0;
              position: absolute;
              right: 0;
              text-transform: none;
              top: 0;
            }
            &:focus {
              box-shadow: unset;
            }
          }
        }
        .pr-qa-sort-headline {
          font-size: 18px;
          width: 100%;
          text-align: center;
          left: 0;
          right: 0;
          text-transform: none;
          top: -100px;
          @include breakpoint($medium-up) {
            width: 200%;
            right: 100%;
            left: auto;
            top: -32px;
          }
          @include breakpoint($large-up) {
            top: -64px;
          }
        }
        .pr-qa-display-item-icon {
          display: none;
        }
        .pr-qa-display-ask-question .pr-accessible-btn {
          @include primary-hover;
          font-family: $font--futura-medium;
          font-size: 14px !important;
          text-transform: capitalize;
          line-height: 30px;
          background: $color-black;
          margin: 0;
          border: none;
          @include breakpoint($small-up) {
            float: none;
            margin: 0 auto;
            min-width: 0;
          }
        }
        .pr-qa-display-item {
          font-family: $font--futura-medium;
          margin: 0;
          span {
            color: $color-black !important;
            font-size: 16px;
            margin-bottom: 24px;
            text-transform: uppercase;
            @include breakpoint($medium-up) {
              font-size: 18px;
              margin-top: 24px;
            }
          }
        }
        .pr-qa-display-item h2 {
          color: $color-black !important;
          font-size: 15px;
        }
        .pr-qa-display-item-details {
          margin: 10px 0;
          left: 0;
          position: absolute;
          top: 32px;
          @include breakpoint($medium-up) {
            padding: 0;
            top: 0;
          }
        }
        .pr-qa-display-answer-by-container.pr-qa-display-answer-by-container-expert {
          border-left: none;
          padding: 0;
          .pr-qa-display-answer-details {
            padding: 0;
          }
        }
        .pr-qa-display-clear-search-button {
          @include breakpoint($large-up) {
            float: left;
            padding: 10px 0 0 100px;
            z-index: 100;
          }
        }
        .pr-qa-display-search-no-results {
          position: relative;
          width: 100%;
          @include breakpoint($large-up) {
            width: 200%;
            top: -30px;
          }
          p {
            margin-top: 12px;
            @include breakpoint($large-up) {
              margin: 12px 0 0 170px;
            }
          }
        }
      }
    }
  }
  .product-full .pr-snippet-rating-decimal {
    display: none;
  }
  .pr-snippet-write-review-link {
    display: none;
  }
  .pr-rd-no-reviews {
    a.pr-snippet-write-review-link {
      display: block;
    }
  }
  div.pr-snippet {
    display: inline-flex;
    @include breakpoint($medium-up) {
      display: flex;
    }
    .spp-page & {
      display: inline-flex;
      @include breakpoint($medium-up) {
        display: flex;
      }
    }
  }
  div.pr-snippet-read-and-write {
    margin-top: 0 !important;
    font-family: $font--futura-book;
    font-weight: 400;
    line-height: 1.2;
    font-size: 15px;
    a.pr-snippet-review-count {
      margin: 0 10px;
      font-size: 15px !important;
      color: $color-black;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    span.pr-snippet-review-count {
      color: $color-black;
    }
  }
  #pr-questionsnippet,
  #pr-questionsnippet.question-processed {
    left: -9999px;
    position: absolute;
  }
  .add-your-answer-link--hide {
    .pr-qa-display-answer-link-container {
      display: none;
    }
  }
}
.mpp,
.content-block-comparison-module-formatter {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        display: inline-block;
        .pr-snippet-review-count {
          color: $color-black;
          line-height: 1.7;
        }
      }
    }
  }
}
.mpp {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        display: inline-flex;
        align-items: center;
        padding-top: 1px;
      }
    }
  }
}

.pr-review-display {
  .pr-snippet-write-review-link {
    padding: 10px;
    display: block;
    border: 2px solid $color-black;
    width: 180px;
    margin: 0 auto;
  }
}

.product-full .p-w-r,
.product-brief .p-w-r {
  div.pr-snippet-rating-decimal {
    display: none;
  }
}

.p-w-r {
  .pr-snippet-stars-reco-inline {
    .pr-snippet-read-and-write {
      span.pr-snippet-review-count,
      a.pr-snippet-review-count {
        .product-brief & {
          font-size: 15px !important;
          line-height: 1.4;
        }
        .product-full__extras & {
          font-size: 16px !important;
          @include breakpoint($large-up) {
            font-size: 20px !important;
          }
        }
      }
    }
  }
}

#pr-write .pr-email_collection-form-group {
  // Hide email field from write a review.
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}
