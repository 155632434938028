.gnav-menu-tout {
  display: table-cell;
  position: relative;
  margin: 0 25px;
  text-align: center;
  width: 16%;
  @include unify-parent('section') {
    &:before {
      content: '';
      width: 100%;
      height: 1px;
      left: 0;
      position: absolute;
      z-index: z('discover', 'under');
      bottom: 100%;
      box-shadow: 0 0 75px 55px $color-white;
      pointer-events: none;
      @include breakpoint($landscape-up) {
        content: none;
      }
    }
  }
  &--text-under-image,
  &--text-over-image {
    a {
      text-decoration: none;
    }
  }
  &--text-over-image {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
  &--text-under-image {
    padding-top: 5px;
    @include breakpoint($landscape-up) {
      @if is_cr20_desktop_nav_refresh() {
        line-height: 12px;
        .gnav-menu-tout__text-link {
          font-family: $font--futura-demi;
          font-size: 14px;
        }
      }
    }
  }
  &__image {
    @include breakpoint($landscape-up) {
      @if is_cr20_desktop_nav_refresh() {
        display: block;
        margin: 0;
      }
    }
  }
}
