.tout-mpp-franchise {
  height: 100%;
  position: relative;
  &__inner {
    border-bottom: solid 1px $color-black;
    max-height: 100%;
    position: relative;
    margin: 1em auto 0 auto;
    padding-bottom: 1em;
    @include breakpoint($medium-up) {
      @include vertical-center;
      border-bottom: none;
      margin: 0 auto;
      padding-bottom: 0;
    }
  }
  &__content {
    text-align: center;
    padding: 0 10px;
  }
  &__title {
    @include h5;
    margin-top: 0;
    &:after {
      content: '';
      display: block;
      width: 35px;
      height: 1px;
      background-color: $color-black;
      margin: 22px auto 12px;
    }
  }
  &__subhead {
    font-size: 1.25em;
  }
  &__attributes {
    text-align: center;
    margin: 2em auto 0 auto;
  }
  &__attribute-item {
    display: inline-block;
    text-align: center;
    width: 30%;
    vertical-align: top;
    .product-attribute__image {
      max-width: 36px;
    }
    .product-attribute__label {
      @include font--text;
      font-size: 14px;
      font-style: italic;
      text-transform: none;
    }
    .product-attribute__title {
      font-size: 11px;
    }
  }
  &__attribute-label {
    font-size: 14px;
    font-style: italic;
    clear: both;
  }
  &__attribute-icon-wrapper {
    display: inline-block;
  }
  &__attribute-icons {
    .product-attribute__icon {
      font-size: 32px;
    }
  }
}

// Creative refresh 2018
// @todo deprecate as new product templates roll out
@mixin tout-mpp-franchise--cr18 {
  .product-grid-v3 {
    .tout-mpp-franchise__inner {
      @include breakpoint($medium-up) {
        @include destroy-vertical-center;
        padding-top: 2em;
      }
    }
  }
}
