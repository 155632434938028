// Overwrite /sites/all/modules/custom/elc_common/libraries/colorbox/colorbox.css as needed

#cboxMiddleLeft,
#cboxMiddleRight,
#cboxTopRight,
#cboxTopCenter,
#cboxTopLeft,
#cboxBottomLeft,
#cboxBottomCenter,
#cboxBottomRight {
  width: auto;
  height: auto;
  background: none;
}

#cboxContent {
  border: 1px solid $color-black;
}

#cboxLoadedContent {
  padding: 0; //add padding to your child elements instead
  margin: 0;
}

// Not using backgrounds anymore universally
#cboxOverlay {
  background-image: none;
  // background: rgba(255,255,255,0.9);
  background: transparent;
  opacity: 1;
}

#cboxClose {
  bottom: auto;
  top: 15px;
  right: 20px;
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: 24px;
  background-color: transparent;
  &:hover {
    background-position: 0 0;
  }
  /*
  background: none;
  text-indent: 0;
  color: #000;
  font-size: 0;
  @include font-icon-base();
  &:before {
    font-size: 22px;
  }
  */
}
/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
  outline: none;
}

#colorbox:focus,
#cboxWrapper:focus {
  outline: none;
}
/* New for 2018 Creative Refresh */
/* @TODO: consider abstacting these styles */
#cboxOverlay.colorbox--video {
  background-color: rgba(0, 0, 0, 0.75);
}

#colorbox {
  &.colorbox--video {
    #cboxContent {
      background: none !important;
      border: none !important;
    }
    #cboxClose {
      // Replace default image
      background-image: url('/media/images/vendor/colorbox/popup_close--white.png');
    }
    #cboxLoadedContent {
      text-align: center;
      margin: 0 auto;
      overflow: hidden !important;
      .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
        iframe,
        object,
        embed,
        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        iframe {
          max-width: 100%;
        }
      }
    }
  }
  #cboxContent {
    #cboxClose:hover {
      background-position: -25px -25px;
    }
  }
}
