.regimen-page {
  &__quiz {
    padding-bottom: 40px;
    .regimen-page--loaded & {
      padding-bottom: 0;
      border: none;
    }
    .regimen-quiz {
      &__fieldset {
        &--age {
          @include breakpoint($landscape-up) {
            .regimen-quiz__fieldset__legend {
              padding-top: 0;
            }
          }
        }
      }
    }
  }
  &__results {
    @include transition(opacity, 200ms);
    display: none;
    // Only show results if the quiz has been loaded
    .regimen-page--loaded & {
      display: block;
    }
    .regimen-page--saving & {
      opacity: 0.4;
    }
    &__title {
      @include font--subheading;
      @include font-padding(subheading, 21px, 66px);
      font-size: 21px;
      text-align: center;
      margin: 0;
      padding: 0 25px;
      @include breakpoint($landscape-up) {
        text-align: left;
      }
    }
    .menu {
      @include breakpoint($landscape-up) {
        &--lvl-1 {
          @include pie-clearfix;
          border-bottom: solid 1px $color-black;
          position: relative;
        }
      }
      &--lvl-2 {
        display: none;
        // Tab content:
        @include breakpoint($landscape-up) {
          position: absolute;
          top: 45px;
          left: 0;
          width: 100%;
          padding: 25px;
        }
      }

      // Menu item styles:
      &__item {
        // Tabs:
        @include breakpoint($landscape-up) {
          &--lvl-1 {
            float: left;
            width: 25%;
          }
        }
        // Expanded states (links):
        &--expanded {
          > .menu__link {
            @include icon(minus, after);
            &:after {
              top: 26px;
            }
            &--lvl-1 {
              @include font--text-alt;
              @include font-padding(text, 18px, 52px);
              @include font-smoothing(auto);
              background: $color-white;
              color: $color-black;
              border-bottom: solid 1px #f3f3f5;
              @include breakpoint($landscape-up) {
                @include font-padding(text, 18px, 43px);
                border: solid 1px $color-black;
                border-bottom: none;
                position: relative;
                z-index: 1;
                top: 1px;
                cursor: default;
                &:after {
                  content: initial;
                }
                .regimen-page--taken & {
                  cursor: default;
                }
              }
            }
            &--lvl-2 {
              @include font-padding(subheading, 21px, 65px);
              background: #f3f3f5;
            }
          }
          > .menu {
            display: block;
          }
        }
        &--lvl-1.first .menu__link--lvl-1 {
          border-top-color: $color-dark-gray;
          @include breakpoint($landscape-up) {
            border-top-color: $color-white;
          }
        }
        @include breakpoint($landscape-up) {
          &--lvl-1.menu__item--expanded.first .menu__link--lvl-1 {
            border-top: solid 1px $color-black;
          }
        }

        // No right margin on the last tab on desktop
        @include breakpoint($landscape-up) {
          &--lvl-1.last .menu__link--lvl-1 {
            margin-right: 0;
          }
        }

        // No bottom-border on last lvl-2 items on mobile:
        &--lvl-2.last .menu__link--lvl-2 {
          border-bottom: none;
          @include breakpoint($landscape-up) {
            border-bottom: solid 1px #f3f3f5;
          }
        }
      }

      // Menu link styles:
      &__link {
        display: block;
        width: 100%;
        cursor: default;
        position: relative;
        padding-right: 70px;
        outline: 0;
        .regimen-page--taken & {
          cursor: pointer;
        }
        @include icon(plus, after) {
          font-size: 10px;
          position: absolute;
          top: 20px;
          right: 45px;
        }
        &:hover {
          text-decoration: none;
        }
        &--lvl-1 {
          @include font--text;
          @include font-smoothing;
          @include font-padding(text, 18px, 52px);
          font-size: 18px;
          background: $color-dark-gray;
          color: $color-white;
          padding-left: 45px;
          border-top: solid 1px $color-white;
          // Tabs:
          @include breakpoint($landscape-up) {
            @include font-padding(text, 18px, 43px);
            text-align: center;
            margin: 0 3px 0 0;
            width: auto;
            font-size: 18px;
            background-color: $color-dark-gray;
            &:after {
              content: initial;
            }
          }
        }
        &--lvl-2 {
          @include font--subheading;
          @include font-smoothing;
          @include font-padding(subheading, 21px, 52px);
          font-size: 21px;
          background: $color-white;
          color: $color-black;
          padding-left: 67px;
          border-bottom: solid 1px #f3f3f5;
        }
      }
    }
    .product-grid-wrapper {
      @include breakpoint($medium-up) {
        padding: 25px;
        border: none;
        border-bottom: solid 1px #f3f3f5;
      }
    }
    .product-grid {
      // Mobile primary/ancillary headers:
      .regimen-results-header__text {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
      &__item {
        @include breakpoint($medium-up) {
          min-height: 590px;
        }
        &--primary-result {
          border-top: solid 1px $color-black;
          border-bottom: solid 1px $color-black;
          @include breakpoint($medium-up) {
            border-top: none;
            border-left: solid 2px $color-black;
            border-right: solid 2px $color-black;
            border-bottom: solid 2px $color-black;
          }
        }
      }
    }
    .product-brief {
      padding-top: 20px;
    }

    // Desktop primary/ancillary headers:
    .regimen-results-header {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
        &__text {
          margin: 0;
          padding: 20px 0 0;
          &--primary {
            border: solid 2px $color-black;
            border-bottom: none;
          }
        }
      }
    }
  }
  &__spacer {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  &__footer {
    @include font--text;
    padding: 20px 25px 20px 45px;
    @include breakpoint($landscape-up) {
      padding-top: 0;
    }
    &__copy {
      font-size: 14px;
      line-height: 19px;
      padding: 0;
      margin: 0 0 14px;
    }
    &__link {
      display: inline-block;
      text-decoration: underline;
      &--livechat {
        margin-right: 20px;
      }
    }
  }
  &__take-quiz-msg {
    display: none;
  }
}

// Override default perlgem page elements:
.account-page--regimen {
  // There's a specific point where the sidebar needs to be hidden before
  // elements start overlapping and getting misaligned:
  .account-utilities {
    display: none;
    @include breakpoint(1121px) {
      display: block;
    }
  }
  .account-page__content {
    // Remove default perlgem account content wrapper padding:
    padding: 0;
    // Reset default content width when sidebar is hidden:
    @include breakpoint(max-width 1120px) {
      width: 100%;
    }
  }
}

.regimen_wrapper {
  h2 {
    @include font--subheading;
    font-size: 43px;
    padding: 0px;
    line-height: 65px;
    margin: 0px;
  }
  p {
    @include font--subheading;
  }
}

.regimen_content_threecolumn {
  margin: 0 auto;
  width: 1280px;
  @include breakpoint($portrait-down) {
    width: 100%;
  }
  .basic-grid {
    padding-right: 0px;
    .basic-grid__item {
      @include breakpoint($medium-up) {
        width: 32.7%;
        padding: 0 0 12px 0;
        &:nth-child(2) {
          margin-left: 1%;
        }
        &:nth-child(3) {
          float: right;
        }
      }
    }
    @include breakpoint($portrait-down) {
      padding: 10px;
    }
  }
}

.regimen__tile {
  &--cta-container,
  &--rollover-img {
    display: none;
    opacity: 0;
    a.regimen__tile--rollover-text {
      width: 100%;
    }
    @include breakpoint($medium-down) {
      display: block;
      opacity: 1;
      text-align: center;
      padding: 15px 0;
      a.regimen__tile--rollover-text {
        padding: 0px;
        font-size: 18px;
        color: $color-black;
        width: 55%;
        text-align: center;
        display: block;
        margin: 10px auto;
        @include font--subheading;
      }
      a.regimen__tile--rollover-button {
        display: inline-block;
        border: 1px solid #a0d8ab;
        padding: 11px 34px;
        @include font--subheading;
      }
    }
  }
  &--wrapper {
    img {
      width: 100%;
    }
    @include breakpoint($large-only) {
      &.regimen__hover-ready:hover,
      &.regimen__hover-ready:focus {
        background: #a0d8ab;
        position: relative;
        min-height: 495px;
        .regimen__tile--cta-container {
          width: 100%;
          margin: 0 auto;
          text-align: center;
          padding: 45% 0 0;
          @include breakpoint($medium-down) {
            padding: 17% 0 7%;
          }
          a {
            @include font--subheading;
            display: block;
            text-decoration: none;
            width: 56%;
            margin: 0 auto;
            @include breakpoint($medium-down) {
              width: 70%;
              font-size: 17px;
              padding-top: 35%;
            }
          }
          .regimen__tile--rollover-button {
            @include font--subheading;
            display: block;
            font-size: 23px;
            color: $color-black;
            border: 2px solid $color-black;
            padding: 12px 0;
            margin: 0 auto;
            position: absolute;
            bottom: 50px;
            width: 48%;
            left: 0;
            right: 0;
            margin: auto;
            @include breakpoint($medium-down) {
              bottom: 18px;
              width: 64%;
              font-size: 14px;
            }
          }
        }
        .regimen__tile--image {
          display: none;
        }
        .regimen__tile--rollover-img,
        .regimen__tile--cta-container {
          display: block;
          opacity: 1;
        }
      }
    }
    @include breakpoint($medium-up) {
      &.regimen__hover-ready:hover {
        min-height: 296px;
      }
    }
    @include breakpoint($landscape-up) {
      &.regimen__hover-ready:hover {
        min-height: 505px;
      }
    }
  }
  &--rollover-text {
    padding: 0;
    font-size: 27px;
    @include breakpoint($medium-down) {
      padding: 10% 0 0;
    }
  }
}

.holiday_gift {
  h2 {
    font-family: $font--subheading;
    font-weight: normal;
    font-size: 50px;
    @include breakpoint($portrait-down) {
      font-size: 35px;
    }
    margin: 15px 0 0 0;
    text-transform: uppercase;
    color: $color-red;
  }
  p {
    padding: 0px;
    margin: 0px;
    font-size: 26px;
    @include breakpoint($portrait-down) {
      font-size: 19px;
    }
    font-family: $font--text;
    font-family: $font--futura-medium;
    margin: 0 0 20px 0;
  }
  text-align: center;
  margin: 0 auto;
  width: 100%;
  @include breakpoint($portrait-down) {
    width: 100%;
  }
}

.holiday_wrapper {
  max-width: 320px;
  margin-bottom: 20px;
  @include breakpoint($medium-up) {
    max-width: 1280px;
  }
  margin: 0 auto;
  .regimen__img-tile {
    text-align: center;
    @include breakpoint($landscape-up) {
      border-bottom: none;
      width: auto;
      min-height: auto;
    }
  }
  .regimen__tile--wrapper.alter_width {
    float: left;
    min-height: 330px;
    margin: 5px;
    width: 30%;
    @include breakpoint($portrait-down) {
      width: 100%;
      margin: 0;
      min-height: inherit;
      float: none;
    }
    @include breakpoint($landscape-up) {
      width: 32.3%;
    }
    img {
      max-height: 330px;
      float: left;
      @include breakpoint($medium-up) {
        margin-bottom: 10px;
        float: none;
        width: auto;
      }
      @include breakpoint($large-only) {
        margin-bottom: 0px;
      }
    }
    .regimen__tile--cta-container {
      padding: 0;
    }
    &.regimen__hover-ready:hover {
      min-height: 330px;
      background: transparent;
      @include breakpoint($portrait-down) {
        min-height: inherit;
      }
      .regimen__tile--cta-container {
        padding: 0px;
        a {
          @include breakpoint($large-only) {
            min-height: 330px;
          }
          @include breakpoint($portrait-down) {
            min-height: inherit;
          }
          display: flex;
          align-items: center;
          justify-content: center;
          vertical-align: middle;
          padding: 0px;
        }
      }
      .regimen__tile--image {
        display: inline-block;
      }
    }
  }
}

.holiday_shipping_section {
  padding: 0 5%;
}
