/***** Searchbox 2.0 | ELC Style Updates ****/

/* top is calculated as header height (67px) minus 7px */
.gb-elc__sayt-wrapper {
  display: block;
  margin-top: -2px;
  z-index: 20;
}

.gb-elc__sayt-wrapper gb-elc-sayt gb-sayt {
  border-left: none;
  border-right: none;
  border-top: none;
  .gb-product-card__content {
    gb-elc-add-to-cart {
      button:hover {
        background: $color-green;
      }
    }
    &--title,
    &--title-description,
    &--benefit,
    &--price,
    &--product-details {
      &:hover {
        color: $color-green;
      }
    }
  }
}

.gb-elc__sayt-wrapper gb-elc-sayt gb-sayt > div {
  border-top: 1px solid $color-black;
}

/***** Searchbox 2.0 | GB Styles *****/

.gb-elc__search-wrapper {
  border: 1px solid $color-mid-gray;
  width: 100%;
  display: flex;
  top: auto;
  height: 32px;
  align-items: center;
  position: relative;
  -webkit-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  margin-top: -16px;
  margin-bottom: -8px;
}

.gb-elc__search-wrapper svg {
  height: 20px;
  width: 21px;
  margin: 0 0 0 7px;
}

.gb-elc__search-wrapper--searchbox-wrapper {
  flex: 1 0 auto;
  min-width: 170px;
  padding: 0 7px;
  display: flex;
  height: 100%;
}

.gb-elc__search-wrapper--searchbox-wrapper .header-search__box__input::placeholder {
  color: $color-mid-gray;
  opacity: 1;
}

.gb-elc__search-wrapper--searchbox-wrapper .header-search__box__input:-ms-input-placeholder {
  color: $color-mid-gray;
}

.gb-elc__search-wrapper--searchbox-wrapper .header-search__box__input::-ms-input-placeholder {
  color: $color-mid-gray;
}

.gb-elc__search-wrapper {
  width: 100%;
  @include breakpoint($landscape-up) {
    width: auto;
    top: 50%;
    height: 30px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
/* Supplemental Nav */
.page-header__nav__inner .page-header__nav__supplemental .gnav-util--groupby-search {
  float: right;
  height: 70px;
  width: 210px;
}
/* Top Nav */
.page-header__meta-nav .gnav-util--groupby-search {
  position: relative;
  top: 5px;
  float: left;
  height: 30px;
  margin-top: 17px;
  max-width: 160px;
  width: 47%;
  @include breakpoint($landscape-up) {
    position: fixed;
    max-width: 340px;
  }
  @include breakpoint($medium-up) {
    max-width: none;
    width: 66%;
  }
  @include breakpoint($large-up) {
    max-width: 160px;
    width: 47%;
  }
}

.page-header__meta-nav .gnav-util--groupby-search--focused {
  width: 75%;
}

@media (min-width: 768px) {
  .page-header__meta-nav .gnav-util--groupby-search {
    margin-left: -20px;
  }
}

@media (min-width: 1024px) {
  .page-header__meta-nav .gnav-util--groupby-search {
    display: none;
  }
}
