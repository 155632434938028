.giftcards-accordion {
  &__tab {
    @include t3;
    width: 100%;
    height: $giftcards-tab-height;
    line-height: $giftcards-tab-line-height;
    text-align: center;
    color: $color-white;
    background-color: $color-green;
    &:hover {
      cursor: pointer;
    }
  }
  &__body {
    width: 100%;
  }
} // .giftcards-accordion

.gift_cards_landing {
  &.braille_gift_cards_landing {
    .giftcard_container {
      .gift_sendcard,
      .gift_givecard {
        width: 100%;
        @include breakpoint($large-up) {
          width: 33%;
        }
      }
    }
  }
  @include breakpoint($medium-down) {
    padding: 0 20px;
  }
  h1 {
    text-align: center;
    letter-spacing: 0.05em;
    font-family: $font--heading;
    margin-bottom: 10px;
    font-size: 30px;
    @include breakpoint($medium-down) {
      padding-top: 20px;
    }
  }
  h2 {
    margin-bottom: 0;
    letter-spacing: 0.05em;
  }
  .header_text {
    @include breakpoint($portrait-down) {
      margin: 0;
    }
    text-align: center;
    font-size: 21px;
    margin: 0 30%;
  }
  .giftcard_container {
    @include breakpoint($large-up) {
      width: 98%;
      margin: 0 auto;
    }
    .gift_sendcard,
    .gift_givecard {
      @include breakpoint($portrait-down) {
        width: 100%;
      }
      width: 50%;
      float: left;
      text-align: center;
      margin-bottom: 25px;
      h2 {
        margin-bottom: 10px;
        font-family: $font--heading;
      }
      .egift_imagecontainer,
      .gift_imagecontainer {
        @include breakpoint($portrait-down) {
          margin-top: 25px;
        }
        margin-top: 50px;
      }
      .egiftcard_text,
      .giftcard_text {
        @include breakpoint($portrait-down) {
          width: 85%;
          margin: 0 8%;
        }
        @include breakpoint($medium-down) {
          min-height: 126px;
        }
        @include breakpoint($medium-up) {
          min-height: 130px;
        }
        min-height: 110px;
        margin: 0 20% 2%;
        font-size: 18px;
      }
      @include breakpoint($large-up) {
        .giftcard_text {
          text-align: center;
        }
      }
      .disclaimer_text {
        @include breakpoint($portrait-down) {
          margin: 0;
        }
        margin: 3% 11%;
        font-size: 16px;
      }
      input {
        @include breakpoint($large-up) {
          width: auto;
          padding: 2% 5%;
        }
        @include breakpoint($medium-down) {
          width: auto;
          padding: 15px 20px;
        }
        @include breakpoint($portrait-down) {
          width: 80%;
          margin: 15px auto;
        }
        @include breakpoint($xxsmall-down) {
          width: 85%;
        }
        @include primary-hover;
        background: $color-black;
        color: $color-white;
        width: 35%;
        border: none;
        font-size: 14px;
        letter-spacing: 0.1em;
        font-family: $font--heading;
        font-weight: bold;
        padding: 12px 5px 15px 5px;
        border-radius: 0px;
      }
    }
    .gift_sendcard-fullwidth {
      width: 100%;
      .sendeGift_button {
        padding: 1% 3%;
      }
    }
  }
  .line_divider {
    @include breakpoint($portrait-down) {
      display: none;
    }
    clear: both;
    border-top: 1px solid;
    margin-left: 30%;
    width: 40%;
  }
  .gift_balance {
    h2 {
      font-family: $font--heading;
      letter-spacing: 0.1em;
      font-size: 25px;
      margin-top: 30px;
    }
    padding-top: 10px;
    clear: both;
    text-align: center;
    .gift_balance_text {
      font-size: 18px;
    }
    form {
      margin-bottom: 10px;
      input {
        margin-right: 15px;
        height: 40px;
        width: 28%;
        @include breakpoint($portrait-down) {
          line-height: 15px;
          margin: 0 2px 10px 0;
          width: 90%;
        }
        @include breakpoint($medium-up) {
          line-height: 40px;
        }
        @include breakpoint($landscape-up) {
          line-height: 40px;
          width: 20%;
        }
      }
    }
    .form-submit {
      @include breakpoint($large-up) {
        width: auto;
        padding: 1% 2%;
      }
      @include breakpoint($medium-down) {
        width: auto;
        padding: 1% 2%;
        margin: 0;
        height: auto;
        line-height: 18px;
      }
      @include breakpoint($portrait-down) {
        width: 90%;
        padding: 15px 20px;
      }
      @include primary-hover;
      line-height: 3px;
      vertical-align: top;
      width: 16%;
      background: $color-black;
      border: none;
      letter-spacing: 3px;
      font-family: $font--heading;
      font-weight: bold;
      font-size: 14px;
      padding: 0;
      height: 38px;
      border-radius: 0px;
    }
    .remaining-balance {
      margin-top: 20px;
      margin-right: 80px;
    }
  }
  .gift_about {
    h2 {
      letter-spacing: 0.1em;
      font-family: $font--heading;
    }
    text-align: center;
    margin: 85px 0;
    input {
      @include breakpoint($portrait-down) {
        width: 99%;
        margin-top: 15px;
        font-size: 12px;
      }
      @include primary-hover;
      background: $color-black;
      border: none;
      color: $color-white;
      width: auto;
      font-size: 14px;
      letter-spacing: 0.1em;
      font-family: $font--heading;
      font-weight: bold;
      padding: 15px 20px;
      border-radius: 0px;
    }
    .giftcard_about_text {
      @include breakpoint($portrait-down) {
        margin: 0;
        font-size: 20px;
      }
      margin: 1% 19%;
      font-size: 18px;
    }
  }
  &_about {
    .gift_cards-accordion {
      input[type='radio'] {
        display: none;
        & + label {
          position: relative;
          padding-#{$ldirection}: 0;
          cursor: pointer;
          font-size: 0;
        }
        & + label:before {
          content: '';
          position: absolute;
          #{$ldirection}: 0;
          top: 50%;
          margin-top: 1px;
          width: 1px;
          height: 1px;
          background: $color-white;
          background-size: contain;
        }
        &:checked + label:before {
          background: $color-white;
        }
      }
      radio {
        display: none;
      }
      label {
        display: flex;
        width: 100%;
        height: auto;
        cursor: pointer;
        border-bottom: 1px solid $color-black;
        user-select: none;
        margin: 2%;
        padding-bottom: 5px;
        @include breakpoint($medium-up) {
          margin: 0;
          height: 50px;
          padding-bottom: 0;
        }
        div:first-child {
          width: 100%;
          line-height: 2.3;
          margin-#{$ldirection}: 0;
          font-size: 18px;
          font-family: $font--heading;
          @include breakpoint($medium-up) {
            font-size: 32px;
            line-height: 1.4;
            margin-#{$ldirection}: 10px;
          }
        }
      }
      .cross {
        @include swap_direction(margin, 3px 15px 0 0);
        &:before,
        &:after {
          content: '';
          border-top: 2px solid $color-dark-blue-icon;
          width: 15px;
          display: block;
          margin-top: 18px;
          transition: 0.3s;
        }
      }
      .cross:after {
        transform: rotate(90deg);
        margin-top: -2px;
      }
      .content {
        box-sizing: border-box;
        font-size: 16px;
        font-family: $font--heading;
        margin: 10px;
        max-height: 0;
        overflow: hidden;
        transition: max-height, 0.5s;
      }
      input:checked ~ .content {
        max-height: 500px;
        transition: max-height, 1s;
      }
      input:checked ~ label .cross {
        &:before {
          transform: rotate(180deg);
        }
        &:after {
          transform: rotate(0deg);
        }
      }
      .questions {
        margin-top: 20px;
        max-height: 0;
        overflow: hidden;
        transition: max-height, 0.5s;
        label {
          border: none;
          box-shadow: none;
          margin: 0;
        }
      }
      input:checked ~ .questions {
        max-height: 400px;
        border-bottom: 2px solid $color-dark-blue-icon;
        transition: 1s;
      }
    }
  }
}
