/* CART ITEMS / ACCOUNT PRODUCTS STYLE */

.checkout .cart-items,
.account-page .account-products {
  &__item {
    padding: 1em;
    &--desc,
    &--qty {
      @include font--cta;
      text-transform: uppercase;
      font-family: $font--subheading;
      font-size: 18px;
    }
    &--price,
    &--total {
      @include t6;
    }
    &--product-name {
      @include h7;
      font-size: 11px;
      &,
      & a {
        color: $color-cr18-black;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &--product-subname {
      @include h12;
      &,
      & a {
        color: $color-black;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
      @if $cr18 == true {
        font-size: 18px;
        @include breakpoint($medium-up) {
          font-size: 21px;
        }
      }
    }
    &--size {
      font-size: 14px;
    }
    &--shade {
      @include h13;
      &__label {
        display: none;
      }
    }
    &--swatch {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 10px;
      float: left;
    }
    &--color {
      margin-top: 1em;
    }
    &--add-to-favorites {
      display: block;
      &,
      &:hover {
        text-decoration: none;
      }
    }
    &.custom-kit {
      .kit-item__sub-items {
        .cart-items__item--thumb {
          display: none;
        }
      }
      .price,
      .qty,
      .total {
        display: none;
      }
      .product {
        padding: 0;
        border-bottom: none !important;
        .desc {
          &.cart-items__item--desc {
            padding: 1em 0;
            border-bottom: 1px solid $color-light-gray;
            @include breakpoint($small-up) {
              margin: -50px 0 25px 13%;
            }
            @include breakpoint($ipad-pro-portrait-only) {
              margin: -50px 0 25px 15%;
            }
            .cart-items__item--product-name {
              letter-spacing: 1px;
              font-family: $font--futura-demi;
              font-size: 12px;
            }
            .cart-items__item--product-subname {
              font-family: $font--futura-medium;
              font-size: 15px;
              text-transform: capitalize;
            }
            .size {
              font-family: $font--futura-medium;
              letter-spacing: 0;
              font-size: 14px;
              text-transform: lowercase;
            }
          }
        }
        &:last-child {
          .desc {
            border: 0;
            margin-bottom: 0;
          }
        }
      }
      .custom-kit__desc {
        height: 48px;
        border-bottom: 1px solid $color-light-gray;
        margin-bottom: 18px;
        @include breakpoint($mobile-up) {
          margin-bottom: 0;
        }
        @include breakpoint($medium-portrait-only) {
          margin-bottom: 18px;
        }
        .custom-kit__sub-title {
          margin-bottom: 20px;
        }
        .custom-kit__remove {
          display: block;
          margin-left: 25%;
        }
        .cart-items__item--actions {
          .kit-item__action--remove {
            border-right: 1px solid $color-drak-black;
            padding-right: 10px;
            font-size: 12px;
            font-family: $font--futura-medium;
          }
          .kit-item__action--edit {
            font-size: 12px;
            font-family: $font--futura-medium;
          }
        }
        .cart-item-remove {
          position: absolute;
          top: 15px;
          left: 131%;
          width: 20px;
          @include breakpoint($mobile-up) {
            display: none;
          }
        }
        position: relative;
      }
      .cart-items__item--sub {
        margin-left: 0;
        clear: inherit;
        .mobile-total {
          @include breakpoint($mobile-up) {
            display: none;
          }
        }
        @include breakpoint($mobile-up) {
          clear: both;
        }
        .cart-item.cart-items__item {
          margin-bottom: 0 !important;
          @include breakpoint($mobile-up) {
            margin-top: 22px;
          }
        }
      }
      .custom-kit__price {
        float: left;
        margin-right: 50px;
      }
      .custom-kit__price.mobile-hidden {
        @include breakpoint($medium-portrait-only) {
          display: block;
        }
      }
      .custom-kit__quantity {
        margin-right: 34%;
        float: left;
        @include breakpoint($medium-portrait-only) {
          margin-right: 8%;
          .quantity_content.mobile-hidden {
            display: block;
          }
        }
        @include breakpoint($medium-landscape-only) {
          margin: 0 8% 0 7%;
        }
        @include breakpoint($ipad-pro-portrait-only) {
          margin: 0 7% 0 5%;
        }
      }
      .total {
        display: block;
      }
      .custom-kit__mobile-quantity {
        margin-left: 35%;
        display: inline-block;
        @include breakpoint($mobile-up) {
          display: none;
        }
      }
      .mobile-total {
        float: right;
        @include breakpoint($medium-portrait-only) {
          display: none;
        }
      }
    }
  }
}

.checkout__content .cart-items,
.account-page__content .account-products {
  margin-top: 1em;
  &__item {
    position: relative;
    border-bottom: 1px solid $color-light-gray;
    &:last-child {
      border-bottom: 0;
    }
    .sku-price {
      float: right;
    }
    .cart-item-remove {
      display: none;
    }
    &--thumb {
      float: left;
      width: 33.3333333%;
      padding-right: 1em;
      height: 100px;
      @include breakpoint($small-up) {
        width: 15%;
      }
      @include breakpoint($large-up) {
        width: 11%;
        padding-right: 0.5em;
      }
    }
    &--desc {
      margin: 0 0 0 33.3333333%;
      @include breakpoint($small-up) {
        float: left;
        width: 45%;
        margin: 0;
        padding-right: 1em;
      }
      @include breakpoint($large-up) {
        width: 28%;
        padding-right: 0.5em;
      }
    }
    &--remove-form {
      margin-top: 1em;
    }
    &--price {
      float: left;
      margin-left: 33.3333333%;
      padding-right: 1em;
      &--non-sale {
        text-decoration: line-through;
      }
      &--sale {
        color: $color-red;
        display: inline-block;
      }
      @include breakpoint($small-up) {
        width: 15%;
        margin-left: 0;
      }
      @include breakpoint($large-up) {
        width: 9%;
      }
    }
    &--qty {
      float: left;
      position: relative;
      margin: -5px 0 0 10px;
      @include breakpoint($medium-up) {
        margin-left: 20px;
      }
      @include breakpoint($large-up) {
        margin: 0;
      }
      select {
        min-width: 3.25em;
        padding-right: 20px;
      }
      @include breakpoint($large-up) {
        width: 9%;
        padding-left: 0.5em;
        text-align: center;
      }
      @include breakpoint($medium-down) {
        padding-left: 0.3em;
        padding-right: 0.3em;
        text-align: center;
      }
    }
    &--replenishment {
      clear: both;
      padding-top: 1em;
      margin: 0 0 1em 33.3333333%;
      @include breakpoint($small-up) {
        clear: none;
        float: right;
        width: 40%;
        margin-left: 0;
      }
      @include breakpoint($large-up) {
        float: right;
        width: 28%;
        margin: 0;
        padding-right: 0.5em;
        padding-top: 0;
      }
    }
    &--total {
      float: right;
      @include breakpoint($small-up) {
        position: absolute;
        top: 1em;
        right: 1em;
        width: 15%;
      }
      @include breakpoint($large-up) {
        position: relative;
        top: auto;
        right: auto;
        width: 10%;
        padding-right: 0.4em;
      }
    }
    &.kit-item {
      .action {
        clear: both;
        margin-bottom: 0.5em;
        div {
          margin-right: 1em;
          display: inline-block;
        }
      }
    }
    &--add-to-favorites {
      margin-top: 1em;
    }
  }
  .account-products__item.kit-item {
    .product__price {
      float: right;
    }
    &.custom-kit {
      .product__price {
        float: none;
        @include breakpoint($medium-up) {
          float: left;
        }
      }
    }
  }
  &__header {
    display: none;
    &--item {
      @include font--cta;
      font-size: 15px;
      float: left;
      background-color: $color-light-gray;
      padding: 1em 1em 1em 0;
      &:last-child {
        padding-right: 0;
      }
    }
    &--thumb {
      width: 10%;
    }
    &--products,
    &--frequency {
      width: 30%;
    }
    &--price,
    &--total {
      width: 10%;
    }
    &--qty {
      width: 10%;
      padding-right: 0px;
      text-align: center;
    }
    &--price {
      @include breakpoint($large-up) {
        padding-left: 1.8em;
      }
    }
    &--frequency {
      @include breakpoint($large-up) {
        padding-left: 1.2em;
      }
    }
  }
}

.checkout__content .cart-items {
  &__header {
    @include breakpoint($medium-up) {
      display: block;
      padding: 0 1em;
    }
    @include breakpoint($medium-down) {
      &--products {
        width: 45%;
      }
      &--price,
      &--qty,
      &--total {
        width: 15%;
      }
      &--total {
        padding-left: 10px;
      }
      &--qty {
        text-align: center;
      }
      &--frequency {
        display: none;
      }
    }
  }
  &__item {
    &--qty {
      .sample_value {
        padding-right: 20px;
      }
    }
    @include breakpoint($medium-down) {
      &--desc {
        width: 40%;
      }
      &--price,
      &--qty,
      &--total {
        width: 15%;
      }
      &--total {
        padding-left: 10px;
      }
      &--qty {
        margin-left: 0px;
        select {
          margin: 0 auto;
        }
      }
      &--replenishment {
        float: left;
        clear: left;
      }
    }
  }
}

.checkout__sidebar .cart-items {
  &__item {
    padding-top: 0;
    border-bottom: none;
    &--thumb {
      float: left;
      width: 20%;
      padding-right: 1em;
    }
    &--desc {
      min-height: 50px;
      float: left;
      width: 58%;
      padding-right: 1em;
    }
    &--color {
      margin-top: 0.5em;
    }
    &--qty {
      float: right;
      width: 20%;
      text-align: right;
      margin-bottom: 1em;
    }
    &--total {
      float: right;
      width: 22%;
      text-align: right;
    }
    &.kit-item {
      .kit-items {
        clear: both;
        .product {
          padding: 1em 0 0;
        }
      }
    }
  }
}
/* VIEWCART NO-REPLENISHMENT ADJUSTMENTS */
.checkout .no-replenishment {
  .cart-items {
    &__header {
      &--products {
        width: 45%;
      }
      &--price {
        width: 18%;
      }
      &--qty {
        width: 17%;
      }
      &--frequency {
        display: none;
      }
    }
    &__item {
      &--desc {
        width: 40%;
      }
      &--price {
        width: 20%;
        text-align: center;
        margin-right: 0.5em;
        @include breakpoint($medium-down) {
          margin-right: 0;
          width: 17%;
        }
      }
    }
  }
}
