///
/// Expando-menu
///
.expando-menu {
}

.expando-menu-trigger {
  @include icon('plus', after);
  position: relative;
  cursor: pointer;
  &:after {
    position: absolute;
    right: 0;
    font-size: 13px;
    top: 8px;
  }
  .expando-menu__parent--expanded & {
    @include icon('minus', after);
  }
}

.expando-sub-menu {
  display: none;
  .expando-menu__parent--expanded & {
    display: block;
  }
}

///
/// Expando-block
/// A content block that becomes click-expandable with a title at mobile
///
.expando-block {
  border-bottom: $color-light-gray solid 1px;
  padding: 1em;
  .sidebar-page__content & {
    margin: 0 -1em;
  }
  @include breakpoint($medium-up) {
    padding: 0 1em;
    border-bottom-width: 0;
    margin: 0 1em;
  }
  &.wrapper {
    margin: 0 auto;
  }
}

.expando-block__title {
  position: relative;
}

.expando-block__title--mobile {
  position: relative;
  cursor: pointer;
  .expando-block--expanded & {
    margin-bottom: 15px;
  }
  @include breakpoint($medium-up) {
    display: none;
  }
}

.expando-block__title--pc {
  font-size: 34px;
  //font-style: italic;
  font-family: $font--text--italic;
  font-weight: normal;
  margin: 0 0 0.5em 0;
  &:after {
    content: '';
    display: block;
    background: $color-light-gray;
    position: relative;
    top: 5px;
    width: 35px;
    height: 1px;
  }
  @include breakpoint($medium-down) {
    display: none;
  }
}

.expando-block__icon {
  @include icon('plus', after);
  @include single-transition($default-transition-property, $default-transition-duration, $default-transition-function);
  position: absolute;
  right: 0;
  top: 0;
  right: 15px;
  .expando-block--expanded & {
    @include icon('minus', after);
  }
  .product--full &,
  .after-product & {
    font-size: 15px;
  }
}

.expando-block__content {
  @include single-transition($default-transition-property, $default-transition-duration, $default-transition-function);
  height: 0;
  overflow: hidden;
  .expando-block--expanded & {
    height: auto;
  }
  @include breakpoint($medium-up) {
    display: block;
    height: auto;
    margin-bottom: 1em;
  }
}

.expando-block__anchor {
  margin-top: -120px;
  position: absolute;
}
