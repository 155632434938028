/**
 * Search form(s)
 */
.page-main {
  form.search-form {
    padding: 8px 0;
  }
  .search-form {
    &__fields {
      margin: 0 auto;
      text-align: center;
      @include breakpoint($medium-up) {
        width: 80%;
      }
      .search-term {
        margin-right: 12px;
        @include breakpoint($medium-up) {
          width: 80%;
        }
      }
    }
  }
}
