.social-login {
  &__container {
    text-align: center;
    padding: 5px 0 30px 0;
  }
  &__divider {
    @include h15;
    width: 95%;
  }
  &__terms {
    margin-top: 15px;
    padding: 0 26px;
    font-size: 14px;
    text-align: left;
    p {
      margin: 0;
      a {
        font-family: $font--futura-book;
        text-transform: uppercase;
      }
    }
  }
  &__title,
  &__info {
    display: none;
  }
  &__email-opt-in {
    text-align: left;
    font-size: 14px;
    margin: 0 26px;
    padding-top: 15px;
    span {
      display: block;
      margin-left: 2em;
    }
  }
  &__opt-in-label {
    width: 100%;
  }
}

#sign-in-panel .social-login {
  &__container {
    padding: 5px 0 0 0;
  }
  padding-top: 0;
  @include breakpoint($medium-up) {
    margin-top: 35%;
    padding-top: 34%;
  }
  @include breakpoint($large-up) {
    margin-top: 30px;
  }
  &__email-opt-in,
  &__terms {
    font-size: 18px;
    padding-top: 2%;
    @include breakpoint($medium-up) {
      font-size: 15px;
      padding-top: 15px;
    }
  }
}
/* GNAV styling */
.gnav-util__content__inner {
  .social-login {
    &__divider {
      width: 87%;
    }
    &__container {
      padding: 0;
      @include breakpoint($landscape-up) {
        padding: 5px 0px 30px 0;
      }
    }
  }
}
/* Disconnect button styling */
#facebook_disconnect,
#vkontakte_disconnect {
  display: inline;
  padding-left: 22px;
  input.form-submit {
    height: 25px;
    margin-bottom: 4px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 20px;
  }
}
/* Order confirmation page */
.order-confirmation-page,
#confirmation-panel {
  .social-login {
    padding-left: 20px;
    padding-bottom: 20px;
    &__container {
      text-align: left;
      padding-bottom: 0;
    }
    &__terms,
    &__divider {
      display: none;
    }
    &__title {
      display: block;
      font-family: $font--subheading;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      font-weight: normal;
      line-height: 1;
      font-size: 24px;
    }
    &__info {
      margin-bottom: 10px;
      display: block;
    }
    &__email-opt-in {
      margin: 0;
    }
  }
}
/* product review page */
.email_check {
  .social-login {
    &__divider {
      width: 100%;
    }
  }
}
/* Social info section on Account preferences page */
.social-info {
  margin-top: 30px;
  border-top: 1px solid $color-light-gray;
  &__connect {
    margin: 30px 0;
  }
  &__header {
    font-family: $font--subheading;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: normal;
    line-height: 1;
    font-size: 24px;
  }
}
/* Appointment Booking */
.sign-in-form-container {
  .social-login {
    &__divider {
      width: 100%;
    }
    &__terms,
    &__email-opt-in {
      font-size: 16px;
      a {
        text-decoration: underline;
      }
    }
  }
}
/* Review page */
.field-content {
  .spp-page {
    .pr_prod_img,
    .email_check,
    .full_sign_in {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 49%;
      }
    }
  }
}

.checkout {
  .social-login__divider {
    @include breakpoint($medium-up) {
      margin: 0 auto 36px auto;
    }
    @include breakpoint($large-up) {
      margin: 0 auto 15px auto;
    }
  }
}
