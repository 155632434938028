.utility-nav {
  background-color: transparent;
  float: right;
  padding: 0 11px 0 0;
  text-align: right;
  position: static;
  width: 34%;
  justify-content: flex-end;
  @include breakpoint($medium-up) {
    width: 20%;
  }
  @include breakpoint($large-up) {
    width: 38%;
  }
  @include breakpoint($landscape-up) {
    float: none;
    width: auto;
    text-align: left;
    padding: 0;
  }
  &__item {
    display: inline-block;
    vertical-align: middle;
    border-right: none;
    font-family: $font--futura-book;
    font-size: 18px;
    float: none;
    &:not(:last-child) {
      @include breakpoint($landscape-up) {
        @if is_cr20_desktop_nav_refresh() {
          padding-#{$rdirection}: 0;
        } @else {
          padding-#{$rdirection}: 13px;
        }
      }
    }
    text-align: right;
    text-transform: uppercase;
    @include breakpoint($landscape-up) {
      text-align: left;
      text-transform: none;
      width: auto;
      display: block;
      float: left;
    }
    a:not(.cart-block__footer__button--checkout) {
      @include secondary-hover;
      font-size: 14px;
      text-decoration: none;
      display: inline-block;
      vertical-align: bottom;
      line-height: 14px;
      @include breakpoint($medium-up) {
        font-size: 15px;
      }
      @include breakpoint($large-up) {
        vertical-align: middle;
      }
    }
    &-link {
      margin-left: 0;
      @include breakpoint($landscape-up) {
        margin-left: 18px;
      }
    }
  }
  &__cart {
    float: none;
    margin: 0 5px;
    width: 10%;
    @include breakpoint($landscape-up) {
      float: left;
      margin: 0;
      width: auto;
    }
    &.gnav-util {
      .page-header__utils & {
        border-left: none;
        width: 14px;
        @include breakpoint($medium-up) {
          width: auto;
          @if is_2020_nav_redesign() {
            margin-#{$ldirection}: 10px;
          }
        }
        @include breakpoint($landscape-up) {
          margin: 0;
          @if is_cr20_desktop_nav_refresh() {
            margin-#{$ldirection}: 19px;
          }
        }
      }
      @if is_2020_nav_redesign() {
        .sticky-nav-active & {
          @include breakpoint($medium-landscape-only) {
            margin-#{$ldirection}: 5px;
          }
        }
      }
    }
  }
  &__menu-items,
  &__loyalty-state,
  &__signin-text,
  &__account-text,
  &__cart-icon {
    color: $color-black;
    cursor: pointer;
    display: block;
    text-decoration: none;
    letter-spacing: 0;
    line-height: 26px;
    padding-top: 4px;
    position: relative;
    @include breakpoint($landscape-up) {
      padding-top: 5px;
      @if is_cr20_desktop_nav_refresh() {
        color: $color-cr18-black;
      } @else {
        color: $color-white;
      }
    }
  }
  &__menu,
  &__loyalty {
    display: none;
    @include breakpoint($large-up) {
      display: block;
    }
  }
  &__signin-text {
    display: block;
    font-size: 14px;
    @include breakpoint($large-up) {
      font-size: 15px;
      padding-top: 0;
    }
    .elc-user-state-logged-in & {
      display: none;
    }
    a {
      font-size: 13px;
      @include breakpoint($medium-up) {
        font-size: 15px;
      }
    }
  }
  &__account-text {
    display: none;
    .elc-user-state-logged-in & {
      display: block;
    }
  }
  &__cart-icon {
    &.gnav-util__icon {
      line-height: 37px;
      height: 37px;
      width: 23px;
      background-image: url('/media/images/cart--icon-black.png');
      background-repeat: no-repeat;
      background-position: 0px 6px;
      background-size: 18px 23px;
      @include breakpoint($landscape-up) {
        color: $color-white;
        line-height: 37px;
        height: 37px;
        padding: 0 10px 0 8px;
        width: auto;
        @if is_cr20_desktop_nav_refresh() {
          background-image: url('/media/images/cart--icon-black.png');
        } @else {
          background-image: url('/media/images/cart--icon-white.png');
        }
        .page-header__utils & {
          font-size: 15px;
        }
      }
    }
  }
  &__cart-count {
    color: $color-cr18-black;
    display: none;
    height: 20px;
    font-size: 12px;
    left: 39%;
    letter-spacing: -0.01em;
    line-height: 1em;
    padding-top: 2px;
    position: absolute;
    text-align: center;
    width: 20px;
    top: 13px;
    margin-left: -10px;
    @include breakpoint($landscape-up) {
      left: 50%;
      top: 13px;
      margin-left: -10px;
      @if is_cr20_desktop_nav_refresh() {
        @include breakpoint($landscape-up) {
          color: $color-cr18-black;
        }
      } @else {
        color: $color-white;
      }
    }
    .gnav-util--cart--nonempty & {
      display: block;
    }
  }
  &__cart-arrow {
    display: none;
    left: 50%;
    margin-left: -5px;
    min-width: 11px;
    min-height: 11px;
    opacity: 0;
    position: absolute;
    top: 27px;
    visibility: hidden;
    z-index: 5;
  }
  &__loyalty.loyalty_email_signup {
    @if is_2020_nav_redesign() {
      display: none;
    } @else {
      visibility: hidden;
      display: block;
      width: 0;
      border-right: unset;
    }
    @include breakpoint($large-up) {
      @if is_2020_nav_redesign() {
        display: block;
      } @else {
        visibility: visible;
      }
    }
  }
  &__account-text {
    @include breakpoint($landscape-up) {
      @if is_cr20_desktop_nav_refresh() {
        margin-#{$ldirection}: 18px;
      }
    }
  }
}
@if is_2020_nav_redesign() {
  @include breakpoint($small-down) {
    .utility-nav__account-button {
      display: none;
    }
  }
}
