.giftcards-form {
  &__header {
    @include h11;
    margin-bottom: 1em;
  }
  &__field {
    &--select-value {
      margin-bottom: 1.3333333333em;
      select {
        // Hide the dropdown, since the block grid replaces it.
        @include breakpoint($small-up) {
          display: none;
        }
      }
    }

    // Block grid that shows all dropdown select choices. See perlgem-side JS.
    &--price {
      display: none;
      @include breakpoint($small-up) {
        display: block;
        width: 100%;
        border-top: 1px solid $color-gray;
        border-left: 1px solid $color-gray;
        &-option {
          @include t6;
          float: left;
          width: 25%;
          height: 55px;
          line-height: 55px;
          text-align: center;
          border-right: 1px solid $color-gray;
          border-bottom: 1px solid $color-gray;
          &:hover {
            background-color: $color-light-gray;
            cursor: pointer;
          }
          &.selected {
            color: $color-white;
            background-color: $color-green;
          }
        }
      }
    }
    &--email,
    &--confirm-email,
    &--to,
    &--from {
      width: 100%;
      margin-bottom: 1.3333333333em;
      @include breakpoint($small-up) {
        float: left;
        width: 47.5%;
      }
      input {
        width: 100%;
      }
    }
    &--email,
    &--to {
      @include breakpoint($small-up) {
        margin-right: 5%;
      }
    }
    &--message {
      width: 100%;
      margin-bottom: 1.3333333333em;
      @include breakpoint($small-up) {
        float: left;
      }
      textarea {
        width: 100%;
        height: 6em;
        padding-top: 0.6666666667em;
      }
    }
    &--giftcard-number,
    &--giftcard-pin {
      width: 100%;
      margin-bottom: 1.3333333333em;
      @include breakpoint($small-up) {
        float: left;
        width: 30%;
        margin-right: 5%;
      }
      input {
        width: 100%;
      }
    }
    input,
    textarea {
      @include input-placeholder {
        font-size: 15.75px;
      }
    }
  } // &__field

  &__message-count {
    @include t3;
    display: block;
    margin: 1em 0;
    color: $color-mid-gray;
  }
  &__terms {
    @include t1;
    margin-bottom: 1.7142857142em;
    color: $color-mid-gray;
  }
  &__submit-container {
    width: 100%;
    input {
      width: 100%;
    }

    // Make this match the width of half the prices boxes.
    .giftcards-content--pgiftcards & {
      @include breakpoint($small-up) {
        width: 50%;
      }
    }

    // Make this match the width of the other form inputs.
    .giftcards-content--egiftcards & {
      @include breakpoint($small-up) {
        width: 47.5%;
      }
    }

    // Submit container on the balance page is a little different.
    .giftcards-content--balance & {
      @include breakpoint($small-up) {
        float: left;
        width: 30%;
      }
    }
  } // &__submit-container
} // .giftcards-form
