.customer-service-contact-form {
  @include clearfix;
  &__section {
    &.address-form {
      fieldset legend {
        display: block;
      }
    }
  }
  &__categories {
    @include clearfix;
    width: 100%;
    margin: 1em 0 !important;
    padding: 0;
    border-bottom: 1px solid $color-black;
  }
  &__category {
    display: block;
    float: left;
    border: 1px solid $color-dark-gray;
    background-color: $color-dark-gray;
    color: $color-white;
    width: 19%;
    margin: 0 1% -1px 0;
    padding: 0.5em 0.25em;
    text-align: center;
    list-style-type: none;
    font-size: 16px;
    cursor: pointer;
    @include breakpoint($medium-down) {
      width: 48%;
      margin: 1%;
    }
  }
  &__category.active-category {
    color: $color-black;
    background-color: $color-white;
    border: 1px solid $color-black;
    //font-style: italic;
    @include font--text--italic;
    //border: none;
  }
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  textarea,
  select,
  .selectBox {
    margin: 10px 25px 10px 0;
    border-color: $color-black;
    &.error {
      border-color: $color-red;
      color: $color-red;
    }
  }
  input[type='radio'] ~ label::before,
  input[type='radio'] ~ .label::before {
    font-size: 14px;
    margin-top: -4px;
    margin-right: 10px;
  }
  input[type='text'],
  select {
    width: 47%;
    @include breakpoint($small-down) {
      width: 97%;
    }
  }
  input {
    &[disabled] {
      background-color: $color-gray;
      cursor: not-allowed;
    }
    &.customer-service-contact-form__input--half {
      float: left;
      @include breakpoint($small-down) {
        float: none;
      }
    }
  }
  textarea {
    width: 97%;
    padding: 10px 15px;
    height: 5em;
  }
  select {
    margin: 0.5em 3% 0.5em 0;
  }
  fieldset {
    border: none;
    margin: 0;
    padding: 0;
    max-width: 100%;
  }
  &__errors {
    color: $color-red;
  }
  &__radio-item,
  &__radio-item--use-products,
  &__radio-item--site-member {
    display: block !important;
    margin: 1em 0;
  }
  &__radio-item--use-products,
  &__radio-item--site-member {
  }
  .customer-service-contact-form__submit {
    margin: 0 0 1em 1px;
  }
}

.customer-service-contact-form__category-content {
  display: none;
}

.customer-service-contact-form_select-wrapper {
  width: 47%;
  @include breakpoint($small-down) {
    width: 97%;
  }
}
