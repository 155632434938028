///
/// customer service internal page
///
.cs-page__title {
  @include content-contain;
  text-align: center;
  border-bottom: $color-black solid 1px;
  h1 {
    @include font--heading;
    @include font-padding(heading);
    @if $cr18 == true {
      font-size: 66px;
      @include breakpoint($medium-up) {
        font-size: 56px;
      }
    }
  }
}

.cs-page__landing-grid {
  .cs-page__landing-grid__item {
    border-bottom: $color-light-gray solid 1px;
    @include breakpoint($medium-up) {
      width: 33.333%;
      float: left;
      border-right: $color-light-gray solid 1px;
      &:nth-of-type(3n + 3) {
        border-right-width: 0;
      }
    }
  }
}

.cs-page__footer {
  position: relative;
}

///
/// CS contacts block - layout changes depending on placement
///
.cs-contacts {
  border-top: $color-black solid 1px;
  background: $color-silver;
  padding: 10px 0;
  position: relative;
  margin-top: -1px;
  @include breakpoint($medium-up) {
    min-height: 200px;
  }
  .cs-page__navigation & {
    margin: 1em;
    border: $color-light-gray solid 1px;
    padding-bottom: 1em;
  }
}

.cs-contacts__grid-item {
  text-align: center;
  h3 {
    @include h11;
    margin: 0 0 0.5em 0;
  }
  .cs-page__footer & {
    @include breakpoint($medium-down) {
      &:before {
        border-top: $color-dark-gray solid 1px;
        width: 35px;
        display: block;
        content: '';
        margin: 1em auto;
      }
    }
    @include breakpoint($medium-up) {
      width: 33.333%;
      float: left;
      display: inline-block;
      min-height: 180px;
      border-right: $color-light-gray solid 1px;
      padding-top: 1.5em;
      &:nth-of-type(3n + 3) {
        border-right-width: 0;
      }
    }
  }
  .cs-page__navigation & {
    &:before {
      border-top: $color-light-gray solid 1px;
      width: 35px;
      display: block;
      content: '';
      margin: 1em auto;
    }
  }
}

.cs-page__navigation .cs-contacts__grid-item.cs-contacts__grid-item--first:before {
  border-top-width: 0;
}

.cs-page__footer .cs-contacts__grid-item.cs-contacts__grid-item--first:before {
  @include breakpoint($medium-down) {
    border-top-width: 0;
  }
}

.cs-contacts__copy--large {
  @include t4;
  margin-bottom: 0;
}

.cs-contacts__cta {
  @include breakpoint($medium-down) {
    // @include icon("arrow--right", after, true);
    // &:after {
    //   margin-left: 4px;
    //   margin-top: -2px;
    //   font-size: 8px;
    // }
    &:hover:after {
      text-decoration: none;
    }
  }
  .cs-page__footer & {
    @include breakpoint($medium-up) {
      @include button-basic;
      display: inline-block;
      min-width: 250px;
    }
  }
  .cs-page__navigation & {
    // @include icon("arrow--right", after);
    // &:after {
    //   margin-left: 4px;
    //   margin-top: -2px;
    //   font-size: 8px;
    // }
    &:hover:after {
      text-decoration: none;
    }
  }
}

///
/// CS expanding menu
///
.cs-menu__parent {
  > h3 {
    @include h10;
    @if $cr18 == true {
      font-size: 26px;
    }
    &:after {
      font-size: 12px;
    }
  }
}

.cs-menu__children {
  padding: 0 0 1em 1em;
  line-height: 2em;
}
// static link inside expando-menu
.expando-menu__static {
  @include h10;
  @if $cr18 == true {
    font-size: 26px;
  }
}

///
/// FAQ specifics
///
.cs-faq__copy li {
  line-height: 2em;
}

///
/// CS page - expando block inside content area
///

.cs-page__content-item {
  .expando-block__icon {
    right: 0;
  }
  .expando-block__title--mobile span {
    padding-right: 1em;
    display: inline-block;
  }
}

// AfterPay FAQ
.afterpay-faq {
  &--hero-banner {
    .hero-banner {
      &__copy {
        color: $color-white;
        transform: translate(0, 100px);
      }
    }
  }
  &--how-it-works {
    .appt-book {
      padding-bottom: 0;
      .appt-book-page-header {
        .appt-book-page-header-content {
          h2 {
            font-family: $font--heading;
            font-size: 30px;
            font-weight: 500;
            padding-bottom: 0;
          }
        }
      }
      &-sections-container {
        display: block;
        @include breakpoint($medium-up) {
          display: flex;
          section {
            flex: 33.33%;
          }
        }
      }
    }
    .cta-tout {
      span {
        font-size: 14px;
        display: block;
        line-height: 19px;
      }
      text-align: center;
      padding: 10px;
      @include breakpoint($medium-up) {
        padding-bottom: 0;
        margin-bottom: 0;
      }
      &__lazyload-image {
        width: auto;
      }
      &__inner {
        min-height: unset;
        @include breakpoint($medium-up) {
          min-height: 180px;
        }
      }
    }
  }
}
