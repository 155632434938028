.footer-section-primary {
  font-size: 15px;
  width: 100%;
  &--pc-only {
    display: none;
  }
  @include breakpoint($landscape-up) {
    &--mobile-only {
      display: none;
    }
    &--pc-only {
      display: block;
    }
  }
  &__header {
    border-bottom: $color-white solid 1px;
    font-size: 15px;
    padding: 15px 2em;
    position: relative;
    @include breakpoint($landscape-up) {
      border-bottom: none;
      padding: 0;
    }
    &.footer-section-primary--expandable {
      @include icon(caret--right, after);
      cursor: pointer;
      &:after {
        font-size: 15px;
        position: absolute;
        right: 2em;
        top: 15px;
        transition: all 0.75s ease;
      }
    }
    .footer-section-primary--mobile-expanded & {
      border-bottom: none;
    }
    &.footer-section-primary__header--open {
      border-bottom: none;
      &:after {
        transform: rotate(90deg);
      }
    }
  }
  &__title {
    font-size: 15px;
    font-weight: normal;
    margin: 0;
    cursor: default;
    @include breakpoint($landscape-up) {
      font-family: $font--futura-demi;
      text-transform: uppercase;
      margin: 1em 0;
    }
  }
  &__content {
    display: block;
    margin: 0 2em;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.75s ease-out;
    &.footer-section-primary__content--open,
    .footer-section-primary--mobile-expanded & {
      margin: 1em 2em;
      max-height: 90vh;
      transition: all 0.75s ease-in;
      overflow-y: visible;
      @include breakpoint($landscape-up) {
        margin: 0;
      }
    }
    @include breakpoint($landscape-up) {
      height: auto;
      margin: 0;
      max-height: none;
      overflow: visible;
    }
  }
  .menu__item {
    padding-bottom: 0.5em;
    .footer-chat-link {
      .icon {
        margin-#{$rdirection}: 5px;
      }
      @include breakpoint($landscape-up) {
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .icon {
      font-size: 16px;
      margin-#{$rdirection}: 7px;
      @include breakpoint($landscape-up) {
        margin-#{$rdirection}: 5px;
      }
    }
    .icon--call {
      background: url(/media/export/cms/phone.svg) 0 0 no-repeat;
      top: 2px;
    }
    .icon--mail {
      background: url(/media/export/cms/email.svg) 0 0 no-repeat;
      top: 5px;
    }
    .icon--chat {
      background: url(/media/export/cms/chat.svg) 0 0 no-repeat;
      top: 5px;
    }
    .mob-icon {
      margin-#{$rdirection}: 6px;
      height: 16px;
      width: 16px;
      display: inline-block;
      background-size: contain;
      position: relative;
      @include breakpoint($landscape-up) {
        margin-#{$rdirection}: 6px;
      }
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__sms {
    clear: both;
    margin: 25px 0;
    height: 47px;
    border: 1px solid $color-white;
    text-align: center;
  }
  &__sms-content {
    background-image: url(/media/export/cms/chat-icon-white.svg);
    background-repeat: no-repeat;
    background-size: 23px 19px;
    background-position: 60px 10px;
    padding: 6px 15px;
  }
}
