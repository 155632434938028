/**
 * Pagination
 */
.pagination-container {
  border: solid 1px $color-dark-gray;
  border-width: 1px 0;
  .pagination {
    text-align: center;
    padding: 25px 0;
    margin: 0 auto;
    span {
      display: inline-block;
      @include font--cta;
      &:after {
        content: '\00b7';
      }
      &.last:after {
        content: '';
      }
      a,
      strong {
        display: inline-block;
        padding: 0 16px;
      }
      &.first a {
        padding-left: 0;
      }
      &.last a {
        padding-right: 0;
      }
    }
  }
}
