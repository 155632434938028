.site-offline a {
  color: $color-darker-gray;
  text-decoration: none;
}

.site-offline a:hover {
  text-decoration: underline;
}

.site-offline__background {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
  text-align: center;
}

@media (min-width: 768px) {
  .site-offline__background {
    background-size: contain;
    margin: 40px auto;
  }
}

.site-offline__inner {
  padding: 40px 20px;
}

.site-offline__welcome {
  font-family: $font--futura-book;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.2;
  padding: 30px 0 0 0;
  margin: 0;
  font-size: 80px;
  font-style: italic;
  line-height: 0.7em;
}

@media (min-width: 768px) {
  .site-offline__welcome {
    padding: 60px 0 0;
    font-size: 170px;
    line-height: 0.5em;
  }
}

.site-offline__welcome--ca {
  font-size: 66px;
  line-height: 0.8em;
}

@media (min-width: 768px) {
  .site-offline__welcome--ca {
    font-size: 120px;
  }
}

.site-offline__origins {
  font-family: $font--futura-book;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.2;
  padding: 0;
  margin: 0 0 15px 0;
  font-size: 40px;
}

@media (min-width: 768px) {
  .site-offline__origins {
    margin: 0;
    font-size: 55px;
  }
}

.site-offline__origins--ca {
  margin: 0;
}

.site-offline__apologies,
.site-offline__working {
  font-family: $font--futura-book;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.2;
  padding: 0;
  margin: 0;
  font-size: 30px;
}

.site-offline__apologies--ca {
  margin: 10px auto;
}

@media (min-width: 768px) {
  .site-offline__apologies--ca {
    max-width: 380px;
    margin: 20px auto 0 auto;
  }
}

@media (min-width: 768px) {
  .site-offline__working--ca {
    max-width: 340px;
    margin: 0 auto;
  }
}

.site-offline__help {
  margin: 21px auto;
}

.site-offline__help:after {
  content: '';
  display: table;
  clear: both;
}

.site-offline__help:before,
.site-offline__help:after {
  content: '';
  display: block;
  max-width: 229px;
  height: 1px;
  background-color: $color-darker-gray;
  margin: 20px auto;
}

.site-offline__help-now {
  font-family: $font--futura-book;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.2;
  padding: 0;
  margin: 0;
  font-size: 26px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .site-offline__help-now {
    font-size: 30px;
  }
}

.site-offline__help-now--ca {
  margin-top: 15px;
}

.site-offline__help-available {
  font-family: $font--futura-book;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.2;
  max-width: 350px;
  padding: 0;
  margin: 0 auto;
  font-size: 20px;
}

@media (min-width: 768px) {
  .site-offline__help-available--ca {
    max-width: 300px;
  }
}

.site-offline__call {
  font-family: $font--futura-book;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.2;
  padding: 0;
  margin: 0 0 10px 0;
  font-size: 24px;
}

@media (min-width: 768px) {
  .site-offline__call {
    margin: 0 0 5px 0;
    font-size: 26px;
  }
}

.site-offline__call--ca {
  max-width: 300px;
  margin: 0 auto 10px auto;
}

@media (min-width: 768px) {
  .site-offline__call--ca {
    max-width: 100%;
    margin: 0 0 10px 0;
  }
}

.site-offline__email {
  font-family: $font--futura-book;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.2;
  padding: 0;
  margin: 0 0 10px 0;
  font-size: 24px;
}

@media (min-width: 768px) {
  .site-offline__email {
    font-size: 26px;
  }
}

.site-offline__thankyou {
  padding: 0;
  margin: 0;
  font-size: 20px;
}

.site-offline__thankyou--ca {
  margin-top: 15px;
}

.site-offline__checkback {
  max-width: 350px;
  padding: 0;
  margin: 0 auto;
  font-size: 20px;
}

.site-offline__checkback--ca {
  max-width: 560px;
}
