.loyalty-nav,
.giftcards-nav {
  position: relative;
  width: 100%;
  height: 210px;
  padding-top: 1em;
  background: $color-light-gray url(/media/images/giftcards/header-background.jpg) repeat-x center center;
  border-bottom: 1px solid $color-black;
  &__header {
    @include h2;
    text-align: center;
    color: $color-white;
    letter-spacing: 0.5px;
  }
  &__tabs {
    position: absolute;
    bottom: 0;
    left: 1em;
    right: 1em;
  }
  &__tab {
    display: block;
    float: left;
    width: 24.5%;
    height: $giftcards-tab-height;
    margin: 0 0.25%;
  }
  &__link {
    @include t3;
    display: block;
    width: 100%;
    height: $giftcards-tab-height;
    line-height: $giftcards-tab-line-height;
    text-align: center;
    color: $color-white;
    text-decoration: none;
    background-color: $color-dark-gray;
    &:hover {
      background-color: $color-green;
      text-decoration: none;
      cursor: pointer;
    }
  }
  &__tab--active {
    border: 1px solid $color-black;
    border-bottom: none;
  }
  &__tab--active > &__link {
    color: $color-black;
    font-style: italic;
    background-color: $color-white;
  }
} // .giftcards-nav
