.tout-carousel-formatter {
  margin: 0 auto;
  position: relative;
  width: 80%;
  &[class*='max-width-'],
  &.full-width {
    width: 100%;
    padding-inline: 40px;
    @include breakpoint($medium-up) {
      // Allow space for arrows.
      padding-inline: 105px;
    }
    .tout-carousel-formatter__grid {
      position: relative;
      .carousel-controls {
        .slick-prev {
          #{$ldirection}: -38px;
          @include breakpoint($medium-up) {
            #{$ldirection}: -80px;
          }
        }
        .slick-next {
          #{$rdirection}: -38px;
          @include breakpoint($medium-up) {
            #{$rdirection}: -80px;
          }
        }
      }
    }
    .slick-list {
      margin-inline: 0;
    }
  }

  .carousel-controls .slick-arrow {
    height: 35px;
    position: absolute;
    top: 50%;
    width: 100%;
    .slick-next,
    .slick-prev {
      height: 35px;
    }
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
  }
  .slick-list {
    margin: 0 43px;
    .slick-track {
      margin: 0 auto;
    }
  }
  .tout-carousel-formatter_items {
    box-sizing: border-box;
    width: 100%;
    .tout-carousel-formatter__slide {
      padding-inline: 9px;
      display: inline-block;
      max-width: 100%;
      width: 100%;
      .gnav-menu-tout {
        .gnav-formatter--lvl-2 & {
          font-family: $font--futura-demi;
          font-size: 14px;
          margin: 0 15px;
          width: auto;
        }
        .gnav-menu-tout__image {
          vertical-align: top;
        }
        .gnav-menu-tout__text {
          line-height: 20px;
          height: 20px;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
    $slidesToShow: (1, 2, 3, 4, 5);
    @each $value in $slidesToShow {
      &.slides-to-show-medium-#{$value}:not(.slick-initialized) {
        .tout-carousel-formatter__slide {
          max-width: calc(100% / #{$value});
          &:nth-child(#{$value}) ~ .tout-carousel-formatter__slide {
            display: none;
          }
        }
      }
      &.slides-to-show-landscape-#{$value}:not(.slick-initialized) {
        .tout-carousel-formatter__slide {
          @include breakpoint($large-up) {
            max-width: calc(100% / #{$value});
          }
          &:nth-child(#{$value}) ~ .tout-carousel-formatter__slide {
            @include breakpoint($large-up) {
              display: none;
            }
          }
        }
      }
    }
  }
}
