.page-origins__facts {
  display: block;
  width: 100%;
  border-bottom: 1px solid $color-black;
  margin-bottom: 80px;
  padding-bottom: 100px;
  background-repeat: no-repeat;
  @include breakpoint($medium-up) {
    display: table;
  }
  &-column {
    display: block;
    text-align: center;
    @include breakpoint($medium-up) {
      display: table-cell;
      padding: 0 50px;
      width: 50%;
    }
  }
  &-header {
    @include font--heading--secondary;
    //@include font--text;
    font-size: 50px;
    line-height: 0.9em;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      font-size: 90px;
    }
    em {
      @include font--heading--secondary--italic;
    }
  }
  &-copy {
    @include font--text;
    font-size: 20px;
    em {
      @include font--text--italic;
    }
    @include breakpoint($medium-up) {
      font-size: 24px;
      @include font--heading--secondary;
      em {
        @include font--heading--secondary--italic;
      }
    }
  }
  .origins-video__iframe {
    display: none;
    max-width: 100%;
  }
}

.collection-video-colorbox .origins-video__trigger {
  display: none;
}
