.section-foundation-finder {
  .page-footer {
    display: none;
  }
}

.mantle-form-options-field {
  &__header {
    position: relative;
    .style--bare {
      font-family: $font--futura-demi;
      line-height: 32px;
      @include breakpoint($medium-up) {
        font-size: 32px;
      }
    }
  }
  &__subheader {
    .style--bare {
      font-family: $font--futura-book;
      margin: 0 20px;
      @include breakpoint($medium-up) {
        font-size: 24px;
      }
    }
  }
  &__helper-text {
    height: 0px;
    overflow: hidden;
    opacity: 0;
    font-size: 16px;
    line-height: 1.5;
    #colorbox & {
      height: auto;
      overflow: visible;
      opacity: 1;
    }
  }
  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1002;
    padding: 10px;
    background: $color-light-gray;
    border-top: 1px solid $color-mid-gray;
    visibility: hidden;
    .button {
      height: 40px;
      line-height: 2.8;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__footer-cta {
    display: inline-block;
    .button {
      width: 100%;
    }
  }
  &__intro {
    .helper-text-link {
      display: inline-block;
      height: 20px;
      background: url('/media/export/cms/foundation_finder/info-black.svg') no-repeat;
      font-size: 0;
      position: absolute;
      width: 20px;
      top: 5px;
      @include breakpoint($medium-up) {
        top: 7px;
        margin-left: 10px;
      }
    }
    .mantle-custom-text {
      display: inline-block;
    }
  }
}

.form-indicator-menu {
  display: flex;
  justify-content: center;
  text-align: center;
  &__item {
    display: flex;
    opacity: 1;
    font-family: $font--futura-medium;
    &:before {
      margin: 5px;
    }
    &:after {
      content: ' ';
      border-top: 1px solid $color-black;
      margin-top: 7px;
      display: inline-block;
      width: 80px;
    }
    &:last-child:after {
      display: none;
    }
    &.active {
      font-family: $font--futura-medium;
      opacity: 1;
      &:after {
        opacity: 0.5;
        font-weight: normal;
      }
      ~ .form-indicator-menu {
        &__item {
          opacity: 0.5;
          font-weight: normal;
        }
      }
    }
    &__link {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.colorbox--quiz {
  &#colorbox {
    #cboxContent {
      #cboxClose {
        background: url(/media/export/cms/foundation_finder/close.png) no-repeat;
        background-size: cover;
        width: 20px;
        height: 20px;
        &:hover {
          background-position: initial;
        }
      }
      #cboxLoadedContent {
        font-family: $font--futura-book;
        text-align: center;
        .overlay-header {
          font-size: 32px;
          padding: 30px 0 25px 0;
        }
        .overlay-subheader {
          font-size: 18px;
          font-family: $font--futura-medium;
          text-transform: uppercase;
        }
        .overlay-body {
          padding: 0 15px 25px 15px;
          font-family: $font--futura-demi;
        }
      }
    }
  }
}
