.cart-block-wrapper {
  @include breakpoint($landscape-up) {
    border: solid 1px $color-black;
    border-top: none;
  }
}

.gnav-util__icon__cart-count {
  position: absolute;
  top: 11px;
  left: 50%;
  font-size: 12px;
  line-height: 1em;
  letter-spacing: -0.01em;
  color: $color-white;
  background-color: $color-green;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  padding-top: 2px;
  margin-left: 4px;
  display: none;
  @include breakpoint($landscape-up) {
    background-color: $color-black;
    top: 8px;
    margin-left: 5px;
  }
  .gnav-util--cart--nonempty & {
    display: block;
  }
}

.cart-block-wrapper.loading {
  // @TODO: loading icon goes here.
  .cart-block {
    visibility: hidden;
  }
}

.cart-block {
  padding: 32px 0 0;
  @include breakpoint($landscape-up) {
    width: 450px;
  }
  &__content {
    padding: 0 25px 20px;
  }
  &__content.custom-kit {
    .cart-block__footer {
      .custom-kit__message {
        display: block;
      }
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 0.25em;
  }
  &__header {
    @include pie-clearfix;
    border-bottom: solid 1px $color-black;
    padding: 0 0 8px;
    &__item-count {
      float: right;
    }
  }
  &__title {
    @include font--subheading;
    float: left;
    margin: 0;
    padding: 0;
    font-size: 27px;
    &__text {
      display: none;
      &--empty {
        display: inline-block;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
      &--nonempty {
        .cart-block--nonempty & {
          display: inline-block;
        }
      }
      &--added {
        .cart-block--added & {
          display: inline-block;
        }
      }
    }
  }
  &__item-count {
    @include font--subheading;
    color: $color-dark-gray;
    font-size: 21px;
    // font-size: 27px;
    display: none;
    .cart-block--singular &--singular {
      display: block;
    }
    .cart-block--plural &--plural {
      display: block;
    }
    .cart-block--empty & {
      display: none;
    }
    .cart-block--added & {
      display: none;
    }
  }
  &__items {
    // In the "new" state, only show the items that were just added:
    &--new {
      display: none;
      .cart-block--added & {
        display: block;
      }
    }
    &--new.custom-kit {
      .custom-kit__message {
        display: block;
      }
    }
    &--all.custom-kit,
    &--new.custom-kit {
      .cart-item.kits {
        .cart-price__section {
          top: 3%;
          right: 0;
          text-align: right;
        }
        .cart-item__kit-items {
          clear: inherit;
          margin-left: 72px;
          @include breakpoint($mobile-up) {
            margin-left: 83px;
          }
        }
      }
    }
    .cart-block--added &--all {
      display: none;
    }
    &__view-bag {
      @include a1;
      display: block;
      padding: 20px 0 50px;
    }
  }
  &__footer {
    margin-top: 20px;
    &__summary {
      @include pie-clearfix;
      display: none;
      margin: 0 0 15px;
      .cart-block--nonempty &,
      .cart-block--added & {
        display: block;
      }
    }
    &__item-count {
      float: left;
      // Intentionally supressing item count in footer, see ORNF-122
      display: none;
      .cart-block--plural & {
        display: none;
      }
    }
    &__subtotal {
      color: $color-black;
      display: block;
      &__label {
        @include font--subheading;
        font-size: 27px;
        float: left;
      }
      &__value {
        // font-size: 27px;
        // Intentionally changed to match "Your Bag Contains" header size (ORNF-122)
        @include font--text-alt;
        font-size: 21px;
        letter-spacing: 0;
        float: right;
      }
      .cart-block--added & {
        display: none;
      }
    }
    .custom-kit__message {
      clear: both;
      float: left;
      font-family: $font--futura-medium;
      font-size: 16px;
    }
    &__button {
      width: 100%;
      &--checkout {
        display: none;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: block;
          line-height: 3.9;
        }
        &:hover {
          background: $color-green;
        }
      }
      &--continue-shopping {
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
    }
  }
  &__shipping {
    padding: 17px;
    background-color: $color-light-gray;
    display: none;
    min-height: 80px;
    .cart-block--nonempty &,
    .cart-block--added & {
      display: block;
    }
    &__title {
      @include font--subheading;
      font-size: 21px;
      margin: 0;
      padding: 0;
      @if $cr18 == true {
        color: $color-cr18-black;
      }
      @if $cr18 == false {
        color: $color-green;
      }
    }
    &__copy {
      @include font--text;
      color: $color-dark-gray;
      font-size: 14px;
      margin: 2px 0 5px;
    }
  }
}
