.footer-links {
  text-transform: none;
  font-family: $font--futura-book;
  background-color: $color-cr18-black;
  color: $color-white;
  overflow: hidden;
  font-size: 15px;
  height: auto;
  line-height: 42px;
  @include breakpoint($landscape-up) {
    height: 42px;
    width: 95%;
    float: left;
  }
  &__content {
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
  }
  &__choosers,
  &__tertiary-nav,
  &__copyright {
    width: 100%;
    @include breakpoint($landscape-up) {
      display: inline;
      float: left;
    }
  }
  &__choosers {
    height: 60px;
    @include breakpoint($landscape-up) {
      height: auto;
      width: 25%;
    }
    .selectbox--new .selectBox,
    .selectbox--new.selectBox,
    select.selectbox--new {
      background-color: $color-cr18-black;
      color: $color-white;
      font-size: 15px;
      text-transform: none;
      margin-top: 5px;
      min-width: 60px;
      //@todo replace with SVG when new icons are in place
      background-image: url('/media/images/global/expand_bar_arrow_down.png');
      @include breakpoint($large-up) {
        background-image: none;
      }
      &:before {
        border-top-color: $color-white;
      }
      &:after {
        border-top-color: $color-cr18-black;
      }
    }
    .selectbox--new {
      .selectBox-menuShowing,
      &.selectBox-menuShowing {
        &:before {
          border-top-color: transparent;
          border-bottom-color: $color-white;
        }
        &:after {
          border-top-color: transparent;
          border-bottom-color: $color-cr18-black;
        }
      }
    }
    .country-chooser {
      text-align: left;
    }
    .locale-select {
      display: none;
    }
    .country-select {
      height: 42px;
      line-height: 42px;
    }
    .language-select {
      height: 15px;
      line-height: 15px;
      margin: 14px 0 0 0;
      padding-left: 15px;
      border-left: 1px solid $color-mid-gray;
      &__item {
        margin: 0;
        padding: 0;
        &:first-child {
          border-left: none;
        }
      }
      &__link {
        text-transform: none;
        font-size: 15px;
      }
    }
  }
  &__copyright {
    text-align: center;
    height: 60px;
    @include breakpoint($landscape-up) {
      text-align: right;
      height: auto;
      width: 25%;
    }
  }
  &__tertiary-nav {
    height: 15px;
    line-height: 15px;
    padding: 30px 0;
    border-top: 1px solid $color-white;
    @include breakpoint($landscape-up) {
      margin-top: 14px;
      border: 0;
      padding: 0;
      width: 50%;
    }
    li {
      border-right: 1px solid $color-mid-gray;
      &:last-child {
        border-right: none;
      }
    }
  }
  &__items {
    text-align: center;
  }
  &__item {
    padding: 0 15px;
    display: inline-block;
    text-align: left;
  }
  // specificity wars
  .country-select__selectbox-selectBox-dropdown-menu li,
  .country-select__selectbox-selectBox-dropdown-menu li.selectBox-selected {
    background: $color-cr18-black;
    text-transform: none;
  }
  + a {
    width: 10%;
    position: absolute;
    right: 15px;
    bottom: 23px;
    @include breakpoint($landscape-up) {
      width: 3%;
      float: right;
      position: static;
      right: 0;
      bottom: 0;
    }
    img {
      height: 30px;
      width: 25px;
    }
  }
}

.page-footer {
  &__secondary {
    position: relative;
  }
}
