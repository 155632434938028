.gnav-offers-block {
  @include pie-clearfix;
  padding: 0;
  width: 100%;
  @if is_2020_nav_redesign() {
    border-bottom: none;
    @include breakpoint($landscape-up) {
      border-bottom: solid 1px $color-black;
    }
  } @else {
    border-bottom: solid 1px $color-black;
  }
  @if is_2020_nav_redesign() {
    background-color: $color-ivory;
  } @else if $cr18 == true {
    background-color: $color-cr18-black;
  }
  @if is_cr20_desktop_nav_refresh() {
    @include breakpoint($landscape-up) {
      border-bottom: none;
      background-color: $color-ivory;
    }
  } @else {
    @include breakpoint($landscape-up) {
      background-color: transparent;
      border-bottom: none;
      width: auto;
      padding: 0;
    }
  }
  &--animated .gnav-offers-block__list__item {
    @include transition(opacity 1.2s ease, visibility 1.2s ease);
  }
  &__headline {
    float: left;
    &-link {
      display: inline-block;
      float: left;
      width: auto;
    }
  }
  &__list {
    @include breakpoint($landscape-up) {
      float: left;
    }
  }
  &__headline,
  &__offer {
    @include font-smoothing;
    @if is_2020_nav_redesign() {
      color: $color-cr18-black;
    } @else {
      color: $color-white;
    }
    @if is_cr20_desktop_nav_refresh() {
      @include breakpoint($landscape-up) {
        color: $color-cr18-black;
      }
    } @else {
      @include breakpoint($landscape-up) {
        color: $color-white;
      }
    }
  }
  &__headline {
    @include font--text;
    @include font-padding(text, 18px, 29px);
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0;
    margin: 0 10px 0 0;
    display: none;
    @include breakpoint($landscape-up) {
      @include font-padding(text, 18px, 30px);
      display: block;
    }
  }
  &__list {
    height: $offerbar-height;
    width: 100%;
    @if $cr18 == true {
      text-align: center;
    }
    @include breakpoint($landscape-up) {
      height: 37px;
      width: auto;
      @if $cr18 == true and not is_cr20_desktop_nav_refresh() {
        text-align: left;
      }
    }
    &__item {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      width: 100%;
      @if is_cr20_desktop_nav_refresh() {
        @include breakpoint($landscape-up) {
          .gnav-offers-block__headline ~ .gnav-offers-block__list > &,
          .gnav-offers-block__headline-link ~ .gnav-offers-block__list > & {
            width: auto;
          }
        }
      } @else {
        @include breakpoint($landscape-up) {
          width: auto;
        }
      }
      &--active {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__offer {
    @include font--text-alt;
    @include font-padding(text, 14px, 29px);
    font-family: $font--subheading;
    display: inline-block;
    text-decoration: none;
    font-size: 20px;
    padding-top: 4px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    padding-right: 7px;
    padding-left: 12px;
    &:hover {
      text-decoration: none;
    }
    @include breakpoint($landscape-up) {
      // @include icon("arrow--right", after, true);
      @include font-padding(text, 18px, 37px);
      font-family: $font--subheading;
      font-size: 20px;
      letter-spacing: 0.02em;
      padding-top: 4px;
      display: block;
      // font-style: normal;
      text-transform: uppercase;
      padding-right: 0;
      &:after {
        margin-left: 4px;
        margin-top: -2px;
        font-size: 8px;
      }
    }
  }
  &__more-link {
    @include font--text-alt;
    @include icon('arrow--right', after);
    @include font-padding(text, 14px, 29px);
    @include font-smoothing;
    color: $color-white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    padding-right: 20px;
    float: right;
    &:after {
      margin-left: 4px;
      margin-top: -2px;
      font-size: 8px;
    }
    &:hover {
      text-decoration: none;
    }
    @if is_cr20_desktop_nav_refresh() {
      @include breakpoint($landscape-up) {
        color: $color-cr18-black;
      }
    }
  }
}
