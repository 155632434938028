body.lp {
  padding: 0;
  margin: 0;
}

#custom-container #lp-dashboard-btn-container {
  position: absolute;
  top: 2px;
  right: 2px;
  color: $color-white;
  font-size: 1.2em;
  padding: 5px 0px 9px;
  margin-right: 5px;
  display: block;
}

#custom-container #brand-logo-placement {
  border: 0px;
  margin-top: 4px;
}

#lp-dashboard-btn-container div {
  border-bottom: 1px solid $color-white;
  float: left;
  margin-right: 12px;
  margin-top: 9px;
}

#lp-dashboard-btn-container a {
  color: $color-dark-gray;
  font-family: $font--cta;
  font-size: 14px;
  letter-spacing: normal;
}

#custom-container #user-input-container {
  border: 1px solid #d3d3d3;
  position: absolute;
  top: 65px;
  width: 412px;
}

#custom-container {
  width: 850;
  overflow: hidden;
  color: $color-black;
}

#chat_window,
.chat-window-container {
  height: auto;
  @include breakpoint($medium-up) {
    overflow: hidden;
  }
}

#chat_window.livechat__chatwindow-mobile {
  overflow: scroll;
}

#chatLines {
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;
  .no-icon-line {
    font-size: 14px;
    padding: 10px 25px;
  }
  .icon {
    background: url('/media/customer_service/live_chat/user_default_img.jpg');
    float: left;
    height: 34px;
    margin-right: 15px;
    overflow: hidden;
    width: 34px;
    img {
      width: 100%;
      heught: 100%;
    }
  }
}

.rich-line-content {
  width: 100%;
  clear: both;
  padding: 25px;
}

.rich-line-content img {
  height: 100%;
  width: 100%;
}

.rich-line-content .icon {
  float: left;
  clear: left;
}

.rich-line-content .txt-line {
  font-size: 14px;
  width: 295px;
  float: left;
  word-wrap: break-word;
}

.rich-line-content .txt-line a {
  color: blue;
  text-decoration: underline;
}

.rich-line-content .icon-agent,
.rich-line-content .icon-system {
  background-color: $color-black;
}

#custom-container .beauty-consultant-profile {
  width: 351px;
  float: left;
}

.beauty-consultant-profile .consultant-msg {
  width: 350px;
  float: left;
  font-size: 14px;
  padding-left: 35px;
  visibility: visible;
}

.agent-typing-msg {
  clear: both;
  color: $color-dark-gray;
  font-style: italic;
  padding: 3px 6px;
}

#user-input-container .chat-window-container {
  float: left;
  height: 473px;
  overflow-x: hidden;
  position: relative;
  width: 412px;
}

#custom-container .text-input-container {
  border-right: 1px solid #d3d3d3;
  height: 114px;
  padding-top: 20px;
  position: absolute;
  top: 540px;
  width: 412px;
}

#custom-container .input-field-container {
  float: left;
  height: 30px;
}

#custom-container input.chat-input-field {
  width: 250px;
  margin-left: 25px;
  height: 48px;
}
/* */

#emailTranscript,
#closeChat {
  cursor: pointer;
  font-size: 9px;
  margin-top: 9px;
  letter-spacing: 0.3em;
  border-bottom: 0px;
}

#close-chat-container {
  margin-right: 100px;
}

#custom-container .btn {
  cursor: pointer;
}

#custom-container .add-to-bag-btn-container .btn-addToBag {
  color: $color-white;
  cursor: pointer;
  padding-top: 11px;
  background: $color-black;
  text-transform: uppercase;
  text-align: center;
}

#custom-container {
  .send-btn {
    background: url('/media/customer_service/live_chat/btn_send.png');
    cursor: pointer;
    display: block;
    float: left;
    height: 48px;
    margin: 0 25px;
    width: 82px;
    background-repeat: no-repeat;
  }
  #requestChat {
    background: url('/media/customer_service/live_chat/btn_start_chat.png') no-repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: 0 none;
    cursor: pointer;
    height: 50px;
    width: 162px;
  }
}

#custom-container .btn-upload {
  background: none;
  border: 0 none;
  color: $color-dark-gray;
  display: block;
  font-family: $font--cta;
  font-size: 14px;
  text-decoration: underline;
  text-transform: uppercase;
  width: 100%;
}

#custom-container .btn-upload img {
  margin: 0 auto;
  display: block;
}

#custom-container .upload-silhoette {
  background: url('/media/customer_service/live_chat/btn_upload_photo.jpg') no-repeat;
  background-repeat: no-repeat;
  height: 164px;
  margin: 15px auto 0;
  width: 163px;
  text-align: center;
  img {
    max-height: 164px;
  }
}

#custom-container .brand-topband {
  padding: 10px 25px;
  border-bottom: 1px solid #d3d3d3;
}

#custom-container .right-col-top .top-right-photo-upload-container {
  .login-text {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 300px;
  }
  button.btn {
    text-align: center;
    display: none;
  }
}

#vba-window-container #vba-logo {
  font-size: 1.5em;
  color: $color-light-gray;
}

#vba-logo {
  height: 45px;
  width: 212px;
}
/* layout */

#custom-container .right-col-top .top-right-video-container {
}

#custom-container .right-col-top .top-right-photo-upload-container {
}

#custom-container .right-col-top .top-right-photo-upload-container .login-text {
  font-size: 14px;
  text-align: center;
  margin: 20px auto 0px;
  width: 300px;
}

#custom-container .right-col-top {
  position: absolute;
  border-bottom: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  border-top: 1px solid #d3d3d3;
  border-left: 0px;
  float: right;
  height: 335px;
  left: 411px;
  line-height: 1.5em;
  overflow: hidden;
  position: absolute;
  top: 65px;
  width: 412px;
}

#custom-container .right-col-bottom {
  position: absolute;
  float: right;
  height: 253px;
  left: 411px;
  position: absolute;
  top: 400px;
  width: 412px;
  border: 1px solid #d3d3d3;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0px;
}

#chatContainer {
  position: relative;
  display: none;
  position: absolute;
  height: 100%;
  overflow: auto;
  width: 412px;
}
/* photo upload */

#custom-container .section-heading-photo {
  @include font--subheading;
  // font-family: $font--subheading;
  font-size: 24px;
  letter-spacing: 0.1em;
  margin: 12px 0;
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px;
}

#custom-container .section-heading-consultant {
  @include font--subheading;
  // font-family: $font--subheading;
  font-size: 24px;
  margin: 15px 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  letter-spacing: 0.1em;
  overflow: hidden;
}

#custom-container .section-heading-consultant img {
  display: block;
  margin-left: -13px;
}

#custom-container .icon-consultant,
#custom-container .icon-chat-visitor {
  /* width:74px; height:86px; */
}

#custom-container .chat-face-icon {
  width: 60px;
  height: 60px;
  float: left;
  background-color: none;
  /* float: left; */
  margin: 0 25px 20px 35px;
  text-align: left;
  background: url('/media/customer_service/live_chat/user_default_img.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  img {
    width: 100%;
    height: 100%;
  }
}

#custom-container .consultant-name {
  @include font--subheading;
  // font-family: $font--subheading;
  font-size: 18px;
  margin-bottom: 12px;
  font-weight: bold;
  padding-left: 35px;
  text-align: left;
}

#custom-container .consultant-fav-prodcut,
#custom-container .consultant-location {
  font-size: 14px;
  text-align: left;
  padding-left: 35px;
  text-transform: none;
}

#photo-upload-query #cboxClose {
  background: url('/media/customer_service/live_chat/icon_close.png');
  background-repeat: no-repeat;
  top: 30px;
  right: 10px;
}

#photo-upload-query .next-row {
  clear: both;
}

#photo-upload-query .choose-photo-btn {
}

#photo-upload-query .file-chosen-state span {
  height: 12px;
  width: 175px;
  display: block;
  text-overflow: ellipsis;
}

#photo-upload-query .msg-instructions .profile-pic-upload__help p {
  margin-bottom: 10px;
  font-size: 16px;
}

#photo-upload-query .msg-instructions .profile-pic-upload__help ul {
  line-height: 30px;
  list-style: none;
  margin: 0 0 0 20px;
}

#photo-upload-query .msg-instructions {
  margin: 0 auto;
  font-size: 16px;
  padding: 12px 0 14px 0;
  width: 525px;
  text-align: left;
}

#photo-upload-query .btn-instructions {
  float: left;
  font-size: 0.9em;
  margin: 5px auto 0;
  text-align: center;
  width: 250px;
}

#photo-upload-query .msg-header {
  @include font--subheading;
  // font-family: $font--subheading;
  text-align: left;
  margin-top: 24px;
  margin-left: 25px;
  font-size: 28px;
  color: $color-black;
  text-transform: uppercase;
}

#photo-upload-query .photo-upload-row {
  width: 400px;
}

#photo-upload-query #choose-file-btn {
  background: url('/media/customer_service/live_chat/choose_file.png');
  cursor: pointer;
  float: left;
  height: 50px;
  margin: 0;
  padding: 8px;
  text-align: center;
  width: 250px;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

#photo-upload-query #file-save-btn {
  margin: 5px auto;
  background: url('/media/customer_service/live_chat/save.png');
  background-repeat: no-repeat;
  height: 50px;
  padding-top: 7px;
  width: 250px;
}

#photo-upload-query #file-chosen-state {
  float: left;
  padding: 6px 0 0 0;
  text-align: center;
  margin-top: 25px;
  border-bottom: 1px solid $color-black;
}

#photo-upload-query #file-chosen-state span {
  font-family: $font--cta;
}

.vba-x-close {
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  cursor: pointer;
}

body.lp #cboxOverlay {
  background: none repeat scroll 0 0 gray;
  height: 100%;
  position: fixed;
  width: 100%;
}

body.lp #cboxOverlay {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 9999;
  display: none;
  opacity: 0.92;
}
/* */
#beauty-consultant-panel {
  .section-heading-consultant {
  }
  .btn-arrowRight {
    float: left;
    margin-top: 30px;
    width: 50px;
  }
}
/* */

#product-recommendations-panel {
  @include font--subheading;
  // font-family: $font--subheading;
  display: block;
  color: $color-black;
}

#product-recommendations-panel .section-heading-lbl {
  margin: 12px;
  letter-spacing: 0.1em;
  font-family: $font--futura-medium;
  margin-bottom: 15px;
}

#product-recommendations-panel .product-sku-panel {
  padding: 10px;
  padding-top: 0px;
}

#product-recommendations-panel .carousel-left-arrow img,
#product-recommendations-panel .carousel-left-arrow-off img {
  margin-left: 0px;
}

#product-recommendations-panel .carousel-right-arrow img,
#product-recommendations-panel .carousel-right-arrow-off img {
  margin-left: 0px;
}

#product-recommendations-panel .carousel-left-arrow,
#product-recommendations-panel .carousel-right-arrow {
  height: 13px;
  margin-top: 30px;
  opacity: 1;
  width: 50px;
}

#product-recommendations-panel .carousel-left-arrow-off,
#product-recommendations-panel .carousel-right-arrow-off {
  opacity: 0.5;
  width: 50px;
  height: 13px;
  cursor: default;
  margin-top: 30px;
}

#product-recommendations-panel .carousel-left-arrow-off {
  float: left;
}

#product-recommendations-panel .carousel-right-arrow-off {
  float: right;
}

#product-recommendations-panel .carousel-left-arrow {
  float: left;
  cursor: pointer;
}

#product-recommendations-panel .carousel-right-arrow {
  float: right;
  cursor: pointer;
}

#product-recommendations-panel .carousel-content-container {
  float: left;
  height: 300px;
  overflow: hidden;
  width: 290px;
}

#product-recommendations-panel .carousel-content-container .carousel-panel-mask {
  min-width: 2000px;
  left: 0;
  position: relative;
}

#product-recommendations-panel .carousel-content-container .carousel-content-panel {
  float: left;
  height: 275px;
  width: 295px;
}

#product-recommendations-panel .carousel-content-container .img-col {
  float: left;
  margin-right: 15px;
  text-align: center;
  width: 50px;
}

#product-recommendations-panel .carousel-content-container .img-col img {
  width: 100%;
}

#product-recommendations-panel .carousel-content-container .img-col .mock-prod-img {
  width: 60px;
  height: 90px;
}

#product-recommendations-panel .carousel-content-container .detail-col {
  float: left;
  width: 220px;
}

#product-recommendations-panel .carousel-content-container .prod-title {
  font-family: $font--heading;
  color: $color-light-green;
  font-size: 11px;
  line-height: 1.2;
  margin-bottom: 10px;
  overflow: hidden;
  text-transform: uppercase;
  width: auto;
}

#product-recommendations-panel .carousel-content-container .prod-subtitle {
  font-family: $font--cta;
  font-size: 13px;
  margin-bottom: 10px;
  overflow: hidden;
  width: auto;
}

#product-recommendations-panel .carousel-content-container .prod-units {
  font-family: $font--heading;
  font-size: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  width: auto;
}

#product-recommendations-panel .carousel-content-container .shade-container {
  float: left;
  height: 22px;
  overflow: hidden;
  width: auto;
}

#product-recommendations-panel .carousel-content-container .shade-container .shade-shim {
  overflow: hidden;
  float: left;
  border-radius: 18px;
  -moz-border-radius: 18px;
  width: 18px;
  height: 18px;
}

#product-recommendations-panel .carousel-content-container .shade-container .shade-name {
  float: left;
  line-height: 16px;
  margin-left: 5px;
  overflow: hidden;
  width: auto;
}

#product-recommendations-panel .carousel-content-container .price-info {
  float: right;
  font-family: $font--text;
  font-style: italic;
  height: auto;
  width: auto;
  .favorites-hitarea {
    margin-top: -12px;
    .icon--heart-o {
      float: right;
      cursor: pointer;
    }
  }
}

#product-recommendations-panel .carousel-content-container .add-to-bag-btn-container {
  margin: 65px 0 55px;
  position: relative;
}

#product-recommendations-panel .heart-icon {
  background-image: url('/media/customer_service/live_chat/heart_icon.png');
  background-repeat: no-repeat;
  height: 15px;
  margin-top: 16px;
  position: absolute;
  right: 0;
  width: 18px;
  background-size: 100% auto;
}

#product-recommendations-panel .heart-icon.add-icon-heart {
  background-image: url('/media/customer_service/live_chat/add_icon_heart.png');
}

#product-recommendations-panel .carousel-content-container .btn-addToBag {
  background-image: url('/media/customer_service/live_chat/btn_add_to_bag.png');
  background-repeat: no-repeat;
  background-size: auto auto;
  cursor: pointer;
  height: 50px;
  padding: 12px 0;
  text-align: center;
  background-color: $color-white;
  position: absolute;
  width: 248px;
  right: 37px;
  text-indent: -2000px;
}

#product-recommendations-panel .carousel-content-container .msg-addToBag {
  display: none;
  margin-left: 45px;
  padding-top: 50px;
}

#product-recommendations-panel .section-heading-consultant img {
  display: block;
  margin-left: -8px;
}
/* landing and post chat target pages */
.beauty-consultations {
  text-align: center;
  padding: 5px;
  text-align: center;
  margin: 0px auto;
  width: 750px;
  text-transform: uppercase;
}

.beauty-consultations .conditional-has-added-to-bag {
  display: none;
  margin-top: 24px;
  margin-bottom: 12px;
}

.beauty-consultations .beauty-consultations-headline {
  font-family: $font--heading;
  margin-bottom: 5px;
  letter-spacing: 4px;
  font-size: 1.5em;
  text-transform: uppercase;
}

.thankyou-page-tiles {
  margin: 0 auto;
  width: 945px;
  margin-bottom: 20px;
}

.thankyou-page-tiles img {
  padding-right: 3px;
}

.thankyou .beauty-consultations-headline {
  margin: 36px;
}

.beauty-consultations .beauty-consultations-large-msg {
  font-family: $font--heading;
  line-height: 36px;
  letter-spacing: 4px;
  font-size: 1.5em;
  width: 500px;
  margin: 0px auto;
  text-transform: uppercase;
}

.beauty-consultations .beauty-consultations-large-msg:after {
  content: '';
  display: block;
  background: $color-black;
  position: relative;
  top: 10px;
  width: 35px;
  height: 1px;
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: 15px;
}

.beauty-consultations-large-msg .quotes {
  font-size: 24px;
  font-style: italic;
  line-height: 28px;
}

.beauty-consultations-large-msg .msg-content {
  width: 500px;
  margin: 0 auto;
}

.beauty-consultations-large-msg .msg-content .h6 {
  padding-bottom: 12px;
  font-size: 15px;
  font-family: $font--futura-medium;
}

.beauty-consultations-large-msg .msg-content p {
  font-size: 14px;
  margin-bottom: 14px;
}

.vba-tgt-pg-divider {
  border-bottom: 1px solid #d3d3d3;
  width: 500px;
  margin: 0 auto;
}

.beauty-consultations-lbl {
  font-size: 1em;
  text-transform: uppercase;
  font-family: $font--futura-medium;
}

.beauty-consultations-placement-msg {
  font-size: 1.4em;
}

.beauty-consultations .btn-choices-row {
  width: 345px;
  height: 45px;
  margin: 0px auto;
}

.beauty-consultations .btn-choices-row .or-space {
  padding: 10px;
}

.beauty-consultations .btn-choices-row div {
  float: left;
}

.beauty-consultations #order-question-btn,
.beauty-consultations #write-review-btn {
  margin: 0 auto;
  width: 150px;
  background-color: $color-black;
  color: $color-white;
}

.beauty-consultations #start-chat-btn {
  border-radius: 5px;
  padding: 10px;
  width: 150px;
}

.beauty-consultations .beauty-consultations-instructions {
  font-size: 0.9em;
  text-transform: initial;
}

.beauty-consultations-instructions a {
  border-bottom: 1px solid $color-mid-gray;
  height: 17px;
  width: auto;
  display: inline-block;
  text-transform: initial;
  text-decoration: none;
}
/* thank you page b */
.beauty-consultations-instructions button.create-account {
  background-image: url('/media/customer_service/live_chat/create_accout.png');
  background-repeat: no-repeat;
  height: 50px;
  width: 248px;
  border: 0;
}
/* */
.vba-btn {
  color: $color-white;
  margin: 0 auto;
  cursor: pointer;
}

.vba-txt-btn {
  text-align: center;
  width: 175px;
  background-color: $color-red;
  color: $color-white;
  height: 32px;
  margin: 10px auto;
  padding-top: 10px;
  cursor: pointer;
}

#photo-upload-query .file-save-btn-row {
  float: right;
  width: 250px;
  position: relative;
}

#photo-upload-query .vba-txt-btn {
  background-color: $color-black;
}

.thank_you_page {
  margin-top: 100px;
}
