/**
 * Generic typeahead
 */
.typeahead-wrapper {
  .typeahead-suggestions__item {
    padding: 4px 0;
  }
  .typeahead-search__see-all-results {
    @include a1;
  }
  @include breakpoint($medium-up) {
    .product-grid__content {
      border-top: 0;
    }
  }
  .product-grid__item {
    border-bottom: 0;
  }
}

/**
 * Search form(s)
 */
.page-main {
  .search-form {
    position: relative;
    .typeahead-wrapper {
      position: absolute;
      z-index: 999;
      width: 100%;
      background-color: $color-white;
      ul {
        padding: 8px 20px;
      }
    }
  }
}
