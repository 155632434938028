.page-origins__milestone {
  margin: 0;
  .origins__milestone {
    // Deal with the background image
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 265px;
    display: flex;
    flex-direction: column;
    width: 100%;
    @include breakpoint($medium-up) {
      display: table;
    }

    // Image and content styling
    // On mobile, rearrange so that the image is always on top and the content below
    // On desktop, use the natural flow
    &-content,
    &-image {
      flex: 1;
      @include breakpoint($medium-up) {
        width: 50%;
        vertical-align: top;
        display: table-cell;
      }
    }
    &-image {
      order: 1;
      text-align: center;
    }
    &-content {
      order: 2;
      text-align: center;
      vertical-align: middle;
      @include font--text;
      p {
        margin-bottom: 40px;
      }
    }
    &-copy,
    &-year {
      max-width: 75%;
      margin: auto;
    }
    &-copy {
      @include font--text;
      em {
        @include font--text--italic;
        font-style: normal;
      }
      &--blockquote {
        vertical-align: top;
        margin: 0.4em 0 40px 0;
      }
    }
    &-year {
      font-size: 74px;
      //font-style: italic;
      @include font--text--italic;
    }

    // Styling for when a milestone has an image
    &--without-image {
      display: block;
      .origins__milestone-content,
      .origins__milestone-image {
        @include breakpoint($medium-up) {
          width: 100%;
          display: block;
        }
      }
      .origins__milestone-image {
        text-align: center;
        img {
          max-width: 100%;
          display: inline-block;
        }
      }
    }
  }
}
