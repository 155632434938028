///////////////////////////////
/// HERO Helper
/// design debug tool
/// associated with large and 50-50 heroes
/// debug div loads via js - see file ~/or_base/js/site/hero_helper.js
///////////////////////////////

.hero-helper {
  border: $color-light-gray solid 1px;
  background-size: 10% 10%;
  background-image: linear-gradient(to right, $color-light-gray 1px, transparent 1px),
    linear-gradient(to bottom, $color-light-gray 1px, transparent 1px);
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  &--large {
  }
  &--5050 {
  }
}

.hero-debug {
  &.hero-large {
    .hero-large__inner {
      border: #f00 solid 2px;
    }
  }
  &.hero-50-50__content-wrap {
    .hero-50-50__content {
      border: #f00 solid 2px;
    }
  }
}
