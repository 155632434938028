/// Flexible Tout - includes variants \\\

// our row dimensions at 100
// 1920x676
// 1280x451
// 1024x371
// 768x270
// 225 default

.flex-tout {
  cursor: default;
  color: $color-white;
  position: relative;
  &.clickable-processed {
    cursor: pointer;
  }
  &.flex-tout--color-dark {
    color: $color-black;
  }
  // bg image has hover effect
  &__bg-img {
    @include transition(opacity, 200ms);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 1;
    position: absolute;
    // custom visibility for mobile alt state
    .flex-tout--has-mobile-alt & {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
  }
  &__inner {
    position: relative;
    height: inherit;
    margin: 0 auto;
    text-align: center;
    padding: 0;
    overflow: hidden;
    .flex-tout--img &,
    .flex-tout--text & {
      background-color: $color-black;
    }
    .flex-tout--text.flex-tout--color-dark &,
    .flex-tout--img.flex-tout--color-dark & {
      background-color: $color-white;
    }
    // breakpoint heights
    min-height: 225px;
    .large-format & {
      min-height: 325px;
    }
    .large-format & {
      @include breakpoint($medium-up) {
        padding-top: 15px;
        margin-bottom: 15px;
      }
    }
    .flex-grid__item:nth-of-type(1) & {
      .large-format & {
        @include breakpoint($medium-up) {
          margin-right: 7px;
        }
      }
    }
    .flex-grid__item:nth-of-type(2) & {
      .large-format & {
        @include breakpoint($medium-up) {
          margin-left: 7px;
        }
      }
    }
    .flex-tout--has-mobile-alt & {
      height: auto;
    }
    @include breakpoint($medium-up) {
      height: 270px;
      .large-format & {
        height: 651px;
      }
    }
    @include breakpoint($landscape-up) {
      height: 371px;
      .large-format & {
        height: 491px;
      }
      .flex-tout--has-mobile-alt & {
        height: 371px;
      }
    }
    @include breakpoint(1200px, 1280px) {
      .large-format & {
        height: 591px;
      }
    }
    @include breakpoint($largest-up) {
      height: 451px;
      .large-format & {
        height: 733px;
      }
      .flex-tout--has-mobile-alt & {
        height: 451px;
      }
    }
    @include breakpoint($xxlarge-up) {
      height: 676px;
      .large-format & {
        height: 901px;
      }
      .flex-tout--has-mobile-alt & {
        height: 676px;
      }
    }
    @include breakpoint(1800px) {
      .large-format & {
        height: 1001px;
      }
    }
  }
  &.clickable-processed {
    .flex-tout__inner:hover {
      .flex-tout__bg-img {
        opacity: 0.7;
      }
    }
  }
  // content
  &__title {
    font-weight: normal;
    line-height: 1.1;
    margin: 0;
  }
  &__subhead {
    line-height: 1.2;
    .flex-tout__mobile-alt & {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 1.2;
      @include breakpoint($largest-up) {
        font-size: 22px;
      }
    }
  }
  &__bg-img {
    @include transition(opacity, 200ms);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 2;
  }
  &__inner-pos {
    z-index: 2;
    position: absolute;
    width: 100%;
    padding: 25px;
    .flex-tout--align-v-top & {
      top: 0;
      bottom: auto;
    }
    .flex-tout--align-v-center & {
      @include vertical-center;
      position: absolute;
      bottom: auto;
    }
    .flex-tout--align-v-bottom & {
      top: auto;
      bottom: 0;
    }
    // custom visibility for mobile alt state
    .flex-tout--has-mobile-alt & {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
  }
  // overlay variant
  &__overlay {
    @include font-smoothing;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.75);
    color: $color-black;
    padding: 10px;
    width: 100%;
    position: relative;
    &.flex-tout--color-dark {
      color: $color-white;
      background-color: rgba(0, 0, 0, 0.75);
    }
    &.flex-tout-width--50 {
      @include breakpoint($landscape-up) {
        width: 50%;
      }
    }
    &.flex-tout-width--75 {
      @include breakpoint($landscape-up) {
        width: 75%;
      }
    }
  }
  &__overlay-content {
    border: $color-black solid 2px;
    padding: 10px;
    .flex-tout--color-dark & {
      border-color: $color-white;
    }
  }
  // separator element
  &__separator:after {
    content: '';
    display: block;
    width: 50%;
    height: 2px;
    margin: 12px auto 12px;
  }
  // General rules for our mobile alt behavior
  &__mobile-alt {
    background-color: $color-white;
    //bottom: 0;
    color: $color-black;
    display: block;
    height: auto;
    position: relative;
    width: 100%;
    //z-index: 3;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__mobile-alt-img-wrap {
  }
  &__mobile-alt-img {
    float: left;
    width: 100%;
  }
  &__mobile-alt-content {
    clear: both;
    padding: 10px;
  }
}

// OVERLAY STYLE specific styles
.flex-tout--overlay {
  background-color: rgba(0, 0, 0, 0.75);
  .flex-tout__separator:after {
    background-color: $color-black;
  }
  &.flex-tout--color-dark {
    background-color: rgba(255, 255, 255, 0.75);
    .flex-tout__separator:after {
      background-color: $color-white;
    }
  }
  .flex-tout__subhead {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 1.2;
    @include breakpoint($xsmall-up) {
      font-size: 22px;
    }
  }
}

// IMAGE LAYER STYLE specific styles
.flex-tout--img {
  &__upper-image {
    @include vertical-center;
    max-height: 100%;
    display: block;
    margin: 0 auto;
    // custom visibility for mobile alt state
    .flex-tout--has-mobile-alt & {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
  }
  &__img-pos {
    position: absolute;
    top: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
    text-align: center;
    // custom visibility for mobile alt state
    .flex-tout--has-mobile-alt & {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
  }
}

// TEXT LAYER STYLE specific styles
.flex-tout--text {
  .flex-tout__inner-pos {
    text-align: center;
  }
  &__content-wrap {
    margin: 0 auto;
  }
  .flex-tout__separator:after {
    background-color: $color-white;
  }
  &.flex-tout--color-dark {
    .flex-tout__separator:after {
      background-color: $color-black;
    }
  }
}
