// If you have questions about why z-index is treated this way
// http://www.sitepoint.com/better-solution-managing-z-index-sass/
// https://twitter.com/w3cplus/status/479910313198768128
// http://sassmeister.com/gist/341c052928c956c1a751
////////////////////////////////////////////////////////////////////////////////
@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @warn "No layer found for `#{inspect($layers...)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}

$z-layers: (
  /// FIX THIS /// add header z-index, increase discover
  'default': 1,
  'discover': (
    'bg-img-left': 2,
    'bg-img-top': 2,
    'title': 3,
    'label': 4,
    'text': 5,
    'video-frame': 6,
    'video-player': 7,
    'video-screenshot': 8,
    'close-icon': 8,
    'under': -1
  ),
  'video-module': (
    'video-frame': 6,
    'video-player': 7,
    'video-screenshot': 8
  ),
  'ingredient-gallery': (
    'arrow': 5,
    'filter': 9,
    'filter-icon': 10
  )
);
