.regimen-quiz {
  text-align: center;
  border-bottom: solid 3px $color-black;
  &__title {
    @include font--subheading;
    @include font-padding(subheading, 24px, 68px);
    margin: 0;
    font-size: 24px;
    &__inner {
      max-width: 1280px;
      margin: 0 auto;
      display: block;
    }
    @include breakpoint($landscape-up) {
      text-align: left;
      padding-left: 25px;
    }
  }
  &__intro-text {
    @include font--text;
    display: none;
    margin: 0 20px;
    font-size: 30px;
    line-height: 1;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  &__content {
    border-top: solid 1px $color-light-gray;
    @include breakpoint($landscape-up) {
      border-top-color: $color-black;
      background-color: #f5f5f3;
      padding: 22px 0;
      &__inner {
        display: table;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
      }
    }
  }
  &__section {
    @include breakpoint($landscape-up) {
      display: table;
      width: 100%;
      &--actions {
        border-top: solid 1px $color-light-gray;
        margin-top: 22px;
      }
    }
    .regimen-quiz--4-col & {
      @include breakpoint($largest-up) {
        display: table-cell;
        vertical-align: middle;
        &--main {
          width: 82%;
        }
        &--actions {
          width: 18%;
          border-left: solid 1px $color-light-gray;
          border-top: none;
          margin-top: none;
        }
      }
    }
  }
  &__grouping {
    @include transition(opacity, 200ms);
    @include breakpoint($landscape-up) {
      display: table-cell;
      border-left: solid 1px $color-light-gray;
      vertical-align: middle;
      &:first-child {
        border-left: none;
      }
      &--age {
        width: 15.416%;
      }
      &--gender {
        width: 17.63%;
      }
      &--benefit {
        width: 34.194%;
      }
      &--actions {
        width: 100%;
      }
    }
  }
  &__fieldset {
    margin: 0 20px;
    border-bottom: solid 1px $color-light-gray;
    padding: 0 0 25px;
    @include breakpoint($landscape-up) {
      border-bottom: none;
    }
    &--gender {
      @include breakpoint($medium-up) {
        min-height: 137px;
      }
      @include breakpoint(1109px) {
        min-height: 123px;
      }
      @include breakpoint($largest-up) {
        min-height: 137px;
      }
    }
    &--age {
      @include breakpoint($landscape-up) {
        padding-bottom: 0;
      }
      .regimen-quiz__fieldset__options {
        width: 142px;
        margin: 0 auto;
        text-align: left;
      }
    }
    &--skin-type,
    &--benefit {
      padding-bottom: 0;
    }
    &__legend {
      @include font--subheading;
      font-size: 21px;
      margin: 0;
      padding: 21px 0;
      display: block;
      @include breakpoint($landscape-up) {
        font-size: 24px;
      }
    }
    &__options {
      @include pie-clearfix;
    }
  }
  &__label {
    cursor: pointer;
    padding: 0 10px;
    input[type='radio']:checked ~ .label {
      color: $color-green;
    }
    &__text {
      @include font--cta;
      text-transform: uppercase;
      font-size: 14px;
      &:before {
        font-size: 25px;
      }
      &__inner {
        position: relative;
        top: 2px;
      }
    }
    // Selector Warz
    input[type='radio'] ~ .label:before {
      margin-right: 0;
    }
  }
  .product-attribute {
    @include transition(color, 200ms);
    cursor: pointer;
    display: inline-block;
    width: 130px;
    vertical-align: top;
    padding-bottom: 8px;
    &:hover {
      color: $color-dark-gray;
    }
    &.active,
    &.active:hover {
      color: $color-green;
    }
  }
  &__fieldset {
    &--skin-type .product-attribute {
      width: 30%;
      &__title {
        @include breakpoint($landscape-up) {
          min-height: 28px;
        }
      }
    }
    @include breakpoint($landscape-up) {
      &--benefit .product-attribute {
        width: 19%;
      }
    }
  }
  &__actions {
    vertical-align: top;
    padding: 22px 20px;
    @include breakpoint($landscape-up) {
      padding-bottom: 5px;
    }
    .regimen-quiz--4-col & {
      @include breakpoint($largest-up) {
        padding: 20px 40px;
      }
    }
    &__label {
      @include font--text;
      font-size: 24px;
      margin: 0;
      padding: 0;
    }
    &__submit {
      width: 100%;
      margin: 18px 0 0;
      @include breakpoint($landscape-up) {
        width: 150px;
        // margin-left: 18px;
        margin-top: 0;
      }
      .regimen-quiz--4-col & {
        @include breakpoint($largest-up) {
          width: 100%;
          margin-left: 0;
          margin-top: 18px;
        }
      }
    }
    &__reset-link,
    &__save-link {
      display: none;
      padding: 20px 0 0;
    }
    &__save-link {
      .elc-user-state-anonymous .regimen-quiz--taken & {
        display: block;
      }
    }
    &__reset-link {
      .regimen-quiz--taken & {
        display: block;
      }
    }
    &__label,
    &__submit {
      @include breakpoint($landscape-up) {
        display: inline-block;
      }
      .regimen-quiz--4-col & {
        @include breakpoint($largest-up) {
          display: block;
        }
      }
    }
  }
  &--no-age-val {
    .regimen-quiz {
      &__grouping {
        &--gender,
        &--benefit,
        &--actions {
          opacity: 0.4;
        }
      }
    }
    .product-attribute:hover {
      color: $color-black;
    }
  }
}

// Parent templates can use this however they want. By default it's unstyled and
// hidden though.
.regimen-results-header {
  display: none;
}
