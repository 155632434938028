// This was done before Welcome 15 was introduced
// @TODO -- Convert this to welcome15

$gnav-signup-max-width: 380px;

.site-email-signup {
  padding: 64px 0 29px;
  margin: auto;
  max-width: $gnav-signup-max-width;
  &__close {
    position: absolute;
    right: 5%;
    color: $color-black;
    cursor: pointer;
    top: 15px;
    font-weight: 900;
  }
  &__title {
    @include font--heading;
    text-align: center;
    font-size: 25px;
    letter-spacing: 1;
    margin: 0 0 6px;
    line-height: 32px;
  }
  &__title--text-large,
  &__title--text-medium,
  &__title--text-small {
    text-align: left;
    margin: 0 0 0 13%;
    font-family: $font--futura-demi;
  }
  &__subtitle {
    @include font--text;
    font-size: 30px;
    color: $color-black;
    text-align: center;
    margin: 0;
    font-style: italic;
  }
  &__subtitle--text-large,
  &__subtitle--text-medium,
  &__subtitle--text-small {
    text-align: left;
    margin: 0 13% 0 13%;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: 0;
    line-height: 1.2;
  }
  &__title--text-large,
  &__subtitle--text-large {
    font-size: 27px;
    @include breakpoint($landscape-up) {
      font-size: 30px;
    }
  }
  &__title--text-medium,
  &__subtitle--text-medium {
    font-size: 25px;
  }
  &__title--text-small,
  &__subtitle--text-small {
    font-size: 18px;
    @include breakpoint($landscape-up) {
      font-size: 16px;
    }
  }
  &__copy {
    text-align: center;
    font-size: 16px;
    color: #565656;
  }
  &__messages {
    height: auto;
    text-align: center;
    padding: 12px 0 0;
  }
  &__message {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.01em;
    &--error {
      color: $color-red;
    }
    &--success {
      color: $color-black;
      display: none;
    }
  }
  &__fields {
    @include pie-clearfix;
    width: 100%;
    margin: 0 auto;
    input[type='email'],
    input[type='tel'] {
      height: 30px;
      @include swap_direction(margin, 0 0 4% 13%);
      letter-spacing: 1px;
      line-height: 1.2;
      font-size: 15px;
    }
  }
  &__field {
    @include font--subheading;
    // 48px high including border means 46px.
    @include font-padding(subheading, 24px, 46px);
    font-size: 24px;
    display: block;
    width: 280px;
    margin: auto;
    margin-bottom: 25px;
    padding-bottom: 0;
  }
  input[type='email'].site-email-signup__field {
    @include input-placeholder {
      color: $color-mid-gray;
    }
  }

  // Checkbox plugin

  &__terms-conditions {
    display: none;
    font-size: 12px;
    color: #565656;
    position: relative;
    &__checkbox,
    &__more {
      margin: 0 53px 20px 53px;
      color: $color-black;
      font-size: 12px;
      text-transform: none;
      p {
        margin-top: 0;
        font-size: 9px;
        font-family: $font--futura-medium;
        text-align: left;
        color: $color-black;
        @include breakpoint($landscape-up) {
          font-size: 12px;
          max-width: 300px;
          line-height: 1.4;
        }
      }
      p > a {
        text-decoration: underline;
        font-family: $font--futura-medium;
        font-size: 9px;
        text-transform: none;
        @include breakpoint($landscape-up) {
          font-size: inherit;
        }
      }
      .success-email-signup & {
        display: none;
      }
    }
    &__more a {
      @include link;
    }
  }
  &__submit {
    display: block;
    margin: auto;
    margin-bottom: 15px;
    min-width: 280px;
    &--align-left {
      float: left;
      margin-left: 50px;
    }
    &--align-center {
      float: none;
    }
    &--align-right {
      float: right;
      margin-right: 48px;
    }
    &--align-left,
    &--align-center,
    &--align-right {
      height: 30px;
      line-height: 30px;
      letter-spacing: 1px;
      min-width: 100px;
      font-size: 15px;
    }
    &:hover {
      background-color: $color-green;
    }
  }
  &__success {
    position: relative;
    text-align: center;
    padding: 70px 0;
    text-transform: none;
    &-bg {
      position: absolute;
    }
    &-bg--1 {
      top: 0;
      left: 0;
    }
    &-bg--2 {
      bottom: 0;
      right: 0;
    }
    &-header {
      @include font--subheading;
      text-transform: none;
      margin-bottom: 14px;
      font-size: 30px;
      letter-spacing: 1px;
      line-height: 1.5;
      @if $cr18 == false {
        color: $color-black;
      }
    }
    &-offer {
      line-height: 1.2;
      font-size: 16px;
      letter-spacing: 1px;
      &__code {
        @include font--subheading;
        color: $color-black;
        font-family: $font--futura-medium;
        text-transform: uppercase;
      }
    }
    &-terms {
      margin-top: 12px;
      margin-bottom: 20px;
      a {
        text-transform: unset;
        color: $color-mid-gray;
      }
    }
    &-cta {
      margin-bottom: 25px;
      .button--medium {
        min-width: 172px;
      }
    }
    &-reminder {
      font-size: 14px;
    }
  }
  &__qr-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}

.site-email-signup,
.footer-formatter-primary {
  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    font-size: 15px;
    margin-bottom: 20px;
    width: 100%;
    letter-spacing: 1px;
    line-height: 1.42;
    &.photo-tip {
      padding-top: 5px;
    }
    a {
      text-decoration: none;
    }
    .tooltiptext-under,
    .tooltiptext-over {
      visibility: hidden;
      width: 100%;
      max-width: 400px;
      background-color: $color-white;
      color: $color_black;
      border: 1px solid $color-mid-gray;
      border-radius: 6px;
      padding: 10px;
      position: absolute;
      z-index: 1;
      left: 0%;
      opacity: 0;
      transition: opacity 0.3s;
    }
    .tooltiptext-under {
      top: 125%;
    }
    .tooltiptext-over {
      @include breakpoint($landscape-up) {
        bottom: 125%;
      }
    }
    .tooltiptext-under::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 17%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color_black transparent;
    }
    .tooltiptext-over::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 17%;
      border-width: 5px;
      border-style: solid;
      border-color: $color_black transparent transparent transparent;
    }
    &:hover .tooltiptext-under,
    &:hover .tooltiptext-over {
      visibility: visible;
      opacity: 1;
    }
    &.gdpr-skin-tool {
      max-width: 300px;
    }
  }
}

.menu__list {
  .site-email-signup {
    margin: -32px 0 0;
  }
}

.site-email-signup,
.footer-formatter-primary {
  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    font-size: 15px;
    margin-bottom: 20px;
    width: 100%;
    letter-spacing: 1px;
    line-height: 1.42;
    &.photo-tip {
      padding-top: 5px;
    }
    a {
      text-decoration: none;
    }
    .tooltiptext-under,
    .tooltiptext-over {
      visibility: hidden;
      width: 100%;
      max-width: 400px;
      background-color: $color-white;
      color: $color_black;
      border: 1px solid $color-mid-gray;
      border-radius: 6px;
      padding: 10px;
      position: absolute;
      z-index: 1;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s;
      height: auto;
      overflow: hidden;
    }
    .tooltiptext-under {
      top: 125%;
    }
    .tooltiptext-over {
      @include breakpoint($landscape-up) {
        bottom: 125%;
      }
    }
    .tooltiptext-under::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 17%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color_black transparent;
    }
    .tooltiptext-over::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 17%;
      border-width: 5px;
      border-style: solid;
      border-color: $color_black transparent transparent transparent;
    }
    &:hover .tooltiptext-under,
    &:hover .tooltiptext-over {
      visibility: visible;
      opacity: 1;
    }
    &.gdpr-skin-tool {
      max-width: 300px;
    }
  }
}

.footer-formatter-primary {
  .tooltip {
    .tooltiptext-over {
      width: 100%;
      @include breakpoint($landscape-up) {
        width: 150%;
      }
    }
  }
}

.success-email-signup {
  .site-email-signup {
    &__message--success {
      display: block;
    }
    &__title,
    &__subtitle,
    &__fields,
    &__submit,
    &__terms-conditions,
    &__copy {
      display: none;
    }
  }
}

.popup-section {
  background-color: $color-white;
}
.promotion-signup-thanks {
  #cboxContent {
    height: 220px !important;
    width: 320px !important;
    margin: 0 auto;
    position: absolute !important;
    top: 50%;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    @include breakpoint($landscape-up) {
      top: 0;
    }
    .site-email-signup__success {
      width: 215px;
      padding: 30px 55px;
      box-sizing: content-box;
      @include breakpoint($medium-up) {
        padding: 30px 0;
        width: 315px;
        box-sizing: border-box;
      }
      &-offer {
        font-size: 14px;
        margin-bottom: 10px;
      }
      &-reminder {
        display: none;
      }
    }
    #cboxClose {
      color: $color-black;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      background-image: none;
      text-indent: 0;
      top: 15px;
      #{$rdirection}: 15px;
      .icon--close {
        position: relative;
        width: 16px;
        height: 16px;
        color: $color-black;
        font-weight: 900;
      }
    }
  }
  &#colorbox {
    #cboxWrapper {
      #cboxContent {
        #cboxLoadedContent {
          padding: 0;
        }
      }
    }
  }
}
.site-email-signup {
  &--promotion {
    margin: 0 auto 50px;
    padding-top: 30px;
    border-top: 1px solid $color-black;
    border-bottom: 1px solid $color-black;
    @include breakpoint($medium-up) {
      margin: 50px auto;
      padding: 50px 30px;
    }
    .site-email-signup {
      &__submit,
      &__terms-conditions {
        margin-top: 25px;
      }
      &__submit {
        min-width: 150px;
        border-radius: 0;
        line-height: 2.3;
        height: 38px;
        border: 1px solid $color-black;
        @include breakpoint($medium-up) {
          min-width: 210px;
        }
      }
      &__fields {
        input[type='email'],
        input[type='tel'] {
          margin: 0 auto 20px;
          height: 40px;
          border: 1px solid $color-black;
          border-radius: 0;
          @include breakpoint($medium-up) {
            width: 325px;
            @include swap_direction(margin, 0 40px 20px 20px);
          }
          @include breakpoint($landscape-up) {
            width: 415px;
          }
        }
      }
      &__close {
        display: none;
      }
      &__title {
        margin: 0 auto;
        font-size: 32px;
        text-transform: initial;
        text-align: center;
        font-weight: initial;
        @include breakpoint($medium-up) {
          font-size: 54px;
          margin-bottom: 10px;
        }
        @include breakpoint($landscape-up) {
          font-size: 60px;
        }
      }
      &__subtitle {
        margin: 0 auto;
        text-transform: initial;
        text-align: center;
        font-style: initial;
      }
    }
  }
  .cta-tout__inner {
    padding: 0 20px;
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
}

.adpl {
  &.apdl-promotion {
    input[type='email'],
    input[type='tel'] {
      & + label {
        pointer-events: none;
        line-height: 1.7;
        height: 1.7em;
        @include breakpoint($medium-up) {
          margin-#{$ldirection}: 20px;
        }
      }
      &.adpl__mode-label {
        & + label::before {
          margin-top: 15px;
          color: $color-black;
        }
      }
    }
  }
}
