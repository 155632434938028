// Sign-in Form. This applies to both the gnav dropdown and the standalone
// perlgem page.
.signin-block {
  padding: 27px 0 0;
  width: 100%;
  text-align: left;
  .elc-user-state-logged-in & {
    padding: 0;
    @include breakpoint($landscape-up) {
      padding: 27px 0 0;
    }
  }
  &__forms {
    @include breakpoint($landscape-up) {
      width: 370px;
    }
    .elc-user-state-logged-in & {
      display: none;
    }
  }
  &--signin {
    .signin-block__form--register {
      display: none;
    }
    .signin-block__form--signin {
      display: block;
    }
  }
  &__form--signin {
    display: none;
  }
  &__main,
  &__footer {
    padding: 0 25px;
  }
  &__title {
    @include font--subheading;
    text-align: center;
    font-size: 40px;
    margin: 0;
  }
  &__subtitle {
    @include font--text-alt;
    font-size: 18px;
    line-height: 1;
    color: $color-dark-gray;
    text-align: center;
    margin: -3px 0 15px;
  }
  &__field {
    display: block;
    width: 100%;
    margin: 0 auto 10px;
    &--first-name,
    &--last-name {
      width: 49%;
    }
    &--first-name {
      float: left;
    }
    &--last-name {
      float: right;
    }
  }
  &__toggle-password {
    padding: 4px 0 9px;
    &__option {
      text-align: center;
      display: inline-block;
      &--hide {
        display: none;
      }
      .signin-block--show-password & {
        &--show {
          display: none;
        }
        &--hide {
          display: inline-block;
        }
      }
    }
  }
  &__footer {
    background-color: #f8f8f8;
    padding-top: 4px;
    padding-bottom: 15px; // Match the padding top of 4px + 11px from .signin-block__checkboxes
  }
  &__checkboxes {
    padding: 11px 0 0;
  }
  &__checkbox {
    display: block;
    margin-bottom: 8px;
    position: relative;
    padding-left: 25px;
    font-size: 14px;
    line-height: 20px;
    &__text {
      @include font--text;
      color: $color-dark-gray;
      &:before {
        color: #d3d3d3;
        position: absolute;
        top: 4px;
        left: 0;
      }
      a {
        color: $color-black;
        text-decoration: none;
      }
    }
    :checked {
      ~ .signin-block__checkbox__text:before {
        color: $color-black;
      }
    }
  }
  &__forgot-password {
    color: $color-black;
    display: block;
    padding: 11px 0;
  }
  &__submit {
    width: 100%;
    margin-top: 9px;
  }
  &__toggle {
    @include a1;
    @include font-padding(cta, 14px, 50px);
    text-align: center;
    &__cta {
      text-decoration: underline;
    }
  }
  &__authed {
    @include pie-clearfix;
    display: none;
    @include breakpoint($landscape-up) {
      min-width: 370px;
    }
    &__nav {
      @include breakpoint($landscape-up) {
        min-height: 400px;
        padding: 0 30px 30px;
        float: left;
      }
      &__title {
        @include font--subheading;
        @include font-padding(subheading, 18px, 46px);
        padding-left: 20px;
        font-size: 18px;
        margin: 0;
        // Wait for js to render the title text as it's raw, user-entered
        // mustache.
        display: none;
        @include breakpoint($landscape-up) {
          font-size: 24px;
          padding: 0;
          line-height: 1;
          margin: 0 0 15px;
        }
        &--rendered {
          display: block;
        }
      }
      &__signout-link {
        @include font-padding(cta, 14px, 46px);
        font-size: 14px;
        border-top: solid 1px $color-black;
        border-bottom: solid 1px $color-black;
        display: block;
        padding-left: 20px;
        @include breakpoint($landscape-up) {
          border: none;
          display: inline;
          padding: 0;
          line-height: 1;
        }
      }
      .menu {
        // Gotta to override the mobile styling for the secondary links here
        // (sticky footer).
        background-color: transparent;
        color: $color-black;
        position: static;
        bottom: auto;
        width: auto;
        padding: 0;
        margin: 0;
        @include breakpoint($landscape-up) {
          margin: 0 0 20px;
        }
        &__item {
          float: none;
          text-align: left;
          border: none;
        }
        &__link {
          @include font--text;
          @include transition(color, 200ms);
          @include font-padding(text, 14px, 55px);
          border-top: solid 1px $color-black;
          padding-left: 20px;
          font-size: 14px;
          font-style: normal;
          color: $color-black;
          @include breakpoint($landscape-up) {
            font-size: 16px;
            line-height: 1.5;
            padding: 0;
            border: none;
          }
          &:hover {
            color: $color-green;
            text-decoration: none;
          }
        }
      }
    }
    &__content {
      @include breakpoint($landscape-up) {
        float: right;
        width: 300px;
        border-left: solid 1px $color-light-gray;
        padding: 0 20px 0 30px;
        min-height: 400px;
      }
    }
    .elc-user-state-logged-in & {
      display: block;
    }
  }
}
/* per LONL-442, hide signin block when logged out */
.elc-user-state-anonymous {
  .gnav-util__icon__arrow,
  .hide_anon {
    display: none;
  }
  .hide_anon {
    @include breakpoint($medium-down) {
      display: inline;
    }
  }
  .signin-gnav-link {
    line-height: 65px;
    color: $color-black;
    padding-left: 10px;
    padding-right: 10px;
    font-family: $font--subheading;
    font-size: 23px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.05em;
    @include breakpoint($medium-down) {
      display: none;
    }
  }
}

.elc-user-state-logged-in {
  .hide_auth {
    display: none;
  }
}

.signin-block.signin-block--signin form input[type='text'] {
  padding-left: 8px;
}
