///
/// shared styles used in custom html
///

// custom menu - used on sidebar pages
.custom-menu-header {
  @include h10;
}

.custom-menu {
  line-height: 2em;
}
