$lp--one-column-mpp-height: (min-height 640px) (orientation portrait);

#prechat_container {
  #cboxClose {
    right: 20px;
  }
}

.cbox-prechat-mod {
  padding: 0px !important;
}

.stickychat-msg {
  display: none;
}

#prechat_container {
  padding: 25px;
}
/* prechat overlay */
.prechat-form-container {
  overflow: hidden;
  background-color: $color-white;
  overflow: hidden;
  .prechat-form-body {
    .wrapper-msg {
      position: relative;
    }
  }
  .msg-wrapper {
    padding-bottom: 20px;
  }
  .liveperson-btn-container {
    display: inline-block;
    margin-top: 20px;
    width: 515px;
    .column-content {
      width: 250px;
    }
  }
}

#cboxLoadedContent {
  /* allows for edge to edge black header */
  padding: 0;
}

.prechat-form-container .prechat-x-close {
  background: url('/media/customer_service/live_chat/icon_close.png') no-repeat scroll 100% 60% transparent;
  clear: both;
  float: right;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.prechat-form-container #beauty-advice-btn {
  float: left;
}

.prechat-form-container #order-question-btn {
  float: right;
}

.prechat-form-container .require-msg {
  font-family: $font--cta;
  font-size: 14px;
  text-transform: none;
}

.prechat-form-container .instruction-msg,
.prechat-form-container .chat-hours-msg {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 10px;
}

.prechat-form-container .chat-hours-msg {
  float: right;
}

.prechat-form-container .registered-input {
  border: 1px solid #bababa;
  font-family: $font--subheading;
  height: 50px;
  padding: 12px;
}

.sticky-chat-content .prechat-form-container .registered-input {
  font-size: 20px;
  width: 217px;
}

.sticky-chat .sticky-chat__icon.icon-livechat {
  @if $cr18 == true {
    background: url('/sites/origins/themes/or_base/img/tmp/editorial/chat.svg');
    background-size: 25px 25px;
    height: 25px;
    width: 25px;
  }
  @if $cr18 == false {
    background: url('/media/customer_service/live_chat/chat.jpeg');
    height: 21px;
    width: 25px;
  }
  background-repeat: no-repeat;
  display: inline-block;
}

.prechat-form-container .registered-input span {
  letter-spacing: 0.1em;
  text-transform: lowercase;
}

.prechat-form-container .form-row-msg p {
  font-size: 0.8em;
}

.btn-upload {
  display: none;
}

.prechat-form-container h4 {
  font-size: 28px;
  font-weight: normal;
  line-height: 20px;
  margin: 0 0 25px;
  font-family: $font--subheading;
  text-transform: uppercase;
  display: block;
}

.prechat-form-container .form-row {
  clear: both;
}

.prechat-form-container .form-row .form-col-one,
.prechat-form-container .form-row .form-col-two {
  width: 250px;
  float: left;
}

.prechat-form-container .textarea-form-row {
  padding: 12px 0 6px 0;
}

.prechat-form-container .textarea-top-msg {
  clear: both;
  padding-top: 25px;
}

.prechat-form-container textarea {
  width: 498px;
  box-sizing: border-box;
}

#prechat_container .textarea-top-msg {
  margin-top: 12px;
}

#prechat_container .textarea-top-msg,
.prechat-form-container label {
  display: block;
  font-size: 12px;
  letter-spacing: 0.2em;
  margin-bottom: 1px;
  color: $color-black;
}

.prechat-form-container .prechat-input {
  height: 50px;
  padding: 5px 20px;
  width: 250px;
}

.prechat-form-container .prepopulated-input {
  margin-top: 6px;
}

.prechat-form-container .form-col-one {
  float: left;
}

.prechat-form-container .form-col-two {
  float: left;
  margin-left: 15px;
}

.prechat-form-container .form-col {
  width: 49%;
}

.prechat-form-container .form-row-btn {
  margin: 10px auto;
  text-align: center;
}

.prechat-form-container .form-spacer {
  float: left;
  margin: 20px;
}

.prechat-form-container .form-row-msg {
  clear: both;
}

.prechat-form-container .form-row-btn {
  margin: 0 auto;
  width: 150px;
  background-color: $color-black;
  display: inline-block;
  text-align: center;
}

.prechat-form-container .form-row-btn div {
  float: left;
}

.prechat-form-container .form-row-btns {
  width: 480px;
  margin: 10px auto;
  cursor: pointer;
}

.prechat-form-container .form-row-btn span {
  color: $color-white;
}

.prechat-form-container #textarea-your-question {
  font-size: 20px;
  height: 50px;
  padding: 15px 20px;
  width: 515px;
}

.prechat-form-container .lpbuttonproduct-msg,
.prechat-form-container .footarea .prechat-messaging-area {
  padding: 15px 0;
  text-transform: none;
  font-family: $font--text;
}
/* lpbuttonproduct */
.product-question-btn .chat_link_left_text_container {
}

.product-question-btn .chat_link_left_text {
  color: $color-white;
  font-size: 16px;
  letter-spacing: 0.2em;
  text-decoration: none;
  text-transform: uppercase;
}
/* styles used to override globals as inline prechat css */
h4.prechat-heading {
  text-align: left;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: normal;
  margin-top: 5px;
}

.prechat-form-container {
  text-align: left;
  font-size: 20px;
}

.lpbuttonproduct-msg {
  clear: both;
  padding: 6px 0px;
  font-size: 10px;
}

.prechat-form-container .not-you-link {
  font-size: 11px;
}

.prechat-messaging-area a {
  border-bottom: 1px solid $color-black;
  font-family: $font--cta;
  text-decoration: none;
  text-transform: uppercase;
}

.prechat-messaging-area p {
  margin: 0px;
  padding-bottom: 16px;
  font-size: 16px;
}

.prechat-messaging-area {
  clear: both;
  font-size: 11px;
  padding: 0;
  text-transform: none;
  color: $color-black;
}

.prechat-messaging-area .email-transcript,
.prechat-messaging-area .willdo-msg {
  font-size: 14px;
}

.prechat-messaging-area-top {
  margin-bottom: 20px;
  text-transform: none;
  font-size: 11px;
}

.prechat-messaging-area-top .required {
  letter-spacing: 0.2em;
  padding: 3px 0;
  color: $color-black;
}

.prechat-msg-emailtranscript-send {
  color: $color-black;
  font-size: 0.5em;
  letter-spacing: 0.3em;
  margin-top: 12px;
  text-transform: none;
}

#prechat_container .prechat-form-container .not-you-container {
  display: none;
}

#prechat_container .prechat-form-container .textarea-top-msg {
  height: auto;
}

#prechat_container .prechat-form-container .prechat-messaging-area {
  clear: left;
  float: left;
  font-family: $font--text;
}

#prechat_container .prechat-form-container .lpbuttonproduct-msg,
#prechat_container .prechat-form-container .willdo-msg,
#prechat_container .prechat-form-container .require-msg {
}

#prechat_container .prechat-form-container .form-col-one label,
#prechat_container .prechat-form-container .form-col-two label {
  display: none;
}

.prechat-form-container .livechat-btn-container .product-question-btn {
  float: right;
  width: 200px;
  clear: none;
  background-color: $color-dark-gray;
  height: 40px;
  margin-right: 2px;
}

.prechat-form-container .livechat-btn-container {
  padding-top: 10px;
  width: 225px;
  float: right;
}

#live_chat .select2-results {
  border: 1px solid $color-light-gray;
  padding: 0;
  margin: 0px 0px 4px 0px;
}

#product-page-livechat-hitarea {
  height: 25px;
  overflow: hidden;
  width: 95px;
  z-index: 99;
  position: fixed;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
}

#product-page-livechat-hitarea .product-page-livechat-hitarea-mask {
  position: relative;
  top: -1px;
  left: 0px;
  color: $color-red;
  width: 350px;
}

.page-products {
  #product-page-livechat-hitarea {
    display: block;
  }
  #product-page-livechat-hitarea {
    @include breakpoint($lp--one-column-mpp-height) {
      display: none;
    }
  }
}

.section-collections {
  #product-page-livechat-hitarea {
    display: none;
  }
}

.pre_chat_overlay {
  #cboxMiddleLeft,
  #cboxTopLeft,
  #cboxTopCenter,
  #cboxTopRight,
  #cboxBottomLeft,
  #cboxBottomCenter,
  #cboxBottomRight,
  #cboxMiddleRight,
  #cboxClose {
    display: none !important;
  }
}

.pre_chat_overlay_bg#cboxOverlay {
  background: $color-black;
  opacity: 0.5;
}
/* sticky-chat footer area */
.sticky-chat .prechat-form-container .registered-input span {
  color: $color-black;
}

.sticky-chat .prechat-form-container #input-email-address,
.sticky-chat .prechat-form-container #input-first-name {
  height: 26px;
}

.sticky-chat .sticky-chat-content {
  margin-top: 6px;
  margin-left: -17px;
}

.sticky-chat .prechat-form-container .form-col-two {
  margin-top: 18px;
}

.sticky-chat .prechat-messaging-area p {
  padding-bottom: 11px;
}

.sticky-chat .prechat-form-container {
  border: 1px solid lightgray;
  width: 240px;
  height: 434px;
  background-color: $color-white;
}

.sticky-chat .prechat-form-container span {
  font-size: 0.6em;
  letter-spacing: 0.2em;
  display: none;
}

.sticky-chat .prechat-x-close {
  visibility: hidden;
  background: url('/media/images/customer_service/vba/vba-x-close.png') no-repeat scroll 100% 60% transparent;
}

.sticky-chat .prechat-form-container .require-msg {
  font-size: 12px;
  margin: 1px 0 3px 0;
}

.sticky-chat .prechat-form-container #textarea-your-question,
.sticky-chat .prechat-form-container #input-email-address,
.sticky-chat .prechat-form-container #input-first-name {
  width: 217px;
}

.sticky-chat .prechat-form-container #textarea-your-question {
  margin-top: 14px;
  height: 60px;
  resize: none;
  font-size: 0.7em;
}

.sticky-chat .prechat-form-container .textarea-top-msg,
.sticky-chat .prechat-form-container .form-col label {
  display: none;
}

.sticky-chat .prechat-form-container .form-col-two {
  margin-left: 0px;
  margin-top: 10px;
}

.sticky-chat .prechat-form-container .lpbuttonproduct-msg {
  margin-bottom: 6px;
}

.sticky-chat .prechat-form-container .prechat-messaging-area {
  margin-top: 12px;
  font-size: 14px;
  color: $color-darker-gray;
}

.sticky-chat .sticky-chat__copy {
  height: 42px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sticky-chat__copy a:nth-of-type(2) {
  text-decoration: underline;
}

.sticky-chat .prechat-form-container .prechat-input {
  margin: 3px 0;
}

.sticky-chat .prechat-form-container .product-question-btn {
  margin: 6px 0;
}

.sticky-chat .integrated-prechat-settled {
  height: 80px;
  overflow: hidden;
}

.sticky-chat .integrated-prechat {
  margin-top: -57px;
}

.integrated-prechat-container {
  height: 480px;
  /* height: auto; */
}

.sticky-chat {
  @include transition(bottom 0.2s ease-in);
  @include animation-duration(1s);
  @include animation-fill-mode(both);
  // Always use this for white on black text to keep the font smooth:
  @include font-smoothing;
  cursor: pointer;
  position: fixed;
  // Don't be greedy with your z-indexes. This thing shouldn't overlap other
  // fixed elements, like the expanded gnav/utility nav/overlays, etc. because
  // it's always expanded, while those other things can be closed.
  z-index: 15;
  bottom: -70px;
  padding: 8px 17px 15px;
  text-align: center;
  width: 240px;
  height: 110px;
  border-bottom: none;
  &__headline {
    font-size: 21px;
    font-weight: normal;
    text-transform: uppercase;
    font-family: $font--subheading;
    margin: 0 0 2px;
    color: $color-white;
  }
  &__copy {
    font-size: 14px;
    letter-spacing: 0.3em;
    line-height: 20.8px;
    margin: 0;
    padding: 0;
    color: $color-white;
    letter-spacing: 1px;
  }
  &.expanded,
  &:hover {
    bottom: 0 !important;
  }
  &.bounce {
    @include animation-name(stickyOfferBounce);
  }
}

.sticky-chat {
  left: 0;
  @if $cr18 == true {
    background-color: $color-cr18-black;
    .spp--sticky-add-to-bag-active & {
      @if $show-livechat-spp-sticky == true {
        display: none;
      }
      @include breakpoint($medium-up) {
        left: 0;
        right: auto;
        z-index: 100;
      }
    }
  }
  @if $cr18 == false {
    background-color: $color-green;
  }
  @include breakpoint($medium-up) {
    left: auto;
    right: 0px;
    width: 260px;
    bottom: -70px !important;
    border: none;
    @if $cr18 == true {
      left: 0;
      right: auto;
    }
  }
  &__mobile {
    background-color: $color-black;
    color: $color-white;
    border-radius: 50%;
    height: 50px;
    bottom: 85px;
    right: 18px;
    width: 50px;
    z-index: 99;
    position: fixed;
    text-align: center;
    padding: 8px 0;
  }
  &__icon {
    top: 1px;
    margin-right: 3px;
    font-size: 15px;
    top: -1px;
    position: relative;
    vertical-align: middle;
  }
  @media (max-width: 1024px) {
    display: none;
  }
}

.sticky-chat a {
  text-decoration: none;
}

.sticky-chat .stickychat-msg {
  display: block;
}

.sticky-chat .prechat-msg {
  display: none;
}

.sticky-back-to-top {
  @include border-radius(50%);
  @include transition(opacity 0.4s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out);
  opacity: 0;
  text-align: center;
  width: 26px;
  height: 26px;
  overflow: hidden;
  position: fixed;
  top: 50%;
  right: 16px;
  background: $color-white;
  border: solid 1px $color-black;
  display: none;
  pointer-events: none;
  z-index: 0;
  .js-sticky-header & {
    z-index: 100;
    opacity: 1;
    pointer-events: auto;
  }
  &__text {
    @include hide-text;
    display: block;
    line-height: 1px;
  }
  &__icon {
    font-size: 11px;
    position: relative;
    left: 1px;
    top: 2px;
  }
  &:hover {
    border-color: $color-light-gray;
  }
  @include breakpoint($medium-up) {
    display: block;
  }
}

/*******  sticky-foot-form  *********/
.sticky-chat-content .prechat-form-container .instruction-msg {
  font-family: $font--text;
  width: 200px;
  font-size: 14px;
  margin: 20px 0px 0px 20px;
  text-transform: none;
}

.sticky-chat-content .prechat-form-container .require-msg {
  margin: 3px 0 3px 20px;
  text-transform: uppercase;
}

.sticky-chat-content .prechat-form-container .form-row {
  padding: 0 20px;
}

.sticky-chat-content .prechat-form-container #input-first-name,
.sticky-chat-content .prechat-form-container #input-email-address {
  width: 217px;
  height: 45px;
  color: $color-black;
}

.sticky-chat-content .prechat-form-container .textarea-top-msg {
  clear: both;
  padding-top: 0px;
  display: block !important;
}

.sticky-chat-content .prechat-form-container #textarea-your-question {
  margin-top: 7px;
  height: 60px;
  resize: none;
  font-size: 20px;
  color: $color-black;
}

.sticky-chat-content .prechat-form-container .msg-wrapper {
  padding-bottom: 10px;
}

.sticky-chat-content .prechat-form-container .form-col-two {
  margin-left: 0px;
  margin-top: 5px;
}

.sticky-chat-content .prechat-form-container .prechat-input {
  padding: 0px 15px;
}

.sticky-chat-content .prechat-form-container #textarea-your-question {
  padding: 10px 15px;
  width: 217px;
  color: $color-black;
}

.sticky-chat-content .prechat-form-container .liveperson-btn-container {
  display: inline-block;
  margin-top: 15px;
  width: 217px;
}

.sticky-chat-content .prechat-form-container .liveperson-btn-container .column-content {
  width: 217px !important;
}

.sticky-chat-content .prechat-form-container .lpbuttonproduct-msg {
  line-height: 21px;
  margin-top: 3px;
  margin-left: 2px;
  padding-bottom: 0px !important;
  font-size: 14px !important;
}

.sticky-chat-content .prechat-form-container .lpbuttonproduct-msg,
.prechat-form-container .footarea .prechat-messaging-area {
  padding: 15px 0;
  text-transform: none;
  font-family: $font--text;
}

.sticky-chat-content .prechat-form-container {
  border: 1px solid #b5b6b1;
  width: 260px;
  height: auto;
  background-color: $color-white;
}

.sticky-chat-content .prechat-form-container .prechat-messaging-area {
  margin-top: 0px;
}

.sticky-chat-content .prechat-messaging-area p {
  width: 217px;
  font-size: 14px;
  font-family: $font--text;
  line-height: 20px;
}

.sticky-chat-content .prechat-messaging-area p.willdo-msg {
  display: none;
}

.sticky-chat-content .prechat-messaging-area a {
  border-bottom: 0;
  font-weight: normal;
  font-family: $font--text;
  text-decoration: underline;
  text-transform: none;
}

.sticky-chat-content .prechat-form-container .liveperson-btn-container .column-content .beauty-advice-btn,
.sticky-chat-content .prechat-form-container .liveperson-btn-container .column-content .order-question-btn {
  width: 217px;
}

.pro-chat-popup-wrapper {
  margin: 56px 25px 0;
}

.pro-chat-popup-wrapper .pro-chat-popup-closetext {
  font-family: $font--cta;
  font-size: 14px;
  position: absolute;
  right: 39px;
  top: 17px;
}

.pro-chat-popup-wrapper .pro-chat-popup-closetext a {
  text-decoration: underline;
}

.pro-chat-popup-wrapper .pro-chat-popup-content h4,
.pro-chat-popup-wrapper .pro-chat-popup-content .at-h4 {
  color: $color-light-green;
  font-family: $font--subheading;
  font-size: 34px;
  letter-spacing: 3px;
  line-height: 25px;
  margin: 0;
  font-weight: normal;
}

.pro-chat-popup-wrapper .pro-chat-popup-content p {
  font-size: 16px;
  margin-top: 12px;
}

.pro-chat-popup-wrapper .button.pro-start_chat {
  width: 248px;
}

.pro_chat_overlay #cboxClose {
  background: url('/media/customer_service/live_chat/icon_close.png');
  background-repeat: no-repeat;
  background-size: 100% 60%;
  float: right;
  right: 8px;
  top: 9px;
  width: 8px;
}

#cboxOverlay.pro_chat_overlay_bg {
  background: transparent;
}

#cboxOverlay.pro_chat_overlay {
  border: none;
}

#need_close {
  position: absolute;
  top: 3px;
  z-index: 999;
}

#need_help {
  position: absolute;
  z-index: 1000;
}
