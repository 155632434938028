$light-green: $color-light-gray;

.tooltip {
  position: relative;
  display: inline-block;
  &:hover .tooltip__label {
    cursor: pointer;
    text-decoration: underline;
  }
  &__close {
    display: block;
    position: absolute;
    right: 14px;
    top: 37px;
    color: $color-black;
    font-size: 14px;
  }
  &__content {
    display: none;
    text-align: center;
    // position: absolute;
    // top: 100%;
    padding: 30px;
    // width: 400px;
    // max-width: 300%;
    @include font--text;
    margin-bottom: 20px;
    &:hover {
      cursor: pointer;
    }
    &-item {
      display: block;
      margin-bottom: 10px;
    }
    &-wrapper {
      position: relative;
    }
    @include breakpoint($medium-up) {
      // max-width: 270%;
    }
    @include breakpoint($landscape-up) {
      // max-width: 400%;
    }
    // z-index: 10;
    background: $light-green;
    border: 1px solid $color-green;
    // left: 50%;
    text-transform: none;
    margin-bottom: 3px;
    // @include transform(translate(-50%, 0));
  }
  &.active {
    .tooltip__content,
    .arrow {
      display: block;
    }
  }
  .arrow {
    position: relative;
    background: $color-white;
    margin-top: 10px;
    display: block;
    margin-bottom: -1px;
    z-index: 11;
    display: none;
  }
  .arrow:after,
  .arrow:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .arrow:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $light-green;
    border-width: 8px;
    margin-left: -8px;
  }
  .arrow:before {
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: $color-green;
    border-width: 9px;
    margin-left: -9px;
  }
}

.terms-tooltip {
  bottom: 2px;
  #{$ldirection}: 5px;
  position: relative;
  cursor: pointer;
  .icon--information {
    width: 16px;
    height: 15px;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 280px;
    background-color: $color-white;
    color: $color_black;
    border: 1px solid $color-mid-gray;
    padding: 10px;
    position: absolute;
    z-index: 1;
    #{$ldirection}: -123px;
    opacity: 0;
    transition: opacity 0.3s;
    height: auto;
    font-size: 14px;
    font-weight: normal;
    text-align: #{$ldirection};
    font-family: $font--futura-book;
    letter-spacing: normal;
    line-height: 1.4;
  }
  .tooltiptext-under {
    top: 150%;
    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      #{$ldirection}: 45%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color_black transparent;
    }
  }
  .tooltiptext-over {
    bottom: 125%;
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      #{$ldirection}: 45%;
      border-width: 5px;
      border-style: solid;
      border-color: $color_black transparent transparent transparent;
    }
  }
  &:hover {
    .tooltiptext-under,
    .tooltiptext-over {
      visibility: visible;
      opacity: 1;
    }
  }
}
