.gnav-menu-item,
.gnav-menu-link {
  font-family: $font--futura-book;
  position: relative;
  color: $color-black;
  display: block;
  @include breakpoint($landscape-up) {
    display: inline-block;
    padding: 0 12px;
    position: static;
  }
  &__title {
    margin: 0;
    @if is_cr20_desktop_nav_refresh() {
      position: relative;
      @include swap_direction(padding, 0 14px 0 0);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sticky-nav-active & {
      @include breakpoint($landscape-up) {
        @include swap_direction(padding, 0 6px 0 0);
      }
      @include breakpoint($large-up) {
        @include swap_direction(padding, 0 9px 0 0);
      }
    }
  }
  &__arrow-icon {
    visibility: hidden;
    opacity: 0;
    width: 14px;
    padding: 0;
    margin-#{$ldirection}: 5px;
    transition: opacity 0.25s ease, visibility 0.25s ease;
    display: flex;
    position: absolute;
    #{$rdirection}: -2px;
    .ada-enabled & {
      visibility: visible;
      opacity: 1;
      border-#{$ldirection}: 7px solid transparent;
      border-#{$rdirection}: 7px solid transparent;
      border-top: 7px solid $color-black;
    }
  }
  &.gnav-menu-link--category {
    color: $color-mid-gray;
    font-weight: normal;
    &:after {
      top: 25px;
      right: 19px;
    }
  }
  &__link {
    display: block;
    color: $color-black;
    text-decoration: none;
    &:hover {
      color: $color-green;
      text-decoration: underline;
    }
  }
}
