
.hero-banner {
  height: 100%;
  &--color {
    &-light {
      color: $color-white;
    }
    &-dark {
      color: $color-dark-gray;
    }
    &-extra-dark {
      .hero-banner__inner,
      .hero-banner__text {
        color: $color-drak-black;
      }
    }
  }
  &__inner {
    background-color: $color-light-gray;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: 100%;
    position: relative;
    overflow: hidden;
    // This can be a link, so reset any potential link styles:
    display: block;
    color: $color-black;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &--white {
      background: $color-white;
    }
    &--darkgray {
      background: $color-dark-gray;
    }
    &--green {
      background: $color-green;
    }
    .hero-banner__right-side-content {
      @include breakpoint($portrait-up) {
        position: absolute;
        right: 35px;
        top: 30%;
        width: 120px;
        p {
          font-size: 22px;
          font-family: $font--futura-book;
        }
        strong {
          font-family: $font--futura-demi;
          font-weight: normal;
        }
      }
    }
  }
  &__img-for-mpp {
    background-position: -99999px;
    background-repeat: no-repeat;
    img {
      width: 100%;
    }
    .mpp & {
      background-position: 50%;
      background-size: cover;
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
  &__text-wrapper {
    @include vertical-horizontal-center;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    @include breakpoint($medium-up) {
      padding: 0 50px;
    }
    &--vpos-top {
      @include breakpoint($portrait-up) {
        top: 40%;
      }
    }
  }
  &--text-no-image {
    min-height: 90px;
    @include breakpoint($medium-up) {
      min-height: 360px;
      width: 100%;
    }
  }
  &__text {
    color: $color-cr18-black;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    // text horizontal position
    &.hero-banner--text-hpos-center {
      margin-left: auto;
      margin-right: auto;
      clear: both;
      p {
        text-align: center;
      }
    }
    &.hero-banner--text-hpos-left {
      float: left;
      margin-left: 5%;
      clear: both;
      .hero-banner__title,
      .hero-banner__copy {
        text-align: left;
      }
    }
    &.hero-banner--text-hpos-right {
      float: right;
      margin-right: 5%;
      .hero-banner__title,
      .hero-banner__copy {
        float: right;
        text-align: right;
        clear: both;
      }
      .hero-banner__copy {
        @include breakpoint($medium-up) {
          text-align: center;
        }
      }
    }
    &.hero-banner--text-hpos-leftmost {
      clear: both;
      float: left;
      margin-left: 0;
    }
    &.align-left {
      .hero-banner__title,
      .hero-banner__copy {
        text-align: left;
        margin: auto 0;
        float: left;
      }
    }
    &.align-right {
      .hero-banner__title,
      .hero-banner__copy {
        text-align: right;
      }
    }
    &.align-center {
      .hero-banner__title,
      .hero-banner__copy {
        text-align: center;
      }
    }
    .hero-banner__tagline {
      &--font-futura-demi {
        font-family: $font--futura-demi;
      }
      &--font-size-20 {
        font-size: 20px;
        p {
          font-size: 20px;
          text-align: inherit;
          letter-spacing: inherit;
        }
      }
    }
    // Subtitle font
    .hero-banner__copy {
      &--font-futura-book {
        font-family: $font--futura-book;
        font-size: 18px;
        @include breakpoint($medium-up) {
          font-size: 24px;
        }
        strong {
          font-family: $font--futura-demi;
          font-weight: normal;
        }
      }
    }
    // text width
    &.hero-banner--text-width-100,
    .hero-banner__copy--width-100 {
      width: 100%;
    }
    &.hero-banner--text-width-90,
    .hero-banner__copy--width-90 {
      width: 90%;
    }
    &.hero-banner--text-width-80,
    .hero-banner__copy--width-80 {
      width: 80%;
    }
    &.hero-banner--text-width-70,
    .hero-banner__copy--width-70 {
      width: 70%;
    }
    &.hero-banner--text-width-60,
    .hero-banner__copy--width-60 {
      width: 60%;
    }
    &.hero-banner--text-width-50,
    .hero-banner__copy--width-50 {
      width: 50%;
    }
    &.hero-banner--text-width-40,
    .hero-banner__copy--width-40 {
      width: 40%;
    }
    &.hero-banner--text-width-30,
    .hero-banner__copy--width-30 {
      width: 30%;
    }
    &.hero-banner--text-width-20,
    .hero-banner__copy--width-20 {
      width: 20%;
    }
    .hero-banner--color-light & {
      @include font-smoothing;
      color: $color-white;
    }
    .hero-banner--overlay & {
      background-color: $transparent--white;
      padding: 9px;
      &-inner {
        border: 3px solid $color-black;
        padding: 20px;
      }
    }
    &-img {
      display: block;
      max-height: 100%;
      margin: 0 auto;
    }
  }
  &__img {
    min-width: 200px;
    .mpp & {
      display: none;
    }
  }
  &__title {
    @include font--heading;
    font-family: $font--futura-book;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    @include breakpoint($xsmall-up) {
      font-size: 28px;
    }
    @include breakpoint($medium-up) {
      font-size: 55px;
      padding-bottom: 10px;
    }
    p {
      padding: 0;
      margin: 0;
      line-height: 1.143;
      letter-spacing: 0;
      font-size: 20px;
      @include breakpoint($xsmall-up) {
        font-size: 28px;
      }
      @include breakpoint($medium-up) {
        font-size: 55px;
      }
    }
  }
  &__copy {
    @include font--text;
    font-family: $font--futura-book;
    letter-spacing: 0;
    line-height: 1;
    margin: 5px auto;
    text-align: center;
    font-size: 18px;
    @include breakpoint($medium-up) {
      font-size: 24px;
    }
    p {
      margin: 0;
      padding: 0;
      letter-spacing: 0;
      line-height: 1;
      font-size: 18px;
      @include breakpoint($medium-up) {
        font-size: 24px;
      }
    }
  }
  .mobile-hidden {
    display: none;
    @include breakpoint($portrait-up) {
      display: block;
    }
  }

  .pc-hidden {
    display: block;
    @include breakpoint($portrait-up) {
      display: none;
    }
  }
}
