#giftcards.device-mobile .page-content {
  margin-top: 85px;
}

$giftcards-margin: 1.3333333333em;
$giftcards-tab-height: 40px;
$giftcards-tab-line-height: 42px;

@import 'giftcards-accordion';
@import 'giftcards-nav';
@import 'giftcards-content';
@import 'giftcards-form';
