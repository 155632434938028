.signup-footer {
  @include breakpoint($landscape-up) {
    width: 210px;
  }
  &__messages {
    text-align: left;
  }
  &__message {
    font-size: 15px;
    line-height: 15px;
    &--error {
      color: $color-red;
    }
    &--success {
      color: $color-white;
    }
  }
  &__fields {
    @include pie-clearfix;
    width: 100%;
    margin: 0 auto;
    .signup-footer__email,
    .signup-footer__sms {
      margin-bottom: 15px;
      label,
      input {
        font-family: $font--futura-book;
        font-size: 15px;
        line-height: 2;
        width: 100%;
      }
      .tooltip {
        margin-bottom: 0;
        margin-top: 5px;
        margin-left: 0;
      }
    }
    .signup-footer__email-label,
    .signup-footer__sms-label {
      color: $color-white;
      display: block;
    }
    .signup-footer__email-field,
    .signup-footer__sms-field,
    input[type='text'] {
      background-color: $color-cr18-black;
      color: $color-mid-gray;
      display: block;
      height: 30px;
      line-height: 30px;
      padding: 0 20px 0 10px;
    }
    .signup-footer__submit {
      @include cr18-btn-primary;
      border: $color-light-gray solid 1px;
      margin-bottom: 15px;
    }
  }
  &__terms-conditions {
    display: none;
    color: $color-white;
    font-size: 15px;
    margin-bottom: 15px;
    position: relative;
    span.label {
      &:before {
        margin-bottom: 0;
      }
      p {
        font-size: 15px;
        margin-top: 0;
        text-align: justify;
      }
    }
  }
  &__success {
    color: $color-white;
    font-size: 15px;
  }
}
