.gnav-by-collection {
  &--selected {
    .gnav-by-collection__find-regimen,
    .gnav-by-collection__content {
      display: block;
    }
  }
  &__header {
    @include pie-clearfix;
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  &__title {
    @include breakpoint($landscape-up) {
      float: left;
    }
    border-bottom: solid 1px $color-light-gray;
    @include breakpoint($landscape-up) {
      border-bottom: none;
    }
  }
  &__find-regimen {
    @include icon('arrow--right', after);
    line-height: 1;
    display: none;
    padding: 0 20px 0 40px;
    margin: 19px 0 0 0;
    &:after {
      margin-left: 4px;
      margin-top: -2px;
      font-size: 8px;
    }
    &:hover:after {
      text-decoration: none;
    }
    @include breakpoint($landscape-up) {
      display: block;
      line-height: 12px;
      float: left;
      width: 146px;
      padding: 7px 0 0 20px;
      margin: -8px 0 0 18px;
      height: 35px;
      border-left: solid 1px $color-light-gray;
    }
  }
  &__content {
    @include breakpoint($medium-up) {
      display: none;
      margin-top: 19px;
    }
    @include breakpoint($landscape-up) {
      display: block;
      margin-top: 0;
    }
  }
  &__items {
    @include breakpoint($landscape-up) {
      float: left;
      width: 50%;
      margin: 7px 0 0;
    }
  }
  &__item {
    margin: 0 0 19px;
    @include breakpoint($landscape-up) {
      margin: 0 10px 14px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
