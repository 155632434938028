@mixin pb-right {
  float: $rdirection;
  width: 57.664%;
  @include breakpoint($medium-down) {
    .product-extra-content & {
      width: 100%;
      padding: 0 35px 0 35px;
      text-align: center;
    }
  }
  @include breakpoint($medium-up) {
    float: none;
    width: auto;
  }
}

.favorites-product,
.recommended-item__product {
  &-info {
    position: relative;
    padding: 30px 20px 20px 0;
    height: 100%;
    @include breakpoint($medium-up) {
      padding: 67px 20px 34px;
      text-align: center;
    }
    &__name {
      @include pb-right;
      @include font--heading;
      font-size: 11px;
      margin: 0 0 2px;
      font-weight: normal;
      @include breakpoint($medium-up) {
        font-size: 13px;
        line-height: 1.3;
        margin: 0 0 5px;
      }
      &__link {
        color: $color-cr18-black;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &__subname {
      @include pb-right;
      @include font--subheading;
      font-size: 22px;
      margin: 0;
      @include breakpoint($medium-up) {
        font-size: 24px;
      }
      &__link {
        color: $color-black;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &__price {
      @include font--text-alt;
      font-size: 24px;
      @if $cr18 == true {
        font-size: 20px;
      }
      letter-spacing: -0.01em;
      line-height: 1;
      margin: 0 5px 0 0;
      display: inline-block;
      @include breakpoint($medium-up) {
        display: block;
        margin: 0 0 13px;
      }
    }
  }
  &-actions {
    margin-top: 20px;
    &__link {
      @include link;
      display: inline-block;
      margin: 0 5px;
    }
  }
}

.product {
  &__price {
    &--non-sale {
      text-decoration: line-through;
      margin-right: 5px;
    }
    &--sale {
      color: $color-discount-red;
      display: inline-block;
    }
  }
}

.klarna-overlay {
  .klarna-information {
    padding: 20px;
    &__header {
      background: url('#{$base-theme-path}svg-icons/src/icon--klarna.svg') no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 65px;
      margin-bottom: 25px;
      text-indent: -999em;
      width: 120px;
    }
    &__list {
      counter-reset: counter;
      margin: 25px;
    }
    &__list-item {
      align-items: baseline;
      counter-increment: counter;
      display: flex;
      margin-bottom: 10px;
      &::before {
        content: counter(counter) '.';
        flex-basis: 19px;
        font-size: get-rem(24px);
        padding-#{$rdirection}: 10px;
      }
    }
    &__sub-text {
      @include checkout-text--medium;
      letter-spacing: 0;
      .klarna-information__link {
        @include checkout-text--medium-bold;
      }
    }
  }
}
