.tout-bordered-text {
  font-family: $font--futura-book;
  font-size: 15px;
  margin: 0 auto;
  max-width: 1070px;
  padding: 30px 32px;
  text-align: center;
  text-decoration: none;
  @include breakpoint($landscape-up) {
    font-size: 30px;
    padding: 50px 0;
  }
  &__title {
    font-family: $font--futura-book;
    font-size: 32px;
    padding-bottom: 25px;
    text-transform: uppercase;
    @include breakpoint($landscape-up) {
      font-size: 60px;
      padding-bottom: 40px;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 32px;
      @include breakpoint($landscape-up) {
        font-size: 60px;
      }
    }
  }
  &__subhead {
    background: $color-white;
    color: $color-black;
    font-size: 28px;
    display: inline-block;
    padding: 0 10px;
    position: relative;
    text-transform: uppercase;
    top: -0.5em;
    @include breakpoint($landscape-up) {
      font-size: 32px;
      top: -0.7em;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 28px;
      @include breakpoint($landscape-up) {
        font-size: 32px;
      }
    }
  }
  &__body {
    border: 2px solid $color-black;
  }
  &__body-copy {
    line-height: 1.3;
    margin-top: -1em;
    padding: 30px 25px;
    font-size: 18px;
    @include breakpoint($landscape-up) {
      padding: 1.8em 30px;
      font-size: 24px;
    }
    p {
      font-size: 18px;
      line-height: 1.3;
      margin: 0;
      padding: 0;
      @include breakpoint($landscape-up) {
        font-size: 24px;
      }
    }
  }
}
