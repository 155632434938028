// Styles transferred from the interstitial popup
// Template path: origins/themes/ca/template_api/interstitial_popup_ca_v1/interstitial_popup_ca_v1.mustache
#colorbox {
  .interstial-popup {
    padding: 0 20px;
    .popup-content {
      text-align: center;
      vertical-align: middle;
      margin-top: 35px;
      height: auto;
      width: auto;
      &.content-ca {
        border-top: 1px solid $color-light-gray;
        margin-top: 20px;
      }
      h2 {
        font-size: 36px;
        margin-bottom: 5px;
        letter-spacing: 0.05em;
        line-height: 0.9em;
        font-family: $font--subheading;
      }
      .popup_list {
        width: 100%;
        float: left;
        .list_1,
        .list_2,
        .list_3 {
          width: 25%;
          float: left;
          border-right: 1px solid $color-light-gray;
        }
        .list_4 {
          width: 25%;
          float: left;
        }
        p.content {
          font-size: 15px;
        }
      }
      .email_shop {
        font-size: 16px;
        margin-top: 10px;
        text-align: center;
        a {
          text-decoration: underline;
          font-family: $font--cta;
          font-size: 14px;
        }
      }
      .email_shopnow {
        font-size: 16px;
        margin-top: 20px;
        a {
          text-decoration: underline;
          font-size: 14px;
          font-family: $font--cta;
        }
      }
      a.button {
        width: 228px;
        font-size: 21px;
        line-height: 36px;
        margin-top: 10px;
      }
    }
  }
}
