// Country Chooser
.country-chooser {
  @include pie-clearfix;
  color: $color-white;
  font-size: 13px;
  &__flag {
    vertical-align: top;
    position: relative;
    top: 0px;
    font-size: 10px;
  }
}

// Common Classes
.locale-select,
.country-select,
.language-select {
  vertical-align: top;
  display: inline-block;
}

.locale-select,
.language-select {
  @include pie-clearfix;
  &__link {
    @include link($color-gray, $color-white);
    &:hover {
      text-decoration: none;
    }
  }
  &__link--selected {
    color: $color-white;
    &:hover {
      text-decoration: none;
      cursor: default;
    }
  }
}

// Locale Chooser
.locale-select {
  margin: 15px auto 10px auto;
  &__item {
    float: left;
  }
}

// Country Chooser
.country-select {
  position: relative;
  &__menu {
    display: none;
  }
  &__selectbox {
    margin-top: 2px;
    padding: 13px 3px 10px 7px;
    font-family: $font--cta;
    font-size: 14px;
    line-height: 1;
    max-width: 200px;
    border: none;
    height: auto;
    text-align: left;
    background: none;
    &:focus {
      outline: none;
    }
  }
  & .selectBox-arrow {
    display: none;
  }
  &__selectbox-selectBox-dropdown-menu {
    min-width: 150px;
    max-height: 180px;
    position: absolute;
    background: $color-black;
    color: $color-white;
    border: 1px solid $color-black;
    &:focus {
      outline: none;
    }
  }
  &__selectbox-selectBox-dropdown-menu li {
    background: $color-black;
    display: block;
    &:hover {
      cursor: pointer;
    }
    &.selectBox-selected {
      background: $color-dark-gray;
    }
    a {
      @include transition(background, 0.2s, ease-in-out);
      font-family: $font--cta;
      display: block;
      font-size: 14px;
      padding: 10px;
      border-bottom: 1px solid $color-dark-gray;
    }
    &:last-child a {
      border-bottom: none;
    }
    a:hover {
      background: $color-dark-gray;
      text-decoration: none;
    }
  }
}

// Language Chooser
.language-select {
  &__item {
    display: inline-block;
    padding: 2px 10px;
    margin: 13px -8px 10px auto;
    border-left: 1px solid $color-gray;
    &:first-child {
      border-left: none;
      @include breakpoint($medium-up) {
        border-left: 1px solid $color-gray;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
