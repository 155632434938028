.social-media-links {
  display: table;
  .footer-formatter-primary & {
    @include breakpoint($landscape-up) {
      clear: both;
      display: inline-block;
      max-width: 210px;
    }
  }
  &__mobile-title {
    display: table-cell;
    padding-right: 15px;
    @include breakpoint($landscape-up) {
      display: none;
      padding: 0;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    width: 210px;
  }
  &__url {
    color: $color-white;
    margin-#{$rdirection}: 15px;
    @include breakpoint($landscape-up) {
      margin-#{$rdirection}: 18px;
    }
    &:last-child {
      margin-#{$rdirection}: 0;
    }
  }
  svg.icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    fill: $color-white;
  }
}

.weibo-section {
  display: inline;
  vertical-align: middle;
  margin-right: 12px;
}

.wechat-section {
  display: inline-block;
  vertical-align: middle;
  #wechat-wrapper {
    #footer-wechat-qrcode {
      display: none;
      z-index: 999;
      position: absolute;
      background-color: $color-white;
      box-shadow: 0 0 3px $color-mid-gray;
      right: -30px;
      margin-top: 10px;
    }
  }
}
