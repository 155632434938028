@include breakpoint($medium-down) {
  #live_chat {
    #chat_unavailable p:nth-child(2),
    #chat_ended p:nth-child(2) {
      text-align: center;
      font-size: 16px !important;
      color: $color-darker-gray;
      margin-top: 5px;
      font-family: $font--text;
    }
    #chat_window .server span.by,
    .client span.by {
      font-weight: normal;
      display: none;
    }
    #chat_unavailable h3.pg_head,
    #chat_ended h3.pg_head {
      font-size: 17px;
      font-family: $font--futura-medium;
      margin-bottom: 10px;
      border-bottom: 0px !important;
      text-transform: none;
      color: $color-black;
    }
    .mobile_live_chat_required,
    #contact_form_fields > label {
      font-family: $font--futura-medium;
      color: $color-black;
    }
    .chat_email,
    .chat_phone {
      border-bottom: 2px solid $color-light-gray;
    }
    .chat_email a,
    .chat_phone a {
      text-decoration: none;
    }
    #live_chat_section {
      padding: 15px;
      font-family: $font--cta;
      #content {
        .chat_product_queries p,
        .chat_product_queries a,
        .chat_technical_queries p {
          margin: 0 0 0.4em;
        }
        .chat_technical_queries {
          padding: 15px 0 0px;
          text-align: center;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.5;
          transition: color, 200ms;
          color: $color-darker-gray;
          cursor: pointer;
          text-decoration: underline;
          text-decoration: none;
          font-family: $font--text;
        }
        .chat_button {
          width: 100%;
          text-align: center;
        }
        a.chat_email {
          text-decoration: underline;
        }
        .chat_continue_shopping {
          background-color: $color-white;
          padding-top: 20px;
          text-align: center;
        }
      }
      p {
        font-size: 14px;
      }
    }
    #live_chat_form {
      padding: 20px;
      font-family: $font--cta;
    }
    #live_chat_header p {
      text-align: center;
      margin: 0;
      font-size: 14px;
      line-height: 1.3;
      font-family: $font--text;
      letter-spacing: 1px;
    }
    #live_chat_header .sub_header {
      text-align: center;
      margin: 0;
      font-size: 16px;
      line-height: 1.3;
      font-family: $font--text;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }
    #chat_customer_landing {
      padding: 15px 20px;
      font-family: $font--cta;
      background: $color-white;
      #wrapper #content #contact_content .form-item label {
        width: 100%;
        float: left;
      }
      #wrapper #content #contact_content p {
        font-size: 13px;
      }
      .chat_buttons {
        padding: 20px 0px 0 0px;
        position: relative;
        width: 100%;
        float: left;
      }
    }
    #content h2 {
      border: none;
      margin-bottom: 5px;
      text-transform: initial;
      font-size: 19px;
      color: $color-black;
      text-align: center;
      font-weight: bold;
      font-family: $font--heading;
    }
    #content h3.pg_head {
      margin-bottom: 0px;
      border-bottom: 2px solid $color-black;
      text-transform: uppercase;
    }
    #content h3.pg_head.sorry_head {
      text-transform: initial;
      text-align: center;
      font-family: $font--heading;
    }
    #content a.chat_link {
      width: 100%;
      display: inline-block;
      font-size: 20px;
      float: none;
      cursor: pointer;
      padding-top: 0px;
      padding-bottom: 0px;
      letter-spacing: 1px;
    }
    #contact_form_fields {
      display: block;
      label {
        color: $color-black;
        display: block;
        margin: 13px 0 5px;
      }
      input[type='text'] {
        height: 50px;
        margin-top: 14px;
        width: 100%;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        margin: 0 0 20px;
        background-position-y: 12px;
        :nth-child(3) {
          height: 80px;
          padding-bottom: 40px;
          margin-bottom: 5px;
        }
      }
      select {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        width: 100%;
        height: 50px;
        margin: 0 0 20px;
        background-position-y: 12px;
        font-family: $font--text;
        text-transform: capitalize;
      }
    }
    #contact_content {
      padding-left: 0px;
      padding-right: 0px;
      p.subcontent {
        color: #737373;
        margin-top: 15px;
        margin-bottom: 10px;
        font-family: $font--futura-medium;
      }
      p.chat_special {
        color: #888888;
        margin-top: -2px;
      }
      p {
        font-size: 14px;
      }
    }
    #chat_unavailable {
      display: none;
      padding: 10px 0px 20px;
      p {
        font-size: 14px;
        color: $color-black;
        margin-bottom: 15px;
        line-height: 20px;
        font-family: $font--cta;
        font-weight: normal;
      }
      p:nth-child(4) {
        padding-bottom: 0px;
        margin-bottom: 0px;
      }
      a {
        border-bottom: 2px solid $color-light-gray;
        text-decoration: none;
      }
      .back {
        text-transform: uppercase;
        border-bottom: none;
        font-size: 13px;
        margin-top: 10px;
        float: left;
      }
      div.mobile_livechat_freehelp {
        font-size: 13px;
        color: #737373;
        a {
          color: #9fa617;
          text-decoration: none;
        }
      }
    }
    #chat_ended {
      display: none;
      line-height: 28px;
      margin-top: 30px;
      p {
        font-size: 14px;
        color: $color-black;
        line-height: 20px;
        font-family: $font--cta;
        font-weight: normal;
      }
    }
    #chat_window {
      border: 1px solid #bbbbbb;
      height: 230px;
      overflow: auto;
      margin-bottom: 5px;
      padding: 10px 0;
      font-size: 15px;
      span.text a {
        color: $color-black;
        word-break: break-word;
      }
    }
    .chat_buttons input#chat_input {
      display: inline;
      float: left;
      width: 100% !important;
      border: 1px solid #bbbbbb;
      height: 50px;
      margin-top: 0px;
      border-radius: 0;
      -webkit-border-radius: 0px;
      padding: 15px;
    }
    .chat_buttons input[type='image'] {
      float: right;
      margin-top: 2px;
    }
    #cust_serv_content h3#Live_Chat {
      margin-top: 4px;
    }
    #chat_container {
      position: relative;
    }
    .end_chat_win {
      width: 5%;
      height: 30px;
      position: absolute;
      top: 2px;
      right: 10px;
      input#end_chat {
        float: right !important;
        margin: 10px;
      }
    }
    .end_chat_bt {
      // font-family:$font--subheading;
      @include font--subheading;
      float: left;
      width: 100%;
      text-transform: uppercase;
      text-decoration: none !important;
    }
    .end_chat {
      width: 100%;
      background-color: $color-dark-gray;
      line-height: 36px;
      color: $color-white;
      text-transform: uppercase;
      font-size: 20px;
      letter-spacing: 0.05em;
      height: 50px;
    }
    .end_chat_live {
      width: 100%;
      float: left;
      margin-top: 10px;
    }
    #contact_content.prechat_survey .livechat_buttons {
      margin: 10px 0;
      display: inline-block;
      width: 100%;
      .chat_button {
        float: left;
        width: 100%;
        button {
          width: 100%;
          font-size: 20px;
          padding: 0px;
        }
      }
    }
    div.server {
      padding-bottom: 0px;
      border: 1px solid #dbdcdd;
      color: $color-darker-gray;
      font-size: 14px;
      font-weight: normal;
      line-height: 18px;
      margin-bottom: 15px;
      margin-left: 22px;
      margin-right: 20px;
      padding-left: 15px;
      .text {
        background-image: url('/media/customer_service/live_chat/chat_server_ballon.png');
        background-position: left 15px;
        background-repeat: no-repeat;
        display: inline-block;
        margin-left: -23px;
        padding: 13px 14px 14px 22px;
        width: auto;
        word-break: break-all;
        > span {
          color: $color-black !important;
          > a {
            text-decoration: underline;
          }
        }
      }
    }
    div.client {
      padding-bottom: 15px;
      background-image: url('/media/customer_service/live_chat/chat_client_ballon.png');
      background-position: right 15px;
      background-repeat: no-repeat;
      color: $color-white;
      font-size: 14px;
      font-weight: normal;
      line-height: 18px;
      margin-bottom: 0px;
      margin-left: 20px;
      margin-right: 15px;
      padding-right: 8px;
      .text {
        background-color: #808080;
        background-position: right 15px;
        background-repeat: no-repeat;
        display: inline-block;
        padding: 14px;
        width: 100%;
        word-break: break-all;
        > span {
          color: $color-white !important;
          > a {
            text-decoration: underline;
          }
        }
      }
    }
    .cart-dialog {
      background: none repeat scroll 0 0 $color-white;
      border: 3px solid $color-black;
      color: $color-white;
      font-size: 12px;
      line-height: 20px;
      padding: 2px;
      position: absolute;
      text-align: center;
      width: 270px;
      z-index: 1000;
      p {
        margin-bottom: 16px;
        color: $color-black;
        font-size: 16px;
      }
      img {
        border-radius: 4px 4px 4px 4px;
      }
      .block-button {
        display: block;
      }
      .checkout-btn {
        float: right;
        margin: 0;
      }
      .continue-button {
        display: block;
        float: left;
        cursor: pointer;
      }
      .popup-buttons {
        width: 92%;
        margin: 0 auto;
      }
      .inner-popup {
        border: 1px solid $color-black;
        width: 100%;
        padding: 25px 5px;
        text-align: center;
        display: inline-block;
      }
    }
    .overlay {
      background: none repeat scroll 0 0 $color-black;
      height: 100%;
      left: 0;
      opacity: 0.5;
      position: absolute;
      text-align: center;
      top: 0;
      visibility: visible;
      width: 100%;
      z-index: 1000;
    }
    .bb-cart-dialog {
      background: none repeat scroll 0 0 $color-white;
      border: 5px solid $color-white;
      color: $color-mid-gray;
      margin-bottom: 15px;
      .bb-dialog-close {
        cursor: pointer;
        float: right;
        padding-bottom: 50px;
        padding-left: 30px;
      }
      .header {
        color: $color-black;
        font-size: 14px;
        font-family: $font--futura-medium;
        text-transform: uppercase;
      }
    }
    .bb-checkout-btn {
      margin: 5px 0 0;
    }
    .dialog-btn {
      margin: 15px 0 0;
    }
    .chat_select {
      width: 288px;
      text-transform: capitalize;
    }
    .chat_radio {
      padding: 0;
      width: auto;
      margin-top: 13px;
      margin-right: 5px;
      float: left;
      opacity: 1;
      height: 100%;
    }
    .email_form label {
      width: 90%;
      text-align: left;
      margin-left: 35px;
    }
    .email_form .label {
      text-transform: none;
    }
    .email_form .label::first-letter {
      text-transform: uppercase;
    }
    img.live_chat_icon {
      float: left;
      position: relative;
      top: 11px;
      left: 7px;
    }
    #live_chat_txt {
      padding-left: 30px;
    }
    #live_chat_content {
      margin: 0px;
      color: $color-black;
      font-weight: normal;
      background: $color-white;
      float: left;
      width: 100%;
    }
    img.live_person {
      float: right;
      padding-top: 2px;
    }
    #agent_login_type {
      height: 20px;
      float: left;
      width: 100%;
      display: none;
    }
    .agent_typing {
      display: none;
      color: $color-black;
      clear: left;
      margin-bottom: 15px;
      font-size: 12px;
    }
    .mobile_live_chat_button {
      margin: 20px 0 20px;
      width: 100%;
      float: left;
    }
    .promo-message {
      display: none;
    }
    .req_field {
      font-size: 13px;
    }
    .link-overlay-header {
      width: 100%;
      height: 100px;
      position: absolute;
      z-index: 1051;
    }
    .link-overlay-footer {
      width: 100%;
      height: 545px;
      position: absolute;
      z-index: 1000;
    }
    .email_transcript_description {
      text-align: center;
      margin: 0;
      font-size: 14px;
      font-weight: normal !important;
      line-height: 1.3;
      font-family: $font--text;
      letter-spacing: 1px;
    }
    .req_description {
      font-size: 12px;
      font-family: $font--futura-medium;
    }
    #chat_submit {
      width: 100%;
      margin-top: 20px;
      background: none;
      color: $color-black;
      border: 1px solid $color-darker-gray;
      box-shadow: none;
      padding: 0px;
      font-size: 20px;
      margin-bottom: 10px;
      height: 50px;
    }
    #footer {
      border-top: 1px solid $color-darker-gray;
    }
  }
}
