// tout_til_wide_v1
.tout-tile-wide {
  width: 100%;
  max-width: 1280px;
  text-align: center;
  position: relative;
  color: $color-white;
  margin: 0 auto;
  margin-bottom: 10px;
  &.full-width-tout {
    max-width: 100%;
  }
  &.border-tout::before {
    content: '';
    border: 3px solid $color-white;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    opacity: 0.5;
    @include breakpoint($medium-up) {
      border: 5px solid $color-white;
    }
  }
  &__button {
    border: 2px solid $color-white;
    font-family: $font--heading--tertiary;
    font-size: 17px;
    text-transform: uppercase;
    margin: 0 auto;
    position: relative;
    display: inline-block;
    overflow: hidden;
    .color-black & {
      border-color: $color-black;
    }
    .color-white & {
      border-color: $color-white;
    }
  }
  &-button p {
    margin-top: 10px;
  }
  &__headline {
    font-family: $font--heading--tertiary;
    font-size: 33px;
    text-transform: uppercase;
    line-height: 1;
    p {
      font-family: $font--heading--tertiary--regular;
    }
    @include breakpoint($medium-up) {
      font-size: 60px;
    }
    .font-size-small & {
      font-size: 23px;
      @include breakpoint($medium-up) {
        font-size: 33px;
      }
    }
    .image-icon {
      width: 18px;
      margin-left: 5px;
      @include breakpoint($medium-up) {
        width: 25px;
      }
    }
  }
  &__inner-container {
    padding: 33px 0;
    max-width: 90%;
    margin: 0 auto;
    .font-size-small & {
      padding: 23px 0;
      @include breakpoint($medium-up) {
        padding: 33px 0;
      }
    }
    @include breakpoint($medium-up) {
      max-width: 60%;
    }
    .image-tout & {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &.text-center {
      width: 100%;
    }
    &.text-left {
      @include breakpoint($medium-up) {
        max-width: 40%;
        right: 55%;
      }
    }
    &.text-right {
      @include breakpoint($medium-up) {
        max-width: 40%;
        left: 55%;
      }
    }
  }
  &__text-container {
    .image-tout & {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .text-vertical-top & {
      .image-tout & {
        @include breakpoint($small-down) {
          position: relative;
          top: 40px;
        }
      }
    }
    &.color-black {
      color: $color-black;
    }
    &.color-white {
      color: $color-white;
    }
    &.mobile-hidden {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }

  // full link
  a.tout-tile-wide__full-link {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    font-size: 0;
  }
  a.tout-tile-wide__link {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    line-height: 20px;
    padding: 6px 20px;
    float: left;
    display: block;
    width: auto;
    -webkit-font-smoothing: antialiased;
    @include breakpoint($medium-up) {
      padding: 8px 30px;
    }
    .color-black & {
      color: $color-black;
    }
    .color-white & {
      color: $color-white;
    }
  }
}

.multi-masking-grid + .tout-tile-wide:not(.tout-tile-wide-pro-tip):not(.font-size-small) {
  margin-bottom: 0;
  @include breakpoint($medium-up) {
    margin-bottom: 10px;
  }
  @include breakpoint($medium-down) {
    &::before {
      content: '';
      display: block;
      min-height: 10px;
      background-color: $color-white;
    }
  }
}

.multi-masking-grid + .tout-tile-wide:not(.tout-tile-wide-pro-tip)::before {
  @include breakpoint($medium-down) {
    &::before {
      content: '';
      display: block;
      min-height: 10px;
      background-color: none;
    }
  }
}

.tout-tile-wide-pro-tip {
  margin-bottom: 0;
  @include breakpoint($medium-up) {
    margin-bottom: 10px;
  }
  .messages-container {
    padding: 0 10px;
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
}
