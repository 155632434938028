/////////////////////////////////
/// GENERIC/SHARED inline video styles
/// relates to templates using our inline video lbrary
/// ~/themes/or_base/js/site/yt_video_inline.js
/////////////////////////////////

.inline-video {
  &__content {
    background: url('/media/export/cms/video_module/video-frame-transparent.png') center center no-repeat;
    background-size: contain;
    margin: 0 auto;
    max-width: 460px;
    position: relative;
    @include breakpoint($medium-up) {
      max-width: 560px;
    }
    @include breakpoint($landscape-up) {
      max-width: 100%;
    }
  }
  &__screenshot {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: z('video-module', 'video-screenshot');
    margin: 3% 5% 8% 3%;
  }
  &__play-button {
    // Play Icon Circle Background
    @include vertical-horizontal-center;
    position: absolute;
    z-index: z('video-module', 'video-screenshot');
    border-radius: 50%;
    background: rgba($color-black, 0.8);
    width: 74px;
    height: 74px;
    @include breakpoint($landscape-up) {
      width: 104px;
      height: 104px;
    }
    &:before {
      @include vertical-horizontal-center;
      position: absolute;
      content: '';
      z-index: z('video-module', 'video-screenshot');
      border-radius: 50%;
      border: 1px solid rgba($color-black, 0.8);
      width: 79px;
      height: 79px;
      @include breakpoint($landscape-up) {
        width: 109px;
        height: 109px;
      }
    }
    .icon {
      @include vertical-horizontal-center;
      position: absolute;
      color: $color-white;
      z-index: z('video-module', 'video-screenshot');
      font-size: 20px;
      @include breakpoint($landscape-up) {
        font-size: 30px;
      }
    }
  }
  &__video-player {
    height: 0;
    padding-bottom: 69.1%;
    padding-top: 25px;
    position: relative;
  }
  iframe {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: z('video-module', 'video-player');
    padding: 3% 5% 8% 3%;
  }
}

/////////////////////////////////
/// template specific styles
/// ~//themes/or_base/template_api/editorial/video_module_v1/video_module.mustache
/////////////////////////////////

.video-module {
  text-align: center;
  @include breakpoint($medium-up) {
    max-width: 1280px;
    margin: 0 auto;
  }
  &__title-block {
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid $color-medium-gray;
  }
  &__header {
    font-family: $font--text;
    font-style: italic;
    font-size: 18px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    @include breakpoint($medium-up) {
      font-size: 23px;
    }
  }
  &__sub-header {
    font-family: $font--heading;
    font-size: 20px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    @include breakpoint($medium-up) {
      font-size: 26px;
    }
  }
  &__video-block {
    @include clearfix;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 20px 0;
    @include breakpoint($medium-up) {
      padding: 50px;
    }
  }
  &__video-container {
    display: block;
    position: relative;
    margin: 0 5px 0 10px;
    @include breakpoint($landscape-up) {
      margin: 0 auto;
      max-width: 615px;
      // width: 48.04688%;
    }
  }
  &__video-text {
    padding: 10px 20px 0 20px;
    text-align: center;
  }
  &__video-title {
    font-family: $font--text;
    font-style: italic;
    font-size: 26px;
    font-weight: 400;
    padding: 0;
    margin: 0 0 10px 0;
    @include breakpoint($medium-up) {
      font-size: 34px;
    }
  }
  &__video-description {
    max-width: 500px;
    margin: 0 auto 20px auto;
    font-family: $font--text;
    text-align: center;
  }
  &__video-links {
    text-align: center;
  }
  &__link {
    @include link;
    text-decoration: none;
    padding: 10px 30px 10px 10px;
    display: inline-block;
    @include breakpoint($medium-up) {
      padding: 10px 50px 10px 30px;
    }
  }
  &__link-1:after,
  &__link-2:after {
    font-family: 'icons';
    position: absolute;
    margin-top: 2px;
    margin-left: 10px;
  }
  &__link-1:after {
    content: '\E002';
    font-size: 11px;
  }
  &__link-2:after {
    content: '\E003';
    font-size: 9px;
  }
  &.plain_player {
    .inline-video {
      &__content {
        background: none;
        max-width: 100%;
      }
      &__screenshot {
        width: 100%;
        height: 100%;
        margin: 0;
      }
      &__video-player {
        padding-bottom: 93.33%;
        @include breakpoint($medium-up) {
          padding-bottom: 54.4%;
        }
      }
      &__play-button:before {
        border: 0px;
      }
      iframe {
        padding: 0;
      }
    }
    .video-module {
      &__video-block {
        padding: 0;
        @include breakpoint($small-down) {
          overflow: visible;
        }
      }
      &__video-container {
        max-width: 100%;
        margin: 0;
      }
    }
  }
  &.plain_player .inline-video .custom-outline {
    border: 1px dotted #2a6496 !important;
  }
}
