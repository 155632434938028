/**
 * Search results
 */

.search_results {
  @if $cr18 == true {
    min-height: 770px;
  }
  &__header {
    max-width: $max-width-large;
    margin: 0 auto;
  }
  .loading {
    text-align: center;
    padding: 12px 0;
  }
  /* Wrapper */
  &__container {
    @include pie-clearfix;
    border-top: solid 1px $color-dark-gray;
    max-width: $max-width-large;
    margin: 0 auto;
    .close {
      display: inline-block;
      padding-left: 4px;
    }
  }
  /* Dimension filters */
  &__filter_header {
    @include font--subheading;
    font-size: 20px;
    margin: 0 0 20px;
    padding: 12px 0 10px;
    border-bottom: 1px solid $color-black;
    cursor: pointer;
    &::after {
      content: ' +';
    }
    &.collapse {
      &::after {
        content: ' -';
      }
    }
  }
  &__breadcrumbs {
    margin-bottom: 12px;
  }
  &__dimensions {
    @include breakpoint($small-down) {
      text-align: center;
    }
  }
  &__breadcrumbs,
  &__dimensions {
    dt {
      @include font--subheading;
      margin: 0;
      padding-bottom: 8px;
      cursor: pointer;
      font-size: 14px;
      letter-spacing: 1.66px;
      font-family: $font--futura-demi;
    }
    dd {
      padding: 4px 0;
      font-size: 16px;
    }
  }
  /* Summary header */
  &__summary {
    display: none; /* This layer is being replaced with an additional typeahead box. Instead of removing, I'm hiding. */
    text-align: center;
    font-size: 30px;
    padding: 12px 0;
  }
  /* Override the product ratings layer from product_brief */
  .product-brief {
    &__rating {
      @include breakpoint($medium-up) {
        display: inline-block;
        margin-left: 8px;
      }
      @include breakpoint($small-down) {
        float: none;
        width: auto;
      }
    }
    &__rating__count {
      top: auto;
    }
  }
  /* Left/Right column layout */
  .col {
    &--left {
      @include breakpoint($medium-up) {
        display: inline-block;
        float: left;
        width: 25%;
        margin-left: 30px;
        max-width: 250px;
      }
    }
    &--right {
      .results-header {
        display: none;
      }
      @include breakpoint($medium-up) {
        margin-left: 22%;
      }
    }
  }
  .quickshop-search-wrapper {
    .product-quickshop {
      @include breakpoint($small-down) {
        padding: 0;
      }
      .quickshop-arrow {
        display: none;
      }
      &__main {
        top: 0;
      }
    }
  }

  /*
   * Product Results - We're using very quickshop'ish layout, but it's not quite the same
   */
  .product-quickshop {
    background-color: transparent;
    border-width: 0 0 1px 0;

    // Give things a little bit more room on mobile devices
    @include breakpoint($small-down) {
      padding: 25px 15px;
      border-top: solid 1px $color-dark-gray;
    }
    &.last {
      border-bottom: 0;
    }
    &__product {
      width: auto;
      padding-left: 0;
      border-right: 0;
      @include breakpoint($medium-up) {
        display: block;
        padding-right: 25px;
      }
    }
    &__images {
      float: left;
      width: 42.336%;
      text-align: center;
      @include breakpoint($medium-up) {
        width: 250px;
      }
      img {
        margin: 0 auto;
        @include breakpoint($medium-up) {
          max-width: 200px;
        }
      }
    }
    &__info {
      display: block;
      float: right;
      width: 57.664%;
      border-right: 0;
      @include breakpoint($medium-up) {
        float: none;
        width: auto;
        margin-left: 250px;
        padding: 20px 0;
      }

      // override the default padding-top to make this a little bit tighter, but only for mobile
      .product-quickshop__footer {
        @include breakpoint($small-down) {
          padding-top: 0;
        }
      }
    }
    &__sku-detail {
      padding-bottom: 12px;
      @include breakpoint($small-down) {
        text-align: center;
      }
      &--size {
        @include font--cta;
        font-size: 13px;

        // This fixes an alignment issue with the stars
        position: relative;
        top: -3px;
      }
      &--item {
        display: block;
        @include breakpoint($medium-up) {
          display: inline-block;
        }
      }
    }
    &__footer__add-to-bag {
      .product-add-to-bag {
        @include breakpoint($small-down) {
          width: auto;
        }
      }
    }
    &__sku-select {
      padding-top: 20px;
      border-top: solid 1px $color-light-gray;
    }
    .product-heading {
      &__name,
      &__subheading {
        a:hover {
          text-decoration: none;
        }
      }
      &__name {
        font-size: 14px;
        @if $cr18 == true {
          color: $color-cr18-black;
        }
      }
      &__subheading {
        font-size: 24px;
        margin-bottom: 8px;
      }
    }
    .button {
      &--medium {
        @include breakpoint($small-down) {
          width: 100%;
          min-width: 0;
        }
      }
    }
  }
  /* We may need sorting one day, but it's not currently  in the designs */
  .product-sort-container {
    width: auto;
    display: inline-block;
    text-align: right;
    float: right;
    position: relative;
    height: 27px;
    .product-sort-header {
      margin-right: 10px;
      display: inline-block;
      margin-top: 10px;
    }
    .search_results {
      &-set__arrow {
        display: table-cell;
        float: right;
        position: absolute;
        right: 28px;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
        i {
          @include bordered-triangle(12px, $color-white, down, $color-cr18-black);
          display: block;
          &:before,
          &:after {
            left: 10px;
            top: 5px;
          }
        }
      }
    }
    select.product-sort {
      background: none;
      height: 27px;
      min-width: auto;
      float: right;
      line-height: 1.2;
      text-transform: inherit;
      padding-left: 5px;
      padding-right: 30px;
      margin-right: 10px;
      margin-bottom: -11px;
      border: 0;
      @include breakpoint($medium-up) {
        background-repeat: no-repeat;
        background-position: 100% 50%;
        background-image: url('#{$base-theme-path}img/select-box/down-arrow.svg');
      }
    }
    @include breakpoint($medium-up) {
      width: 40%;
      float: right;
      min-height: 76px;
      padding-top: 22px;
      text-align: right;
      select.product-sort {
        min-width: auto;
        border: 1px solid $color-mid-gray;
        border-radius: 0;
        height: 45px;
      }
    }
  }
  /* Random global overrides */
  dd {
    margin-left: 0;
  }
  .product-sku-price__value {
    font-size: 24px;
  }
  .gb-elc__search-restrict-container {
    .gb-product-card__content {
      gb-elc-add-to-cart {
        button:hover {
          background: $color-green;
        }
      }
      &--title,
      &--title-description,
      &--benefit,
      &--price,
      &--product-details {
        &:hover {
          color: $color-green;
        }
      }
    }
  }
}

.no-result__best-sellers {
  .tabbed-products-block__tabs {
    margin-top: 0;
  }
}

.top-banner__wrapper {
  display: none;
}
/* Show Live Chat for Zero Search Result */
.live-chat {
  background: $color-green;
  text-align: center;
  min-width: 300px;
  padding: 10px 0;
  cursor: pointer;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  &__link {
    padding: 10px 0 1px;
    text-decoration: none;
  }
  &__headline {
    font-weight: normal;
    text-transform: uppercase;
    font-family: $font--subheading;
    margin: 0 0 2px;
  }
  &__icon {
    top: 1px;
    margin-right: 3px;
    font-size: 15px;
    top: -1px;
    position: relative;
    vertical-align: middle;
    &.icon-livechat {
      background: url('/media/customer_service/live_chat/chat.jpeg');
      background-repeat: no-repeat;
      display: inline-block;
      height: 21px;
      width: 25px;
    }
  }
  &__copy {
    font-size: 14px;
    letter-spacing: 0.3em;
    line-height: 20.8px;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: $font--cta;
    text-transform: uppercase;
    span {
      border-bottom: 1px solid $color-white;
    }
  }
}
/* Banner for Zero Result */
.search_banner {
  @include content-contain;
  &__content {
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 35px;
    padding: 17px 0 5px;
    margin: 0;
    color: $color-white;
  }
}
/* CTA for Zero Result */
.esearch-zero-result-page {
  text-align: center;
  margin-top: 28px;
  @include breakpoint($small-down) {
    margin-top: 0;
  }
  @include content-contain;
  .search_banner {
    width: 48%;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    height: 176px;
    @include breakpoint($small-down) {
      width: 100%;
      float: none;
      margin-bottom: 20px;
      border-right: none;
      display: table;
    }
    &__content {
      text-transform: none;
      @include breakpoint($small-down) {
        display: table-cell;
        vertical-align: middle;
        padding-top: 10px;
      }
      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
    .live-chat {
      background: $color-green;
      min-width: 0;
      &__copy {
        font-family: $font--text--italic;
        font-size: 27px;
        text-transform: none;
        padding-top: 6px;
        @include breakpoint($small-down) {
          font-size: 22px;
        }
        span {
          font-family: $font--text;
          border-bottom: none;
        }
      }
      &__headline {
        padding-top: 18px;
        @include breakpoint($small-down) {
          font-size: 28px;
        }
      }
      &__start-chat {
        margin-bottom: 14px;
        text-transform: uppercase;
        font-size: 22px;
        text-decoration: underline;
        font-family: $font--futura-book;
        @include breakpoint($small-down) {
          font-size: 18px;
        }
      }
      &__icon {
        background: url('/media/export/cms/esearch/live_chat_logo.png');
        background-repeat: no-repeat;
        height: 21px;
        width: 28px;
      }
    }
    &:first-child {
      margin-right: 0.8%;
    }
    &:nth-child(2) {
      margin-left: 0.8%;
      background: url('/media/export/cms/esearch/discover_regimen.jpg');
      margin-top: 17px;
      background-repeat: no-repeat;
      background-size: cover;
      .disocver-regimen-text {
        font-size: 51px;
        line-height: 43px;
        font-family: $font--heading--secondary;
        @include breakpoint($small-down) {
          font-size: 32px;
          line-height: 33px;
        }
        span {
          font-family: $font--heading--secondary--italic;
        }
      }
      .disocver-regimen-link {
        text-transform: uppercase;
        font-size: 22px;
        text-decoration: underline;
        margin-top: 15px;
        font-family: $font--futura-book;
        @include breakpoint($small-down) {
          font-size: 18px;
        }
      }
      @include breakpoint($small-down) {
        margin-left: 0;
      }
    }
    &__image {
      img {
        height: 100%;
      }
    }
  }
  &__best-seller {
    clear: both;
    margin-top: 65px;
    @include breakpoint($small-down) {
      margin-top: 20px;
    }
  }
  &__bs-dash {
    &:after {
      content: '';
      display: block;
      background: $color-black;
      position: relative;
      top: 36px;
      width: 13%;
      height: 1px;
      margin: 0 auto;
      display: none;
    }
  }
  &__bs-title {
    margin-top: 29px;
    font-size: 33px;
    text-transform: uppercase;
    font-family: $font--heading;
    @include breakpoint($small-down) {
      font-size: 18px;
      margin-top: 20px;
    }
  }
  &__bs-link {
    font-size: 19px;
    text-transform: uppercase;
    margin-top: 7px;
    text-decoration: underline;
    font-family: $font--futura-book;
    @include breakpoint($small-down) {
      font-size: 18px;
    }
  }
  &__suggestion {
    font-size: 21px;
    margin-top: 29px;
    @include breakpoint($small-down) {
      font-size: 13px;
    }
  }
  &__best-match {
    @include breakpoint($small-down) {
      font-size: 14px;
      padding: 0 5px;
    }
    span {
      font-family: $font--text--italic;
    }
  }
  &__no-items {
    @include breakpoint($small-down) {
      font-size: 18px;
    }
  }
}
/* Texts for Zero Search Results */
.search_results {
  &__summary {
    @include content-contain;
  }
  &__no-results {
    font-size: 18px;
  }
  em {
    font-family: $font--futura-medium;
    font-style: normal;
  }
  .no-results-recommend {
    .no-results-search-for {
      margin-top: 30px;
      em {
        font-style: italic;
      }
    }
  }
  /* Hide Author name and  content title  **/
  #top-content-banner,
  #zero-results {
    .contextual-links-region {
      h2 a,
      .submitted {
        display: none;
      }
    }
  }
  &__no-results {
    .no-results-recommend {
      margin-top: 14px;
      font-size: 20px;
      margin-left: 0;
    }
    .no-results-found {
      font-size: 33px;
      padding-right: 1%;
    }
  }
  /* Best Sellers */
  &__bestsellers {
    .product-quickshop {
      &__product {
        @include breakpoint($medium-up) {
          padding: 20px 50px;
          border-right: 1px solid $color-light-gray;
        }
        .product-heading {
          &__name {
            @include breakpoint($medium-up) {
              font-size: 16px;
            }
          }
          &__subheading {
            @include breakpoint($medium-up) {
              font-size: 34px;
            }
            margin-bottom: 16px;
          }
        }
      }
      &__sku-select {
        border-top: none;
        padding-top: 0;
      }
      &__footer {
        &__price {
          span {
            font-size: 30px;
          }
        }
      }
      @include breakpoint($small-down) {
        padding: 0;
        border: none;
      }
    }
    .product-brief {
      &__rating {
        @include breakpoint($small-down) {
          float: right;
          width: 57.664%;
        }
      }
      &__title {
        @include breakpoint($medium-up) {
          min-height: 20px;
        }
      }
      &__subtitle {
        @include breakpoint($medium-up) {
          min-height: 65px;
        }
      }
      &__concern {
        &.product-skin__type {
          display: none;
          &:nth-child(2) {
            display: block;
          }
        }
      }
    }
  }
  #top-content-banner {
    margin-top: 37px;
    margin-bottom: 26px;
    .search_banner {
      max-width: 100%;
      &__content {
        text-transform: none;
        font-size: 37px;
        padding: 0;
        padding-left: 0.5%;
        line-height: 60px;
        height: 73px;
        font-family: $font--heading--secondary;
        i {
          font-family: $font--heading--secondary--italic;
        }
        @include breakpoint($small-down) {
          font-size: 15px;
          line-height: 66px;
          @media (orientation: landscape) {
            font-size: 20px;
          }
        }
      }
    }
    @include breakpoint($small-down) {
      margin-bottom: 0;
    }
  }
}

@include breakpoint($medium-up) {
  .search_results__header {
    padding-top: 30px;
    .product-grid__content {
      min-height: 420px;
    }
  }
}

.at-h2-results {
  margin: 0;
  font-size: 1.5em;
  font-family: $font--futura-medium;
}

.no-result {
  .search_results {
    min-height: auto;
    &__summary {
      padding-bottom: 0;
      .live-chat__start-chat {
        margin-bottom: 0;
      }
    }
    span {
      font-size: 20px;
    }
    p {
      font-size: 20px;
    }
    em {
      font-size: 22px;
      font-family: $font--futura-demi;
    }
  }
}

.search_results__summary {
  padding-bottom: 0;
  .live-chat__start-chat {
    margin-bottom: 0;
  }
}

.enable-endeca__search {
  @include breakpoint($medium-up) {
    .product-grid__item {
      min-height: 550px;
    }
  }
  .search_results__summary {
    font-size: 20px;
  }
  .top-banner__wrapper {
    display: block;
    &-img {
      position: absolute;
      margin: 0;
    }
    .top-banner__inner-wrapper {
      position: relative;
      text-align: center;
      .top-banner__title {
        font-family: $font--futura-medium;
        letter-spacing: 1px;
        margin: 0 auto;
        padding: 20px 30px;
      }
      .top-banner__btn {
        border: 1px solid $color-black;
        padding: 3px 7px;
        font-size: 15px;
      }
    }
    .top-banner__img {
      height: 120px;
    }
  }
  .search_results__container {
    border-top: none;
    .col {
      &--left {
        @include breakpoint($medium-up) {
          float: left;
          width: 20%;
        }
      }
      &--right {
        @include breakpoint($medium-up) {
          width: 78%;
        }
        .results-header {
          display: block;
          @include breakpoint($medium-up) {
            .results-summary {
              width: 60%;
              float: left;
            }
          }
          .results-summary__head {
            text-align: center;
            font-weight: normal;
            margin: 0 auto;
            padding: 20px 30px;
            @include breakpoint($medium-up) {
              text-align: inherit;
            }
          }
        }
        .results {
          .product-brief {
            padding: 15px 25px;
            @include breakpoint($medium-up) {
              width: 95%;
              padding: 0;
              display: inline-block;
              margin: 0 10px 0;
              position: relative;
            }
            &__detail {
              margin-bottom: 25px;
              @include breakpoint($medium-up) {
                margin-bottom: 5px;
              }
            }
            &__description {
              @include breakpoint($medium-up) {
                width: 100%;
              }
            }
            &__subtitle {
              a {
                font-family: $font--futura-book;
              }
            }
            &__benefit {
              @include breakpoint($medium-up) {
                span {
                  font-family: $font--futura-demi;
                }
              }
            }
            &__sized-detail {
              @include breakpoint($medium-up) {
                min-height: 41px;
                align-items: center;
                margin: 0 auto;
              }
            }
            &__sizes-wrapper {
              display: inline-block;
              margin-top: 10px;
              margin-bottom: 10px;
              z-index: 0;
            }
            &__cta-section {
              @include breakpoint($medium-up) {
                position: static;
                margin: 0;
              }
            }
            &__cta-spp-link {
              vertical-align: top;
            }
          }
        }
      }
    }
    .pagination-container {
      border: none;
    }
  }
  .search_results__filter_header {
    position: relative;
    font-size: 14px;
    font-family: $font--futura-demi;
    letter-spacing: 1.66px;
    margin-right: 0;
    padding-top: 20px;
    padding-bottom: 10px;
    &::after {
      content: '\002B';
      position: absolute;
      right: 25px;
      font-size: 33px;
      top: 6px;
      font-weight: normal;
    }
    &.collapse::after {
      content: '\2012';
      top: 3px;
    }
  }
  .search_results__filter_clear {
    p {
      a.button {
        width: 35%;
        font-size: 15px;
        font-family: $font--futura-demi;
        height: 25px;
        border-color: transparent;
        border-style: solid;
        border-width: 1px;
        line-height: 25px;
        text-transform: uppercase;
        padding: 0px 6px;
      }
    }
  }
  .results {
    a {
      font-size: 15px;
      letter-spacing: 0.33px;
      line-height: 2.3;
      @include breakpoint($medium-up) {
        line-height: 1.3;
        &.sku-list-sizes__button {
          line-height: 30px;
        }
        &.product-brief__subtitle-link {
          font-family: $font--futura-demi;
          line-height: 1;
        }
      }
      &.product-brief__spp-link {
        line-height: 1;
      }
    }
    &.accordion-content {
      a {
        font-weight: 300;
        font-size: 16px;
        color: $color-dark-gray;
        &:hover {
          text-decoration: none;
        }
        &::before {
          content: ' ';
          border: 1px solid $color-black;
          display: inline-flex;
          margin-right: 8px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  &.device-mobile {
    .page-header.alt-nav-mob,
    .page-header__nav {
      .gnav-util--search-v2 {
        &.gnav-util--search {
          &.gnav-util {
            @if is_2020_nav_redesign() {
              max-width: none;
              width: 83%; // Magic number of what's left after the hamburger
            } @else {
              max-width: 215px;
              width: 55%;
            }
          }
        }
      }
      .utility-nav {
        &.utility-nav-v1 {
          @include breakpoint($xxsmall-portrait) {
            float: right;
            margin-left: 0;
          }
        }
      }
      .utility-nav__loyalty.loyalty_email_signup {
        display: none;
      }
    }
    .search_results {
      &_mobile__overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(0, 0, 0, 0.6);
        top: 0;
        z-index: 999;
      }
      &_mobile__close {
        width: 37px;
        height: 37px;
        background: $color-white;
        margin-left: 10px;
        padding-left: 9px;
      }
      &_mobile_inner {
        max-height: 600px;
        overflow-y: scroll;
        position: relative;
      }
      &_mobile__popup {
        position: fixed;
        top: 0px;
        background: rgb(255, 255, 255);
        height: 94.5%;
        width: 90%;
        z-index: 999;
        right: 0px;
        .search_results {
          &__dimensions {
            text-align: left;
            padding: 0 20px;
          }
          &__breadcrumbs {
            padding: 0 20px;
            .results {
              .close_x,
              a {
                display: inline;
              }
            }
          }
          &_set__footer {
            position: fixed;
            background: $color-white;
            bottom: 6%;
            width: 90%;
            padding: 5px;
            p {
              margin-bottom: 5px;
              a {
                display: block;
                width: auto;
                padding: 0;
                height: auto;
                margin: 0;
                line-height: 2.5;
              }
            }
          }
        }
      }
    }
    .search_results {
      &-set__arrow {
        display: table-cell;
        i {
          @include bordered-triangle(12px, $color-white, down, $color-cr18-black);
          display: block;
          &:before,
          &:after {
            left: 10px;
            top: 5px;
          }
        }
      }
      &-overlay__close {
        background: $color-light-gray;
        font-size: 20px;
        left: 8px;
        padding: 3px 7px;
        position: fixed;
        top: 30px;
        z-index: 17;
        margin: 0;
      }
      &-header-summary {
        text-align: center;
      }
      &-filters-text {
        padding: 0 15px;
      }
      &-overlay-nofilters {
        text-align: center;
        margin-top: 19%;
      }
    }
  }
}
