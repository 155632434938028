.store-locator {
  $standard-padding: 30px;
  $standard-padding-mobile: 15px;
  $search-panel-width-desktop: 30%;
  $tab-height: 42px;
  $store-brand-image-width: 75px;
  $store-brand-image-margin: 15px;
  $content-min-height: 600px;
  font-family: $font--text;
  @include breakpoint($medium-up) {
    position: relative; // set up a position context for the map canvas holder
  }
  &__content {
    @include breakpoint($medium-up) {
      width: $search-panel-width-desktop;
      min-height: $content-min-height;
      height: 100%;
      border-right: 1px solid $color-black;
    }
  }
  &__map-container {
    width: 100%;
    height: 350px;
    @include breakpoint($small-down) {
      display: none;
      .detail-view & {
        display: none;
      }
    }
    @include breakpoint($medium-up) {
      width: 100% - $search-panel-width-desktop;
      height: 100%;
      min-height: $content-min-height;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &__map-canvas {
    width: 100%;
    height: 100%;

    // fix for a squashed image issue, with the map controls
    // (see: http://stackoverflow.com/questions/9904379/google-map-zoom-controls-not-displaying-correctly)
    .gmnoprint img {
      max-width: none;
    }
  }
  &__hero {
    padding-top: ($standard-padding / 2);
    padding-bottom: ($standard-padding / 2);
  }
  &__hero,
  &__my-store {
    &-heading {
      @include font--subheading;
      margin: 0;
      @include breakpoint($small-down) {
        font-size: 1.5em;
      }
    }
  }
  &__body {
    @include breakpoint($small-down) {
      position: relative;
    }
  }
  &__main {
    padding-top: 115px;
    @include breakpoint($medium-up) {
      padding-top: 0;
    }
    @include breakpoint($small-down) {
      .detail-view & {
        padding-top: 0;
      }
    }
  }
  &__hero,
  &__error-messages,
  &__controls,
  &__sections,
  &__filter,
  &__stores,
  &__my-store {
    padding-left: $standard-padding-mobile;
    padding-right: $standard-padding-mobile;
    @include breakpoint($medium-up) {
      padding-left: $standard-padding;
      padding-right: $standard-padding;
    }
  }
  &__filter {
    @include breakpoint($small-down) {
      display: none !important;
    }
    @include font--subheading;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
    position: relative;
    // font-family: $font--subheading;
    text-transform: uppercase;
    font-size: 1.1em;
    @include breakpoint($medium-up) {
      padding-top: $standard-padding - 5px;
    }
    &-heading {
      margin: 0;
      font-size: 1.35em;
      margin-bottom: 10px;
      cursor: pointer;
      @include icon(plus, after) {
        font-size: 0.5em;
        margin-left: 5px;
        vertical-align: middle;
        padding-bottom: 2px;
      }
      &-count {
        position: absolute;
        top: $standard-padding;
        right: $standard-padding;
        color: $color-light-gray;
      }
    }
    &.expanded &-heading {
      @include icon(minus, after) {
        font-size: 0.5em;
        margin-left: 5px;
        vertical-align: middle;
        padding-bottom: 2px;
      }
    }
    &-items {
      @include clearfix;
      display: none;
    }
    &.expanded &-items {
      display: block;
    }
    &-item {
      float: left;
      border: 2px solid $color-light-gray;
      width: 40%;
      margin: 0 5% 5% 0;
      padding: 10px;
      text-align: center;
      cursor: pointer;
      &:nth-child(2n + 1) {
        // odd elements
        float: left;
        width: 48%;
        margin: 0 0 10px;
      }
      &:nth-child(2n + 0) {
        // even elements
        float: right;
        width: 48%;
        margin: 0 0 10px;
      }
      &.active {
        background-color: lighten($color-light-gray, 7.5%);
        border-color: $color-black;
      }
    }
  }
  &__controls {
    @include breakpoint($small-down) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      padding: $standard-padding-mobile;
      text-align: center;
      width: 100%;
      .detail-view & {
        display: none;
      }
    }
    @include breakpoint($medium-up) {
      border-bottom: 2px solid $color-dark-gray;
      padding-bottom: ($standard-padding / 2);
    }
    fieldset,
    form {
      border: none;
      margin: 0;
      padding: 0;
      width: 100%;
      overflow: visible;
    }
  }
  &__distance-container {
    padding: 10px 0;
    &.hide {
      display: none;
    }
  }
  &__country {
    &.neither {
      display: none;
    }
  }
  &__search {
    //font-family: $font--text !important;
    //text-transform: none !important;
    border-color: $color-dark-gray;
    text-transform: none !important;
    height: 30px;
    @include input-placeholder {
      text-transform: none !important;
      color: $color-mid-gray !important;
    }
    @include breakpoint($small-down) {
      width: 66%;
    }
    @include breakpoint($medium-up) {
      padding-top: 7px;
      line-height: 33px;
      vertical-align: top;
      margin-top: 1px;
      min-width: 65%;
    }
  }
  input[type="text"].search {
    height: 30px;
  }
  &__search-submit {
    min-width: 30%;
    position: relative;
    top: 2px;
    height: 30px;
    border: none;
    line-height: 1;
    @include breakpoint($portrait-down) {
      top: 10px;
    }
    @include breakpoint($small-down) {
      top: 1px;
    }
  }
  &__locate {
    font-family: $font--cta;
    font-size: 0.8em;
    text-transform: uppercase;
    margin-top: 10px;
    @include breakpoint($portrait-down) {
      margin-top: 20px;
    }
    &,
    & a {
      color: $color-black;
      text-transform: uppercase;
    }
  }
  &__alt-text {
    display: none;
  }
  &__error-messages {
    margin: ($standard-padding / 2) 0;
    display: none; // messages must be shown, hidden by default
    &.active {
      display: block;
    }
  }
  &__error-message {
    color: $color-red;
    &.inactive {
      // for individual messages (which are inactive by default)
      display: none;
    }
  }
  &__responsive-tab-container {
    position: relative;
    padding-bottom: $tab-height;
    height: 100%;
  }
  &__responsive-tabs {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $tab-height;
    list-style-type: none;
  }
  &__responsive-tab {
    cursor: pointer;
    height: $tab-height;
    padding: 10px $standard-padding;
    border: 1px solid gray;
    font-family: $font--cta;
    font-size: 0.8em;
    text-transform: uppercase;
    color: $color-dark-gray;
    @include breakpoint($medium-up) {
      width: 100%;
    }
    &.active {
      display: none;
    }
    &.results {
      display: none;
    }
    &.directions {
      display: none;
    }
  }
  &__responsive-tab-content {
    overflow: auto;
    > * {
      display: none;
    }
    .active {
      display: block;
    }
    .results {
      @include breakpoint($small-down) {
        display: block;
        .detail-view & {
          display: block;
        }
      }
    }
    .directions {
      display: none;
    }
  }
  &__sections {
    display: none;
  }
  &__stores {
    padding-top: 10px;
  }
  &__my-store {
    padding-top: $standard-padding;
  }
  &__results {
    width: 100%;
  }
  &__result {
    .detail-view & {
      display: none;
      &.active {
        display: block;
      }
    }
    &-content {
      position: relative;
      margin-top: ($standard-padding / 2);
      padding-top: ($standard-padding / 2);
      border-top: 1px solid $color-light-gray;
      cursor: pointer;
      padding-left: $store-brand-image-width + $store-brand-image-margin; // to accommodate for store name/brand images on left side
      @include breakpoint(($medium-only) (orientation portrait)) {
        padding-left: 0px;
      }
      .detail-view &,
      .store-locator__my-store & {
        margin-top: ($standard-padding / 2);
        padding-top: ($standard-padding / 2);
        cursor: auto;
      }
    }
    &:first-child &-content {
      margin-top: 0;
      padding-top: 0;
      border: none;
      .detail-view & {
        margin-top: ($standard-padding / 2);
        padding-top: ($standard-padding / 2);
        border-top: 1px solid $color-light-gray;
      }
    }
    &-back-button {
      @include link;
      margin-top: 5px;
      cursor: pointer;
      @include icon('arrow--right', before) {
        font-size: 0.65em;
        margin-right: 0.6em;
        transform: rotate(180deg);
        vertical-align: baseline;
      }
      display: none;
      .detail-view & {
        display: block;
      }
    }
    &-set-favorite {
      @include box-shadow(0 0 0 0 $color-white);
      font-size: 1.25em;
      background-color: $color-white;
      color: $color-black;
      border: 1px solid $color-black;
      padding-top: 2px;
      margin-bottom: ($standard-padding / 2);
      .set-text {
        display: none;
      }
      .store-locator__my-store &,
      .my-store & {
        background-color: $color-black;
        color: $color-white;
        .set-text {
          display: inline;
        }
        .not-set-text {
          display: none;
        }
      }
    }
    &[data-store-id='ORIGINS'],
    &[data-store-id="MACY'S"],
    &[data-store-id="DILLARD'S"],
    &[data-store-id='BELK'],
    &[data-store-id='SEPHORA'],
    &[data-store-id='ULTA BEAUTY'],
    &[data-store-id='BEAUTY BRANDS'] {
      .store_name .marker {
        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }
    &-address {
      position: relative;
      &-line,
      &-city-state-zip-line {
        width: 75%;
      }
    }
    &-directions {
      margin-top: ($standard-padding / 2);
      &-link {
        @include link;
        @include icon('arrow--right', after) {
          font-size: 0.65em;
          margin-left: 0.6em;
          vertical-align: baseline;
        }
      }
    }
    &-hours {
      margin-bottom: ($standard-padding / 2);

      // Temporarily NOT displaying the "Hours" link, and simply displaying the hours content, despite how long
      // it gets. To revert to previous behavior, simply remove the link's display: none and the content's
      // display: block, and remove content's commented out "display: none" to revert this to previous behavior.
      &-link {
        @include link;
        display: none;
      }
      &-content {
        //display: none;
        display: block;
      }
      &.expanded &-content {
        display: block;
      }
    }
    &-actions {
      margin-top: ($standard-padding / 2);
      display: none;
      .detail-view & {
        display: block;
      }
    }
  }
  &__result-location {
  }
  &--desktop &__result-location {
    display: none;
  }
  &__result {
    .result_number {
      display: none;
    }
    .distance {
      position: absolute;
      top: 0;
      right: 0;
      width: 25%;
      text-align: right;
      @include breakpoint(($medium-only) (orientation portrait)) {
        position: relative;
        width: auto;
        text-align: left;
      }
    }
    &-name {
      position: absolute;
      left: 0;
      min-height: 70px;
      margin-right: $store-brand-image-margin;
      width: $store-brand-image-width;
      @include breakpoint(($medium-only) (orientation portrait)) {
        position: relative;
      }
    }
    &-sub-name {
      font-family: $font--futura-medium;
    }
    &[data-store-id='ORIGINS'] &-name,
    &[data-store-id="MACY'S"] &-name,
    &[data-store-id="DILLARD'S"] &-name,
    &[data-store-id='BELK'] &-name,
    &[data-store-id='SEPHORA'] &-name,
    &[data-store-id='ULTA BEAUTY'] &-name,
    &[data-store-id='BEAUTY BRANDS'] &-name {
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
    }
    &[data-store-id='ORIGINS'] &-name {
      background-image: url('/media/images/locator/origins.png');
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/origins.png', sizingMethod='scale');
      -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/origins.png', sizingMethod='scale')";
    }
    &[data-store-id="MACY'S"] &-name {
      background-image: url('/media/images/locator/macys.png');
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/macys.png', sizingMethod='scale');
      -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/macys.png', sizingMethod='scale')";
    }
    &[data-store-id="DILLARD'S"] &-name {
      background-image: url('/media/images/locator/dillards.png');
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/dillards.png', sizingMethod='scale');
      -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/dillards.png', sizingMethod='scale')";
    }
    &[data-store-id='BELK'] &-name {
      background-image: url('/media/images/locator/belk.png');
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/belk.png', sizingMethod='scale');
      -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/belk.png', sizingMethod='scale')";
    }
    &[data-store-id='SEPHORA'] &-name {
      background-image: url('/media/images/locator/sephora.png');
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/sephora.png', sizingMethod='scale');
      -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/sephora.png', sizingMethod='scale')";
    }
    &[data-store-id='ULTA BEAUTY'] &-name {
      background-image: url('/media/images/locator/ultabeauty.png');
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/ultabeauty.png', sizingMethod='scale');
      -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/ultabeauty.png', sizingMethod='scale')";
    }
    &[data-store-id='BEAUTY BRANDS'] &-name {
      background-image: url('/media/images/locator/beautybrands.png');
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/beautybrands.png', sizingMethod='scale');
      -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/locator/beautybrands.png', sizingMethod='scale')";
    }
  }
  &__tooltip {
    background-color: $color-white;
    border: 1px solid $color-black;
    padding: ($standard-padding / 3);
    position: relative;
    &-close {
      //@include icon("checkbox", before, false, true);
      cursor: pointer;
      position: absolute;
      top: ($standard-padding / 3) - 1;
      right: ($standard-padding / 3);
      &:hover {
        //@include icon("checkbox--checked", before, false, true);
      }
    }
  }
  &__result-events {
    border-bottom: 1px solid $color-light-gray;
    margin-bottom: ($standard-padding / 2);
    padding-bottom: ($standard-padding / 2);
    display: none;
    .detail-view &,
    .store-locator__my-store & {
      display: block;
    }
    &-heading {
      margin: 0;
      font-size: 1.35em;
      margin-bottom: 10px;
      font-family: $font--subheading;
      text-transform: uppercase;
    }
    &-list {
      &__item {
        &-header {
          margin: 0;
          font-size: 1.1em;
          font-family: $font--subheading;
          text-transform: uppercase;
          .no-date & {
            font-size: 1em;
            font-family: $font--text;
            text-transform: none;
          }
        }
        &-date {
          margin-top: 10px;
          .no-date & {
            display: none;
          }
        }
        margin-top: 10px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
  &__result-pin {
    text-align: center;
    font-family: $font--futura-medium;
    cursor: pointer !important;
    &.origins {
    }
    &-image {
      height: 21px;
    }
    &.origins &-image {
      height: 28px;
    }
  }
  &__my-store-saved {
    padding: 3.75em 2em 0;
    text-align: center;
    &-header {
      @include h9;
      margin-top: 0;
      margin-bottom: 0.5em;
      min-height: 0.5em;
      color: $color_green;
    }
    &-copy {
      @include t4;
    }
    &-cta {
    }
  }
}
